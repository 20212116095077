import React from 'react';
import { useCurrentQuestion } from '@lib/web/editor';

import MultipleResponse from './components/MultipleResponse';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorAnswerMultipleResponse() {
  const { questionId } = useCurrentQuestion();
  const { handleSelect, handleBlocksChange } = useEventHandle(questionId);
  const { responseValues, mrqMinCorrectAnswerNum, mrqMaxCorrectAnswerNum } =
    useData(questionId);

  return (
    <MultipleResponse
      responseValues={responseValues}
      onSelect={handleSelect}
      handleBlocksChange={handleBlocksChange}
      mrqMinCorrectAnswerNum={mrqMinCorrectAnswerNum}
      mrqMaxCorrectAnswerNum={mrqMaxCorrectAnswerNum}
    />
  );
}
