import {
  FocusEvent,
  memo,
  MouseEvent,
  TouchEvent,
  useCallback,
  useRef,
  useState,
} from 'react';
import Router from 'next/router';
import {
  ListItemIcon,
  ListItemText,
  MenuItem,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Box from '@mui/material/Box';
import { alpha } from '@mui/material/styles';
import { FloatingMenu, ResponsiveMenu, SquareAvatar } from '@front/ui';
import EmojiText from '@lib/ia/src/components/EmojiMessage';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { isNil } from 'lodash';

import Card from '../../components/Card';
import Icon from '../../components/Icon';

import CardLayoutTooltip from './CardLayoutTooltip/CardLayoutTooltip';
import {
  CardCornerAvatarEvent,
  CardLayoutConfig,
  CardLayoutItem,
  CardLayoutItemEvent,
  CardLayoutItemStatus,
} from './types';

const styles = {
  root: {
    display: 'grid',
    gap: 3,
  },
  cardContainer: {
    display: 'grid',
    gap: 2.5,
  },
  groupTitle: {
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    mb: 2,
  },
};

const getGridTemplateColumns = (columns: number[]) => {
  return columns.map((column) => `repeat(${column}, minmax(0, 1fr))`);
};

type CornerAvatarProps = {
  item: CardLayoutItem;
  avatar?: string;
  avatarText?: string;
  eventMap?: CardLayoutItem['cornerAvatarEventMap'];
};

function CornerAvatar({
  item,
  avatar,
  avatarText,
  eventMap,
}: CornerAvatarProps) {
  const { getIaAction } = useIaAction();

  if (isNil(avatar) && isNil(avatarText)) return null;

  const handleOnMouseEnter = (ev: MouseEvent) => {
    if (!eventMap || !eventMap.onMouseEnter) return;
    const onMouseEnter = getIaAction<CardCornerAvatarEvent>(
      eventMap.onMouseEnter.value
    );
    onMouseEnter?.action?.({
      target: item,
      anchorEl: ev.currentTarget,
    });
  };

  const handleOnMouseLeave = (ev: MouseEvent) => {
    if (!eventMap || !eventMap.onMouseLeave) return;
    const onMouseLeave = getIaAction<CardCornerAvatarEvent>(
      eventMap.onMouseLeave.value
    );
    onMouseLeave?.action?.({
      target: item,
      anchorEl: ev.currentTarget,
    });
  };

  const handleOnBlur = (ev: FocusEvent) => {
    if (!eventMap || !eventMap.onBlur) return;
    const onBlur = getIaAction<CardCornerAvatarEvent>(eventMap.onBlur.value);
    onBlur?.action?.({
      target: item,
      anchorEl: ev.currentTarget,
    });
  };

  return (
    <Box
      sx={[!!eventMap && { zIndex: 5 }]}
      onMouseEnter={handleOnMouseEnter}
      onMouseLeave={handleOnMouseLeave}
      onBlur={handleOnBlur}
      className="card-corner-avatar"
    >
      <SquareAvatar src={avatar} size={16}>
        {avatarText}
      </SquareAvatar>
    </Box>
  );
}

const CardItem = memo(function CardItem({
  item,
  selected,
  settings,
  onMouseEnter,
  onClick,
  onLongPress,
}: {
  item: CardLayoutItem;
  selected?: boolean;
  settings: CardLayoutConfig['settings'];
  onMouseEnter: (event: MouseEvent, item: CardLayoutItem) => void;
  onClick: (item: CardLayoutItem) => void;
  onLongPress: (
    ev: MouseEvent<HTMLButtonElement> | TouchEvent<HTMLButtonElement>,
    item: CardLayoutItem
  ) => void;
}) {
  const { getIaAction } = useIaAction();
  const getErrorActions = useCallback(
    (actions?: CardLayoutItemEvent[]) => {
      if (!actions) return;
      return actions.map((action) => {
        let onClickFunc;
        if (action.type === 'link') {
          onClickFunc = () => Router.push(action.value);
        } else {
          const onClickAction = getIaAction(action.value);
          onClickFunc = () => onClickAction?.action?.();
        }
        return {
          icon: action.icon || '',
          text: action.text || '',
          onClick: onClickFunc,
        };
      });
    },
    [getIaAction]
  );

  return (
    <Card
      key={item.id}
      avatar={item.avatar}
      subAvatars={item.subAvatars}
      subAvatarsCount={item.subAvatarsCount}
      cornerComponent={
        <CornerAvatar
          item={item}
          avatar={item.cornerAvatar}
          avatarText={item.cornerAvatarText}
          eventMap={item.cornerAvatarEventMap}
        />
      }
      title={item.title}
      titleSuffixIcon={item.titleSuffixIcon}
      description={item.description}
      properties={item.properties}
      tags={item.tags}
      link={item.action?.type === 'link' ? item.action.value : undefined}
      onClick={() => onClick(item)}
      onMouseEnter={(ev) => onMouseEnter(ev, item)}
      onLongPress={(ev) => onLongPress(ev, item)}
      selected={selected}
      avatarSize={settings.avatarSize}
      avatarBlackAndWhite={settings.avatarBlackAndWhite}
      avatarShowStacked={settings.avatarShowStacked}
      subAvatarSize={settings.subAvatarSize}
      maxSubAvatars={settings.maxSubAvatars}
      disabled={item.status === CardLayoutItemStatus.Disabled}
      descriptionLines={settings.descriptionLines}
      optimizeRenderEnabled={settings.optimizeRenderEnabled}
      {...(item.type === 'action'
        ? {
            type: 'action',
            actionSettings: {
              state: item.actionSettings?.state,
              errorMessage: item.actionSettings?.errorMessage,
              errorActions: getErrorActions(item.actionSettings?.errorActions),
            },
          }
        : { type: 'default' })}
    >
      {item.tooltip && <CardLayoutTooltip {...item.tooltip} />}
    </Card>
  );
});

type CardLayoutProps = Omit<CardLayoutConfig, 'layout'>;

export default function CardLayout({
  settings,
  items,
  groups,
}: CardLayoutProps) {
  const gridTemplateColumns = getGridTemplateColumns(settings.columns);
  const [moreAnchorEl, setMoreAnchorEl] = useState<Element | null>(null);
  const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [focusedItem, setFocusedItem] = useState<CardLayoutItem | null>(null);
  const menuRef = useRef<Element | null>(null);
  const boxRef = useRef<Element | null>(null);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const { getIaAction } = useIaAction();

  const showMenu = (event: MouseEvent | TouchEvent) => {
    setMenuAnchorEl(event.target as HTMLElement);
    setMenuOpen(true);
  };

  const handleMouseEnter = useCallback(
    (event: MouseEvent, item: CardLayoutItem) => {
      if (!item.events?.length || !mdUp) {
        setMoreAnchorEl(null);

        return;
      }

      const currentTarget = event.currentTarget;
      setTimeout(() => {
        setMoreAnchorEl(currentTarget);
        setFocusedItem(item);
      }, 0);
    },
    [mdUp]
  );

  const handleLayoutMouseLeave = () => {
    setMoreAnchorEl(null);
  };

  const handleLayoutMouseMove = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      if (!moreAnchorEl || !mdUp) return;
      if (
        event.target instanceof HTMLDivElement &&
        !menuRef.current?.contains(event.target) &&
        !moreAnchorEl?.contains(event.target)
      ) {
        setMoreAnchorEl(null);
      }
    },
    [mdUp, moreAnchorEl]
  );

  const handleMobileCardPress = useCallback(
    (event: MouseEvent | TouchEvent, item: CardLayoutItem) => {
      if (!item.events?.length || mdUp) {
        return;
      }

      setFocusedItem(item);
      showMenu(event);
    },
    [mdUp]
  );

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const handleMenuClick = useCallback(
    (item: CardLayoutItem, event: CardLayoutItemEvent) => {
      if (event.type === 'link') {
        Router.push(event.value);
      } else {
        const onClickAction = getIaAction<CardLayoutItem>(event.value);

        onClickAction?.action?.(item);
      }
      setMenuOpen(false);
    },
    [getIaAction]
  );

  const handleCardClick = useCallback(
    (item: CardLayoutItem) => {
      if (!item.action || item.action.type === 'link') {
        return;
      }

      const onClickAction = getIaAction<CardLayoutItem>(item.action.value);
      onClickAction?.action?.(item);
    },
    [getIaAction]
  );

  const isSelected = useCallback(
    (item: CardLayoutItem) => {
      return (
        (!mdUp && menuOpen && focusedItem?.id === item.id) || item.selected
      );
    },
    [focusedItem?.id, mdUp, menuOpen]
  );

  const isEmpty =
    items?.length === 0 || groups?.every((group) => group.items.length === 0);

  return (
    <Box
      ref={boxRef}
      sx={styles.root}
      onMouseLeave={handleLayoutMouseLeave}
      onMouseMove={handleLayoutMouseMove}
    >
      {items && (
        <Box sx={[styles.cardContainer, { gridTemplateColumns }]}>
          {items?.map((item) => (
            <CardItem
              key={item.id}
              item={item}
              selected={isSelected(item)}
              settings={settings}
              onMouseEnter={handleMouseEnter}
              onLongPress={handleMobileCardPress}
              onClick={handleCardClick}
            />
          ))}
        </Box>
      )}

      {groups?.map((group) => (
        <Box key={group.id}>
          <Typography sx={styles.groupTitle}>{group.title}</Typography>
          <Box sx={[styles.cardContainer, { gridTemplateColumns }]}>
            {group.items.map((item) => (
              <CardItem
                key={item.id}
                item={item}
                selected={isSelected(item)}
                settings={settings}
                onMouseEnter={handleMouseEnter}
                onLongPress={handleMobileCardPress}
                onClick={handleCardClick}
              />
            ))}
          </Box>
        </Box>
      ))}

      {settings?.emptyText && isEmpty && (
        <EmojiText text={settings.emptyText} icon={settings.emptyIcon} />
      )}

      <FloatingMenu
        ref={menuRef}
        open={Boolean(moreAnchorEl)}
        anchorEl={moreAnchorEl}
        placement="left-start"
        modifiers={[
          {
            name: 'preventOverflow',
            enabled: true,
            options: {
              altAxis: true,
              altBoundary: true,
              tether: false,
              rootBoundary: 'viewport',
            },
          },
        ]}
      >
        <FloatingMenu.Button onClick={showMenu} selected={menuOpen}>
          <Icon name="ActionMore" />
        </FloatingMenu.Button>
      </FloatingMenu>
      {!!focusedItem?.events && !!menuAnchorEl && (
        <ResponsiveMenu
          open={menuOpen}
          onClose={handleMenuClose}
          menuProps={{
            anchorEl: menuAnchorEl,
            anchorOrigin: {
              vertical: 'center',
              horizontal: 'right',
            },
            transformOrigin: {
              vertical: 'center',
              horizontal: 'left',
            },
          }}
          sheetProps={{
            defaultContentHeight: focusedItem.events.length * 45,
          }}
        >
          {focusedItem.events?.map((event) => (
            <MenuItem
              key={`${event.type}-${event.value}`}
              value={event.value}
              onClick={() => handleMenuClick(focusedItem, event)}
            >
              {event.icon && (
                <ListItemIcon>
                  <Icon name={event.icon} width={16} height={16} />
                </ListItemIcon>
              )}
              <ListItemText>{event.text}</ListItemText>
            </MenuItem>
          ))}
        </ResponsiveMenu>
      )}
    </Box>
  );
}
