import { BlockSchema, TipTapNode } from '@blocknote/core';
import { basicComposerSchema } from '@lib/web/composer';
import FileBlock, {
  fileBlockDefaultProps,
} from '@lib/web/thread/ThreadTextComposer/components/blocks/FileBlock';

import ImageBlock, {
  imageBlockDefaultProps,
} from '../components/blocks/ImageBlock';
import PatchBlockContainerEnter from '../components/blocks/PatchBlockContainerEnter';

export const threadComposerSchema = {
  ...basicComposerSchema,
  image: {
    propSchema: imageBlockDefaultProps,
    node: ImageBlock as TipTapNode<'image', false>,
  },
  file: {
    propSchema: fileBlockDefaultProps,
    node: FileBlock as TipTapNode<'file', false>,
  },
  patchBlockContainerEnter: {
    propSchema: {},
    node: PatchBlockContainerEnter as TipTapNode<
      'patch-block-container-enter',
      false
    >,
  },
} satisfies BlockSchema;

export type ThreadComposerSchema = typeof threadComposerSchema;
