import { useMemo } from 'react';
import { useDateFormat } from '@front/helper';
import {
  DisplayTableLayoutConfig,
  DisplayTableLayoutRow,
  DisplayTableLayoutSection,
} from '@lib/ia/src/layouts/DisplayTableLayout/types';
import { useAuth, useIaClubs, useThreadHierarchy } from '@lib/web/apis';
import { useRenderThreadMessageToHtml } from '@lib/web/thread/hooks/message/useRenderThreadMessageToHtml';
import { getChannelHeadThumbnailDetail } from '@lib/web/thread/utils/headThumbnailUtils';
import { getClubIconPath } from '@lib/web/utils';
import { Channel, ChannelData } from 'stream-chat';

import { useChannelInformation } from '../../hooks/channel/useChannelInformation';
import { useThread } from '../../hooks/core/useThread';
import { getChannelLocationDetail } from '../../utils/locationUtils';

const useChannelClub = (channel: Channel) => {
  const { data } = useIaClubs(
    {
      filter: 'clubLogo;clubName;clubSlug',
      search: `clubId:${channel.data?.clubId}`,
      searchFields: 'clubId:eq',
      limit: 1,
    },
    {
      enable: !!channel.data?.clubId,
    }
  );
  return data?.data.items[0];
};

export const useThreadChannelPropertiesLayoutConfig = ({
  channel,
  mode,
}: {
  channel: Channel;
  mode: 'expandableBrief' | 'expandableFull' | 'full';
  expandable?: boolean;
}) => {
  const { displayDatetimeFormat } = useDateFormat();
  const { getChannelInformation } = useChannelInformation();
  const { getThreadUser, getThreadLocationDisplay } = useThread();
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();
  const channelClub = useChannelClub(channel);
  const { userId } = useAuth();

  const { data: hierarchyData } = useThreadHierarchy(
    {
      channelId: channel.id as string,
      channelType: channel.type as 'team' | 'public',
    },
    { enable: !!channel.id && !!channel.type }
  );

  return useMemo(() => {
    const {
      channelCreator,
      firstMessageCreatedAt,
      channelMemberIds,
      channelEngagedMemberIds,
      replies,
      lastMessageCreator,
      lastMessageCreatedAt,
    } = getChannelInformation(channel);
    const channelCreatorUser = getThreadUser(channelCreator);
    const lastMessageCreatorUser = getThreadUser(lastMessageCreator);
    const locationDetail =
      channel.data && getChannelLocationDetail(channel.data as ChannelData);
    const locationDisplay = getThreadLocationDisplay(locationDetail);
    const headThumbnailDetail =
      channel.data &&
      getChannelHeadThumbnailDetail(channel.data as ChannelData);

    const channelCreatorUserId =
      channelCreatorUser?.type === 'user' ? channelCreatorUser.userId : '';
    const lastMessageCreatorUserId =
      lastMessageCreatorUser?.type === 'user'
        ? lastMessageCreatorUser.userId
        : '';
    const isPublicThread = channel.type === 'public';

    const rowCreatedBy: DisplayTableLayoutRow = {
      id: 'createdBy',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
          text: 'Created By',
        },
        value: {
          type: 'avatarText',
          avatar: channelCreatorUser?.image,
          text: channelCreatorUser?.name,
          clickAction: 'openMemberProfile',
          userId: channelCreatorUserId,
          meId: userId,
          metadata: {
            userId: channelCreatorUserId,
          },
        },
      },
      state: 'active',
    };

    const rowCreatedTime: DisplayTableLayoutRow = {
      id: 'createdTime',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeDateOrTime',
          },
          text: 'Created Time',
        },
        value: {
          type: 'text',
          text: displayDatetimeFormat(firstMessageCreatedAt),
        },
      },
    };

    const rowVisibility: DisplayTableLayoutRow = {
      id: 'visibility',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'LoginSee',
          },
          text: 'Visibility',
        },
        value: {
          type: 'tag',
          text: isPublicThread ? 'Public' : 'Private',
        },
      },
    };
    const rowMembers: DisplayTableLayoutRow = {
      id: 'members',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PrivacyFriends',
          },
          text: 'Members',
        },
        value: isPublicThread
          ? {
              type: 'tag',
              icon: {
                type: 'icon',
                value: 'TestRanking',
              },
              text: 'Everyone',
            }
          : {
              type: 'avatarGroup',
              avatars: channelMemberIds.map((memberId) => {
                const memberUser = getThreadUser(memberId);
                return {
                  name: memberUser?.name || '',
                  avatarUrl: memberUser?.image || '',
                };
              }),
              maxDisplayNumber: 5,
              clickAction: 'openMembers',
            },
      },
    };
    const rowActiveMembers: DisplayTableLayoutRow = {
      id: 'activeMembers',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PrivacyFriends',
          },
          text: 'Active Members',
        },
        value: {
          type: 'avatarGroup',
          avatars: channelEngagedMemberIds.map((memberId) => {
            const memberUser = getThreadUser(memberId);
            return {
              name: memberUser?.name || '',
              avatarUrl: memberUser?.image || '',
            };
          }),
          maxDisplayNumber: 5,
          clickAction: 'openActiveMembers',
        },
      },
    };
    const rowReplies: DisplayTableLayoutRow = {
      id: 'replies',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ThreadsThread',
          },
          text: 'Replies',
        },
        value: {
          type: 'text',
          text: replies.toString(),
        },
      },
    };
    const rowLastReply: DisplayTableLayoutRow = {
      id: 'lastReply',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainProfileSolid',
          },
          text: 'Last Reply',
        },
        value:
          replies > 0
            ? {
                type: 'avatarText',
                avatar: lastMessageCreatorUser?.image,
                text: lastMessageCreatorUser?.name,
                clickAction: 'openMemberProfile',
                userId: lastMessageCreatorUserId,
                meId: userId,
                metadata: {
                  userId: lastMessageCreatorUserId,
                },
              }
            : { type: 'emptyText' },
      },
    };
    const rowLastActivity: DisplayTableLayoutRow = {
      id: 'lastActivity',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'PropertyTypeDateOrTime',
          },
          text: 'Last Activity Time',
        },
        value:
          replies > 0
            ? {
                type: 'text',
                text: displayDatetimeFormat(lastMessageCreatedAt),
              }
            : { type: 'emptyText' },
      },
    };
    const rowClub: DisplayTableLayoutRow = {
      id: 'club',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'MainApps',
          },
          text: 'Thread’s Club',
        },
        value: channelClub
          ? {
              type: 'avatarTag',
              avatarUrl:
                channelClub.clubLogo || getClubIconPath(channelClub.clubName),
              text: channelClub.clubName,
              clickAction: 'openClub',
              metadata: {
                clubSlug: channelClub.clubSlug,
              },
            }
          : { type: 'emptyText' },
      },
    };
    const rowLocation: DisplayTableLayoutRow = {
      id: 'location',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'TextEditorLineLocationPoint',
          },
          text: 'Thread’s Location',
        },
        value: locationDisplay
          ? locationDisplay.type === 'icon'
            ? {
                type: 'tag',
                icon: {
                  type: 'icon',
                  value: locationDisplay.icon,
                },
                text: locationDisplay.name,
              }
            : {
                type: 'avatarTag',
                text: locationDisplay.name,
                avatarUrl: locationDisplay.avatarUrl,
              }
          : { type: 'emptyText' },
      },
    };

    const rowQuestionId: DisplayTableLayoutRow = {
      id: 'questionId',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ChatQuestionList',
          },
          text: 'Question ID',
        },
        value:
          channelClub && locationDetail?.shortId
            ? {
                type: 'avatarTag',
                avatarUrl:
                  channelClub.clubLogo || getClubIconPath(channelClub.clubName),
                text: `Question ${locationDetail.shortId}`,
              }
            : { type: 'emptyText' },
      },
    };

    const rowHeadThumbnail: DisplayTableLayoutRow = {
      id: 'headThumbnail',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ChatThread',
          },
          text: 'Head Thumbnail',
        },
        value: {
          type: 'select',
          optionClickAction: 'selectHeadThumbnail',
          subOptionClickAction: 'selectHeadThumbnailSubOption',
          options: headThumbnailDetail?.options || [],
        },
      },
    };

    const rowParentThread: DisplayTableLayoutRow = {
      id: 'parentThread',
      cells: {
        label: {
          type: 'iconText',
          icon: {
            type: 'icon',
            value: 'ThreadsThread',
          },
          text: 'Parent Thread',
        },
        value: hierarchyData?.data.parentChannel
          ? {
              type: 'avatarText',
              avatar: getThreadUser(
                hierarchyData.data.parentChannel.createdById
              )?.image,
              text: `${
                getThreadUser(hierarchyData.data.parentChannel.createdById)
                  ?.name
              }:`,
              emphasisText: true,
              textSuffixHtml: renderThreadMessageToHtml(
                hierarchyData.data.parentChannel.firstMessageText,
                hierarchyData.data.parentChannel.firstMessageCustomBlocks
              ),
              clickAction: hierarchyData.data.parentChannel.hasPermission
                ? 'openChannel'
                : undefined,
              metadata: {
                channelCid: hierarchyData.data.parentChannel.cid,
              },
            }
          : { type: 'emptyText' },
      },
    };

    const rowChildThreads: DisplayTableLayoutRow[] =
      hierarchyData?.data.childChannels.map((childChannel, index) => ({
        id: `childThread:${childChannel.cid}`,
        cells: {
          label:
            index === 0
              ? {
                  type: 'iconText',
                  icon: {
                    type: 'icon',
                    value: 'ThreadsThread',
                  },
                  text: 'Child Thread',
                }
              : {
                  type: 'empty',
                },
          value: {
            type: 'avatarText',
            avatar: getThreadUser(childChannel.firstMessageCreatedById)?.image,
            text: `${
              getThreadUser(childChannel.firstMessageCreatedById)?.name
            }:`,
            emphasisText: true,
            textSuffixHtml: renderThreadMessageToHtml(
              childChannel.firstMessageText,
              childChannel.firstMessageCustomBlocks
            ),
            clickAction: 'openChannel',
            metadata: {
              channelCid: childChannel.cid,
            },
          },
        },
      })) || [];
    const rowChildThreadsWhenEmpty: DisplayTableLayoutRow[] = [
      {
        id: 'childThread',
        cells: {
          label: {
            type: 'iconText',
            icon: {
              type: 'icon',
              value: 'ThreadsThread',
            },
            text: 'Child Thread',
          },
          value: {
            type: 'emptyText',
          },
        },
      },
    ];
    const rowChildThreadSeeAll: DisplayTableLayoutRow = {
      id: 'childThreadSeeAll',
      cells: {
        label: {
          type: 'empty',
        },
        value: {
          type: 'button',
          icon: 'ActionArrowRightUp',
          action: 'seeAllChildThreads',
          text: 'See all',
        },
      },
    };

    const rowHideButton = {
      gridTemplateColumns: '80px 1fr',
      cells: [
        {
          type: 'button',
          action: 'hide',
          text: 'Hide',
          icon: 'ActionChevronUp',
        },
      ],
    };
    const rowShowButton = {
      gridTemplateColumns: '120px 1fr',
      cells: [
        {
          type: 'button',
          action: 'show',
          text: 'See more',
          icon: 'ActionChevronDown',
        },
      ],
    };

    const rows = mode === 'expandableBrief' ? [rowMembers, rowLocation] : [];
    const sections: DisplayTableLayoutSection[] =
      mode === 'expandableFull' || mode === 'full'
        ? [
            {
              id: 'overview',
              title: 'Overview',
              defaultOpen: true,
              rows: [
                rowClub,
                rowLocation,
                rowQuestionId,
                rowVisibility,
                rowHeadThumbnail,
              ],
            },
            {
              id: 'activity',
              title: 'Activity',
              rows: [
                rowCreatedBy,
                rowCreatedTime,
                rowMembers,
                rowActiveMembers,
                rowReplies,
                rowLastReply,
                rowLastActivity,
              ],
            },
            {
              id: 'hierarchy',
              title: 'Hierarchy',
              rows: [
                rowParentThread,
                /**
                 * show the first 4 child threads in expandableFull
                 * show all child threads in full
                 */
                ...(rowChildThreads.length === 0
                  ? rowChildThreadsWhenEmpty
                  : rowChildThreads.slice(0, 4)),
                ...(rowChildThreads.length > 4
                  ? mode === 'expandableFull'
                    ? [rowChildThreadSeeAll]
                    : rowChildThreads.slice(4)
                  : []),
              ],
            },
          ]
        : [];

    const footerRow =
      mode === 'expandableFull'
        ? rowHideButton
        : mode === 'expandableBrief'
        ? rowShowButton
        : undefined;

    return [
      {
        layout: 'display-table-layout',
        table: {
          gridTemplateColumns: {
            xs: 'minmax(0, 1fr) minmax(0, 2fr)',
            md: 'minmax(0, 1fr) minmax(0, 1fr)',
          },
          columnOrder: ['label', 'value'],
          rows,
          sections,
          footerRow,
        },
        settings: {
          rowHeight: 33,
        },
      } as DisplayTableLayoutConfig,
    ];
  }, [
    channel,
    channelClub,
    displayDatetimeFormat,
    getChannelInformation,
    getThreadLocationDisplay,
    getThreadUser,
    hierarchyData,
    mode,
    renderThreadMessageToHtml,
    userId,
  ]);
};
