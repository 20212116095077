import React from 'react';

export default function ChallengeExpired({ ...rest }) {
  return (
    <svg
      width="17"
      height="17"
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.5 11.75C10.433 11.75 12 10.183 12 8.25C12 6.317 10.433 4.75 8.5 4.75C6.567 4.75 5 6.317 5 8.25C5 10.183 6.567 11.75 8.5 11.75ZM8.5 14.25C11.8137 14.25 14.5 11.5637 14.5 8.25C14.5 4.93629 11.8137 2.25 8.5 2.25C5.18629 2.25 2.5 4.93629 2.5 8.25C2.5 11.5637 5.18629 14.25 8.5 14.25Z"
        fill="#6A6A6A"
      />
    </svg>
  );
}
