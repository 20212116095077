import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { PinChatViewSlug } from '../../types/enums/pinChat';
import { generateHook, SwrHelperParams } from '../query';

export type GetIaPinChatResMap = {
  [PinChatViewSlug.PinChatOverlayDefault]: GetIaPinChatOverlayDefaultViewRes;
};

export function patchIaPinChatsQuery<T>(query: IaSearchReq<T>): IaSearchReq<T> {
  let expand: string | undefined = query.expand;

  expand = 'pinChatUsers:pinChatUserId,userId,status';

  return {
    ...query,
    expand,
  };
}

export function useIaPinChats<
  T extends PinChatViewSlug | undefined = undefined
>(query: IaSearchReq<T> = {}, { ...config }: SwrHelperParams = {}) {
  return generateHook<
    T extends PinChatViewSlug
      ? GetIaPinChatResMap[T]
      : GetIaPinChatOverlayDefaultViewRes
  >(
    config.enable === false ? '' : `v2/ia/pin-chats?${getIaQueryString(query)}`,
    {
      paginate: true,
      auth: true,
      errorRetryCount: 0,
      immutable: true,
      ...config,
    }
  );
}
