import { ReactNode } from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import MuiPopper, { PopperProps as MuiPopperProps } from '@mui/material/Popper';

import Scrollbar from '../../atoms/Scrollbar';

const styles = {
  popperContent: {
    minWidth: 265,
    py: '6px',
    display: 'flex',
    flexDirection: 'column',
    bgcolor: 'background.menu',
    color: 'text.primary',
    boxShadow: 9,
    backdropFilter: 'blur(50px)',
    borderRadius: 1,
    border: (theme: Theme) =>
      theme.palette.mode === 'light'
        ? '1px solid rgba(8, 8, 8, 0.05)'
        : '1px solid rgba(255, 255, 255, 0.1)',
  },
  scroll: {
    maxHeight: 350,
  },
};

export type PopperProps = Omit<MuiPopperProps, 'children'> & {
  children: ReactNode;
};

export default function Popper({ children, ...rest }: PopperProps) {
  return (
    <MuiPopper transition {...rest}>
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <Box className="popper-content" sx={styles.popperContent}>
            <Scrollbar sx={styles.scroll}>{children}</Scrollbar>
          </Box>
        </Fade>
      )}
    </MuiPopper>
  );
}
