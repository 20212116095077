import { FC, useContext, useEffect, useMemo, useRef, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { SystemStyleObject } from '@mui/system';
import {
  BlockNoteEditor,
  BlockNoteEditorOptions,
  BlockSchema,
} from '@blocknote/core';
import {
  BlockNoteView,
  FormattingToolbarProps,
  useBlockNote,
} from '@blocknote/react';
import { useToggleSideMenu } from '@front/helper';
import { basicBlockEditingStyles } from '@lib/web/composer/TextComposer/config/basicComposerBlockStyles';
import { basicSlashMenuItems } from '@lib/web/composer/TextComposer/config/basicSlashMenuItems';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import {
  filterComposerBlocksByExtensionsAndToolbars,
  proseMirrorNodeToComposerBlocks,
} from '@lib/web/composer/utils/basicBlockFormatter';
import { Extensions } from '@tiptap/core';
import { isEqual } from 'lodash';
import { EditorView } from 'prosemirror-view';

import { InlineMention } from './components/blocks/InlineMention';
import BlockSelection from './components/BlockSelection';
import FormattingToolbar from './components/FormattingToolbar';
import FormattingToolbarPositioner from './components/FormattingToolbarPositioner';
import { SideMenu, SideMenuPositioner } from './components/SideMenu';
import SlashMenu from './components/SlashMenu';
import SlashMenuPositioner from './components/SlashMenuPositioner';
import { SuggestMenuItem } from './components/SuggestionMenu/SuggestionMenu';
import SuggestionMenuController from './components/SuggestionMenu/SuggestionMenuController';
import { PreventPressTabSink } from './extensions/preventPressTabSink';
import {
  useCustomProseMirrorPlugins,
  useFileDroppableDropCursor,
} from './hooks';
import {
  ComposerBlock,
  ComposerSlashMenuItem,
  ComposerToolbarMenuItem,
} from './types';

const styles = {
  root: {
    position: 'relative',
    '& .ProseMirror': {
      fontFamily: 'unset',
      px: 0,
      bgcolor: 'transparent',
    },
  },
};

export type TextComposerProps<
  T extends BlockSchema,
  M = Record<string, any>
> = Partial<Omit<BlockNoteEditorOptions<T>, 'theme' | 'initialContent'>> & {
  blockSchema?: T;
  slashMenuItems?: ComposerSlashMenuItem<any>[]; // XXX: dont use 'any'
  toolbarItems?: ComposerToolbarMenuItem<any>[]; // XXX: dont use 'any'
  disabledBlockTypes?: string[];
  sx?: BoxProps['sx'];
  blockStyles?: (
    | SystemStyleObject<Theme>
    | ((theme: Theme) => SystemStyleObject<Theme>)
  )[];
  sideMenuPositionerSx?: BoxProps['sx'];
  defaultBlocks?: ComposerBlock[];
  mainEditor?: boolean;
  onBlocksChange: (blocks: ComposerBlock[]) => void;
  placeholder?: string;
  disabled?: boolean;
  onAddBlock?: (block: ComposerSlashMenuItem<T>) => void;
  onConvertBlockType?: (type: string) => void;
  onKeyDown?: (event: KeyboardEvent) => void;
  onSlashMenuShowHide?: (show: boolean) => void;
  onMentionQueryChange?: (text: string | null) => void;
  customTiptapExtensions?: Extensions;
  autoFocus?: boolean;
  mentionItems?: SuggestMenuItem<M>[];
  validateDroppableFile?: (editor: BlockNoteEditor<T>) => boolean;
};

export default function TextComposer<
  T extends BlockSchema,
  M = Record<string, any>
>({
  blockSchema,
  slashMenuItems,
  toolbarItems,
  disabledBlockTypes = [],
  sx,
  blockStyles = basicBlockEditingStyles,
  sideMenuPositionerSx,
  defaultBlocks,
  onBlocksChange,
  mainEditor,
  placeholder = 'Write here or press ‘/’ for commands...',
  disabled,
  onAddBlock,
  onConvertBlockType,
  onKeyDown,
  onSlashMenuShowHide,
  onMentionQueryChange,
  customTiptapExtensions = [],
  autoFocus,
  mentionItems,
  validateDroppableFile,
  ...props
}: TextComposerProps<T, M>) {
  const { setEditor, setCommands } = useContext(TextComposerContext);
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const [loaded, setLoaded] = useState(false);

  const onBlocksChangeRef = useRef(onBlocksChange);
  onBlocksChangeRef.current = onBlocksChange; // useBlockNote callback will not change after init, we need to make sure it is always latest

  const onKeyDownRef = useRef(onKeyDown);
  onKeyDownRef.current = onKeyDown;

  const editor = useBlockNote({
    editable: !disabled,
    blockSchema,
    slashMenuItems: slashMenuItems ?? basicSlashMenuItems,
    domAttributes: {
      blockContent: { class: 'block-content' },
      inlineContent: { class: 'inline-content' },
    },

    defaultStyles: false,
    onEditorContentChange: (blockNoteEditor) => {
      if (!blockNoteEditor._tiptapEditor.state.doc.firstChild) {
        return;
      }

      onBlocksChangeRef.current(
        proseMirrorNodeToComposerBlocks(
          blockNoteEditor._tiptapEditor.state.doc.firstChild
        )
      );
    },
    _tiptapOptions: {
      editorProps: {
        handleKeyDown(view: EditorView, event: KeyboardEvent): boolean | void {
          onKeyDownRef.current?.(event);
          return undefined; // return undefined to keep default behavior
        },
      },
      extensions: [...customTiptapExtensions, PreventPressTabSink],
    },
    ...props,
  });

  useCustomProseMirrorPlugins(editor);
  useFileDroppableDropCursor(editor, validateDroppableFile);

  const { showSideMenu, setShowSideMenu } = useToggleSideMenu(
    editor.domElement
  );

  useEffect(() => {
    if (!defaultBlocks) {
      return;
    }
    if (defaultBlocks.length > 0) {
      // flushSync was called from inside a lifecycle method, use setTimeout to prevent this
      setTimeout(() => {
        editor._tiptapEditor.chain().setContent(defaultBlocks).run();
      });
    }
    setLoaded(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (loaded) {
      if (!editor._tiptapEditor.state.doc.firstChild) {
        return;
      }
      const oriBlocks = proseMirrorNodeToComposerBlocks(
        editor._tiptapEditor.state.doc.firstChild
      );

      const updatedBlocks = filterComposerBlocksByExtensionsAndToolbars(
        oriBlocks,
        disabledBlockTypes
      );

      if (!isEqual(oriBlocks, updatedBlocks)) {
        setTimeout(() => {
          editor._tiptapEditor.chain().setContent(updatedBlocks).run();

          onBlocksChange(updatedBlocks);
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    toolbarItems,
    slashMenuItems,
    loaded,
    editor._tiptapEditor.state.doc.firstChild,
  ]);

  useEffect(() => {
    setEditor(editor);
    setCommands({
      insertContent: (text: string) => {
        const { state, dispatch } = editor._tiptapEditor.view;
        const { from, to } = state.selection;

        const transaction =
          from !== to
            ? state.tr.insertText(text, from, to)
            : state.tr.insertText(text, from);

        dispatch(transaction);

        editor._tiptapEditor.view.focus();
      },
      clearContent: () => {
        editor._tiptapEditor.chain().clearContent().run();
      },
      focus: () => {
        editor._tiptapEditor.chain().focus().run();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const FormattingToolbarWrap: FC<FormattingToolbarProps<T>> = useMemo(
    () =>
      ({ ...rest }) =>
        <FormattingToolbar {...rest} toolbarItems={toolbarItems} />,
    [toolbarItems]
  );

  useEffect(() => {
    if (autoFocus && loaded) {
      // XXX: sometimes the composer ui is not ready yet, so we use setTimeout to wait for it
      setTimeout(() => {
        editor._tiptapEditor.chain().focus().run();
      });
    }
  }, [autoFocus, editor._tiptapEditor, loaded]);

  useEffect(() => {
    if (disabled && editor._tiptapEditor.options.editable) {
      editor._tiptapEditor.setEditable(false);
    }
    if (!disabled && !editor._tiptapEditor.options.editable) {
      editor._tiptapEditor.setEditable(true);
    }
  }, [disabled, editor._tiptapEditor]);

  if (!loaded) {
    return null;
  }

  return (
    <Box
      sx={[
        styles.root,
        ...blockStyles,
        ...sxProps,
        {
          '--placeholder': `"${placeholder}"`,
        },
      ]}
      onMouseEnter={() => setShowSideMenu(true)}
      onMouseLeave={() => setShowSideMenu(false)}
    >
      <BlockNoteView editor={editor} theme="dark">
        {!disabled && (
          <>
            <FormattingToolbarPositioner
              editor={editor}
              formattingToolbar={FormattingToolbarWrap}
              onConvertBlockType={onConvertBlockType}
            />
            <SlashMenuPositioner<T>
              editor={editor}
              slashMenu={SlashMenu}
              onAddBlock={onAddBlock}
              onShowHide={onSlashMenuShowHide}
            />
            <SideMenuPositioner
              sx={sideMenuPositionerSx}
              active={showSideMenu}
              editor={editor}
              sideMenu={SideMenu}
            />
          </>
        )}
        {customTiptapExtensions.some(
          (extension) => extension.type === InlineMention.type
        ) && (
          <SuggestionMenuController
            editor={editor}
            items={mentionItems}
            onQueryChange={onMentionQueryChange}
          />
        )}
      </BlockNoteView>
      <BlockSelection />
    </Box>
  );
}
