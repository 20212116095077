// eslint-disable-next-line import/prefer-default-export
export const enum MarkGroupEnum {
  PerformanceMistake = 10,
  PerformanceOvertime = 11,
  PerformanceOutOfPractice = 12,

  EmojiSmileysAndPeople = 20,
  EmojiAnimalsAndNature = 21,
  EmojiFoodAndDrink = 22,
  EmojiActivity = 23,
  EmojiTravelAndPlaces = 24,
  EmojiObjects = 25,
  EmojiSymbols = 26,
  EmojiFlags = 27,

  BookmarkQuiz = 30,
  BookmarkQuizQuestionSet = 31,
  BookmarkQuizQuestion = 32,
}

export const enum PracticedStatus {
  Practiced = 1,
  NonPracticed = -1,
}

export const enum MarkStatus {
  Enabled = 1,
  Disabled = -1,
}

export const enum MarkType {
  PerformanceMistake = 10,
  PerformanceOvertime = 11,
  PerformanceOutOfPractice = 12,
  PerformanceCorrect = 13,
  EmojiSmileysAndPeople = 20,
  EmojiAnimalsAndNature = 21,
  EmojiFoodAndDrink = 22,
  EmojiActivity = 23,
  EmojiTravelAndPlaces = 24,
  EmojiObjects = 25,
  EmojiSymbols = 26,
  EmojiFlags = 27,
  BookmarkQuiz = 30,
  BookmarkQuizQuestionSet = 31,
  BookmarkQuizQuestion = 32,
  Hashtag = 40,
  HashtagUserPracticed = 41,
  HashtagUserSingleTagQuiz = 42,
}

export const enum MarkViewSlug {
  IconRhsIAMarkDefault = 'icon_rhs_ia-mark_default',
}
