import React, { HTMLAttributes, useCallback } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { testIdConfig } from '@front/config';
import { TextForm } from '@front/ui';
import { useWordLimit, WordLimitMax } from '@lib/web/hooks';

export type FreeResponseInputProps = {
  value: string | null;
  wordLimitMax?: WordLimitMax;
  onChange?: (value: string) => void;
  isMathematical?: boolean;
  disabled?: boolean;
  inputMode?: HTMLAttributes<
    HTMLInputElement | HTMLTextAreaElement
  >['inputMode'];
};

const styles = {
  mathematicalTextFormInput: {
    '& textarea': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      fontFamily: "'Inconsolata', monospace",
    },
  },
  activeText: {
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),

    span: {
      color: 'text.primary',
    },
  },
};
export default function FreeResponseInput({
  value,
  wordLimitMax,
  onChange,
  isMathematical,
  disabled,
  inputMode,
}: FreeResponseInputProps) {
  const { handleChange, handlePaste } = useWordLimit({
    wordLimitMax,
    onChange,
  });

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (event.key === 'Enter' && !event.shiftKey) {
        // prevent normal enter to break line
        event.preventDefault();
      }
    },
    []
  );

  let extraTextFormProps = {};

  if (isMathematical) {
    /**
     *  in mathematical mode, we will make each row nowrap,
     *  the component behind TextForm is TextareaAutosize
     *  TextareaAutosize + 'nowrap' will enforce us to set the row by ourselves, otherwise the height is wrong
     */
    const numberOfLines = value?.split('\n').length;
    extraTextFormProps = {
      inputSx: styles.mathematicalTextFormInput,
      minRows: numberOfLines,
    };
  }

  return (
    <Box>
      <TextForm
        value={value}
        onChange={handleChange}
        onPaste={handlePaste}
        placeholder="Enter your answer here"
        multiline
        activeText={
          <Box sx={styles.activeText}>
            <span>Shift ⇧ + enter ↵</span> to make a new line
          </Box>
        }
        onKeyDown={handleKeyDown}
        disabled={disabled}
        inputProps={{
          'data-testid': testIdConfig.freeResponseTextArea,
          inputMode,
        }}
        {...extraTextFormProps}
      />
    </Box>
  );
}
