/* eslint-disable @typescript-eslint/no-explicit-any */
import type { FC, MouseEvent } from 'react';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import {
  createTheme,
  Theme,
  ThemeProvider,
  useTheme,
} from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { format as dateFormat, isValid } from 'date-fns';

import base from '../../themes/base';
import exam from '../../themes/exam';
import BottomSheet from '../BottomSheet';
import { DatePickerTypes, DateValue } from '../DatePicker/types/dateTypes';
import DatePickerPanel, { DatePickerPanelProps } from '../DatePickerPanel';
import TextField, { TextFieldProps } from '../TextField';
import TextForm, { TextFormProps } from '../TextForm';

const getDateString = (
  value: DateValue,
  type: DatePickerTypes,
  format: string
) => {
  if (!value) return '';

  if (Array.isArray(value)) {
    const validValues = value
      .map((date) => {
        const newDate = new Date(date);
        if (isValid(newDate)) return dateFormat(newDate, format);

        return null;
      })
      .filter((date) => !!date);

    if (type === 'range') return validValues.join(' - ');

    return validValues.join(', ');
  }

  const newDate = new Date(value);
  if (isValid(newDate)) return dateFormat(newDate, format);

  return '';
};

type DateTextFieldVariantProps = {
  variant?: 'textField';
} & Omit<TextFieldProps, 'onChange'>;

type DateTextFormVariantProps = {
  variant?: 'textForm';
} & Omit<TextFormProps, 'onChange'>;

type DateVariantProps = DateTextFieldVariantProps | DateTextFormVariantProps;

export type DateTextFieldProps = Pick<
  DatePickerPanelProps,
  | 'initDate'
  | 'minDate'
  | 'maxDate'
  | 'defaultValue'
  | 'renderDayButton'
  | 'type'
  | 'value'
  | 'title'
> &
  DateVariantProps & {
    placeholder?: string;
    format?: string;
    renderTextField?: ({
      value,
      onChange,
    }: {
      value: DateValue;
      onChange: (newValue: any) => void;
    }) => FC;
    onChange: (newValue: any) => void;
    onBlur?: () => void;
    disabledPortal?: boolean;
  };

const styles = {
  paper: {
    borderRadius: 'unset',
    background: 'transparent',
    backgroundImage: 'unset',
    boxShadow: 'unset',
    marginTop: -2, // TextField's helper text height
  },
};

type DesktopDatePickerProps = {
  anchorEl: HTMLInputElement | null;
  onClose: () => void;
  disabledPortal?: boolean;
} & Pick<
  DateTextFieldProps,
  | 'title'
  | 'value'
  | 'type'
  | 'initDate'
  | 'maxDate'
  | 'minDate'
  | 'onChange'
  | 'renderDayButton'
>;

type MobileDatePickerProps = {
  open: boolean;
} & Omit<DesktopDatePickerProps, 'anchorEl' | 'width'>;

const DESKTOP_MIN_WIDTH = 295;

function DesktopDatePicker({
  anchorEl,
  title,
  value,
  type = 'default',
  initDate,
  maxDate,
  minDate,
  renderDayButton,
  onChange,
  onClose,
  disabledPortal,
}: DesktopDatePickerProps) {
  const theme = useTheme();
  const updateTheme = theme.palette.mode === 'dark' ? exam : base;
  const width = Math.max(DESKTOP_MIN_WIDTH, anchorEl?.offsetWidth || 0);

  return (
    <ThemeProvider theme={createTheme(updateTheme)}>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        slotProps={{
          paper: {
            sx: styles.paper,
          },
        }}
        marginThreshold={0}
        disablePortal={disabledPortal}
      >
        <DatePickerPanel
          sx={{ width }}
          title={title}
          value={value}
          type={type}
          initDate={initDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          renderDayButton={renderDayButton}
        />
      </Popover>
    </ThemeProvider>
  );
}

function MobileDatePicker({
  title,
  value,
  type = 'default',
  initDate,
  maxDate,
  minDate,
  renderDayButton,
  onChange,
  onClose,
  open,
}: MobileDatePickerProps) {
  return (
    <BottomSheet
      open={open}
      onClose={onClose}
      defaultHeight={title ? 580 : 500}
      maxHeight={600}
    >
      <Box px={2.5}>
        <DatePickerPanel
          title={title}
          value={value}
          type={type}
          initDate={initDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={onChange}
          styleType="mobile"
          renderDayButton={renderDayButton}
        />
      </Box>
    </BottomSheet>
  );
}

export default function DateTextField({
  title,
  value,
  defaultValue,
  placeholder,
  format = 'MM/dd/yyyy',
  type = 'default',
  initDate,
  maxDate,
  minDate,
  renderDayButton,
  renderTextField,
  onChange,
  onBlur,
  variant = 'textField',
  disabledPortal,
  ...rest
}: DateTextFieldProps) {
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null);

  const [textValue, setTextValue] = useState<string>(
    getDateString(value || defaultValue, type, format)
  );

  useEffect(() => {
    setTextValue(getDateString(value, type, format));
  }, [format, type, value]);

  const handleClick = (ev: MouseEvent<HTMLInputElement>) => {
    setAnchorEl(ev?.currentTarget || null);
  };

  const handleClose = () => {
    setAnchorEl(null);
    onBlur?.();
  };

  const handleChange = (newValue: any) => {
    onChange?.(newValue);
    handleClose();
  };

  const datePickerProps = {
    title,
    value,
    type,
    initDate,
    maxDate,
    minDate,
    renderDayButton,
    onClose: handleClose,
    onChange: handleChange,
  };

  const Component = variant === 'textField' ? TextField : TextForm;

  return (
    <>
      {renderTextField ? (
        renderTextField({ value, onChange })
      ) : (
        <Component
          value={textValue}
          focused={Boolean(anchorEl)}
          onClick={handleClick}
          placeholder={placeholder || format}
          {...rest}
          readOnly
        />
      )}

      {smUp ? (
        <DesktopDatePicker
          anchorEl={anchorEl}
          {...datePickerProps}
          disabledPortal={disabledPortal}
        />
      ) : (
        <MobileDatePicker open={Boolean(anchorEl)} {...datePickerProps} />
      )}
    </>
  );
}
