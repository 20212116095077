import { useCallback, useContext } from 'react';
import { useQueueWorker } from '@front/helper';
import { apis, UpdateCreatorQuestionDetailAction } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';
import { composerBlockToUpdateApiParams } from '@lib/web/editor/EditorTextComposer';
import { useCreatorQuestionDetailData } from '@lib/web/editor/hooks/useCreatorQuestionDetailData';
import { callWithToast } from '@lib/web/utils';

import { useCreatorQuestionListData } from './useCreatorQuestionListData';

const MAX_POST_SIZE_IN_BYTES = 80000; // 100 kb (nodejs default payload limit) - 20kb buffer
const DEBOUNCE_TIME = 500;

const updateCreatorQuestionBlocksByChunk = async (
  componentId: string,
  blocks: ComposerBlock[],
  updateCreatorQuestionDetail: typeof apis.editor.updateCreatorQuestionDetail
) => {
  const toProcessComponents = [
    /**
     * api has the supported action to update each block, but that will make logic more complex,
     * so we always clear all the blocks, then recreate them
     */
    {
      id: componentId,
      action: UpdateCreatorQuestionDetailAction.ClearAllBlock,
      data: {},
    },
    ...blocks.map((block) => ({
      id: componentId,
      action: UpdateCreatorQuestionDetailAction.CreateBlock,
      data: composerBlockToUpdateApiParams(block),
    })),
  ];

  const chunkedRes = [];

  while (toProcessComponents.length > 0) {
    let postSizeQuota = MAX_POST_SIZE_IN_BYTES;
    let i = 0;
    for (i = 0; i < toProcessComponents.length; i++) {
      postSizeQuota -= new Blob([JSON.stringify(toProcessComponents[i])]).size;
      if (postSizeQuota < 0) break;
    }

    const components = toProcessComponents.splice(0, i);
    const [res] = await callWithToast(
      () =>
        updateCreatorQuestionDetail({
          components,
        }),
      {
        showLoading: false,
      }
    );
    chunkedRes.push(res);
  }
  return chunkedRes;
};

const buildOptimisticData = ({
  oldDetailData,
  targetComponentId,
  newBlocks,
}: {
  oldDetailData?: GetCreatorQuestionDetailRes;
  targetComponentId: string;
  newBlocks: ComposerBlock[];
}) => {
  if (!oldDetailData) {
    return null;
  }

  return Object.entries(oldDetailData).reduce(
    (acc, [key, components]) => ({
      ...acc,
      [key]: components.map((component) =>
        component.id === targetComponentId
          ? {
              ...component,
              /**
               *  CreatorQuestionDetailBlock is compatible with UpdateCreatorQuestionDetailBlock,
               *  so we can simply use editorBlockToUpdateApiParams to build optimistic Data
               */
              blocks: newBlocks.map(composerBlockToUpdateApiParams),
            }
          : component
      ),
    }),
    {}
  );
};

export const useCreatorQuestionDetailHandle = (questionId: string) => {
  const { addTask } = useQueueWorker();
  const { reloadQuestions } = useCreatorQuestionListData();

  const { detailData, reloadQuestionDetail } =
    useCreatorQuestionDetailData(questionId);

  const { updateCreatorQuestionDetail } = useContext(
    CreatorQuestionDetailContext
  );

  const handleCreatorQuestionDetailChange = useCallback(
    async (
      componentId: string,
      blocks: ComposerBlock[],
      onUpdated?: () => void
    ) => {
      const finishUpdateTaskPromise = new Promise<void>((resolve, reject) => {
        addTask({
          scope: 'handleCreatorQuestionDetailChange',
          taskKey: componentId,
          task: async () => {
            const chunkedRes = await updateCreatorQuestionBlocksByChunk(
              componentId,
              blocks,
              updateCreatorQuestionDetail
            );

            if (chunkedRes.every(Boolean)) {
              // in editor page, we have many data relies on question list data, so when data update, we need to reload them
              void reloadQuestions();
              onUpdated?.();
              resolve();
              return;
            }
            reject();
          },
          debounceTime: DEBOUNCE_TIME,
          shouldInterrupt: true,
        });
      });

      const optimisticData = buildOptimisticData({
        oldDetailData: detailData,
        newBlocks: blocks,
        targetComponentId: componentId,
      });

      if (!optimisticData) {
        return;
      }

      void reloadQuestionDetail(finishUpdateTaskPromise, {
        optimisticData,
      });
    },
    [
      addTask,
      detailData,
      reloadQuestionDetail,
      reloadQuestions,
      updateCreatorQuestionDetail,
    ]
  );

  return {
    handleCreatorQuestionDetailChange,
  };
};
