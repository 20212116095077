import { Theme, useMediaQuery } from '@mui/material';

import { getToolbarButtonConfig, reorderToolbarButtonItems } from '../configs';
import { ToolbarButtonItem } from '../types';

export default function useToolbarButtons(
  items: Array<boolean | ToolbarButtonItem>
) {
  const isMobile = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down('md')
  );

  const validItems: ToolbarButtonItem[] = [];
  items.forEach((item) => {
    if (item && typeof item !== 'boolean') validItems.push(item);
  });

  reorderToolbarButtonItems(validItems, isMobile);

  const visibleButtons = validItems.filter((item) => {
    if (item.hiddenInMore) return false;
    const config = getToolbarButtonConfig(item.type, item.customConfig);
    return isMobile ? config.visible.mobile : config.visible.desktop;
  });

  const threeDotsButtons = validItems.filter((item) => {
    if (item.hiddenInMore) return true;
    const config = getToolbarButtonConfig(item.type, item.customConfig);
    return isMobile ? !config.visible.mobile : !config.visible.desktop;
  });

  return {
    visibleButtons,
    threeDotsButtons,
  };
}
