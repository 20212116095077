import React from 'react';
import { Skeleton } from '@mui/material';
import { AnswerFormatType } from '@lib/web/apis';
import {
  useCreatorQuestionInfoData,
  useCurrentQuestion,
} from '@lib/web/editor';

import { EditorAnswerEssay } from './components/EditorAnswerEssay';
import { EditorAnswerFreeResponse } from './components/EditorAnswerFreeResponse';
import { EditorAnswerGridIn } from './components/EditorAnswerGridIn';
import { EditorAnswerMultipleChoice } from './components/EditorAnswerMultipleChoice';
import { EditorAnswerMultipleResponse } from './components/EditorAnswerMultipleResponse';
import { EditorAnswerTrueFalseNotGiven } from './components/EditorAnswerTrueFalseNotGiven';
import { EditorAnswerUnscramble } from './components/EditorAnswerUnscramble';

export default function EditorAnswer() {
  const { questionId } = useCurrentQuestion();
  const { infoData, isLoading } = useCreatorQuestionInfoData(questionId);

  if (isLoading || !infoData) {
    return <Skeleton width={200} />;
  }

  switch (infoData.answerFormatType) {
    case AnswerFormatType.MultipleChoice:
      return <EditorAnswerMultipleChoice />;

    case AnswerFormatType.MultipleResponse:
      return <EditorAnswerMultipleResponse />;

    case AnswerFormatType.TrueFalseNotGiven:
      return <EditorAnswerTrueFalseNotGiven />;

    case AnswerFormatType.GridIn:
      return <EditorAnswerGridIn />;

    case AnswerFormatType.FreeResponse:
      return <EditorAnswerFreeResponse />;

    case AnswerFormatType.Unscramble:
      return <EditorAnswerUnscramble />;

    case AnswerFormatType.Essay:
      return <EditorAnswerEssay />;

    default:
      console.error(
        'not supported answer format type',
        infoData.answerFormatType
      );
      return null;
  }
}
