import { ReactNode, useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import BaseLayoutCloseLeftPanel from './components/BaseLayoutCloseLeftPanel';
import BaseLayoutComponent from './components/BaseLayoutComponent';
import BaseLayoutRightPanelContainer from './components/BaseLayoutRightPanelContainer';
import useBaseLayout from './hooks/useBaseLayout';
import config from './config';
import { BaseLayoutProvider } from './contexts';

const contentStyles = {
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
};

const getTransformStyles = (transitionProperty: string) => ({
  transitionDuration: `${config.rightPanelAnimationDurationInMs}ms`,
  transitionTimingFunction: 'ease',
  transitionProperty,
});

const styles = {
  root: {
    ...contentStyles,
    overflow: 'hidden',
    height: '100%',
    width: '100%',
  },
  wrapper: {
    position: 'relative',
    display: 'flex',
    height: '100%',
    width: '100%',
    flex: 1,
  },
  main: {
    ...contentStyles,
    height: '100%',
    flex: 1,
    minWidth: 0,
  },
  mainAnimated: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      ...getTransformStyles('transform'),
    },
    [theme.breakpoints.up('md')]: {
      ...getTransformStyles('padding'),
    },
  }),
  leftPanelMain: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      pl: `${config.leftPanelWidth}px`,
    },
  }),
  leftMenu: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      ...getTransformStyles('transform, opacity, visibility'),
      display: 'flex',
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      zIndex: 1,
      opacity: 0,
      visibility: 'hidden',
      transform: 'translateX(-100%)',
    },
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      display: 'flex',
    },
  }),
  menuOpened: (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translateX(0)',
    },
  }),
  nav: (theme: Theme) => ({
    position: 'relative',
    minWidth: config.navWidth,
    height: '100%',
    borderRight:
      theme.palette.mode === 'dark' ? 'unset' : '1px solid rgba(8, 8, 8, 0.05)',
    zIndex: 3,
  }),
  navHidden: {
    display: 'none',
  },
  subNav: {
    position: 'relative',
    minWidth: config.subNavWidth,
    height: '100%',
    zIndex: 3,
  },
  subNavHidden: {
    display: 'none',
  },
  leftPanel: (theme: Theme) => ({
    minWidth: config.leftPanelWidth,
    boxShadow: theme.palette.mode === 'dark' ? 9 : 'unset',
    [theme.breakpoints.up('md')]: {
      ...getTransformStyles('transform, opacity, visibility'),
      position: 'absolute',
      right: 0,
      top: 0,
      bottom: 0,
      opacity: 0.7,
      zIndex: 2,
      visibility: 'hidden',
      transform: `translate(0%)`,
    },
  }),
  leftPanelHidden: {
    display: 'none',
  },
  leftPanelOpened: (theme: Theme) => ({
    [theme.breakpoints.up('md')]: {
      opacity: 1,
      visibility: 'visible',
      transform: 'translate(100%)',
    },
  }),
};

function getMainTransform(
  mainNavOpened?: boolean,
  leftPanelEmpty?: boolean,
  subNavEmpty?: boolean
) {
  return (theme: Theme) => ({
    [theme.breakpoints.down('md')]: {
      transform: `translateX(${
        (leftPanelEmpty ? 0 : config.leftPanelWidth) +
        (!mainNavOpened ? 0 : config.navWidth) +
        (subNavEmpty ? 0 : config.subNavWidth)
      }px)`,
    },
  });
}

export type BaseLayoutProps = {
  children: ReactNode;
  announcementComponent?: ReactNode;
  navComponent?: ReactNode;
  subNavComponent?: ReactNode;
  leftPanelComponent?: ReactNode;
  rightPanelComponent?: ReactNode;
  leftPanelContainerSx?: BoxProps['sx'];
};

function BaseLayout({
  children,
  announcementComponent,
  navComponent,
  subNavComponent,
  leftPanelComponent,
  rightPanelComponent,
  leftPanelContainerSx,
}: BaseLayoutProps) {
  const { leftPanelOpened, hideLeftPanel, isResizing, mainNavOpened } =
    useBaseLayout();
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const [mainAnimationEnabled, setMainAnimationEnabled] = useState(true);

  const leftPanelContainerSxProps = Array.isArray(leftPanelContainerSx)
    ? leftPanelContainerSx
    : [leftPanelContainerSx];

  const leftPanelEmpty = !leftPanelComponent;
  const subNavEmpty = !subNavComponent;

  /**
   * this logic is to avoid the main container looks flashy/lag
   * (issue: https://www.notion.so/rootdomain/Eng-Only-Discover-Back-button-should-not-be-active-on-first-time-landing-on-desktop-a-club-to-d-bfb7f37222cf44b98bf3125ebef4b2db)
   * the reason: when going from a page without the LHS menu to a page with the LHS menu,
   * the main container will reduce the padding-left from 240px to 0px in 300ms duration.
   * => this logic is to remove animation when navigating between pages with and without the LSH menu.
   */
  useEffect(() => {
    setMainAnimationEnabled(false);
    setTimeout(() => {
      if (!leftPanelEmpty) {
        setMainAnimationEnabled(true);
      }
    }, 350);
  }, [leftPanelEmpty]);

  return (
    <Box sx={styles.root}>
      <BaseLayoutComponent>{announcementComponent}</BaseLayoutComponent>
      <Box sx={styles.wrapper}>
        <Box
          sx={[
            styles.leftMenu,
            (mdUp || leftPanelOpened) && !hideLeftPanel && styles.menuOpened,
          ]}
        >
          <BaseLayoutComponent
            sx={[styles.nav, !mainNavOpened && styles.navHidden]}
          >
            {navComponent}
          </BaseLayoutComponent>
          <BaseLayoutComponent
            sx={[styles.subNav, subNavEmpty && styles.subNavHidden]}
          >
            {subNavComponent}
          </BaseLayoutComponent>
          <BaseLayoutComponent
            sx={[
              styles.leftPanel,
              leftPanelEmpty && styles.leftPanelHidden,
              (lgUp || (mdUp && leftPanelOpened)) &&
                !hideLeftPanel &&
                !leftPanelEmpty &&
                styles.leftPanelOpened,
              ...leftPanelContainerSxProps,
            ]}
          >
            {leftPanelComponent}
          </BaseLayoutComponent>
        </Box>
        <Box
          sx={[
            styles.main,
            mainAnimationEnabled && styles.mainAnimated,

            (lgUp || leftPanelOpened) &&
              !hideLeftPanel &&
              !leftPanelEmpty &&
              styles.leftPanelMain,

            (lgUp || leftPanelOpened) &&
              !hideLeftPanel &&
              getMainTransform(mainNavOpened, leftPanelEmpty, subNavEmpty),

            isResizing && { transition: 'none !important' },
          ]}
          className="base-layout-main"
          data-testid="base-layout-main"
        >
          {children}
          <BaseLayoutCloseLeftPanel />
        </Box>
        <BaseLayoutRightPanelContainer>
          {rightPanelComponent}
        </BaseLayoutRightPanelContainer>
      </Box>
    </Box>
  );
}

export default function BaseLayoutRoot({ ...rest }: BaseLayoutProps) {
  return (
    <BaseLayoutProvider>
      <BaseLayout {...rest} />
    </BaseLayoutProvider>
  );
}
