import { useCallback, useRef } from 'react';
import { useLatestValueRef } from '@front/helper';
import { InfiniteHookResponse } from '@lib/web/apis';

import { useBottomScrolled } from './index';

export default function useInfiniteScroll({
  infiniteHookResponse,
  enabled = true,
}: {
  infiniteHookResponse: Pick<
    InfiniteHookResponse<unknown>,
    'isLoadingMore' | 'isReachingEnd' | 'setSize' | 'error'
  >;
  enabled?: boolean;
}) {
  const { isLoadingMore, isReachingEnd, setSize, error } = infiniteHookResponse;

  const disableLoadMore = useLatestValueRef(
    isLoadingMore || isReachingEnd || !!error || enabled === false
  );

  const scrollRef = useRef<HTMLDivElement | null>(null);

  const loaded = !isLoadingMore;
  const handleScrollBottom = useCallback(() => {
    if (!disableLoadMore.current) {
      void setSize((s) => s + 1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded]);

  useBottomScrolled({
    scrollRef,
    onScrolledBottom: handleScrollBottom,
  });

  return {
    scrollRef,
  };
}
