import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { Autocomplete } from '@mui/material';
import { ActionChevronDown as ActionChevronDownIcon } from '@front/icon';
import { TextField } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import useInputEvent from '../hooks/useInputEvent';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutAutoCompleteItem } from '../types';
import { getCustomValidate, getLabelIcon } from '../utils';

type AutoCompleteInputProps = {
  item: FormLayoutAutoCompleteItem;
};

type ActionPayload = {
  name: string;
  value?: any;
};

export default function AutoCompleteInput({ item }: AutoCompleteInputProps) {
  const { control, getValues } = useFormContext();

  const labelIcon = getLabelIcon(item.type, item.icon);

  const validate = item.customRules
    ? getCustomValidate(item.customRules, { getValues })
    : undefined;

  const {
    field: { onChange, onBlur, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules, validate },
  });
  const { getIaAction } = useIaAction();

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: string) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  const handleKeywordChange = (newValue?: string) => {
    if (item.actionMap?.keywordChange) {
      const onKeywordChangeAction = getIaAction<ActionPayload>(
        item.actionMap.keywordChange.value
      );
      onKeywordChangeAction?.action?.({
        name: item.name,
        value: newValue || '',
      });
    }
  };

  return (
    <Autocomplete
      disablePortal
      value={value}
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onChange={(ev: any, newValue: any) => {
        handleChange(newValue);
      }}
      onBlur={handleBlur}
      onInputChange={(ev, newValue: any) => handleKeywordChange(newValue)}
      isOptionEqualToValue={(option, optionValue) => {
        return optionValue
          ? typeof optionValue === 'string'
            ? option === optionValue || option.value === optionValue
            : option.value === optionValue.value
          : false;
      }}
      getOptionLabel={(option) =>
        typeof option === 'string' || typeof option === 'number'
          ? option
          : option.label
      }
      filterOptions={item.isOptionsAsync ? (options) => options : undefined}
      options={item.options}
      clearOnBlur
      disabled={item.disabled}
      popupIcon={<ActionChevronDownIcon />}
      renderInput={({
        InputProps,
        inputProps,
        InputLabelProps,
        size,
        ...params
      }) => (
        <TextField
          className={`ia-form-layout_dropdown ${item.className || ''}`}
          inputRef={InputProps.ref}
          endAdornment={InputProps.endAdornment}
          startAdornment={InputProps.startAdornment}
          {...params}
          inputProps={inputProps}
          label={item.label}
          labelIcon={labelIcon}
          requiredMark={!!item.rules?.required}
          name={item.name}
          placeholder={item.placeholder}
          error={!!error}
          success={successState.success}
          disabled={item.disabled}
          helperText={error?.message || item.helperText}
          size="sm"
        />
      )}
    />
  );
}
