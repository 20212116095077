export const enum UserViewSlug {
  TableCenterIaUserDefault = 'table_center_ia_user_default',
  TableCenterIaUserInternalAccount = 'table_center_ia_user_internal_account',
}

export const enum UserType {
  GENERAL = 0,
  UNCLAIMED = 1,
  INTERNAL = 2,
}
