import { useContext, useEffect, useRef } from 'react';
import { useBaseRightPanel } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { VariableType } from '@lib/web/apis';
import BlockTag from '@lib/web/composer/BlockTag';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { EditorBlockTypes } from '@lib/web/editor/configs';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';
import { useCurrentQuestion } from '@lib/web/editor/hooks';
import {
  TextComposerPanelKeys,
  TextComposerPanelParams,
} from '@lib/web/editor/TextComposerPanels/panel';
import { useCurrentIaClub } from '@lib/web/hooks';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

export type InlineVariableOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineVariableOptions;
    node: ProseMirrorNode;
  }) => string;
};

type BlockTabButtonProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

type VariableProps = BlockTabButtonProps;

const VARIABLE_TYPE_MAPPING = {
  [VariableType.Text]: {
    title: 'Text Variable',
    icon: 'EditorTextVariable',
  },
  [VariableType.Number]: {
    title: 'Number Variable',
    icon: 'EditorNumberVariable',
  },
  [VariableType.NumberFormula]: {
    title: 'Variable Formula',
    icon: 'TestFormula',
  },
};

const BlockTabButton = ({ node, updateAttributes }: BlockTabButtonProps) => {
  const { rightPanelTarget, rightPanelOpened, getRightParams, openRightPanel } =
    useBaseRightPanel<TextComposerPanelParams>();

  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerVariable
  );

  const tagRef = useRef<HTMLSpanElement | null>(null);
  const { sectionId } = useCurrentIaClub();
  const { questionId } = useCurrentQuestion();

  const { data } = useContext(
    CreatorQuestionDetailContext
  ).useCreatorQuestionVariable({
    sectionId,
    creatorQuestionId: questionId,
    type: node.attrs.type,
  });

  const hasData = !!data;
  const active = rightPanelOpened && rightParams.targetId === node.attrs.id;
  const currentVariable = data?.data.items.find(
    (item) => item.id === node.attrs.variableId
  );

  useEffect(() => {
    if (active && node.attrs.isNewCreated) {
      setTimeout(() => {
        updateAttributes({
          isNewCreated: false,
          defaultName: '',
          defaultContent: '',
        });
      });
    }

    if (hasData && active) {
      const inVariableList = rightParams.page === 'list';
      const variableChanged = rightParams.variableId !== node.attrs.variableId;
      const variableTypeChanged = rightParams.type !== node.attrs.type;

      if (inVariableList && (variableChanged || variableTypeChanged)) {
        setTimeout(() => {
          updateAttributes({
            variableId: rightParams.variableId,
            type: rightParams.type,
          });
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    hasData,
    active,
    rightParams.variableId,
    node.attrs.variableId,
    rightParams.type,
    node.attrs.type,
  ]);

  const handleOpenPanel = () => {
    const baseAttributes = {
      type: node.attrs.type,
      targetId: node.attrs.id,
      variableId: node.attrs.variableId,
    };

    setTimeout(() => {
      const isFromOutsidePanel =
        !!tagRef.current?.closest('.base-right-panel-container') &&
        'keepMounted' in rightParams &&
        rightParams.keepMounted;

      if (node.attrs.defaultName || node.attrs.defaultContent) {
        openRightPanel(TextComposerPanelKeys.TextComposerVariable, {
          ...baseAttributes,
          page: 'form',
          variableId: undefined,
          isNewCreated: true,
          defaultValue: {
            name: node.attrs.defaultName,
            content: node.attrs.defaultContent,
          },
          returnPanel: isFromOutsidePanel ? rightPanelTarget : undefined,
          returnParams: isFromOutsidePanel ? rightParams : undefined,
          onVariableCreated: (variableId: string) => {
            updateAttributes({
              variableId,
              type: rightParams.type,
            });
          },
        });
      } else {
        openRightPanel(TextComposerPanelKeys.TextComposerVariable, {
          ...baseAttributes,
          page: 'list',

          returnPanel: isFromOutsidePanel ? rightPanelTarget : undefined,
          returnParams: isFromOutsidePanel ? rightParams : undefined,
        });
      }
    });
  };

  useEffect(() => {
    if (node.attrs.isNewCreated) {
      handleOpenPanel();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [node.attrs.isNewCreated]);

  useEffect(() => {
    if (currentVariable?.name && currentVariable?.name !== node.attrs.name) {
      // prevent "flushSync was called" error
      setTimeout(() => {
        updateAttributes({
          name: currentVariable?.name,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentVariable?.name, node.attrs.name]);

  const error =
    (active || rightParams.variableId === node.attrs.variableId) &&
    rightParams.hasError;
  return (
    <span ref={tagRef}>
      <BlockTag
        icon={
          <Icon
            name={VARIABLE_TYPE_MAPPING[node.attrs.type as VariableType].icon}
          />
        }
        onClick={handleOpenPanel}
        active={active}
        error={error}
      >
        {currentVariable
          ? currentVariable.name
          : VARIABLE_TYPE_MAPPING[node.attrs.type as VariableType].title}
      </BlockTag>
    </span>
  );
};
const Variable = ({ node, updateAttributes }: VariableProps) => {
  return (
    <NodeViewWrapper
      style={{ display: 'inline-block', maxHeight: 18 }}
      className="inline-variable"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <BlockTabButton node={node} updateAttributes={updateAttributes} />
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineVariable = Node.create<InlineVariableOptions>({
  name: EditorBlockTypes.InlineVariable,

  group: 'inline',

  inline: true,

  selectable: false,

  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      variableId: {
        default: '',
      },
      type: {
        default: VariableType.Text,
      },
      isNewCreated: {
        default: false,
      },
      defaultName: {
        default: '',
      },
      defaultContent: {
        default: '',
      },
      name: {
        default: '',
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes, node }) {
    return [
      'span',
      mergeAttributes({
        ...HTMLAttributes,
        class: 'inline-variable',
        'data-content-type': this.name,
        style:
          'color: var(--inline-variable-color); background-color: var(--inline-variable-bgcolor); padding: 4px; border-radius: 4px;',
      }),
      node.attrs.name ||
        VARIABLE_TYPE_MAPPING[node.attrs.type as VariableType].title,
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Variable);
  },
});
