/* eslint-disable @typescript-eslint/no-explicit-any */
import { ToastOptions } from 'react-hot-toast';
import nextId from 'react-id-generator';
import { AxiosPromise, AxiosResponse } from 'axios';
import { BaseSnackbarProps, SnackbarStyleType, toast } from '@front/ui';

type APIType<T> = AxiosPromise<T> | (() => AxiosPromise<T>);

type Options<V> = Partial<
  BaseSnackbarProps &
    SnackbarStyleType & {
      variant: V;
      anchorEl?: Element | undefined;
      disableClose?: boolean | undefined;
      toastOptions?: ToastOptions;
    }
>;
export interface CallWithToastParams {
  id?: string;
  confirmMsg?: string;
  successMsg?: string;
  errorMsg?: string;
  loadingMsg?: string;
  confirmText?: string;
  showAPIError?: boolean;
  showLoading?: boolean;
  toastOptions?: ToastOptions;
  anchorEl?: Element | undefined;
  anchorXGap?: number;
  anchorYGap?: number;
  confirmOptions?: Options<'base'> & {
    confirmText?: string;
    onCancel?: () => void;
  };
  successOptions?: Options<'base'>;
  errorOptions?: Options<'base'>;
  loadingOptions?: Options<'loading'>;
  onSuccess?: (res: any) => void;
  onError?: (res: any) => void;
  onCancel?: () => void;
}

type CheckAwaitedData<T> = Awaited<T> extends null | undefined
  ? Awaited<T>
  : Awaited<T> extends AxiosResponse
  ? Awaited<T>['data']
  : Awaited<T> extends () => AxiosPromise
  ? Awaited<ReturnType<Awaited<T>>> extends AxiosResponse
    ? Awaited<ReturnType<Awaited<T>>>['data']
    : Awaited<ReturnType<Awaited<T>>>
  : Awaited<T>;

export async function call<T extends readonly unknown[] | []>(
  api: T
): Promise<{ -readonly [P in keyof T]: CheckAwaitedData<T[P]> | null }[]>;
export async function call<T>(
  api: T
): Promise<[CheckAwaitedData<T> | null, any]>;
export async function call<T>(api: APIType<T> | APIType<T>[]) {
  try {
    if (Array.isArray(api)) {
      const res = await Promise.all(
        api.map((a) => (a instanceof Promise ? a : a()))
      );
      return [res.map((r) => r.data), null];
    }

    const res: AxiosResponse<T> = await (api instanceof Promise ? api : api());

    if (res.data) {
      return [res.data, null];
    }

    return [null, res.data];
  } catch (err: any) {
    if (err?.response) {
      return [null, err.response.data];
    }
    return [null, err];
  }
}

export async function callWithToast<T extends readonly unknown[] | []>(
  api: T,
  params?: CallWithToastParams
): Promise<{ -readonly [P in keyof T]: CheckAwaitedData<T[P]> | null }[]>;
export async function callWithToast<T>(
  api: T,
  params?: CallWithToastParams
): Promise<[CheckAwaitedData<T> | null, any]>;
export async function callWithToast<T>(
  api: APIType<T> | APIType<T>[],
  {
    id = '',
    confirmMsg = '',
    confirmText = 'Yes',
    successMsg = '',
    errorMsg = '',
    loadingMsg = 'Loading...',
    showAPIError = true,
    showLoading = true,
    anchorEl,
    anchorXGap,
    anchorYGap,
    toastOptions = {},
    confirmOptions = { toastOptions: { duration: 300000 } },
    successOptions = { toastOptions: { duration: 3000 } },
    errorOptions = { toastOptions: { duration: 5000 } },
    loadingOptions = { disableClose: true, toastOptions: {} },
    onSuccess,
    onError,
  }: CallWithToastParams = {}
) {
  const toastId = id || nextId();

  const baseOptions = {
    ...toastOptions,
    id: toastId,
  };

  if (confirmMsg) {
    const { toastOptions: confirmToastOptions, ...rest } = confirmOptions;
    const checked = await toast.confirm(
      confirmMsg,
      {
        anchorEl,
        anchorXGap,
        anchorYGap,
        confirmText,
        ...rest,
      },
      { ...baseOptions, ...(confirmToastOptions || {}) }
    );
    if (!checked) {
      return [null, null, toastId];
    }
  }

  if (showLoading) {
    const { toastOptions: loadingToastOptions, ...rest } = loadingOptions;

    toast.loading(
      loadingMsg,
      { anchorEl, anchorXGap, anchorYGap, ...rest },
      { ...baseOptions, ...(loadingToastOptions || {}) }
    );
  }

  const [success, error] = await call(api);
  if (showLoading) {
    toast.dismiss(toastId);
  }
  if (success && successMsg) {
    const { toastOptions: successToastOptions, ...rest } = successOptions;

    toast.success(
      successMsg,
      { anchorEl, anchorXGap, anchorYGap, ...rest },
      {
        ...baseOptions,
        ...(successToastOptions || {}),
      }
    );
    onSuccess?.(success);
  }

  if (error) {
    const { toastOptions: errorToastOptions, ...rest } = errorOptions;

    if (errorMsg) {
      toast.error(
        errorMsg,
        { anchorEl, anchorXGap, anchorYGap, ...rest },
        {
          ...baseOptions,
          ...(errorToastOptions || {}),
        }
      );
    } else if (showAPIError) {
      // 後端目前有傳三種格式
      const errorMassage =
        error.message ||
        error.response?.message ||
        error.response?.data?.message;

      toast.error(
        typeof errorMassage === 'string'
          ? errorMassage
          : 'Internal server error',
        { anchorEl, anchorXGap, anchorYGap, ...rest },
        {
          ...baseOptions,
          ...(errorToastOptions || {}),
        }
      );
    }
    onError?.(error);
  }

  return [success, error, toastId];
}
