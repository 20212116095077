import filterReducer from '@lib/ia/src/filter/reducers/filterReducer';
import sortReducer from '@lib/ia/src/sort/reducers/sortReducer';
import { combineReducers } from 'redux';

import isLayoutReducer from './iaLayoutReducer';
import settingReducer from './settingReducer';

const rootReducer = combineReducers({
  setting: settingReducer,
  iaLayout: isLayoutReducer,
  sort: sortReducer,
  filter: filterReducer,
});

export default rootReducer;
