import React, {
  KeyboardEvent,
  MouseEvent,
  PointerEvent,
  ReactNode,
  useRef,
} from 'react';
import { reactionSuggestions } from '@front/config';
import { EmojiFavorite as EmojiFavoriteIcon } from '@front/icon';
import { IconButton, ReactionValue } from '@front/ui';

export type ReactionPickerTriggerProps = {
  value: ReactionValue;
  onChangeReaction: (value: ReactionValue | null) => void;
  onHover: (el: Element) => void;
  onPress: () => void;
  disabled?: boolean;
  selected?: boolean;
  children?: ReactNode;
};

const styles = {
  icon: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    display: 'grid',
    alignItems: 'center',
  },
  iconButton: {
    fontSize: 16,
  },
  disabled: {
    opacity: 0.5,
  },
};
export default function ReactionPickerTrigger({
  value,
  onChangeReaction,
  onHover,
  onPress,
  children,
  disabled,
  selected,
}: ReactionPickerTriggerProps) {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const pressTimer = useRef<NodeJS.Timeout | null>(null);
  const clickable = !!onChangeReaction;

  const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
    ev.stopPropagation();
    ev.preventDefault();

    if (!onChangeReaction) return;

    if (value) {
      onChangeReaction(null);
    } else {
      onChangeReaction(reactionSuggestions[0]);
    }
  };

  const handlePointerEnter = (ev: PointerEvent<HTMLButtonElement>) => {
    if (ev.pointerType === 'mouse') {
      onHover(ev.target as Element);
    }
  };

  const handleTouchStart = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
    }
    pressTimer.current = setTimeout(() => {
      onPress();
    }, 500);
  };

  const handleTouchEnd = () => {
    if (pressTimer.current) {
      clearTimeout(pressTimer.current);
      pressTimer.current = null;
    }
  };

  const handleKeyDown = (e: KeyboardEvent) => {
    if (e.key !== 'Enter') return;
    buttonRef.current?.click();
  };

  return (
    <>
      {clickable && (
        <IconButton
          customSize={24}
          onClick={disabled ? undefined : handleClick}
          onPointerEnter={disabled ? undefined : handlePointerEnter}
          onTouchStart={disabled ? undefined : handleTouchStart}
          onTouchEnd={disabled ? undefined : handleTouchEnd}
          onTouchMove={disabled ? undefined : handleTouchEnd}
          sx={styles.iconButton}
          ref={buttonRef}
          selected={selected}
          disableTouchRipple
          onKeyDown={handleKeyDown}
          onContextMenu={(ev) => {
            ev.preventDefault();
            ev.stopPropagation();
            return false;
          }}
        >
          {children || value?.code || <EmojiFavoriteIcon />}
        </IconButton>
      )}

      {!clickable &&
        (children || value?.code || (
          <EmojiFavoriteIcon width={16} height={16} />
        ))}
    </>
  );
}
