import { createContext, Dispatch, SetStateAction } from 'react';

export type HighlightWrapContextValue = [
  Record<string, number>,
  Dispatch<SetStateAction<Record<string, number>>>
];

export const highlightWrapContextInitialValue: HighlightWrapContextValue = [
  {},
  () => {},
];

const HighlightWrapContext = createContext<HighlightWrapContextValue>(
  highlightWrapContextInitialValue
);

export default HighlightWrapContext;
