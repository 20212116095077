import React, { ReactNode, useCallback, useState } from 'react';
import { Box } from '@mui/material';

export default function Hoverable({
  children,
}: {
  children: ({
    hovered,
    hoveredListeners,
  }: {
    hovered: boolean;
    resetHovered: () => void;
    hoveredListeners: {
      onMouseEnter: () => void;
      onMouseLeave: () => void;
      onBlur: () => void;
    };
  }) => ReactNode;
}) {
  const [hovered, setHovered] = useState(false);

  const resetHovered = useCallback(() => setHovered(false), []);
  const setIsHovered = useCallback(() => setHovered(true), []);

  const hoveredListeners = {
    onMouseEnter: setIsHovered,
    onMouseLeave: resetHovered,
    onBlur: resetHovered,
  };

  return (
    <Box>
      {children({
        hovered,
        resetHovered,
        hoveredListeners,
      })}
    </Box>
  );
}
