import { useEffect, useRef } from 'react';
import { alpha, Box, Theme } from '@mui/material';

const styles = {
  keyboardListener: { bottom: 1, zIndex: 2 },
  keyboardContainer: {
    position: 'absolute',
    width: '100%',
    bottom: 0,
    background: (theme: Theme) => theme.palette.background.body,
    backgroundSize: '100vw',
    backgroundAttachment: 'fixed',
  },
  selectionHint: {
    my: '1px',
    textAlign: 'right',
    position: 'relative',
    typography: 'body2',
    fontFamily: (theme: Theme) =>
      theme.palette.mode === 'light' ? 'Georgia' : '',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
    span: {
      color: 'text.primary',
    },
  },
};
const KeyboardListener = ({
  options,
  onChange,
}: {
  options: Record<string, any>[];
  onChange: (id: string) => void;
}) => {
  const keyRef = useRef(false);
  const optionsRef = useRef(options);
  const changeFunc = useRef(onChange);
  changeFunc.current = onChange;
  optionsRef.current = options;

  useEffect(() => {
    const listenKeyDown = (event: KeyboardEvent) => {
      if (
        document.activeElement?.tagName &&
        ['INPUT', 'TEXTAREA'].includes(document.activeElement.tagName)
      )
        return;
      if (keyRef.current) return;
      keyRef.current = true;

      const currentOption = optionsRef.current.find(
        (option) => option.key.toLowerCase() === event.key.toLowerCase()
      );

      if (currentOption && changeFunc.current) {
        changeFunc.current(currentOption.id);
      }
    };
    document.addEventListener('keydown', listenKeyDown);

    return () => {
      document.removeEventListener('keydown', listenKeyDown);
    };
  }, []);

  useEffect(() => {
    const listenKeyUp = () => {
      keyRef.current = false;
    };
    document.addEventListener('keyup', listenKeyUp);

    return () => {
      document.removeEventListener('keyup', listenKeyUp);
    };
  }, []);

  return (
    <Box sx={styles.keyboardListener}>
      <Box sx={styles.keyboardContainer} />
      <Box sx={styles.selectionHint} className="selection-hint">
        Press&nbsp;
        <span>
          {options
            .map((option) => option.key)
            .join(', ')
            .toLowerCase()}
        </span>
        &nbsp;to select
      </Box>
    </Box>
  );
};

export default function KeyboardListenerRoot({
  disabled = false,
  options = [],
  onChange,
}: {
  disabled?: boolean;
  options?: Record<string, any>[];
  onChange: (id: string) => void;
}) {
  if (disabled || !options.length) {
    return null;
  }

  return <KeyboardListener options={options} onChange={onChange} />;
}
