import { Box } from '@mui/material';

import { AlbumLayoutChartExtensionConfig } from './types';

const styles = {
  root: (size: number) => ({
    width: `${size}px`,
    height: `${size}px`,
    borderRadius: '50%',
  }),
  outline: (outline: boolean) => ({
    outline: outline ? '2px solid white' : undefined,
  }),
};

function createConicGradient(data: { color: string; value: number }[]) {
  let startValue = 0;
  const colors: string[] = [];
  data.forEach((item) => {
    const color =
      `${item.color}` +
      ` ${startValue * 100}%` +
      ` ${(startValue + item.value) * 100}%`;
    colors.push(color);
    startValue += item.value;
  });
  return { backgroundImage: `conic-gradient(${colors.join(', ')})` };
}

type AlbumLayoutPieChartExtensionProps = {
  config: AlbumLayoutChartExtensionConfig['config'];
};
export default function AlbumLayoutPieChartExtension({
  config,
}: AlbumLayoutPieChartExtensionProps) {
  const { size = 40, data, outline = false } = config;
  return (
    <Box
      sx={[
        styles.root(size),
        styles.outline(outline),
        createConicGradient(data),
      ]}
    />
  );
}
