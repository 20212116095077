import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import {
  alpha,
  Box,
  PopperProps,
  Skeleton,
  Theme,
  Typography,
} from '@mui/material';
import { appConfig } from '@front/config';
import { EXCLUDE_ELEMENTS, useLatestValueRef } from '@front/helper';
import {
  MainChallenge as MainChallengeIcon,
  TestScratch as TestScratchIcon,
  ThreadsDirectMessages as ThreadsDirectMessagesIcon,
} from '@front/icon';
import { Button, Popper, SquareAvatar, UserAvatar } from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import {
  apis,
  ClubViewSlug,
  isDev,
  useIaClubsList,
  useUserProfileSocial,
} from '@lib/web/apis';
import { call, getIndicators } from '@lib/web/utils';

const styles = {
  popper: {
    zIndex: 20,
    width: 344,
    '& .popper-content': {
      p: '12px',
    },
  },
  profileContainer: {
    display: 'grid',
    overflow: 'hidden',
  },
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 16,
      mt: 0.5,
      mb: 0.5,
    },
  },
  userData: {
    display: 'flex',
    gap: '4px',
    mt: 2,
  },
  userDataItem: {
    px: '4px',
    py: '2px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      fontWeight: 500,
      mr: '5px',
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  buttons: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: 2,
    mt: 2,
    '& .MuiButtonBase-root': {
      minWidth: 'unset',
    },
  },
  bioContainer: {
    mt: 3,
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
  },
  creatorContainer: {
    mt: 2,
    display: 'flex',
    flexDirection: 'column',
  },
  creator: {
    display: 'flex',
    gap: 0.5,
    alignItems: 'center',
  },
};

function useClosePopperWhenMoveOutside(callback: () => void) {
  const callbackRef = useLatestValueRef(callback);
  useEffect(() => {
    function handleClick(event: Event) {
      const clickedElement = event.target as HTMLElement;
      if (
        !clickedElement.closest('.card-corner-avatar') &&
        !clickedElement.closest(EXCLUDE_ELEMENTS.join(','))
      ) {
        callbackRef.current();
      }
    }

    document.addEventListener('mousemove', handleClick);
    return () => {
      document.removeEventListener('mousemove', handleClick);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
}

function FloatingProfileSkeleton() {
  return (
    <Box sx={styles.profileContainer}>
      <Box>
        <Skeleton variant="circular" width={80} height={80} />
      </Box>
      <Box sx={styles.userData}>
        <Skeleton width={65} />
        <Skeleton width={75} />
        <Skeleton width={75} />
      </Box>
      <Box sx={styles.bioContainer}>
        <Skeleton width={120} />
        <Skeleton width={220} />
      </Box>
    </Box>
  );
}

type FloatingProfileProps = {
  userId?: string;
  currentClub?: {
    clubLogo: string;
    clubName: string;
  };
  currentClubGroup?: {
    logo?: string;
    name: string;
    subAvatars: {
      avatarUrl: string;
      name: string;
    }[];
    clubCount: number;
  };
  anchorEl?: Element | null;
  onClose?: () => void;
  followCursor?: boolean;
  show?: boolean; // used when using followCursor
  popperOptions?: PopperProps['popperOptions'];
  disableCta?: boolean;
};

const generateGetBoundingClientRect =
  (x = 0, y = 0) =>
  () =>
    ({
      width: 0,
      height: 0,
      top: y,
      right: x,
      bottom: y,
      left: x,
    } as DOMRect);

const useFollowCursorVirtualElement = ({ enable }: { enable: boolean }) => {
  const [elem, setElem] = useState<{
    getBoundingClientRect: () => DOMRect;
  }>();

  useEffect(() => {
    if (!enable) return;
    const handleMousemove = (e: MouseEvent) => {
      setElem({
        getBoundingClientRect: generateGetBoundingClientRect(
          e.clientX,
          e.clientY
        ),
      });
    };
    document.addEventListener('mousemove', handleMousemove);
    return () => {
      document.removeEventListener('mousemove', handleMousemove);
    };
  }, [enable]);

  return { virtualElement: elem };
};

function FloatingProfile({
  userId,
  currentClub,
  currentClubGroup,
  anchorEl,
  onClose = () => {},
  followCursor = false,
  show = false,
  popperOptions,
  disableCta = false,
}: FloatingProfileProps) {
  const { t } = useTranslation('profile');
  const [loading, setLoading] = useState(false);
  void useClosePopperWhenMoveOutside(onClose);
  const { data, mutate } = useUserProfileSocial(userId);
  const socialData = data?.data;
  const { virtualElement } = useFollowCursorVirtualElement({
    enable: followCursor && show,
  });

  const { totalCount } = useIaClubsList(
    {
      viewSlug: ClubViewSlug.CardCenterDefault,
      ...combineSearch({
        expandSearch: `clubCreator:clubCreatorSourceId:${appConfig.ahaUserId}`,
        expandSearchFields: 'clubCreator:clubCreatorSourceId:eq',
      }),
      limit: 0,
    },
    {
      enable: !userId,
    }
  );

  const toggleFollow = async () => {
    if (!socialData || !userId) return;
    setLoading(true);
    const api = socialData.isFollowing
      ? () => apis.member.unfollowUser(userId)
      : () => apis.member.followUser(userId);

    await call(api);
    await mutate();
    setLoading(false);
  };

  if (!userId) {
    return (
      <Popper
        open={followCursor ? show && !!virtualElement : !!anchorEl}
        anchorEl={followCursor ? virtualElement : anchorEl}
        placement="bottom"
        transition
        sx={styles.popper}
        popperOptions={popperOptions}
      >
        <Box sx={styles.profileContainer}>
          <Box>
            <UserAvatar
              sx={styles.avatar}
              src="/logo.png"
              title="Aha"
              subTitle="@aha"
              size="xl"
            />
          </Box>
          <Box sx={styles.userData}>
            <Typography variant="body1" sx={styles.userDataItem}>
              <span>{totalCount}</span>Clubs
            </Typography>
          </Box>
          <Box sx={styles.creatorContainer}>
            {currentClub && (
              <Box sx={styles.creator}>
                <SquareAvatar src={currentClub.clubLogo} size={16}>
                  {currentClub.clubName}
                </SquareAvatar>
                <Typography>Club creator</Typography>
              </Box>
            )}
            {currentClubGroup && (
              <Box sx={styles.creator}>
                {currentClubGroup.logo ? (
                  <SquareAvatar
                    src={currentClubGroup.logo}
                    size={16}
                    showStacked
                  >
                    {currentClubGroup.name}
                  </SquareAvatar>
                ) : (
                  <AvatarGridFolder
                    size={20}
                    avatars={currentClubGroup.subAvatars || []}
                  />
                )}
                <Typography>Club group creator</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </Popper>
    );
  }

  return (
    <Popper
      open={followCursor ? show && !!virtualElement : !!anchorEl}
      anchorEl={followCursor ? virtualElement : anchorEl}
      placement="bottom"
      transition
      sx={styles.popper}
      popperOptions={popperOptions}
    >
      {socialData && (
        <Box sx={styles.profileContainer}>
          <Box>
            <UserAvatar
              sx={styles.avatar}
              src={socialData.avatarUrl || socialData.photoUrl}
              title={socialData.fullName}
              subTitle={`@${socialData.userName}`}
              indicators={getIndicators(socialData.indicator)}
              size="xl"
            />
          </Box>
          <Box sx={styles.userData}>
            <Typography variant="body1" sx={styles.userDataItem}>
              <span>{socialData.numJoinedClubs}</span>Clubs
            </Typography>
            <Typography variant="body1" sx={styles.userDataItem}>
              <span>{socialData.numFollowers}</span>Followers
            </Typography>
            <Typography variant="body1" sx={styles.userDataItem}>
              <span>{socialData.numFollowings}</span>Following
            </Typography>
          </Box>
          <Box
            sx={[
              styles.buttons,
              (!isDev || socialData.isMe) && { gridTemplateColumns: '1fr' },
            ]}
          >
            {!socialData.isMe ? (
              <>
                <Button
                  prefixIcon={<MainChallengeIcon />}
                  color={socialData.isFollowing ? 'success' : 'default'}
                  loading={loading}
                  onClick={toggleFollow}
                  disabled={disableCta}
                >
                  {socialData.isFollowing
                    ? t('button.Following')
                    : t('button.Follow')}
                </Button>
                {isDev && (
                  <Button
                    prefixIcon={<ThreadsDirectMessagesIcon />}
                    variant="outlined"
                    disabled={disableCta}
                  >
                    Message
                  </Button>
                )}
              </>
            ) : (
              <Button
                prefixIcon={<TestScratchIcon />}
                component={Link}
                href="/profile/account/edit"
                disabled={disableCta}
              >
                Edit Profile
              </Button>
            )}
          </Box>
          <Box sx={styles.bioContainer}>
            {Boolean(socialData.city && socialData.country) && (
              <Typography variant="body1">
                <span role="img" aria-label="location">
                  📍
                </span>{' '}
                {socialData.city}, {socialData.country}
              </Typography>
            )}

            {Boolean(socialData.highSchoolGraduationYear) && (
              <Typography variant="body1">
                <span role="img" aria-label="graduation">
                  🎓
                </span>{' '}
                Graduating in {socialData.highSchoolGraduationYear}
              </Typography>
            )}

            {Boolean(socialData.dreamSchoolName) && (
              <Typography variant="body1">
                <span role="img" aria-label="dream school">
                  🤞
                </span>{' '}
                {socialData.dreamSchoolName}
              </Typography>
            )}
          </Box>
        </Box>
      )}
      {!socialData && <FloatingProfileSkeleton />}
    </Popper>
  );
}

export default FloatingProfile;
