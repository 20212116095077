import { AlbumLayoutItem } from '../../types';

import IaAlbumAvatarItem from './IaAlbumAvatarItem';
import IaAlbumBaseItem from './IaAlbumBaseItem';
import IaAlbumDefaultItem from './IaAlbumDefaultItem';
import IaAlbumIconItem from './IaAlbumIconItem';
import IaAlbumLoadingItem from './IaAlbumLoadingItem';
import IaAlbumTextItem from './IaAlbumTextItem';

type IaAlbumItemProps = {
  item: AlbumLayoutItem;
};

export default function IaAlbumItem({ item }: IaAlbumItemProps) {
  if (!item) return null;
  if (!('type' in item)) {
    return <IaAlbumBaseItem {...item} item={item} />;
  }

  switch (item.type) {
    case 'default':
      return <IaAlbumDefaultItem {...item} />;
    case 'text':
      return <IaAlbumTextItem {...item} />;
    case 'icon':
      return <IaAlbumIconItem {...item} />;
    case 'avatar':
      return <IaAlbumAvatarItem {...item} />;
    case 'loading':
      return <IaAlbumLoadingItem {...item} />;
    default:
      console.warn('Unknown album item type:', item);
      return null;
  }
}
