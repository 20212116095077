import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { QuizViewSlug } from '../../types/enums';
import { generateInfiniteHook, SwrHelperParams } from '../query';

export type GetIaQuizResMap<E extends keyof IaQuizExtensionAttributes> = {
  [QuizViewSlug.SmallIconsCenterQuizDefault]: GetIaQuizSmallIconsCenterQuizDefaultRes<E>;
  [QuizViewSlug.TableCenterQuizDefault]: GetIaQuizTableCenterQuizDefaultRes<E>;
};

export function useIaQuizzes<
  E extends keyof IaQuizExtensionAttributes,
  K = GetIaQuizSmallIconsCenterQuizDefaultRes<E>,
  T extends QuizViewSlug | undefined = undefined
>(
  query: IaSearchReq<QuizViewSlug> = {},
  { enable, ...restConfig }: SwrHelperParams = {}
) {
  const limit = query.limit || 0;

  return generateInfiniteHook<
    T extends QuizViewSlug ? GetIaQuizResMap<E>[T] : K
  >(
    (index) =>
      enable === false
        ? null
        : `v2/ia/quizzes?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    {
      auth: true,
      ...restConfig,
    }
  );
}
