import { Box } from '@mui/material';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';

const styles = {
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& circle': {
      fill: '#01F89B',
      r: 10,
    },
  },
};

export default function ChallengesOngoingIcon({ ...rest }) {
  const sx = rest.sx || {};
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box component="span" {...rest} sx={[styles.icon, ...sxProps]}>
      <ChatNotificationDotIcon width="100%" height="100%" />
    </Box>
  );
}
