import { ReactNode } from 'react';
import { Provider } from 'react-redux';

import { store } from './storage';

type EditorProviderProps = {
  children: ReactNode;
};
export default function EditorProvider({ children }: EditorProviderProps) {
  return <Provider store={store}>{children}</Provider>;
}
