import React, { ReactNode, useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useThreadLocationDisplay } from '@app/web/src/components/AppThreadProvider/hooks/useThreadLocationDisplay';
import { SharedUserContext } from '@lib/web/contexts';
import { ThreadCurrentLocationProvider, ThreadProvider } from '@lib/web/thread';
import { LocationDetail } from '@lib/web/thread/types';

export type AppThreadProviderProps = {
  children: ReactNode;
};

export default function AppThreadProvider({
  children,
}: AppThreadProviderProps) {
  const { pathname, query } = useRouter();
  const { getThreadLocationDisplay } = useThreadLocationDisplay();
  const sharerData = useContext(SharedUserContext);

  const currentLocationDetail: LocationDetail = useMemo(() => {
    return {
      location: pathname,
      locationOwnerId: sharerData.memberId,
      query,
    };
  }, [pathname, query, sharerData.memberId]);

  return (
    <ThreadProvider getThreadLocationDisplay={getThreadLocationDisplay}>
      <ThreadCurrentLocationProvider
        defaultLocationDetail={currentLocationDetail}
      >
        {children}
      </ThreadCurrentLocationProvider>
    </ThreadProvider>
  );
}
