import { useCallback } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import {
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionCorrectAnswerHandle,
  useCreatorQuestionDetailHandle,
} from '@lib/web/editor';

export const useEventHandle = (questionId: string) => {
  const { correctAnswerIdSet } =
    useCreatorQuestionCorrectAnswerData(questionId);

  const { handleCreatorQuestionDetailChange } =
    useCreatorQuestionDetailHandle(questionId);

  const { handleCorrectAnswerChange } =
    useCreatorQuestionCorrectAnswerHandle(questionId);

  const handleBlocksChange = useCallback(
    async (id: string, blocks: ComposerBlock[]) => {
      void handleCreatorQuestionDetailChange(id, blocks);
    },
    [handleCreatorQuestionDetailChange]
  );
  const handleSelect = async (id: string) => {
    if (!questionId) {
      console.warn('questionId is undefined when select choice');
      return;
    }

    const correctAnswers = correctAnswerIdSet.has(id) ? [] : [id];

    void handleCorrectAnswerChange({
      params: {
        id: questionId,
        correctAnswers:
          correctAnswers.length > 0
            ? correctAnswers.map((item) => ({
                componentId: item,
              }))
            : null,
      },
      optimisticData: correctAnswers.map((item) => ({
        id: '',
        componentId: item,
        value: null,
        order: 0,
      })),
    });
  };

  return {
    handleBlocksChange,
    handleSelect,
  };
};
