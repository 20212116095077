import React from 'react';
import { Theme } from '@mui/material';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { alpha } from '@mui/material/styles';
import { ProfileClubAgent as ProfileClubAgentIcon } from '@front/icon';
import { IconButton, Scrollbar, useBaseRightPanel } from '@front/ui';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import UpgradeCtaButton from '@lib/web/thread/ThreadComposer/components/AiAction/UpgradeCtaButton';

import AgentIcon from './AgentIcon';
import SelectAgent from './SelectAgent';
import SelectAgentPrompt from './SelectAgentPrompt';
import SelectRecentPrompt from './SelectRecentPrompt';

const styles = {
  divider: {
    backgroundColor: 'alpha.lightA10',
  },
  root: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    pt: 1,
    pr: 0,
    pl: 0,
    '&:has(.thread-prompt-item)': {
      pb: 2,
    },
  },
  selectPromptScroll: {
    maxHeight: 119,
  },
};

const enum GlobalPanelKeys {
  GlobalManageAgents = 'GlobalManageAgents',
}

export function AiActionButton() {
  const {
    aiActionState: state,
    selectedAction,
    setSelectedAction,
    actionAiEnable,
  } = useThreadComposer();

  const { openRightPanel } = useBaseRightPanel(); // XXX: here is libs/thread , we cannot include GlobalPanelKeys in apps/web

  const selected = selectedAction === 'ai';
  const handleClick = () => {
    if (selected) {
      setSelectedAction(undefined);
      state.setSelectedAgent(null);
    } else {
      setSelectedAction('ai');

      void state.ensureLoadingFinish((agentsData) => {
        if (agentsData.totalCount > 0) return;

        openRightPanel(GlobalPanelKeys.GlobalManageAgents);
      });
    }
  };

  if (!actionAiEnable) return;

  if (selected && state.selectedAgent) {
    return (
      <AgentIcon
        agent={state.selectedAgent?.agent}
        selected={selected}
        onClick={handleClick}
      />
    );
  }
  return (
    <IconButton customSize={24} selected={selected} onClick={handleClick}>
      <ProfileClubAgentIcon />
    </IconButton>
  );
}

export function AiActionContent() {
  const { selectedAction } = useThreadComposer();

  if (selectedAction !== 'ai') return;

  return (
    <>
      <Divider sx={styles.divider} />

      <Box sx={styles.root}>
        <Scrollbar>
          <SelectAgent />
        </Scrollbar>

        <UpgradeCtaButton />

        <Scrollbar sx={styles.selectPromptScroll}>
          <SelectAgentPrompt />
          <SelectRecentPrompt />
        </Scrollbar>
      </Box>
    </>
  );
}
