import { useEffect, useRef } from 'react';

import useLatestValueRef from './useLatestValueRef';

// assume when user click these elements, it's not a click outside
export const EXCLUDE_ELEMENTS = [
  '.toast-snackbar',
  '.MuiMenuItem-root',
  '.composer-side-menu-button',
  '.composer-slash-menu',
  '.MuiBackdrop-root',
  '.MuiPopover-root',
  '.base-Popper-root',
  '.bottom-sheet-bg',
];

const useOutsideClick = (
  callback: (ev: Element) => void,
  useCapture = true
) => {
  const ref = useRef<HTMLElement>(null);
  const onSettingsLoadedRef = useLatestValueRef(callback);

  useEffect(() => {
    function handleClick(event: Event) {
      const clickedElement = event.target as HTMLElement;

      if (
        !ref.current?.contains(clickedElement) &&
        !clickedElement.closest(EXCLUDE_ELEMENTS.join(','))
      ) {
        onSettingsLoadedRef.current(clickedElement);
      }
    }

    document.addEventListener('click', handleClick, useCapture);

    return () => {
      document.removeEventListener('click', handleClick, useCapture);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return ref;
};

export default useOutsideClick;
