import React from 'react';
import { Box, Theme, useMediaQuery } from '@mui/material';
import { OtherMenu as OtherMenuIcon } from '@front/icon';
import { IconButton, useBaseLeftPanel } from '@front/ui';

import useToggleLeftPanel from './useToggleLeftPanel';

// Inside the header

const styles = {
  leftPanelButton: {
    display: 'flex',
    alignItems: 'center',
    pl: 2.5,
    mr: -2,
  },
};

export default function CollapseLeftPanelButton() {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const { hideLeftPanel, leftPanelOpened } = useBaseLeftPanel();

  const handleToggleLeftPanel = useToggleLeftPanel();

  if ((!leftPanelOpened && mdUp && !lgUp) || (hideLeftPanel && mdUp)) {
    return (
      <Box sx={styles.leftPanelButton}>
        <IconButton customSize={28} onClick={handleToggleLeftPanel}>
          <OtherMenuIcon />
        </IconButton>
      </Box>
    );
  }

  return null;
}
