import type { PaletteOptions, Shadows, ThemeOptions } from '@mui/material';

import { getComponents } from './components';
import typography from './typography';

// palette
const palette: PaletteOptions = {
  mode: 'dark' as const,
  primary: {
    light: '#00D1FF',
    main: '#00A3FF',
    dark: '#0072DD',
    contrastText: '#FFFFFF',
  },
  secondary: {
    light: '#9AFFFD',
    main: '#01FFFF',
    dark: '#00DEEB',
    contrastText: '#FFFFFF',
  },
  error: {
    light: '#FF4E8E',
    main: '#FF4769',
    dark: '#FF2951',
    contrastText: '#FFFFFF',
  },
  warning: {
    light: '#FF7401',
    main: '#FF5C01',
    dark: '#FF3701',
    contrastText: '#FFFFFF',
  },
  info: {
    light: '#03A9F4',
    main: '#0288D1',
    dark: '#01579B',
    contrastText: '#FFFFFF',
  },
  success: {
    light: '#01F89B',
    main: '#00DEAD',
    dark: '#00C398',
    contrastText: '#181818',
  },
  tutor: {
    light: '#FA36A8',
    main: '#8B55FF',
    dark: '#484F7B',
    contrastText: '#FFFFFF',
  },
  grey: {
    50: '#F8F8F8',
    100: '#F1F1F1',
    200: '#E7E7E7',
    300: '#D6D6D6',
    400: '#B2B2B2',
    500: '#929292',
    600: '#6A6A6A',
    700: '#565656',
    800: '#363636',
    900: '#242424',
  },
  text: {
    primary: 'rgba(255, 255, 255, 1)',
    secondary: 'rgba(255, 255, 255, .6)',
    disabled: 'rgba(255, 255, 255, .38)',
  },
  background: {
    default: '#181818',
    paper: '#1B1B1B',
    darker: '#080808',
    body: 'linear-gradient(255.56deg, rgba(0, 51, 173, 0.1875) -2.42%, rgba(0, 51, 173, 0) 75.46%), linear-gradient(180deg, rgba(8, 8, 8, 0) 0%, rgba(8, 8, 8, 0.5) 100%), #080808',
    menu: '#151A28',
  },
  highlight: {
    yellow: '#FFCB00',
    orange: '#FF7525',
    red: '#E62937',
    green: '#009E2F',
    blue: '#00A9FF',
  },
  alpha: {
    lightA00: '#FFFFFF',
    lightA05: 'rgba(255, 255, 255, 0.05)',
    lightA10: 'rgba(255, 255, 255, 0.1)',
    lightA30: 'rgba(255, 255, 255, 0.3)',
    lightA50: 'rgba(255, 255, 255, 0.5)',
    lightA64: 'rgba(255, 255, 255, 0.64)',
    lightA75: 'rgba(255, 255, 255, 0.75)',
    darkerA75: 'rgba(8, 8, 8, 0.75)',
    darkerA50: 'rgba(8, 8, 8, 0.50)',
    darkerA30: 'rgba(8, 8, 8, 0.30)',
    darkerA10: 'rgba(8, 8, 8, 0.10)',
    darkA50: 'rgb(24, 24, 24, .5)',
    primaryLightA10: 'rgba(0, 209, 255, 0.1)',
    primaryLightA30: 'rgba(0, 209, 255, 0.3)',
    primaryLightA50: 'rgba(0, 209, 255, 0.5)',
    primaryDarkA50: 'rgba(0, 114, 221, 0.5)',
    primaryGradientA30:
      'linear-gradient(269.99deg, rgba(0, 255, 255, 0.3) 0.01%, rgba(0, 164, 255, 0.3) 99.99%)',
    primaryGradientFaded:
      'linear-gradient(90deg, rgba(1, 196, 249, 0.3) 0%, #01C4F9 100%)',
    primaryGradientVerticalDarkA30:
      'linear-gradient(179.99deg, rgba(1, 184, 230, 0.5) 0.01%, rgba(0, 114, 221, 0.5) 99.99%)',
    primaryGradientVerticalDarkerA50:
      'linear-gradient(179.99deg, rgba(0, 134, 180, 0.5) 0.01%, rgba(0, 64, 171, 0.5) 99.99%)',
    tutorDarkA50: 'rgba(72, 79, 123, 0.5)',
    errorA30: 'rgba(255, 71, 105, 0.3)',
    errorA50: 'rgba(255, 71, 105, 0.5)',
    errorDarkA15: 'rgb(255, 41, 81, 0.15)',
    errorDarkA30: 'rgb(255, 41, 81, 0.3)',
    errorDarkA50: 'rgb(255, 41, 81, 0.5)',
    errorGradientFaded:
      'linear-gradient(90deg, rgba(255, 71, 109, 0.37) 0%, #FF476D 100%)',
    successDarkA30: 'rgba(0, 195, 152, 0.3)',
    successDarkA50: 'rgba(0, 195, 152, 0.5)',
    successLightA30: 'rgba(1, 248, 155, 0.3)',
    successLightA50: 'rgba(1, 248, 155, 0.5)',
    warningGradientFaded:
      'linear-gradient(90deg, rgba(255, 83, 1, 0.35) 0%, #FF5301 100%)',
  },
  gradient: {
    primary: 'linear-gradient(269.99deg, #00FFFF 0.01%, #00A4FF 99.99%)',
    primaryDark: 'linear-gradient(269.99deg, #00DEEB 0.01%, #0072DD 99.99%)',
    primaryDarker: 'linear-gradient(269.99deg, #006F76 0.01%, #00396F 99.99%)',
    primaryVertical: 'linear-gradient(0deg, #01A0F6 0%, #01FCFF 100%)',
    primaryVerticalDark:
      'linear-gradient(179.99deg, #01B8E6 0.01%, #0072DD 99.99%)',
    primaryVerticalDarker:
      'linear-gradient(179.99deg, #0086B4 0.01%, #0040AB 99.99%)',
    tutor: 'linear-gradient(270deg, #FA36A8 0.13%, #8B55FF 100%)',
    error:
      'linear-gradient(270.48deg, #FF2951 13.06%, #FF4769 58.58%, #FF4E8E 99.59%)',
    success: 'linear-gradient(90deg, #01F999 0.13%, #00C398 100%)',
    warning: 'linear-gradient(89.91deg, #FF3301 -3.31%, #FF7501 99.92%)',
    maskAbove: 'linear-gradient(180deg, #080808 0%, rgba(27, 27, 27, 0) 100%)',
    maskBelow: 'linear-gradient(180deg, rgba(8, 8, 8, 0) 0%, #080808 100%)',
  },
  divider: 'rgba(255, 255, 255, .1)',
  action: {
    active: 'rgba(255, 255, 255, .54)',
    hover: 'rgba(255, 255, 255, .4)',
    selected: 'rgba(255, 255, 255, .08)',
    focus: 'rgba(255, 255, 255, .12)',
    disabled: 'rgba(255, 255, 255, .26)',
    disabledBackground: 'rgba(255, 255, 255, .12)',
  },
  common: {
    black: '#000000',
    white: '#FFFFFF',
  },
  button: {
    hoverColor: '#080808',
    hoverBackground: 'white',
    hoverShadow: '#8B8B8B',
    activeColor: '#080808',
    activeBackground: '#D6D6D6',
  },
};

const shadows: Shadows = [
  'none',
  // 1. Shadow Usage: Card, Object
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  // 2. Shadow Usage: Top Bar
  '0px 12px 24px rgba(0, 0, 0, 0.15)',
  // 3. Shadow Usage: Hover State (If needed)
  '0px 16px 32px rgba(0, 0, 0, 0.15)',
  // 4. Shadow Usage: Bottom bar
  '0px -12px 24px rgba(0, 0, 0, 0.15)',
  // 5. Overlay Blur - Top Bar (Dark mode)
  '0px 12px 24px rgba(0, 0, 0, 0.15)',
  // 6. Overlay Blur - Bottom Bar (Dark mode)
  '0px -12px 24px rgba(0, 0, 0, 0.15)',
  // 7. Overlay Blur - Right Panel
  '-12px 0px 24px rgba(0, 0, 0, 0.15)',
  // 8. Overlay Blur - Left Panel
  '12px 0px 24px rgba(0, 0, 0, 0.15)',
  // 9. Special Effect/Overlay Blur/Pop Up
  '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
  // Other
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
  '0px 8px 16px rgba(0, 0, 0, 0.15)',
];

const themeOptions: ThemeOptions = {
  name: 'Base Theme',
  palette,
  typography,
  components: getComponents(palette),
  shadows,
};

export default themeOptions;
