import { useContext, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { isEmpty } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import FormSettingContext from '../components/FormSettingContext';
import { FormLayoutItemActionMap, FormLayoutItemCustomRule } from '../types';

type ActionPayload = {
  name: string;
  value?: any;
};

type UseInputEventParams = {
  name: string;
  type?: 'number' | 'text' | 'textarea' | 'email';
  actionMap?: FormLayoutItemActionMap;
  customRules?: Partial<FormLayoutItemCustomRule>;
  onChange: (newValue: any) => void;
  onBlur: () => void;
};

export default function useInputEvent({
  onChange,
  onBlur,
  name,
  customRules,
  actionMap,
  type,
}: UseInputEventParams) {
  const { submit, triggerSubmitMode } = useContext(FormSettingContext);
  const { getIaAction } = useIaAction();

  const { getValues, formState, trigger } = useFormContext();
  const submitTimeRef = useRef<NodeJS.Timeout>();
  const formStateRef = useRef(formState);

  formStateRef.current = formState;
  const handleInputChange = async (newValue: any) => {
    let checkedValue = newValue;
    if (type === 'number' && isNaN(newValue)) {
      checkedValue = checkedValue.replace(/[^0-9]/gi, '');
    }
    clearTimeout(submitTimeRef.current);

    if (actionMap?.change) {
      const onChangeAction = getIaAction<ActionPayload>(actionMap.change.value);

      if (onChangeAction?.action) {
        const res = await Promise.resolve(
          onChangeAction.action({ name, value: checkedValue })
        );

        if (res === undefined) {
          onChange?.(checkedValue);
        } else {
          onChange?.(res);
        }
      } else {
        onChange?.(checkedValue);
      }
    } else {
      onChange?.(checkedValue);
    }

    if (triggerSubmitMode === 'onChange') {
      setTimeout(() => {
        if (formStateRef.current.isDirty && formStateRef.current.isValid) {
          submit();
        }
      });
    }
  };

  const handleInputBlur = () => {
    onBlur?.();

    if (actionMap?.blur) {
      const onClickAction = getIaAction<ActionPayload>(actionMap.blur.value);
      onClickAction?.action?.({ name, value: getValues(name) });
    }

    if (customRules) {
      Object.values(customRules).forEach((rule) => {
        if ('field' in rule && rule.field && !isEmpty(getValues(rule.field))) {
          trigger(rule.field);
        }
      });
    }

    if (triggerSubmitMode === 'onBlur') {
      setTimeout(() => {
        if (formStateRef.current.isDirty && formStateRef.current.isValid) {
          submit();
        }
      });
    }
  };

  return {
    handleInputChange,
    handleInputBlur,
  };
}
