import React from 'react';

export default function AnalyticsWeakness({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <g clipPath="url(#clip0_28_1163)">
        <rect
          height="5"
          width="5"
          fill="currentColor"
          opacity="0.75"
          rx="2.5"
          x="6.95508"
          y="4"
        />
        <rect
          height="8.59797"
          width="8.6"
          fill="currentColor"
          rx="4.29899"
          x="5.92383"
          y="10.1895"
        />
        <rect
          height="7.00079"
          width="7"
          fill="currentColor"
          rx="3.5"
          x="13.3916"
          y="4"
        />
        <rect
          height="2"
          width="2"
          fill="currentColor"
          rx="1"
          x="14.6953"
          y="18"
        />
        <rect
          height="5"
          width="5"
          fill="currentColor"
          opacity="0.5"
          rx="2.5"
          x="16"
          y="12.6975"
        />
        <g opacity="0.25">
          <rect
            height="3.5"
            width="3.5"
            fill="currentColor"
            rx="1.75"
            x="3"
            y="8.34912"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_28_1163">
          <rect height="24" width="24" fill="currentColor" />
        </clipPath>
      </defs>
    </svg>
  );
}
