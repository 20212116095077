import React from 'react';

export default function EditorDifficultySuper({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 6.54753C9.77 9.87587 6.4 12.1247 5 12.9044V14.5835C8.01 13.1842 10.92 10.4856 12 9.24618C13.08 10.4956 15.99 13.1842 19 14.5835V12.9044C17.6 12.1247 14.23 9.87587 12 6.54753Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 2C9.77 5.70815 6.4 8.35682 5 9.22639V11.0855C8.01 9.53623 10.92 6.37781 12 4.9985C13.08 6.37781 15.99 9.53623 19 11.0855V9.22639C17.6 8.35682 14.23 5.70815 12 2Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.0853C9.5 14.2637 6.4 15.8429 5 16.5326V18.3517C8.05 16.9524 10.92 14.9134 12 13.8039C13.08 14.9134 15.95 16.9524 19 18.3517V16.5326C17.6 15.8429 14.5 14.2637 12 11.0853Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 15.6333C9.32 18.242 6.4 19.5714 5 20.181V22.0001C8 20.6408 9 20.181 12 18.362C15 20.181 16 20.6308 19 22.0001V20.181C17.6 19.5714 14.68 18.252 12 15.6333Z"
        fill="currentColor"
      />
    </svg>
  );
}
