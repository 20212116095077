import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Skeleton } from '@mui/material';
import {
  useCreatorQuestionCorrectAnswerHandle,
  useCreatorQuestionDetailHandle,
  useCurrentQuestion,
} from '@lib/web/editor';
import { EssayAnswerInput } from '@lib/web/ui';

import { useData } from './hooks/useData';

export default function EditorAnswerEssay() {
  const { t } = useTranslation('editor');
  const { questionId } = useCurrentQuestion();
  const [text, setText] = React.useState<string | null>(null);
  const { isInitializingOrLoading, component, limitMax } = useData();
  const { handleCreatorQuestionDetailChange } =
    useCreatorQuestionDetailHandle(questionId);
  const { handleCorrectAnswerChange } =
    useCreatorQuestionCorrectAnswerHandle(questionId);

  useEffect(() => {
    if (text !== null || isInitializingOrLoading || !component) {
      return;
    }
    setText(component.editorBlocks[0]?.text || '');
  }, [component, isInitializingOrLoading, text]);

  const handleChange = useCallback(
    (value: string) => {
      if (!component) {
        return;
      }

      setText(value);

      void handleCreatorQuestionDetailChange(component.id, [
        {
          id: component.id,
          type: 'text',
          text: value,
          attrs: {},
        },
      ]);
      /**
       *  in essay, there is only one correct answer, which is the same as the answer area's first component,
       *  what we need to do is to keep update its value
       */
      // back-end does not accept empty answer
      if (value !== '') {
        void handleCorrectAnswerChange({
          params: {
            id: questionId,
            correctAnswers: [
              {
                componentId: component.id,
                value,
              },
            ],
          },
          debounce: true,
        });
      }
    },
    [
      component,
      handleCorrectAnswerChange,
      handleCreatorQuestionDetailChange,
      questionId,
    ]
  );

  if (!component) {
    return;
  }

  if (isInitializingOrLoading || text === null) {
    return <Skeleton width="100%" height={36} />;
  }

  return (
    <EssayAnswerInput
      value={text}
      onChange={handleChange}
      placeholder={t('Enter example answer here')}
      wordLimitMax={{
        value: limitMax,
      }}
      tooltipTitle={t('Beginning of answer area')}
    />
  );
}
