import { ThreadUser } from '@lib/web/thread/types';

export function isThreadUserAnAgentType(
  user?: ThreadUser | null
): user is Extract<ThreadUser, { type: 'agent' }> {
  return user?.type === 'agent';
}

export function isThreadUserAnUserType(
  user?: ThreadUser | null
): user is Extract<ThreadUser, { type: 'user' }> {
  return user?.type === 'user';
}
