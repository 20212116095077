import { useMemo } from 'react';
import { trueFalseNotGivenSettings } from '@lib/web/editor';

import usePracticeQuestion from '../../../../../../hooks/usePracticeQuestion';
import usePracticeQuestionAnswers from '../../../../../../hooks/usePracticeQuestionAnswers';
import usePracticeQuestionEvent from '../../../../../../hooks/usePracticeQuestionEvent';
import usePracticeQuestionResult from '../../../../../../hooks/usePracticeQuestionResult';
import usePracticeQuestionStatus from '../../../../../../hooks/usePracticeQuestionStatus';
import usePracticeStaticData from '../../../../../../hooks/usePracticeStaticData';
import { useAnswerOptions } from '../../hooks';
import PracticeMultipleChoices from '../../PracticeMultipleChoices';

const styles = {
  choices: {
    '& .chart-render-inner': {
      fontSize: 16,
    },
  },
};
export default function QuizAnswerTrueFalseNotGiven() {
  const { pinned, horizontal } = useAnswerOptions();

  const { isLoading, isSubmitted, startAt } = usePracticeQuestionStatus();

  const { answers: currentAnswers, eliminatedAnswers } =
    usePracticeQuestionAnswers();

  const { onChangeAnswers, onChangeElimination } = usePracticeQuestionEvent();
  const { isCorrect, statistic, correctAnswerIds } =
    usePracticeQuestionResult();
  const { details } = usePracticeQuestion();
  const { mode } = usePracticeStaticData();

  const options = useMemo(() => {
    const questionDetails = [...details].sort((a, b) => a.order - b.order);

    return questionDetails.map((option, index) => {
      return {
        id: option.id,
        key: trueFalseNotGivenSettings.labels[index][0],
        value: trueFalseNotGivenSettings.labels[index],
        selectedRatio:
          statistic?.find((stat) => stat.id === option.examQuestionDetailId)
            ?.selectedRatio || 0,
      };
    });
  }, [details, statistic]);

  const disabled = isSubmitted || isLoading;

  return (
    <PracticeMultipleChoices
      sx={styles.choices}
      value={currentAnswers}
      options={options}
      variant="circle"
      submitted={isSubmitted}
      incorrect={!isCorrect}
      correctAnswers={correctAnswerIds}
      disabled={!startAt || disabled}
      onChange={onChangeAnswers}
      mode={mode}
      onEliminationChange={onChangeElimination}
      eliminatedAnswers={eliminatedAnswers}
      disableSortedByKey
      horizontal={horizontal}
      disableSortedByEliminatedAnswers={pinned && horizontal}
    />
  );
}
