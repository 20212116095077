import { Box, Typography } from '@mui/material';
import { Icon, SquareAvatar } from '@front/ui';
import AvatarGridFolder from '@lib/ia/src/components/AvatarGridFolder';

import { TooltipTitleType } from './types';

const styles = {
  root: {
    display: 'grid',
    gap: 0.5,
    gridTemplateColumns: '16px auto 1fr',
    alignItems: 'start',
  },
  titleContent: {
    fontSize: 12,
    fontWeight: 700,
  },
  titleContentNoWrap: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
  },
  gridAvatar: {
    zIndex: 1,
  },
  avatarText: {
    zIndex: 1,
  },
  prefixIcon: {
    width: 16,
    height: 16,
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    top: 0,
    right: 0,
    display: 'flex',
    alignItems: 'flex-start',
  },
  badgeDot: {
    width: 2.4,
    height: 2.4,
    borderRadius: '50%',
    mt: '0.8px',
    mr: '0.8px',
  },
};

type TitlePrefixProps = Required<TooltipTitleType>['prefix'];

function TitlePrefix(props: TitlePrefixProps) {
  if (props.type === 'icon') {
    return (
      <Box sx={styles.prefixIcon}>
        <Icon name={props.name} width={16} height={16} />
        {props.badge && (
          <Box sx={styles.badge}>
            {props.badge.type === 'dot' && (
              <Box sx={[styles.badgeDot, { bgcolor: props.badge.color }]} />
            )}
            {props.badge.type === 'icon' && (
              <Icon name={props.badge.name} width={3.2} height={3.2} />
            )}
          </Box>
        )}
      </Box>
    );
  }
  if (props.type === 'gridAvatars') {
    return (
      <Box sx={styles.gridAvatar}>
        <AvatarGridFolder
          avatars={props.avatars}
          size={16}
          avatarsCount={props.avatarsCount}
        />
      </Box>
    );
  }
  return (
    <Box sx={styles.avatarText}>
      <SquareAvatar
        src={props.avatarUrl}
        showStacked={props.avatarShowStacked}
        size={16}
      >
        {props.avatarText}
      </SquareAvatar>
    </Box>
  );
}

const TitleSuffix = TitlePrefix;

type TooltipTitleProps = TooltipTitleType;

export default function TooltipTitle({
  content,
  prefix,
  suffix,
  gridTemplateColumns,
  nowrap = true,
}: TooltipTitleProps) {
  return (
    <Box sx={[styles.root, { gridTemplateColumns }]}>
      {prefix && <TitlePrefix {...prefix} />}
      <Typography
        variant="caption"
        sx={[styles.titleContent, nowrap && styles.titleContentNoWrap]}
      >
        {content}
      </Typography>
      {suffix && <TitleSuffix {...suffix} />}
    </Box>
  );
}
