import { useCallback, useMemo } from 'react';
import { apis, useAiTokenBalance } from '@lib/web/apis';
import { Channel } from 'stream-chat';

const isRunOutOfToken = (tokenBalance?: GetAiTokenBalanceRes) => {
  return tokenBalance && tokenBalance.aiTokenTotalAmount === 0;
};

// only channel with agent inside need consume tokens
const hasAgentInsideChannel = (channel: Channel) =>
  !!channel?.data?.assignedAgentId;

export const useTokenBalanceControl = () => {
  const { data, mutate } = useAiTokenBalance();

  const runOutOfToken = useMemo(
    () => isRunOutOfToken(data?.data),
    [data?.data]
  );

  const maybeSendUpgradePlanMessage = useCallback(
    async (channel: Channel) => {
      if (!hasAgentInsideChannel(channel)) return;

      const updatedData = await mutate();

      if (!isRunOutOfToken(updatedData?.data)) return;

      await apis.thread.requireUpgrade({
        channelType: channel.type as 'team' | 'public',
        channelId: channel.id,
      });
    },
    [mutate]
  );

  return {
    maybeSendUpgradePlanMessage,
    runOutOfToken,
  };
};
