import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRouter } from 'next/router';
import { QuizGroupType, QuizType, useAuth, useMemberInfo } from '@lib/web/apis';

const TYPE_NAME_MAP = {
  [QuizGroupType.Bookmark]: 'Playlist',
  [QuizGroupType.Emoji]: 'Emoji',
  [QuizGroupType.Hashtags]: 'Hashtags',
  [QuizGroupType.Mistake]: 'Mistake',
  [QuizGroupType.Overtime]: 'Overtime',
};
export default function usePlaylistTitle(
  data?: GetQuizResultSummaryRes,
  {
    dynamicType,
    dynamicTitle,
  }: {
    dynamicType?: QuizGroupType;
    dynamicTitle?: string;
  } = {}
) {
  const { t } = useTranslation();
  const { userId } = useAuth();
  const router = useRouter();
  const sid = router.query.sid as string;
  const { data: userData } = useMemberInfo(data?.quiz.quizOwnerId, null);

  const typeName = dynamicType
    ? t(`breadcrumb::${TYPE_NAME_MAP[dynamicType]}`, TYPE_NAME_MAP[dynamicType])
    : 'Playlist';

  return useMemo(() => {
    // 1. my playlist
    // 2. my dynamic playlist
    const isSelf =
      (!!userId && userId === data?.quiz.quizOwnerId) ||
      (!data?.quiz.quizOwnerId && !sid);

    if (!data) return undefined;

    const playlistTitle = data.quiz.quizCustomName || dynamicTitle || typeName;
    const clearPlaylistTitle = playlistTitle.split("'s ").pop();

    if (data.challenge || isSelf) return playlistTitle;

    if (!userData) return undefined;

    const name = userData.data.displayName || userData.data.distinctName || '';

    if (data.quiz.type === QuizType.CreatorUserQuiz) {
      return t("name's_attribute", {
        name,
        attribute: 'Creator Playlist',
      });
    }

    return t("name's_attribute", {
      name,
      attribute: clearPlaylistTitle,
    });
  }, [userId, data, sid, dynamicTitle, typeName, userData, t]);
}
