import { useContext } from 'react';
import { ReactionPicker } from '@front/ui';
import { IaPropertyEmoji } from '@lib/ia/src/core/types';

import { TableLayoutPluginContext } from '../../../contexts/tableLayoutPluginContext';

import useReactionActions from './useReactionAction';

export default function IaTableReactionPicker() {
  const [pluginValue, setPluginValue] = useContext(TableLayoutPluginContext);
  const { click: changeReaction } = useReactionActions();
  const handleClose = () => {
    setPluginValue((st) => ({
      ...st,
      reaction: { ...st.reaction, open: false },
    }));
  };
  return (
    <ReactionPicker
      anchorEl={pluginValue.reaction.anchorEl}
      open={!!pluginValue.reaction.open}
      selected={
        pluginValue.reaction.target
          .filter((item) => item.cell?.value)
          .map((item) => item.cell?.value) as IaPropertyEmoji[]
      }
      onClose={handleClose}
      onChange={(value) =>
        changeReaction(pluginValue.reaction.target, value, {
          action: pluginValue.reaction.action,
        })
      }
      onBlurClose={
        pluginValue.reaction.options?.closeOnBlur ? handleClose : undefined
      }
      container={pluginValue.reaction.options?.menuContainer}
    />
  );
}
