import { useCallback, useContext } from 'react';
import {
  QuestionDetailComponentSubType,
  QuestionDetailComponentType,
} from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import {
  CreatorQuestionDetailContext,
  reloadSetting,
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionCorrectAnswerHandle,
  useCreatorQuestionDetailData,
  useCreatorQuestionDetailHandle,
  useCreatorQuestionInfoData,
  useEditorDispatch,
} from '@lib/web/editor';
import { UnscrambleValue } from '@lib/web/editor/EditorAnswer/components/EditorAnswerUnscramble/types';
import { callWithToast } from '@lib/web/utils';

export const useEventHandle = (questionId: string) => {
  const { reloadCorrectAnswer } =
    useCreatorQuestionCorrectAnswerData(questionId);
  const { reloadQuestionDetail } = useCreatorQuestionDetailData(questionId);
  const { reloadInfo } = useCreatorQuestionInfoData(questionId);
  const dispatch = useEditorDispatch();

  const { removeCreatorQuestionComponent, addCreatorQuestionComponent } =
    useContext(CreatorQuestionDetailContext);

  const { handleCreatorQuestionDetailChange } =
    useCreatorQuestionDetailHandle(questionId);

  const handleBlocksChange = useCallback(
    async (id: string, blocks: ComposerBlock[]) => {
      void handleCreatorQuestionDetailChange(id, blocks);
    },
    [handleCreatorQuestionDetailChange]
  );

  const { handleCorrectAnswerChange } =
    useCreatorQuestionCorrectAnswerHandle(questionId);

  const handleDragOverOtherItem = useCallback(
    async ({ newItems }: { newItems: UnscrambleValue[] }) => {
      void handleCorrectAnswerChange({
        params: {
          id: questionId,
          correctAnswers: newItems.map((item, index) => ({
            componentId: item.id,
            order: index,
          })),
          isReplaceAll: true,
        },
        optimisticData: newItems.map((item, index) => ({
          id: '',
          componentId: item.id,
          value: null,
          order: index,
        })),
      });
    },
    [handleCorrectAnswerChange, questionId]
  );

  const handleAddNewChoice = useCallback(async () => {
    await callWithToast(
      addCreatorQuestionComponent({
        type: QuestionDetailComponentType.Answer,
        subType: QuestionDetailComponentSubType.UnscrambleItem,
        creatorQuestionId: questionId,
      })
    );

    void reloadQuestionDetail();
    void reloadCorrectAnswer();
    await reloadInfo();
    dispatch(reloadSetting());
  }, [
    addCreatorQuestionComponent,
    dispatch,
    questionId,
    reloadCorrectAnswer,
    reloadInfo,
    reloadQuestionDetail,
  ]);

  const handleRemoveChoice = useCallback(
    async (id: string) => {
      await callWithToast(
        removeCreatorQuestionComponent({
          creatorQuestionId: questionId,
          creatorQuestionComponentId: id,
        })
      );

      void reloadCorrectAnswer();
      void reloadQuestionDetail();
      await reloadInfo();
      dispatch(reloadSetting());
    },
    [
      dispatch,
      questionId,
      reloadCorrectAnswer,
      reloadInfo,
      reloadQuestionDetail,
      removeCreatorQuestionComponent,
    ]
  );

  return {
    handleBlocksChange,
    handleDragOverOtherItem,
    handleAddNewChoice,
    handleRemoveChoice,
  };
};
