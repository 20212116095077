import { useTranslation } from 'react-i18next';
import { alpha, Theme, Typography } from '@mui/material';
import { Box } from '@mui/material';
import {
  EditorDifficultyEasy as EditorDifficultyEasyIcon,
  EditorDifficultyHard as EditorDifficultyHardIcon,
  EditorDifficultyMedium as EditorDifficultyMediumIcon,
  EditorDifficultySuper as EditorDifficultySuperIcon,
} from '@front/icon';
import { SquareAvatar } from '@front/ui';
import { Difficulty } from '@lib/web/apis';

import usePracticeQuestion from '../../../hooks/usePracticeQuestion';

const styles = {
  root: {
    display: 'flex',
    gap: 2,
    alignItems: 'center',
  },
  avatar: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    '& svg': {
      color: (theme: Theme) => theme.palette.text.primary,
    },
  },
  content: {
    display: 'grid',
  },
  label: {
    fontSize: 12,
    color: 'grey.500',
  },
  difficulty: {
    fontSize: 16,
    fontWeight: 500,
    lineHeight: 1.5,
  },
};

function DifficultyIcon({ difficulty }: { difficulty: Difficulty | null }) {
  switch (difficulty) {
    case Difficulty.Easy:
      return <EditorDifficultyEasyIcon />;
    case Difficulty.Medium:
      return <EditorDifficultyMediumIcon />;
    case Difficulty.Hard:
      return <EditorDifficultyHardIcon />;
    case Difficulty.Super:
      return <EditorDifficultySuperIcon />;
    default:
      return null;
  }
}

export default function DifficultyLevel() {
  const { t } = useTranslation('quiz');
  const questionInfo = usePracticeQuestion();
  const difficulty = questionInfo.isDynamicDifficulty
    ? questionInfo.dynamicDifficulty
    : questionInfo.staticDifficulty;

  if (!difficulty) return null;

  return (
    <Box sx={styles.root}>
      <SquareAvatar sx={styles.avatar}>
        <DifficultyIcon difficulty={difficulty} />
      </SquareAvatar>
      <Box sx={styles.content}>
        <Typography variant="caption" sx={styles.label}>
          {t('Difficulty Level')}
        </Typography>
        <Typography variant="caption" sx={styles.difficulty}>
          {t(`difficulty.${difficulty}`)}
        </Typography>
      </Box>
    </Box>
  );
}
