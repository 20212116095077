import { alpha, createTheme, PaletteOptions } from '@mui/material/styles';

import rem from '../utils/rem';

import typography from './typography';

// eslint-disable-next-line import/prefer-default-export, @typescript-eslint/no-unused-vars
export const getComponents = (palette: PaletteOptions) => {
  const themePalette = createTheme({
    palette,
  }).palette;

  const isDark = themePalette.mode === 'dark';
  return {
    MuiCssBaseline: {
      styleOverrides: {
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          height: '100%',
          width: '100%',
        },
        body: {
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
          background: themePalette.background.body,
          backgroundAttachment: 'fixed',
          '--announcement-bar-height': '0px',

          '--toaster-container-inset-x': '20px',
          '--toaster-container-inset-y': '20px',

          '--inline-variable-color': alpha(themePalette.text.primary, 0.5),
          '--inline-variable-bgcolor': alpha(themePalette.text.primary, 0.1),

          '--inline-highlight-order-color': themePalette.background.default,
          '--inline-highlight-order-bgcolor': themePalette.text.primary,

          '--inline-mention-color': themePalette.primary.main,
          '--inline-mention-bgcolor': alpha(themePalette.primary.main, 0.1),
          '--inline-mention-bgcolor-hover': alpha(
            themePalette.primary.main,
            0.3
          ),
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          height: 40,
          borderRadius: 20,
          '&.Mui-disabled': {
            opacity: 0.5,
          },
          '& .MuiChip-icon': {
            marginLeft: 11,
          },
          '& .MuiChip-deleteIcon': {
            marginRight: 11,
          },
          '& .MuiChip-deleteIcon:not(:disabled):hover': {
            opacity: 1,
          },
        },
        outlined: {
          borderWidth: 0,
          boxShadow: `inset 0 0 0 1px ${themePalette.text.primary}`,
          '&.MuiChip-clickable': {
            // reset mobile hover styles
            '&:hover, &:active': {
              boxShadow: `inset 0 0 0 1px ${themePalette.text.primary}`,
            },
            '@media (hover: hover)': {
              '&:not(:disabled):hover': {
                backgroundColor: alpha(themePalette.text.primary, 0.3),
              },
            },
            '&:not(:disabled):active:not(:has(.MuiChip-deleteIcon:active))': {
              backgroundColor: themePalette.text.primary,
              color: themePalette.background.default,
            },
          },
        },
        filled: {
          backgroundColor: themePalette.text.primary,
          color: themePalette.background.default,
          '&.MuiChip-clickable': {
            // reset mobile hover styles
            '&:hover, &:active': {
              backgroundColor: themePalette.text.primary,
              color: themePalette.background.default,
              boxShadow: `inset 0 0 0 1px ${themePalette.text.primary}`,
            },
            '@media (hover: hover)': {
              '&:not(:disabled):hover': {
                color: themePalette.text.primary,
                backgroundColor: alpha(themePalette.text.primary, 0.3),
                boxShadow: `inset 0 0 0 1px ${themePalette.text.primary}`,
              },
            },
            '&:not(:disabled):active:not(:has(.MuiChip-deleteIcon:active))': {
              boxShadow: `inset 0 0 0 1px ${themePalette.text.primary}`,
              backgroundColor: themePalette.text.primary,
              color: themePalette.background.default,
            },
          },
        },
        label: {
          paddingLeft: 16,
          paddingRight: 16,
          fontSize: rem(14),
          lineHeight: 1,
          letterSpacing: 0.15,
        },
        icon: {
          marginLeft: 8,
          color: 'inherit',
        },
        deleteIcon: {
          marginRight: '6px',
          color: 'inherit',
          '@media (hover: hover)': {
            '&:not(:disabled):hover': {
              color: 'inherit',
              opacity: 0.8,
            },
          },
        },
      },
    },
    MuiSwitch: {
      styleOverrides: {
        root: {
          padding: 0,
          height: 22,
          width: 38,
          '&:has(.Mui-disabled)': {
            opacity: 0.5,
          },
        },
        switchBase: {
          padding: 0,
          height: 22,
          width: 22,
          '&.Mui-checked + .MuiSwitch-track': {
            opacity: 1,
          },
          '&.Mui-checked': {
            transform: 'translateX(16px)',
          },
          '&:hover': {
            backgroundColor: isDark
              ? 'rgba(1, 207, 252, 0.5)'
              : 'rgba(2, 114, 217, 0.5)',
          },
        },
        thumb: {
          backgroundColor: 'white',
          boxShadow: 'unset',
          width: 20,
          height: 20,
        },
        track: {
          backgroundColor: themePalette.grey[500],
          opacity: 1,
          borderRadius: 10,
        },
        colorPrimary: {
          '&.Mui-checked + .MuiSwitch-track': {
            backgroundColor: isDark
              ? themePalette.primary.light
              : themePalette.primary.dark,
          },
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: 0,
          marginRight: 0,
          '@media (hover: hover)': {
            '&:not(.Mui-disabled):hover': {
              backgroundColor: alpha(themePalette.text.primary, 0.05),
            },
          },
        },
        label: {
          marginLeft: 4,
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          backgroundColor: themePalette.text.primary,
        },
        flexContainer: {
          gap: 16,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          ...typography.body1,
          padding: 0,
          opacity: 0.5,
          minHeight: 48,
          fontWeight: 400,
          '&.Mui-selected': {
            fontWeight: 500,
            opacity: 1,
          },
          '& > .MuiTab-iconWrapper': {
            marginRight: 4,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: 'inherit',
          '&:disabled': {
            color: 'inherit',
            opacity: 0.5,
          },
          '@media (hover: hover)': {
            '&:not(:disabled):hover': {
              backgroundColor: themePalette.text.primary,
              color: themePalette.background.default,
            },
          },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        popper: {
          userSelect: 'none' as const,
        },
        tooltip: {
          color: themePalette.background.darker,
          backgroundColor: themePalette.text.primary,
          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
          fontSize: 12,
          lineHeight: '16px',
          padding: '8px',
          fontWeight: 400,
          maxWidth: 200,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '&.Mui-disabled': {
            color: 'inherit',
          },
          '&.MuiInputBase-multiline': {
            padding: 0,
          },
        },
        input: {
          '&.Mui-disabled': {
            WebkitTextFillColor: 'inherit',
          },
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        paper: {
          backgroundImage: 'unset',
          backgroundColor: themePalette.background.darker,
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '& .MuiBackdrop-root': {
            backdropFilter: 'unset',
          },
        },
        paper: {
          backgroundImage: 'unset',
          backgroundColor: themePalette.background.darker,
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundImage: 'unset',
          backgroundColor: themePalette.background.darker,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          paddingTop: 6,
          paddingBottom: 6,
          minWidth: 265,
        },
        paper: {
          backgroundColor: themePalette.background.menu,
          boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
          backdropFilter: 'blur(50px)',
          borderRadius: 1,
          border:
            themePalette.mode === 'light'
              ? '1px solid rgba(8, 8, 8, 0.05)'
              : '1px solid rgba(255, 255, 255, 0.1)',
        },
      },
    },
    MuiListSubheader: {
      styleOverrides: {
        root: {
          lineHeight: '28px',
          padding: '0 12px',
          color: alpha(themePalette.text.primary, 0.5),
          backgroundColor: 'transparent',
          fontSize: 12,
          fontWeight: 400,
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          minHeight: 28,
          paddingTop: 0,
          paddingBottom: 0,
          paddingLeft: 12,
          paddingRight: 12,
          fontSize: 14,
          '@media (max-width: 767.99px)': {
            minHeight: '45px !important',
            fontSize: 16,
          },
          '&.Mui-disabled': {
            opacity: 0.25,
          },
          '&.Mui-selected': {
            backgroundColor: alpha(themePalette.text.primary, 0.1),
          },
          '@media (hover: hover)': {
            '&:not(.Mui-disabled):hover': {
              backgroundColor: alpha(themePalette.text.primary, 0.05),
              color: themePalette.text.primary,
            },
          },
          '& .MuiListItemIcon-root': {
            minWidth: '24px',
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: 'inherit',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          backgroundColor: isDark
            ? alpha(themePalette.text.primary, 0.05)
            : themePalette.grey[50],
          boxShadow: isDark ? '0px 8px 16px rgba(0, 0, 0, 0.15)' : 'none',
          backgroundImage: 'unset',
          borderRadius: '8px',
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '20px',
          '&:last-child': {
            paddingBottom: '20px',
          },
        },
      },
    },
    MuiModal: {
      styleOverrides: {
        root: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        },
        backdrop: {
          backdropFilter: 'blur(25px)',
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        wrapper: {
          paddingLeft: 8,
          paddingRight: 8,
        },
        textAlignLeft: {
          '&:before': {
            display: 'none',
          },
          '& .MuiDivider-wrapper': {
            paddingLeft: 0,
          },
        },
        textAlignRight: {
          '&:after': {
            display: 'none',
          },
          '& .MuiDivider-wrapper': {
            paddingRight: 0,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 8,
          borderBottom: 'unset',
          fontSize: 16,
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          '& .MuiTableCell-root': {
            color: alpha(themePalette.text.primary, 0.5),
            fontWeight: 700,
            fontSize: 14,
          },
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          '& .MuiTableRow-root:not(:last-child) .MuiTableCell-root': {
            borderBottom: '8px solid transparent',
          },
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          paddingRight: 68,
        },
        endAdornment: {
          right: 8,
          display: 'flex',
        },
        listbox: {
          padding: '6px 0',
          backgroundColor: themePalette.background.menu,
          color: themePalette.text.primary,
        },
        option: {
          padding: '3px 12px !important',
          fontSize: 14,
          lineHeight: '22px',

          '@media (hover: hover)': {
            '&:not(:disabled):hover': {
              backgroundColor: alpha(themePalette.text.primary, 0.05),
            },
          },
          '&.MuiAutocomplete-option.Mui-focused': {
            backgroundColor: alpha(themePalette.text.primary, 0.05),
          },
          '&.MuiAutocomplete-option[aria-selected="true"]': {
            backgroundColor: `${alpha(
              themePalette.text.primary,
              0.1
            )} !important`,
          },
          '&.MuiAutocomplete-option.Mui-focusVisible': {
            backgroundColor: alpha(themePalette.text.primary, 0.05),
          },
          '@media (max-width: 767.99px)': {
            minHeight: '45px !important',
            fontSize: 16,
          },
        },
        noOptions: {
          color: themePalette.text.primary,
          backgroundColor: themePalette.background.menu,
          fontSize: 14,
          lineHeight: '22px',
          '@media (max-width: 767.99px)': {
            lineHeight: '45px',
            fontSize: 16,
          },
        },
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          height: 8,
          marginBottom: 0,
          padding: '9px 0',
          '@media (pointer: coarse)': {
            padding: '9px 0',
          },
        },
        rail: {
          backgroundColor: alpha(themePalette.text.primary, 0.5),
        },
        track: {
          background: themePalette.gradient.primary,
          border: 'none',
        },
        thumb: {
          height: 26,
          width: 26,
          background: themePalette.secondary.main,
          '&:after': {
            content: '""',
            position: 'absolute',
            width: 13,
            height: 13,
            borderRadius: '50%',
            backgroundColor: themePalette.background.default,
          },
          boxShadow: 'none !important',
          '&:hover': {
            '&:after': {
              backgroundColor: themePalette.text.primary,
            },
          },
          '&.Mui-active': {
            cursor: 'grabbing',
          },
        },
        mark: {
          opacity: 0,
        },
        markLabel: {
          top: 34,
          color: alpha(themePalette.text.primary, 0.5),
          '@media (pointer: coarse)': {
            top: 34,
          },
        },
        valueLabel: {
          top: 34,
          background: 'none',
          padding: 0,
          '@media (pointer: coarse)': {
            top: 34,
          },
          '&.MuiSlider-valueLabelOpen': {
            transform: 'unset',
          },
        },
      },
    },
  };
};
