import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Theme } from '@mui/material';
import Typography from '@mui/material/Typography';
import { alpha } from '@mui/system';
import { nonNullable } from '@front/helper';
import { EditorAI as EditorAIIcon } from '@front/icon';
import { useAuth } from '@lib/web/apis';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { useFilteredChannels } from '@lib/web/thread/hooks/channels/useFilteredChannels';
import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import PromptItem from '@lib/web/thread/ThreadComposer/components/AiAction/PromptItem';
import { composerHtmlToPlainText } from '@lib/web/thread/ThreadTextComposer';
import { getMessagePlainText } from '@lib/web/thread/utils/messageUtils';
import { uniq } from 'lodash';

const styles = {
  title: {
    typography: 'caption',
    padding: '8px 12px 8px 12px',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  popper: {
    zIndex: 1300,
    '& .popper-content': {
      backgroundColor: 'rgba(21, 26, 40, 1)',
      borderRadius: '4px',
      border: (theme: Theme) =>
        `1px solid ${alpha(theme.palette.text.primary, 0.05)}`,

      boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
      backdropFilter: 'blur(50px)',
    },
  },
};

const useRecentPrompts = (selectedAgentId?: string) => {
  const { member } = useAuth();
  const myMemberId = member?.memberId;
  const recentPromptChannels = useFilteredChannels({
    scope: 'recent-prompt',
    key: myMemberId && selectedAgentId ? selectedAgentId : '',
    filters: {
      assignedAgentId: {
        $eq: selectedAgentId,
      },
      created_by_id: {
        $eq: myMemberId as string,
      },
      firstMessageCreatedById: {
        // use created_by_id to filter is not enough because we might create a child channel with other people's message
        $eq: myMemberId as string,
      },
      $or: [
        // In case a user is removed from a self-created channel, we also add a member filter to ensure the user has the necessary permissions.
        {
          type: 'team',
          members: { $in: [myMemberId as string] },
        },
        {
          type: 'public',
          members: { $in: [myMemberId as string] },
        },
      ],
    },
    sort: [{ created_at: -1 }],
    options: {
      message_limit: 0, // we only need channel data
      member_limit: 0, // we only need channel data
    },
  });

  return useMemo(
    () =>
      recentPromptChannels.dataset
        .map((d) =>
          getMessagePlainText({
            text: d.data?.firstMessageText,
            customBlocks: d.data?.firstMessageCustomBlocks,
          })
        )
        .filter(nonNullable),
    [recentPromptChannels.dataset]
  );
};

function SelectRecentPromptContent({ agentId }: { agentId: string }) {
  const { text } = useThreadComposer();
  const { commands } = useContext(TextComposerContext);
  const { t } = useTranslation('thread');
  const recentPrompts = useRecentPrompts(agentId);

  const keywordToFilter = useMemo(
    () => composerHtmlToPlainText(text).toUpperCase(),
    [text]
  );

  const filteredPrompts = useMemo(
    () =>
      uniq(
        recentPrompts.filter((prompt) =>
          prompt.toUpperCase().includes(keywordToFilter)
        )
      ),
    [keywordToFilter, recentPrompts]
  );

  if (filteredPrompts.length === 0) {
    return null;
  }

  if (filteredPrompts.length < 3) {
    return (
      <>
        <Typography sx={styles.title}>
          {t('composer.ai.shortcut.recent.title')}
        </Typography>
        {filteredPrompts.map((prompt, index) => (
          <PromptItem
            key={index}
            icon={<EditorAIIcon />}
            text={prompt}
            onClick={() => commands.insertContent(prompt)}
          />
        ))}
      </>
    );
  }

  return (
    <>
      <Typography sx={styles.title}>
        {t('composer.ai.shortcut.recent.title')}
      </Typography>
      {filteredPrompts.map((prompt, index) => (
        <PromptItem
          key={index}
          icon={<EditorAIIcon />}
          text={prompt}
          onClick={() => commands.insertContent(prompt)}
        />
      ))}
    </>
  );
}

export default function SelectRecentPrompt() {
  const { aiActionState: state } = useThreadComposer();
  const agentId = state.selectedAgent?.agent.agentId;

  if (!agentId) return null;

  return <SelectRecentPromptContent agentId={agentId} />;
}
