import { forwardRef } from 'react';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import SimpleTooltip from '../../Tooltip/SimpleTooltip';
import IconButton, { IconButtonProps } from '../IconButton';

const styles = {
  hideTooltip: {
    display: 'none',
  },
};

type TipButtonProps<D extends 'button'> = IconButtonProps<
  D,
  {
    title: string;
    customSize?: number;
    selected?: boolean;
    loading?: boolean;
    hideTooltip?: boolean;
  }
>;

function TipButtonInner<D extends 'button'>(
  {
    sx,
    title,
    children,
    disabled,
    customSize,
    hideTooltip,
    ...rest
  }: TipButtonProps<D>,
  ref: React.ForwardedRef<HTMLButtonElement>
) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const button = (
    <IconButton
      ref={ref}
      sx={sx}
      customSize={customSize || (mdDown ? 28 : 24)}
      disabled={disabled}
      {...rest}
    >
      {children}
    </IconButton>
  );

  if (mdDown || disabled) return button;
  return (
    <SimpleTooltip
      title={title}
      slotProps={{
        popper: {
          sx: [!!hideTooltip && styles.hideTooltip],
        },
      }}
    >
      {button}
    </SimpleTooltip>
  );
}
const TipButton = forwardRef<HTMLButtonElement, TipButtonProps<'button'>>(
  TipButtonInner
) as <D extends 'button'>(
  props: TipButtonProps<D> & {
    ref?: React.ForwardedRef<HTMLButtonElement>;
  }
) => ReturnType<typeof TipButtonInner>;

export default TipButton;
