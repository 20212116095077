import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import useSWR, { Key, SWRConfiguration } from 'swr';
import { fetcher } from '@lib/web/apis';

import useToken from './useToken';

export interface Config<Data = unknown, Error = unknown>
  extends Omit<SWRConfiguration<Data, AxiosError<Error>>, 'fallbackData'> {
  fallbackData?: Data;
  withPublicAccessKey?: boolean;
  method?: 'POST' | 'GET';
  auth?: boolean;
  token?: string;
  data?: any;
  baseURL?: string;
}

export function useRequest<Data = unknown, Error = unknown>(
  url?: Key,
  { fallbackData, auth, ...config }: Config<Data, Error> = {}
) {
  const token = useToken();
  const sid = useRouter().query.sid as string;
  const urlKey = auth && !sid ? (token ? url : undefined) : url;
  return useSWR<Data, AxiosError<Error>>(
    urlKey,
    config.withPublicAccessKey
      ? (_url, _config) =>
          fetcher(_url, {
            ..._config,
            _withPublicAccessKey: true,
            _token: token,
            _sid: sid,
          })
      : (_url, _config) =>
          fetcher(_url, {
            ..._config,
            method: config.method,
            _token: token,
            _sid: sid,
            data: config.data,
            baseURL: config.baseURL,
          }),
    config
  );
}
