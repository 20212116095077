import { useEffect } from 'react';

import { usePassageEl } from '../PassageEl';

import { useLineNumberDisplay } from './useLineNumberDisplay';

export function useRenderLineMarker(
  triggerRender?: number | string | boolean,
  targetElement?: HTMLElement | null
) {
  const getLineNumberDisplay = useLineNumberDisplay();
  const { passageEl } = usePassageEl();

  useEffect(() => {
    const allLineMarkTarget = (targetElement || document).querySelectorAll(
      '.inline-line-marker'
    );
    allLineMarkTarget.forEach((lineMarker) => {
      const targetId = lineMarker.getAttribute('data-render-target-id');
      const [line, display] = targetId
        ? getLineNumberDisplay(targetId, {
            dataIdAttr: 'data-render-id',
            contentSelect: '.render-content',
            parentElement: passageEl,
          })
        : [];

      if (typeof display === 'string') {
        const words = display.split(' ');
        if (words.length < 3) {
          lineMarker.innerHTML = `Line ${line} (“${display}”)`;
        } else {
          const firstWord = words[0];
          const lastWord = words[words.length - 1];

          lineMarker.innerHTML = `Line ${line} (“${firstWord}...${lastWord}”)`;
        }
      } else if (display) {
        lineMarker.innerHTML = `Line ${line} (“${display}”)`;
      }
    });
  }, [triggerRender, passageEl, getLineNumberDisplay, targetElement]);
}
