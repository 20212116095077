import React from 'react';

export default function EditorDifficultyEasy({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 9C9.32 11.6087 6.4 12.938 5 13.5477V15.3668C8 14.0075 9 13.5477 12 11.7286C15 13.5477 16 13.9975 19 15.3668V13.5477C17.6 12.938 14.68 11.6187 12 9Z"
        fill="currentColor"
      />
    </svg>
  );
}
