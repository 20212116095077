import ListTooltipLayout from '../../ListTooltipLayout';
import IaAlbumBaseItem from '../components/IaAlbumItem/IaAlbumBaseItem';

import { AlbumLayoutListTooltipConfig } from './types';

const styles = {
  tooltip: {
    '& .MuiTooltip-tooltip': {
      p: 0,
      borderRadius: 1,
      maxWidth: 'unset',
    },
  },
};

type AlbumLayoutListTooltipProps = {
  config: AlbumLayoutListTooltipConfig['config'];
};
export default function AlbumLayoutListTooltip({
  config,
}: AlbumLayoutListTooltipProps) {
  return (
    <IaAlbumBaseItem.Tooltip
      slotProps={{
        popper: {
          sx: styles.tooltip,
        },
      }}
      followCursor
    >
      <ListTooltipLayout {...config} />
    </IaAlbumBaseItem.Tooltip>
  );
}
