import { ReactNode, useEffect, useRef, useState } from 'react';
import Router from 'next/router';

import RouteHistoryContext, {
  initialValue,
  RouteHistoryValue,
} from './RouteHistoryContext';

type RouteHistoryProviderProps = {
  children: ReactNode;
};

export default function RouteHistoryProvider({
  children,
}: RouteHistoryProviderProps) {
  const backRef = useRef(false);
  const providerValue = useState<RouteHistoryValue>(initialValue);

  useEffect(() => {
    const handleRouteChangeStart = (
      nextUrl: string,
      { shallow }: { shallow?: boolean }
    ) => {
      if (shallow) return;

      if (backRef.current) {
        backRef.current = false;
      } else {
        providerValue[1]((st) => ({
          ...st,
          prevRoutes:
            st.prevRoutes[st.prevRoutes.length - 1] === Router.asPath
              ? st.prevRoutes
              : [...st.prevRoutes, Router.asPath],
        }));
      }
    };

    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    Router.beforePopState(() => {
      providerValue[1]((st) => ({
        ...st,
        prevRoutes: st.prevRoutes.slice(0, -1),
      }));

      backRef.current = true;
      return true;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <RouteHistoryContext.Provider value={providerValue}>
      {children}
    </RouteHistoryContext.Provider>
  );
}
