import { useMemo } from 'react';
import { ClubJoinedStatus, ClubStatus, useAuth } from '@lib/web/apis';

import useIaClub from './useIaClub';

export default function useIaClubStatus(clubSlug: string) {
  const { userId } = useAuth();
  const { club, mutate, isEmpty } = useIaClub(clubSlug);

  return useMemo(() => {
    if (!clubSlug || !club) {
      return {
        club,
        joined: undefined,
        beta: undefined,
        comingSoon: undefined,
        notFound: isEmpty,
        active: undefined,
        inactive: undefined,
        mutate,
      };
    }

    if (!userId) {
      return {
        club: club,
        joined: undefined,
        beta: club.clubStatus === ClubStatus.Beta,
        comingSoon: club.clubStatus === ClubStatus.ComingSoon,
        active: club.clubStatus === ClubStatus.Active,
        inactive: club.clubStatus === ClubStatus.Inactive,
        notFound: false,
        mutate,
      };
    }

    return {
      club,
      joined: club.joinStatus === ClubJoinedStatus.Joined,
      beta: club.clubStatus === ClubStatus.Beta,
      comingSoon: club.clubStatus === ClubStatus.ComingSoon,
      active: club.clubStatus === ClubStatus.Active,
      inactive: club.clubStatus === ClubStatus.Inactive,
      notFound: false,
      mutate,
    };
  }, [clubSlug, club, userId, mutate, isEmpty]);
}
