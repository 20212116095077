import { MouseEvent, TouchEvent, useCallback, useRef, useState } from 'react';
import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import Badge from '@mui/material/Badge';
import { useDateFormat, useLongPress, useToggleSideMenu } from '@front/helper';
import { PrivacyPrivate as PrivacyPrivateIcon } from '@front/icon';
import {
  DotLoadingIcon,
  IndicatorGroup,
  MaskIcon,
  SimpleTooltip,
  SquareAvatar,
  useTruncated,
} from '@front/ui';
import Emphasis from '@lib/ia/src/components/Emphasis';
import Icon from '@lib/ia/src/components/Icon';
import RichText from '@lib/ia/src/components/RichText';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import ReplyDivider from '@lib/ia/src/layouts/ChannelLayout/components/ContentItem/MessageItem/components/ReplyDivider';

import {
  AgentComposingStatus,
  AgentMaterialProcessStatus,
  MessageItemAction,
  MessageItemConfig,
} from '../../../types';
import NotificationCta from '../../NotificationCta/NotificationCta';

import ActionBar from './components/ActionBar';
import DateDivider from './components/DateDivider';
import NewThreadDivider from './components/NewThreadDivider';
import Replies from './components/Replies';
import SideMenu from './components/SideMenu';
import TypingEffect from './components/TypingEffect';
import { useReadStateControl } from './hooks/useReadStateControl';

const styles = {
  root: {
    width: '100%',
  },
  container: {
    position: 'relative',
    display: 'grid',
    gridTemplateColumns: '32px 1fr',
    textAlign: 'left',
    gap: 1,
    width: '100%',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
    },

    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    py: 1,
  },
  rootIsUnread: {
    backgroundColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.1),

    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.error.dark, 0.15),
      },
    },
  },
  rootClickable: {
    cursor: 'pointer',
  },
  maskIcon: {
    width: { xs: 28, md: 32 },
    height: { xs: 28, md: 32 },
    '& svg': {
      width: { xs: 16, md: 24 },
      height: { xs: 16, md: 24 },
    },
  },
  avatarPrivateBadge: {
    '& .MuiBadge-badge': {
      backgroundColor: 'background.darker',
      px: 0,
      minWidth: 'unset',
      width: 14,
      height: 14,
    },
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    minWidth: 0,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  name: {
    display: 'flex',
    alignItems: 'center',
  },
  ellipsis: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  underline: {
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  timestamp: {
    flexShrink: 0,
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  content: {
    minHeight: '17px',
    typography: 'chatBody',
    width: '100%',
    wordBreak: 'break-word',
    '& .html-renderer': {
      display: 'block',
    },
  },
  contentIsNewThread: {
    '& p': {
      fontWeight: '700 !important',
    },
  },
  helperText: {
    width: '100%',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
  cta: {
    mt: 0.5,
    display: 'flex',
    gap: 2,
  },
};

const Message = ({
  content,
  contentVariant,
  agentComposingStatus,
  agentMaterialProcessStatus,
  enableTypingEffect,
  showTypingEffect = false,
}: {
  content: MessageItemProps['content'];
  contentVariant: MessageItemProps['contentVariant'];
  agentComposingStatus: MessageItemProps['agentComposingStatus'];
  agentMaterialProcessStatus: MessageItemProps['agentMaterialProcessStatus'];
  enableTypingEffect: MessageItemProps['enableTypingEffect'];
  showTypingEffect: MessageItemProps['showTypingEffect'];
}) => {
  if (
    agentMaterialProcessStatus === AgentMaterialProcessStatus.Paragraph &&
    agentComposingStatus !== AgentComposingStatus.Completed
  ) {
    return <DotLoadingIcon />;
  }

  if (enableTypingEffect && showTypingEffect) {
    return <TypingEffect text={content} variant={contentVariant} />;
  }

  return <RichText text={content} variant={contentVariant} />;
};

function SenderAvatar(props: MessageItemConfig['sender']) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if (props.type === 'avatar') {
    const { avatarUrl, name, badge } = props;

    if (badge === 'private') {
      return (
        <Badge
          overlap="circular"
          sx={styles.avatarPrivateBadge}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          badgeContent={<PrivacyPrivateIcon width={12} height={12} />}
        >
          <SquareAvatar size={mdUp ? 32 : 28} src={avatarUrl}>
            {name}
          </SquareAvatar>
        </Badge>
      );
    }

    return (
      <SquareAvatar size={mdUp ? 32 : 28} src={avatarUrl}>
        {name}
      </SquareAvatar>
    );
  }

  if (props.type === 'icon') {
    const { icon, color, bgColor, size } = props;
    return (
      <MaskIcon
        sx={[
          styles.maskIcon,
          {
            color,
            background: bgColor,
          },
        ]}
      >
        <Icon name={icon} width={size || 24} height={size || 24} />
      </MaskIcon>
    );
  }

  return null;
}

type MessageItemProps = MessageItemConfig;

export default function MessageItem({
  id,
  sender,
  content,
  sentTime,
  lastActivityTime,
  showReplyThread,
  replies = 0,
  showReplyDivider,
  replyDividerReplies = 0,
  members,
  helperText,
  clickItemAction,
  clickSenderAction,
  viewThreadAction,
  events = [],
  actionBarEvents = [],
  dateDividerTime,
  readState,
  previousMessageProps,
  eventMenuClassName,
  contentVariant,
  cta,
  agentComposingStatus,
  agentMaterialProcessStatus,
  enableTypingEffect = false,
  showTypingEffect = false,
}: MessageItemProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();
  const { displayMessageTimeFormat } = useDateFormat();
  const [sideMenuDropdownOpen, setSideMenuDropdownOpen] = useState(false);
  const boxRef = useRef<HTMLDivElement | null>(null);
  const { showSideMenu, setShowSideMenu } = useToggleSideMenu(boxRef.current);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));
  const { containerRef, isTruncated } = useTruncated();

  const senderClickable = !!clickSenderAction;

  const onClickSender = (e: MouseEvent): void => {
    if (!clickSenderAction) {
      return;
    }

    e.stopPropagation();
    e.preventDefault();

    const onClickAction = getIaAction<MessageItemAction['metadata']>(
      clickSenderAction.value
    );
    onClickAction?.action?.(clickSenderAction.metadata);
  };

  const handleItemClick = (): void => {
    if (!clickItemAction) return;

    if (clickItemAction.type === 'link') {
      void iaRoutingAction(clickItemAction.value);
    } else {
      const onClickAction = getIaAction<string>(clickItemAction.value);
      onClickAction?.action?.(id);
    }
  };

  const onLongPress = useCallback((): void => {
    if (mdUp) return;
    setSideMenuDropdownOpen(true);
  }, [mdUp, setSideMenuDropdownOpen]);

  const longPress = useLongPress(onLongPress, 500);

  const handleMouseDown = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      longPress.onMouseDown(event);
    },
    [longPress]
  );
  const handleMouseUp = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      longPress.onMouseUp(event);
    },
    [longPress]
  );
  const handleMouseLeave = useCallback(
    (event: MouseEvent<HTMLDivElement>): void => {
      longPress.onMouseLeave(event);
      setShowSideMenu(false);
    },
    [longPress, setShowSideMenu]
  );
  const handleTouchStart = useCallback(
    (event: TouchEvent<HTMLDivElement>): void => {
      longPress.onTouchStart(event);
    },
    [longPress]
  );
  const handleTouchEnd = useCallback(
    (event: TouchEvent<HTMLDivElement>): void => {
      longPress.onTouchEnd(event);
    },
    [longPress]
  );

  const { showIsNewThread, showIsUnread, isPreviousNewThread } =
    useReadStateControl({
      sentTime,
      readState,
      previousMessageReadState: previousMessageProps?.readState,
      hasReplyThread: replies > 0 && !!showReplyThread,
    });

  const isDifferentDate =
    dateDividerTime !== previousMessageProps?.dateDividerTime;

  const showActionBar = actionBarEvents && actionBarEvents.length > 0;

  return (
    <Box
      sx={styles.root}
      className={`message-item ${
        showIsNewThread ? 'message-item-new-thread' : ''
      } ${showIsUnread ? 'message-item-unread' : ''}`}
    >
      {isDifferentDate && <DateDivider time={dateDividerTime} />}
      <Box
        sx={[
          styles.container,
          !!clickItemAction && styles.rootClickable,
          !!showIsUnread && styles.rootIsUnread,
        ]}
        className="thread-message-item"
        onMouseEnter={() => setShowSideMenu(true)}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onClick={handleItemClick}
        ref={boxRef}
      >
        {showIsNewThread && (isDifferentDate || !isPreviousNewThread) && (
          <NewThreadDivider />
        )}

        <SimpleTooltip title={sender.name} followCursor>
          <Box>
            <SenderAvatar {...sender} />
          </Box>
        </SimpleTooltip>
        <Box sx={styles.main}>
          <Box sx={styles.header}>
            <Box
              sx={[
                styles.name,
                styles.ellipsis,
                senderClickable && styles.underline,
              ]}
              ref={containerRef}
              onClick={onClickSender}
            >
              {isTruncated ? (
                <SimpleTooltip title={sender.name}>
                  <Typography
                    variant="body2"
                    display="inline"
                    fontWeight={700}
                    sx={styles.ellipsis}
                  >
                    {sender.name}
                  </Typography>
                </SimpleTooltip>
              ) : (
                sender.name
              )}
              {!!sender.indicators && (
                <IndicatorGroup indicators={sender.indicators} size={16} />
              )}
            </Box>

            <Typography variant="caption" sx={styles.timestamp}>
              {displayMessageTimeFormat(sentTime)}
            </Typography>
          </Box>
          <Box
            sx={[
              styles.content,
              !!showIsNewThread && styles.contentIsNewThread,
            ]}
            className="thread-message-item_content"
          >
            <Message
              content={content}
              contentVariant={contentVariant}
              agentComposingStatus={agentComposingStatus}
              agentMaterialProcessStatus={agentMaterialProcessStatus}
              enableTypingEffect={enableTypingEffect}
              showTypingEffect={showTypingEffect}
            />
          </Box>
          {!!cta && cta.length > 0 && (
            <Box sx={styles.cta}>
              {cta.map((item, index) => (
                <NotificationCta key={index} {...item} />
              ))}
            </Box>
          )}

          {showActionBar && <ActionBar id={id} events={actionBarEvents} />}

          {helperText && (
            <Box sx={styles.helperText}>
              <Typography variant="caption">
                <Emphasis content={helperText} />
              </Typography>
            </Box>
          )}

          {showReplyThread && (
            <Replies
              id={id}
              replies={replies}
              members={members}
              lastActivityTime={lastActivityTime}
              viewThreadAction={viewThreadAction}
            />
          )}
        </Box>
        <SideMenu
          id={id}
          boxRef={boxRef}
          dropdownOpen={sideMenuDropdownOpen}
          setDropdownOpen={setSideMenuDropdownOpen}
          show={showSideMenu}
          setShow={setShowSideMenu}
          eventMenuClassName={eventMenuClassName}
          events={events}
        />
      </Box>
      {showReplyDivider && <ReplyDivider replies={replyDividerReplies} />}
    </Box>
  );
}
