import { useMemo } from 'react';
import {
  ChannelLayoutConfig,
  ChannelLayoutLoadingConfig,
} from '@lib/ia/src/layouts/ChannelLayout/types';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { Channel } from 'stream-chat';
import { StreamMessage } from 'stream-chat-react';

export const useThreadMessageChannelLayoutConfig = ({
  channel,
  messages,
  isLoading,
  messageIdToChildChannel,
  availableEventNames = [],
}: {
  channel: Channel;
  messages: StreamMessage<StreamChatGenerics>[] | undefined;
  isLoading: boolean;
  messageIdToChildChannel: Record<string, Channel>;
  availableEventNames?: string[];
}) => {
  return useMemo(() => {
    return [
      {
        layout: 'channel-layout',
        items:
          messages?.map((message) => ({
            type: 'customize',
            value: 'messageItem',
            metadata: {
              channel,
              message,
              messages,
              childChannel: messageIdToChildChannel[message.id],
              availableEventNames,
            },
          })) || [],
      } as ChannelLayoutConfig,
      ...(isLoading
        ? [
            {
              layout: 'channel-layout-skeleton',
            } as ChannelLayoutLoadingConfig,
          ]
        : []),
    ];
  }, [
    availableEventNames,
    channel,
    isLoading,
    messageIdToChildChannel,
    messages,
  ]);
};
