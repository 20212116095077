import {
  composerBlocksToHtml,
  composerHtmlToPlainText,
} from '@lib/web/thread/ThreadTextComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { StreamMessage } from 'stream-chat-react';

export const getMessagePlainText = (
  message: Pick<StreamMessage<StreamChatGenerics>, 'text' | 'customBlocks'>
) => {
  if (message.text) {
    return composerHtmlToPlainText(message.text);
  }
  /**
   * If the message content is too large, there will be no message.text.
   * In this case, we need to use message.customBlocks to render the content.
   * For more details, see useBuildMessageData.
   */
  return message.customBlocks
    ? composerHtmlToPlainText(composerBlocksToHtml(message.customBlocks))
    : '';
};
