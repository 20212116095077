import React from 'react';

export default function MainClubGroup({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 3C4.8 3 3 4.8 3 12C3 19.2 4.8 21 12 21C19.2 21 21 19.2 21 12C21 4.8 19.2 3 12 3Z"
        fill="currentColor"
      />
      <path
        d="M13.8006 6H17.0566C17.5776 6 18 6.52107 18 6.94343V10.1994C18 10.7204 17.5776 11.1428 17.0566 11.1428H13.8006C13.2796 11.1428 12.8572 10.6217 12.8572 10.1994V6.94343C12.8572 6.42235 13.2796 6 13.8006 6Z"
        fill="#080808"
      />
      <path
        d="M6.94343 6H10.1994C10.7204 6 11.1428 6.52107 11.1428 6.94343V10.1994C11.1428 10.7204 10.7204 11.1428 10.1994 11.1428H6.94343C6.42235 11.1428 6 10.6217 6 10.1994V6.94343C6 6.42235 6.42235 6 6.94343 6Z"
        fill="#080808"
      />
      <path
        d="M6.94343 12.8572H10.1994C10.7204 12.8572 11.1428 13.3783 11.1428 13.8006V17.0566C11.1428 17.5776 10.7204 18 10.1994 18H6.94343C6.42235 18 6 17.4789 6 17.0566V13.8006C6 13.2796 6.42235 12.8572 6.94343 12.8572Z"
        fill="#080808"
      />
      <path
        d="M13.8006 12.8572H17.0566C17.5776 12.8572 18 13.3783 18 13.8006V17.0566C18 17.5776 17.5776 18 17.0566 18H13.8006C13.2796 18 12.8572 17.4789 12.8572 17.0566V13.8006C12.8572 13.2796 13.2796 12.8572 13.8006 12.8572Z"
        fill="#080808"
      />
    </svg>
  );
}
