import { MouseEvent } from 'react';
import { alpha, ButtonBase, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { compactNumberFormat, EmphasizeButton, UserAvatar } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';

import { useIaAction } from '../../../core/IaAction/useIaAction';
import { ProfileLayoutAvatarArea } from '../types';

const styles = {
  root: {
    mt: 1,
  },
  avatar: {
    gap: 2,
    alignItems: 'flex-end',
    '& .MuiTypography-body1': {
      fontSize: 20,
      fontWeight: 700,
    },
    '& .MuiTypography-caption': {
      fontSize: 16,
      mt: 0.5,
      mb: 0.5,
    },
  },
  intro: {
    mt: 1,
    typography: 'appBody',
    color: 'alpha.lightA75',
  },
  propertyContainer: {
    display: 'flex',
    gap: 0,
    mt: 2,
  },
  property: {
    px: '4px',
    py: '2px',
    display: 'flex',
    gap: '5px',
    typography: 'body1',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    '& span': {
      color: (theme: Theme) => theme.palette.text.primary,
      fontWeight: 500,
    },
  },
  propertyButton: {
    borderRadius: 1,
    '&:hover': {
      bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  buttonContainer: {
    mt: 3,
    display: 'flex',
    gap: 1,
  },
  button: {
    width: '100%',
    minWidth: 'unset',
  },
  buttonFixedWidth: {
    maxWidth: '149px',
  },
};

export type AreaAvatarProps = ProfileLayoutAvatarArea;

export default function AreaAvatar({
  avatar,
  intro,
  properties,
  buttons,
}: AreaAvatarProps) {
  const { getIaAction } = useIaAction();

  return (
    <Box sx={styles.root}>
      <UserAvatar
        sx={styles.avatar}
        src={avatar.url}
        title={avatar.title}
        subTitle={avatar.subtitle}
        indicators={avatar.indicators}
        size="xl"
      />
      {intro && <Typography sx={styles.intro}>{intro}</Typography>}
      {properties && (
        <Box sx={styles.propertyContainer}>
          {properties.map(({ name, value, clickAction }) =>
            clickAction?.value ? (
              <ButtonBase
                key={name}
                sx={[styles.property, styles.propertyButton]}
                onClick={() => getIaAction(clickAction?.value)?.action()}
              >
                <span>{compactNumberFormat(value)}</span>
                {name}
              </ButtonBase>
            ) : (
              <Box key={name} sx={styles.property}>
                <span>{compactNumberFormat(value)}</span>
                {name}
              </Box>
            )
          )}
        </Box>
      )}
      {buttons && (
        <Box sx={styles.buttonContainer}>
          {buttons.map((button) => {
            const {
              key,
              icon,
              text,
              value,
              buttonVariant,
              buttonColor,
              fullWidth,
            } = button;

            const iaAction = getIaAction<
              typeof button | MouseEvent<HTMLButtonElement>
            >(value);

            return (
              <EmphasizeButton
                key={key}
                sx={[styles.button, !fullWidth && styles.buttonFixedWidth]}
                prefixIcon={icon && <Icon name={icon} width={16} height={16} />}
                variant={buttonVariant}
                color={buttonColor}
                onClick={(ev: MouseEvent<HTMLButtonElement>) => {
                  iaAction?.action(ev);
                }}
                loading={
                  typeof iaAction?.inProgress === 'function'
                    ? iaAction.inProgress(button)
                    : iaAction?.inProgress
                }
              >
                {text}
              </EmphasizeButton>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
