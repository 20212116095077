import React, { Fragment, ReactNode, useMemo, useRef, useState } from 'react';
import { isMacOs } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import {
  alpha,
  Box,
  ButtonBase,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  MenuItem,
  Theme,
  Typography,
} from '@mui/material';
import {
  BlockNoteEditor,
  BlockSchema,
  DefaultBlockSchema,
  ToggledStyle,
} from '@blocknote/core';
import {
  useEditorContentChange,
  useEditorSelectionChange,
} from '@blocknote/react';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
  TextEditorBold as TextEditorBoldIcon,
  TextEditorItalic as TextEditorItalicIcon,
  TextEditorTypeText as TextEditorTypeTextIcon,
} from '@front/icon';
import {
  IconButton,
  ResponsiveMenu,
  ResponsiveTooltip,
  SimpleTooltip,
} from '@front/ui';
import groupBy from 'lodash/groupBy';

import ThemeProvider from '../../../components/ThemeProvider';
import { ComposerToolbarMenuItem } from '../../types';

const styles = {
  root: {
    display: 'flex',
    backgroundColor: 'background.default',
    color: 'text.primary',
    borderRadius: '4px',
    boxShadow: 9,
  },
  styledToolbar: {
    px: 1.5,
    height: '28px',
    display: 'flex',
    alignItems: 'center',
    gap: '3.5px',
  },
  styledButtonIcon: {
    typography: 'body2',
    fontWeight: 'bold',
  },
  styleButtonTooltip: {
    '& .MuiTooltip-tooltip': {
      p: 1,
      color: 'text.primary',
      bgcolor: 'background.darker',
      whiteSpace: 'nowrap',
    },
  },
  styleButtonTooltipInner: {
    display: 'flex',
    flexDirection: 'column',
  },
  styleButtonTooltipInnerHint: {
    opacity: 0.64,
  },
  moreToolbar: {
    typography: 'body2',
    gap: 1,
    borderRadius: '2px 0 0 2px',
    px: 1.5,
    height: '28px',
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        color: 'text.primary',
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
    borderRight: (theme: Theme) =>
      `1px solid ${alpha(theme.palette.text.primary, 0.1)}`,
  },
  moreToolbarTooltip: {
    '.MuiTooltip-tooltip': {
      color: 'text.primary',
      bgcolor: 'background.darker',
      ml: '-0px !important',
      mt: '4px !important',
      p: 1,
    },
  },
  menu: {
    '& .MuiPaper-root': {
      width: '260px',
    },
  },
  hint: {
    flex: 'unset',
    ml: 'auto',

    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
    fontFamily: 'Inconsolata',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 1.5,
  },
};

export const ToggledStyleButton = <BSchema extends BlockSchema>(props: {
  editor: BlockNoteEditor<BSchema>;
  toggledStyle: ToggledStyle;
  name: string;
  hints: string[];
  children: ReactNode;
}) => {
  const [active, setActive] = useState<boolean>(
    props.toggledStyle in props.editor.getActiveStyles()
  );

  useEditorContentChange(props.editor, () => {
    setActive(props.toggledStyle in props.editor.getActiveStyles());
  });

  useEditorSelectionChange(props.editor, () => {
    setActive(props.toggledStyle in props.editor.getActiveStyles());
  });

  const toggleStyle = (style: ToggledStyle) => {
    props.editor.focus();
    props.editor.toggleStyles({ [style]: true });
  };

  return (
    <ResponsiveTooltip
      title={
        <Box sx={styles.styleButtonTooltipInner}>
          <Typography variant="caption" mb="2px">
            {props.name}
          </Typography>
          {props.hints.map((hint, i) => (
            <Typography
              key={i}
              variant="numberCaption"
              sx={styles.styleButtonTooltipInnerHint}
            >
              {hint}
            </Typography>
          ))}
        </Box>
      }
      tooltipProps={{
        slotProps: {
          popper: {
            sx: styles.styleButtonTooltip,
          },
        },
      }}
    >
      <IconButton
        customSize={24}
        onClick={() => toggleStyle(props.toggledStyle)}
        selected={active}
      >
        {props.children}
      </IconButton>
    </ResponsiveTooltip>
  );
};

type FormattingToolbarDropdownProps<
  BSchema extends BlockSchema = DefaultBlockSchema
> = {
  editor: BlockNoteEditor<BSchema>;
  toolbarItems: ComposerToolbarMenuItem<BSchema>[];
  onConvertBlockType?: (type: string) => void;
};

const FormattingToolbarDropdown = <T extends BlockSchema>({
  editor,
  toolbarItems,
  onConvertBlockType,
}: FormattingToolbarDropdownProps<T>) => {
  const { t } = useTranslation('editor');
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    editor.focus();
  };

  const toolbarMenuGroups = useMemo(
    () => groupBy(toolbarItems, (item) => item.type),
    [toolbarItems]
  );

  const currentBlock = useMemo(
    () => editor.getTextCursorPosition().block,
    [editor]
  );

  const selectedMenuItem = useMemo(
    () => toolbarItems.find((item) => item.isSelected(currentBlock)),
    [toolbarItems, currentBlock]
  );

  return (
    <>
      <SimpleTooltip
        title={t('composer.formattingToolbar.Turn into')}
        slotProps={{
          popper: {
            sx: styles.moreToolbarTooltip,
          },
        }}
      >
        <ButtonBase
          ref={buttonRef}
          sx={styles.moreToolbar}
          onClick={handleOpen}
        >
          <TextEditorTypeTextIcon width={16} height={16} />
          <Typography variant="body2">{selectedMenuItem?.display}</Typography>
          {open && <ActionChevronDownIcon width={16} height={16} />}
          {!open && <ActionChevronUpIcon width={16} height={16} />}
        </ButtonBase>
      </SimpleTooltip>
      <ThemeProvider mode="dark">
        <ResponsiveMenu
          open={open}
          onClose={handleClose}
          menuProps={{
            sx: styles.menu,
            anchorEl: buttonRef.current,
            anchorOrigin: {
              vertical: 'top',
              horizontal: 'left',
            },
            transformOrigin: {
              vertical: -32,
              horizontal: 10,
            },
          }}
          sheetProps={{
            defaultContentHeight: toolbarItems.length * 45,
          }}
        >
          <Box>
            {[
              {
                type: 'turnInto',
                label: t('composer.formattingToolbar.Turn into'),
              },
              {
                type: 'transform',
                label: t('composer.formattingToolbar.Transform'),
              },
            ].map(({ type, label }) => {
              const group = toolbarMenuGroups[type];
              if (!group || group.length === 0) return null;

              return (
                <Fragment key={type}>
                  <ListSubheader>{label}</ListSubheader>
                  {group.map((item) => (
                    <MenuItem
                      key={item.nodeType}
                      value={item.nodeType}
                      onClick={() => item.onClick(editor, onConvertBlockType)}
                      selected={item.isSelected(currentBlock)}
                    >
                      <ListItemIcon>{item.icon}</ListItemIcon>
                      <ListItemText>{item.display}</ListItemText>
                      <ListItemText sx={styles.hint}>{item.hint}</ListItemText>
                    </MenuItem>
                  ))}
                </Fragment>
              );
            })}
          </Box>
        </ResponsiveMenu>
      </ThemeProvider>
    </>
  );
};
const FormattingToolbar = <T extends BlockSchema>({
  editor,
  toolbarItems,
  onConvertBlockType,
}: {
  editor: BlockNoteEditor<T>;
  toolbarItems?: ComposerToolbarMenuItem<T>[];
  onConvertBlockType?: (type: string) => void;
}) => {
  const metaKeyName = isMacOs ? 'cmd' : 'ctrl';

  return (
    <ThemeProvider mode="light">
      <Box sx={styles.root}>
        {!!toolbarItems?.length && (
          <FormattingToolbarDropdown
            editor={editor}
            toolbarItems={toolbarItems}
            onConvertBlockType={onConvertBlockType}
          />
        )}
        <Box sx={styles.styledToolbar}>
          <ToggledStyleButton
            editor={editor}
            toggledStyle="bold"
            name="Bold"
            hints={[`${metaKeyName}+B`, '**text**']}
          >
            <Box sx={styles.styledButtonIcon} component="span">
              <TextEditorBoldIcon />
            </Box>
          </ToggledStyleButton>
          <ToggledStyleButton
            editor={editor}
            toggledStyle="italic"
            name="Italicize"
            hints={[`${metaKeyName}+I`, '*text*', '_text_']}
          >
            <Box sx={styles.styledButtonIcon} component="span">
              <TextEditorItalicIcon />
            </Box>
          </ToggledStyleButton>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default FormattingToolbar;
