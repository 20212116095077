import React, { useEffect } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { animated, useSpring } from '@react-spring/web';

const AnimatedBox = animated(Box);

export type AnimateBoxProps = BoxProps & {
  variable?: string | number | boolean;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const AnimateBox = React.forwardRef<any, AnimateBoxProps>(
  ({ variable, ...rest }, ref) => {
    const [styles, api] = useSpring(() => ({ opacity: 0 }));

    useEffect(() => {
      api.start({ opacity: 1 });
      return () => {
        api.start({ opacity: 0, immediate: true });
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [variable]);

    return <AnimatedBox ref={ref} style={styles} {...rest} />;
  }
);

export default AnimateBox;
