import { useCallback } from 'react';
import { Box, ListItemIcon } from '@mui/material';
import { IconButton, SimpleTooltip } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { MessageItemEvent } from '@lib/ia/src/layouts/ChannelLayout/types';

const styles = {
  iconList: {
    minWidth: 'unset',
  },
};

export type MessageItemActionBarProps = {
  id?: string;
  events: MessageItemEvent[];
};

export default function ActionBar({
  id,
  events = [],
}: MessageItemActionBarProps) {
  const { getIaAction, iaRoutingAction } = useIaAction();

  const handleActionBarClick = useCallback(
    (event: MessageItemEvent): void => {
      if (event.type === 'link') {
        iaRoutingAction(event.value);
      } else {
        const onClickAction = getIaAction<string>(event.value);
        onClickAction?.action?.(id, event.metadata);
      }
    },
    [getIaAction, iaRoutingAction, id]
  );

  return (
    <Box>
      {events.map(
        (event) =>
          event.icon && (
            <ListItemIcon
              sx={styles.iconList}
              key={`${event.type}-${event.value}`}
            >
              <SimpleTooltip title={event.text} placement="top">
                <IconButton
                  customSize={24}
                  onClick={() => handleActionBarClick(event)}
                >
                  <Icon name={event.icon} />
                </IconButton>
              </SimpleTooltip>
            </ListItemIcon>
          )
      )}
    </Box>
  );
}
