import React from 'react';
import { useClubSetting } from '@lib/web/apis';
import {
  useCreatorQuestionInfoData,
  useCurrentQuestion,
} from '@lib/web/editor';
import { useClubSlug } from '@lib/web/hooks';

import MultipleChoices from './components/MultipleChoices';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorAnswerMultipleChoice() {
  const { questionId } = useCurrentQuestion();
  const { choiceValues } = useData(questionId);
  const { handleSelect, handleBlocksChange } = useEventHandle(questionId);
  const { infoData } = useCreatorQuestionInfoData(questionId);
  const clubSlug = useClubSlug();
  const { data: clubSettingData } = useClubSetting(clubSlug);
  const clubSetting = clubSettingData?.data;

  return (
    <MultipleChoices
      choiceValues={choiceValues}
      onSelect={handleSelect}
      handleBlocksChange={handleBlocksChange}
      blankOptionEnabled={
        clubSetting?.isEnableNegativeScoring && infoData?.isOfferBlankOption
      }
    />
  );
}
