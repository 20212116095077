import {
  forwardRef,
  Fragment,
  MouseEvent,
  ReactNode,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import Link from 'next/link';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { ActionMore as ActionMoreIcon } from '@front/icon';
import { IconButtonProps, LoadingIcon, ResponsiveDropdown } from '@front/ui';
import { TipButton } from '@lib/web/ui';

import useToolbarButtons from './hooks/useToolbarButtons';
import { getToolbarButtonConfig } from './configs';
import { ToolbarButtonItem, ToolbarButtonType } from './types';

const styles = {
  moreIcon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '0 0 16px',
    maxWidth: 16,
    fontSize: 12,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  buttonItem: {
    width: 'auto',
    minWidth: (theme: Theme) =>
      theme.breakpoints.down('md') ? '24px' : '28px',
  },
  moreOptionPopper: {
    zIndex: 1600,
    '& .popper-content': {
      mt: 1,
      minWidth: '171px',
    },
  },
  option: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  disabledOption: {
    opacity: 0.5,
  },

  icon: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 12,
    '& svg': {
      width: 16,
      height: 16,
    },
  },
  dot: {
    position: 'absolute',
    width: '6px',
    height: '6px',
    borderRadius: '50%',
    bgcolor: 'error.dark',
    top: '-3px',
    right: '-3px',
  },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getTitleIcon(item: ToolbarButtonItem, isMobile: boolean, t: any) {
  const config = getToolbarButtonConfig(item.type, item.customConfig);
  let icon;
  if (!item.renderIcon) {
    icon = isMobile ? config.icon.mobile : config.icon.desktop;
  } else {
    icon = item.renderIcon();
  }
  const title = item.renderTitle ? item.renderTitle() : t(config.titleKey);

  return { icon, title };
}

export type ResponsiveToolbarProps = {
  items?: Array<boolean | ToolbarButtonItem>;
  moreButtonRedDot?: boolean;
  shouldKeepOpened?: boolean;
  onMoreMenuClose?: () => void;
  onMoreButtonClick?: () => void;
  isOptionEqualToValue?: (ev: {
    display: ToolbarButtonType;
    value: ToolbarButtonType;
    item: ToolbarButtonItem;
  }) => boolean;
};

type IconProps = {
  icon: ReactNode;
  showRedDot?: boolean;
};

function DotIcon({ icon, showRedDot }: IconProps) {
  return (
    <Box sx={styles.icon}>
      {icon}
      {showRedDot && <Box component="span" sx={styles.dot} />}
    </Box>
  );
}

type RenderOptionProps = {
  item: ToolbarButtonItem;
};

function RenderOption({ item }: RenderOptionProps) {
  const { t } = useTranslation();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const { title, icon } = getTitleIcon(item, mdDown, t);
  const href = item.href;

  const disabled = !!item.disabled || !!item.loading;
  return (
    <Box
      ref={item.buttonRef}
      className={item.className}
      sx={[styles.option, disabled && styles.disabledOption]}
      {...(href && !disabled ? { href, component: Link } : {})}
    >
      {item.loading ? (
        <LoadingIcon />
      ) : (
        <DotIcon icon={icon} showRedDot={item.showRedDot} />
      )}
      {title}
      {!!item.actionIcon && (
        <Box
          component="span"
          display="inline-flex"
          alignItems="center"
          ml="auto"
        >
          {item.actionIcon}
        </Box>
      )}
    </Box>
  );
}

type ResponsiveToolbarButtonProps = IconButtonProps & {
  title: string;
  icon: ReactNode;
  href?: string;
  onClick?: (ev: MouseEvent<Element>) => void;
  hideTooltip?: boolean;
};

const ResponsiveToolbarButton = forwardRef<
  HTMLButtonElement,
  ResponsiveToolbarButtonProps
>(({ title, icon, href, ...rest }, ref) => (
  <TipButton
    title={title}
    ref={ref}
    {...rest}
    {...(href ? { href, component: Link } : {})}
  >
    {icon}
  </TipButton>
));

ResponsiveToolbarButton.displayName = 'ResponsiveToolbarButton';

export default function ResponsiveToolbar({
  items = [],
  moreButtonRedDot = false,
  shouldKeepOpened = false,
  onMoreMenuClose,
  onMoreButtonClick,
  isOptionEqualToValue,
}: ResponsiveToolbarProps) {
  const { t } = useTranslation();
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const [moreButtonOpened, setMoreButtonOpened] = useState(false);
  const moreButtonRef = useRef<HTMLButtonElement>(null);
  const { visibleButtons, threeDotsButtons } = useToolbarButtons(items);

  const handleMoreClose = () => {
    if (!shouldKeepOpened) {
      onMoreMenuClose?.();
      setMoreButtonOpened(false);
    }
  };

  const handleMoreOptionClick = async (item: ToolbarButtonItem) => {
    if (!item.disabled) {
      item.onClick?.();
    }
    if (!item.disabledAutoClose) handleMoreClose();
  };

  const handleMoreOptionHover = async (item: ToolbarButtonItem) => {
    if (!item.disabled) {
      item.onHover?.();
    }
  };

  const moreButtonOptions = threeDotsButtons.map((item) => {
    return {
      display: item.type,
      value: item.type,
      item,
    };
  });

  const moreButtonClick = () => {
    onMoreButtonClick?.();
    setMoreButtonOpened(true);
  };

  return (
    <>
      {visibleButtons.map((item, index) => {
        const config = getToolbarButtonConfig(item.type, item.customConfig);
        const { title, icon } = getTitleIcon(item, mdDown, t);
        if (config.render) {
          return <Fragment key={index}>{config.render(item)}</Fragment>;
        }

        if (item.customComponent) {
          return <Fragment key={index}>{item.customComponent}</Fragment>;
        }
        return (
          <ResponsiveToolbarButton
            key={index}
            title={title}
            icon={<DotIcon icon={icon} showRedDot={item.showRedDot} />}
            href={item.href}
            onClick={item.onClick}
            sx={styles.buttonItem}
            selected={item.selected}
            ref={item.buttonRef}
            className={item.className}
            hideTooltip={item.hideTooltip}
            disabled={item.disabled || item.loading}
            loading={item.loading}
          />
        );
      })}
      {moreButtonOptions.length > 0 && (
        <TipButton
          title={t('toolbar.More')}
          customSize={mdDown ? 28 : 24}
          onClick={moreButtonClick}
          selected={moreButtonOpened}
          ref={moreButtonRef}
        >
          <Box sx={styles.moreIcon}>
            <ActionMoreIcon />
            {moreButtonRedDot && <Box component="span" sx={styles.dot} />}
          </Box>
        </TipButton>
      )}
      <ResponsiveDropdown
        open={moreButtonOpened}
        options={moreButtonOptions}
        onClick={(ev) => handleMoreOptionClick(ev.item)}
        onHover={(ev) => handleMoreOptionHover(ev.item)}
        onClose={handleMoreClose}
        isOptionEqualToValue={isOptionEqualToValue}
        menuDropdownProps={{
          anchorEl: moreButtonRef.current,
          popperProps: {
            sx: styles.moreOptionPopper,
            // need this class name to avoid RHS being closed and history being cleared
            // ref BaseLayoutRightPanelContainer.tsx#L70
            className: 'right-panel-more-options',
          },
        }}
        renderOption={(ev) => <RenderOption item={ev.item} />}
      />
    </>
  );
}
