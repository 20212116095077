import { ReactNode } from 'react';
import { Skeleton, Theme, useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import {
  BaseLayoutContainer,
  BaseLayoutScrollContainer,
  Scrollbar,
} from '@front/ui';
import { AnswerFormatType, PinnedQuestionMode } from '@lib/web/apis';

import usePracticeQuestion from '../../hooks/usePracticeQuestion';
import useQuizPinQuestion from '../../hooks/useQuizPinQuestion';

import AnswerOptions from './components/AnswerOptions';
import Passage from './components/Passage';
import PinnedQuestion from './components/PinnedQuestion';
import Question from './components/Question';

const styles = {
  // basic
  box: { position: 'relative' },

  // displayRow
  content: {
    display: 'flex',
    flex: 1,
    gap: '46px',
    py: 1,
  },
  passage: {
    flex: '0 0 50%',
    '& .simplebar-wrapper': {
      height: '100%',
    },
  },
  question: {
    flex: '0 0 36%',
  },
  paddingLineNumberIndent: {
    pl: '35px',
  },
  scrollbar: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  columnContainerRoot: {
    position: 'relative',
    flex: 1,
  },
  wrapper: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'grid',
    gridTemplateRows: '1fr 0',
    transition: '300ms',
  },
  containerWrapper: {
    position: 'relative',
  },
  columnContainer: {
    display: 'grid',
    gap: 3,
    maxWidth: '736px !important',
  },
  shadowContainer: {
    height: '100%',
    position: 'relative',
    mx: -2,
  },

  shadowInner: {
    mx: 2,
  },

  passageGap: {
    height: 40,
    userSelect: 'none',
  },
};

export function QuestionContentSkeleton() {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  if (lgUp)
    return (
      <BaseLayoutContainer sx={styles.content}>
        <Box sx={[styles.box, styles.passage]}>
          <Scrollbar sx={styles.scrollbar}>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Skeleton width="100%" />
            <Box height={40} />
          </Scrollbar>
        </Box>
        <Box sx={[styles.box, styles.question]}>
          <Box sx={styles.shadowContainer}>
            <Scrollbar sx={styles.scrollbar}>
              <Box sx={styles.shadowInner}>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Box>
            </Scrollbar>
          </Box>
        </Box>
      </BaseLayoutContainer>
    );
  return (
    <Box sx={styles.columnContainerRoot}>
      <Scrollbar sx={styles.scrollbar}>
        <BaseLayoutContainer sx={styles.columnContainer}>
          <div>
            <Skeleton width="100%" />
          </div>
          <div>
            <Skeleton width="100%" />
            <Skeleton width="100%" />
          </div>
        </BaseLayoutContainer>
      </Scrollbar>
    </Box>
  );
}

const useQuestionLayout = () => {
  const { answerFormatType } = usePracticeQuestion();
  return answerFormatType === AnswerFormatType.Essay
    ? 'one-column'
    : 'two-columns';
};

type QuestionContentProps = {
  extraContent?: ReactNode;
};

export default function QuestionContent({
  extraContent,
}: QuestionContentProps) {
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const {
    viewMode,
    setViewMode,
    wrapperRef,
    scrollbarRef,
    passageRef,
    pinnedRef,
    pinnedQuestionScrollbarRef,
  } = useQuizPinQuestion();
  const { creatorQuestionInfo } = usePracticeQuestion();
  const layout = useQuestionLayout();

  if (!lgUp) {
    return (
      <Box sx={styles.columnContainerRoot}>
        <Box sx={styles.wrapper} ref={wrapperRef}>
          <Box sx={styles.containerWrapper}>
            <Scrollbar
              sx={styles.scrollbar}
              scrollableNodeProps={{ ref: scrollbarRef }}
            >
              <BaseLayoutContainer sx={styles.columnContainer}>
                <div ref={passageRef}>
                  <Passage />
                </div>
                {viewMode === PinnedQuestionMode.Minimization && (
                  <Box
                    sx={[
                      !!creatorQuestionInfo?.isShowPassageLineNum &&
                        styles.paddingLineNumberIndent,
                    ]}
                  >
                    <Question />
                    <div data-testid="quiz-answer-options">
                      <AnswerOptions />
                    </div>
                    {extraContent}
                  </Box>
                )}
              </BaseLayoutContainer>
            </Scrollbar>
          </Box>
          <PinnedQuestion
            ref={pinnedRef}
            scrollableNodeProps={{ ref: pinnedQuestionScrollbarRef }}
            viewMode={viewMode}
            onViewModeChange={setViewMode}
          />
        </Box>
      </Box>
    );
  }

  if (layout === 'one-column') {
    return (
      <BaseLayoutScrollContainer
        sx={styles.columnContainer}
        scrollRef={scrollbarRef}
      >
        <Passage />
        <Box
          sx={[
            !!creatorQuestionInfo?.isShowPassageLineNum &&
              styles.paddingLineNumberIndent,
          ]}
        >
          <Question />
          <div data-testid="quiz-answer-options">
            <AnswerOptions />
          </div>
          {extraContent}
        </Box>
      </BaseLayoutScrollContainer>
    );
  }

  return (
    <BaseLayoutContainer sx={styles.content}>
      <Box sx={[styles.box, styles.passage]}>
        <Scrollbar
          sx={styles.scrollbar}
          scrollableNodeProps={{ ref: scrollbarRef }}
        >
          <Passage />
          <Box sx={styles.passageGap} />
        </Scrollbar>
      </Box>
      <Box sx={[styles.box, styles.question]}>
        <Box sx={styles.shadowContainer}>
          <Scrollbar sx={styles.scrollbar}>
            <Box sx={styles.shadowInner}>
              <Question />
              <div data-testid="quiz-answer-options">
                <AnswerOptions />
              </div>
              {extraContent}
            </Box>
          </Scrollbar>
        </Box>
      </Box>
    </BaseLayoutContainer>
  );
}
