import { useCallback } from 'react';
import { useDateFormat } from '@front/helper';
import { ComposerBlock } from '@lib/web/composer';
import { useNotificationMessageContent } from '@lib/web/thread/hooks/message/useNotificationMessageContent';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { streamDateToString } from '@lib/web/thread/utils/streamUtils';
import { StreamMessage } from 'stream-chat-react';

import { useRenderThreadMessageToHtml } from './useRenderThreadMessageToHtml';

export const useMessageInformation = () => {
  const { displayDateDivider } = useDateFormat();
  const { renderThreadMessageToHtml } = useRenderThreadMessageToHtml();
  const { getNotificationMessageContent } = useNotificationMessageContent();

  const getMessageInformation = useCallback(
    (message: StreamMessage<StreamChatGenerics>) => {
      const customBlocks = message.customBlocks as ComposerBlock[] | undefined;

      const messageCreator = message.user?.id;
      const messageCreatedAt = message.parentMessageCreatedAt
        ? streamDateToString(message.parentMessageCreatedAt)
        : streamDateToString(message.created_at);

      const dateDividerTime = displayDateDivider(messageCreatedAt);

      const content = renderThreadMessageToHtml(message.text, customBlocks);
      const notificationMessageContent = getNotificationMessageContent({
        messageCreatorId: messageCreator,
        notificationId: message.customNotificationId,
      });

      return {
        content: content || notificationMessageContent.text,
        messageCreator,
        messageCreatedAt,
        dateDividerTime,
        cta: notificationMessageContent.cta,
      };
    },
    [
      displayDateDivider,
      getNotificationMessageContent,
      renderThreadMessageToHtml,
    ]
  );

  return {
    getMessageInformation,
  };
};
