import React, { createContext, ReactNode, useReducer } from 'react';
import { Difficulty, ExamMode } from '@lib/web/apis';

import CreateQuizReducer from './reducer';
import { CreateQuizActions, CreateQuizState } from './type';

const defaultValues = {
  questionCount: 1,
  tags: [],
  marks: [],
  isOpenTimer: true,
  mode: ExamMode.Practice,
  challengers: [],
  difficulty: Difficulty.Easy,
  isIncognito: false,
};
export const initialValue = {
  values: defaultValues,

  defaultValues,

  sectionId: '',

  isOfficial: false,
  officialQuestionCount: 0,
  maxQuestionCount: 0,
  minQuestionCount: 0,
  customizeDisabled: [],
  isRandomChallengers: false,

  isLoading: true,
  isLocked: false,
  isChallengersLoading: false,
};

type CreateQuizProviderProps = {
  children: ReactNode;
};

export const CreateQuizContext = createContext<CreateQuizState>(initialValue);

export const CreateQuizDispatchContext = createContext<
  React.Dispatch<CreateQuizActions>
>({} as React.Dispatch<CreateQuizActions>);

export function CreateQuizProvider({ children }: CreateQuizProviderProps) {
  const [value, dispatch] = useReducer(CreateQuizReducer, initialValue);

  return (
    <CreateQuizContext.Provider value={value}>
      <CreateQuizDispatchContext.Provider value={dispatch}>
        {children}
      </CreateQuizDispatchContext.Provider>
    </CreateQuizContext.Provider>
  );
}

export const CreateQuizConsumer = CreateQuizContext.Consumer;
