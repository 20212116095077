import MultipleChoices from './components/MultipleChoices';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorSolutionMultipleChoice() {
  const { solutionValues } = useData();
  const { handleBlocksChange } = useEventHandle();

  return (
    <MultipleChoices
      solutionValues={solutionValues}
      handleBlocksChange={handleBlocksChange}
    />
  );
}
