export const getScrollParent = (node: Element): Element | undefined => {
  const regex = /(auto|scroll)/;

  const parents = (_node: ParentNode, ps: ParentNode[]): ParentNode[] => {
    if (_node.parentNode === null) {
      return ps;
    }
    return parents(_node.parentNode, ps.concat([_node]));
  };

  const style = (_node: Element, prop: string) =>
    getComputedStyle(_node, null).getPropertyValue(prop);

  const overflow = (_node: Element) =>
    style(_node, 'overflow') +
    style(_node, 'overflow-y') +
    style(_node, 'overflow-x');

  const scroll = (_node: Element) => regex.test(overflow(_node));

  /* eslint-disable consistent-return */
  const scrollParent = (_node: Element): Element | undefined => {
    if (!(_node instanceof HTMLElement || _node instanceof SVGElement)) {
      return;
    }

    const ps = _node.parentNode ? parents(_node.parentNode, []) : null;

    if (ps) {
      for (let i = 0; i < ps.length; i += 1) {
        if (scroll(ps[i] as Element)) {
          return ps[i] as Element;
        }
      }
    }

    return document.scrollingElement || document.documentElement;
  };

  return scrollParent(node);
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getTextWidth = (val: any, fontStyle = '16px "Ubuntu"') => {
  if (!val || typeof val !== 'string') return 0;
  const canvas: HTMLCanvasElement = document.createElement('canvas');
  const context = canvas.getContext('2d');

  if (context) {
    context.font = fontStyle;
    const metrics = context.measureText(val);
    return metrics.width;
  }
  return 0;
};

export const getToastAnchor = (el: Element): [x: number, y: number] => {
  if (!el) return [0, 0];
  const rect = el.getBoundingClientRect();
  const parent = el.parentNode as Element;
  const parentRect = parent ? parent.getBoundingClientRect() : rect;

  const offsetY = window.innerHeight - rect.top;
  const offsetX = window.innerWidth - parentRect.right - 20;

  return [offsetX, offsetY];
};

const domUtils = {
  getScrollParent,
  getTextWidth,
  getToastAnchor,
};

export default domUtils;
