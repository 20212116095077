import React from 'react';
import Box from '@mui/material/Box';
import { MaskIcon, SquareAvatar } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useThreadViewDetail } from '@lib/web/thread/hooks/view/useThreadViewDetail';

const avatarSizeMap = {
  md: {
    singleAvatar: 40,
    multiAvatar: 30,
    multiAvatarBadge: 25,
    multiAvatarBadgeFontSize: 14,
  },
  sm: {
    singleAvatar: 16,
    multiAvatar: 12,
    multiAvatarBadge: 10,
    multiAvatarBadgeFontSize: 7,
  },
};

const iconSizeMap = {
  md: 40,
  sm: 16,
};

const styles = {
  root: {
    position: 'relative',
  },
  badge: {
    position: 'absolute',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    bgcolor: 'text.primary',
    color: 'background.darker',
    typography: 'caption',
  },
};

export type DirectMessagesViewIconProps = {
  view: GetThreadViewRes;
  size: 'md' | 'sm';
};

export default function ThreadViewIcon({
  view,
  size,
}: DirectMessagesViewIconProps) {
  const { viewIcon } = useThreadViewDetail(view);

  if (!viewIcon) return null;

  if (viewIcon.type === 'icon' && viewIcon.icon) {
    const iconSize = iconSizeMap[size];
    return <Icon name={viewIcon.icon} width={iconSize} height={iconSize} />;
  }

  if (viewIcon.type === 'emoji') return viewIcon.emoji;

  if (viewIcon.type === 'avatar') {
    const avatarBadgeCount = viewIcon.avatarBadgeCount || 0;
    const isMultiAvatar = avatarBadgeCount > 1;
    const avatarSize = avatarSizeMap[size];

    return (
      <Box
        sx={[
          styles.root,
          {
            mr: `${
              isMultiAvatar
                ? avatarSize.multiAvatarBadge - avatarSize.multiAvatar / 2
                : 0
            }px`,
          },
        ]}
      >
        <SquareAvatar
          size={
            isMultiAvatar ? avatarSize.multiAvatar : avatarSize.singleAvatar
          }
          src={viewIcon.avatar}
          title={viewIcon.avatarTitle}
        >
          {viewIcon.avatarTitle}
        </SquareAvatar>
        {isMultiAvatar && (
          <MaskIcon
            sx={[
              styles.badge,
              {
                width: avatarSize.multiAvatarBadge,
                height: avatarSize.multiAvatarBadge,
                top: avatarSize.multiAvatar / 2,
                left: avatarSize.multiAvatar / 2,
                fontSize: avatarSize.multiAvatarBadgeFontSize,
              },
            ]}
          >
            {avatarBadgeCount > 9 ? '9+' : avatarBadgeCount}
          </MaskIcon>
        )}
      </Box>
    );
  }

  return null;
}
