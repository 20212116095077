import React, { HTMLAttributes, useState } from 'react';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { testIdConfig } from '@front/config';
import { SimpleTooltip, TextForm } from '@front/ui';

import useWordLimit, { WordLimitMax } from '../../hooks/useWordLimit';

export type EssayAnswerInputProps = {
  value: string | null;
  onChange?: (value: string) => void;
  placeholder?: string;
  wordLimitMax?: WordLimitMax;
  tooltipTitle?: string;
  enableHoveredStyle?: boolean;
  inputMode?: HTMLAttributes<
    HTMLInputElement | HTMLTextAreaElement
  >['inputMode'];
};

const styles = {
  root: {
    '.textform-main': {
      borderBottom: 'none',
    },
    textarea: {
      py: '0 !important',
    },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .textform-main': {
          borderBottom: 'none', // overwrite the default ui behavior
        },
      },
    },
  },
  rootHover: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        '& .indicator': {
          background: (theme: Theme) => theme.palette.text.primary,
        },
      },
    },
  },
  indicator: {
    position: 'absolute',
    marginLeft: '-12px', // 4px width + 8px space in figma
    height: '24px',
    width: '4px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    cursor: 'pointer',
  },
  indicatorFocus: {
    background: (theme: Theme) => theme.palette.text.primary,
  },
  indicatorActive: {
    background: (theme: Theme) => theme.palette.text.primary,
  },
  input: {
    typography: (theme: Theme) =>
      theme.palette.mode === 'dark' ? 'body1' : 'examBody',
  },
};
export default function EssayAnswerInput({
  value,
  onChange,
  placeholder,
  wordLimitMax,
  tooltipTitle,
  enableHoveredStyle = false,
  inputMode,
}: EssayAnswerInputProps) {
  const { handleChange, handlePaste } = useWordLimit({
    wordLimitMax,
    onChange,
  });

  const [focused, setFocused] = useState(false);

  return (
    <Box sx={[enableHoveredStyle && styles.rootHover]}>
      <SimpleTooltip title={tooltipTitle}>
        <Box
          className="indicator"
          sx={[
            styles.indicator,
            focused && styles.indicatorFocus,
            !!value && styles.indicatorActive,
          ]}
        />
      </SimpleTooltip>

      <TextForm
        sx={styles.root}
        value={value}
        placeholder={placeholder}
        onChange={handleChange}
        onPaste={handlePaste}
        inputSx={styles.input}
        onFocus={() => setFocused(true)}
        onBlur={() => setFocused(false)}
        multiline
        inputProps={{
          'data-testid': testIdConfig.essayAnswerTextArea,
          inputMode,
        }}
      />
    </Box>
  );
}
