import { AxiosError, AxiosResponse } from 'axios';
import { Question } from '@app/web/src/reducers/quizReducer/types';
import { RootState } from '@app/web/src/store';
import { apis, ExamMode, ExamQuestionType } from '@lib/web/apis';
import { BLANK_OPTION_VALUE } from '@lib/web/practice';
import { call } from '@lib/web/utils';
import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { differenceInHours } from 'date-fns';

import { QuizProfileQuestionType } from '../reducers/quizReducer/types';

const types = {
  FETCH_QUIZ: 'QUIZ/FETCH_QUIZ',
  CLEAR_QUIZ_QUESTION_ERROR: 'QUIZ/CLEAR_QUIZ_QUESTION_ERROR',

  START_QUIZ: 'QUIZ/START_QUIZ',
  PAUSE_QUIZ: 'QUIZ/PAUSE_QUIZ',
  RESUME_QUIZ: 'QUIZ/RESUME_QUIZ',
  FINISH_QUIZ: 'QUIZ/FINISH_QUIZ',
  REVIEW_QUIZ: 'QUIZ/REVIEW_QUIZ',

  GO_QUESTION: 'QUIZ/GO_QUESTION',

  UPDATE_VIEW_STYLE: 'QUIZ/UPDATE_VIEW_STYLE',
  QUIT_QUIZ: 'QUIZ/QUIT_QUIZ',
  UPDATE_ANSWER: 'QUIZ/UPDATE_ANSWER',
  UPDATE_ANSWER_WITH_STATE: 'QUIZ/UPDATE_ANSWER_WITH_STATE',
  UPDATE_NOTE: 'QUIZ/UPDATE_NOTE',
  UPDATE_NOTE_WITH_STATE: 'QUIZ/UPDATE_NOTE_WITH_STATE',
  UPDATE_HTML: 'QUIZ/UPDATE_HTML',

  ELIMINATE_ANSWER: 'QUIZ/ELIMINATE_ANSWER',

  // Practice
  LAZY_SUBMIT: 'QUIZ/LAZY_SUBMIT',
  CANCEL_SUBMIT: 'QUIZ/CANCEL_SUBMIT',

  SUBMIT_ANSWER: 'QUIZ/SUBMIT_ANSWER',

  TOGGLE_BOOKMARK: 'QUIZ/TOGGLE_BOOKMARK',
  UPDATE_EMOJI: 'QUIZ/UPDATE_EMOJI',

  // Mock Exam
  SUBMIT_ALL_ANSWERS: 'QUIZ/SUBMIT_ALL_ANSWERS',

  ENTER_PROFILE_QUESTION: 'QUIZ/ENTER_PROFILE_QUESTION',
  LEAVE_PROFILE_QUESTION: 'QUIZ/LEAVE_PROFILE_QUESTION',
  STORE_TIME_DURATION: 'QUIZ/STORE_TIME_DURATION',
  TOGGLE_SUBMIT_BUTTON: 'QUIZ/TOGGLE_SUBMIT_BUTTON',
  SET_PROFILE_QUESTION_TARGET_ID: 'QUIZ/SET_PROFILE_QUESTION_TARGET_ID',
  SET_PROFILE_QUESTION_DIRTY: 'QUIZ/SET_PROFILE_QUESTION_DIRTY',
  SET_PROFILE_QUESTION_LOADING: 'QUIZ/SET_PROFILE_QUESTION_LOADING',

  // Layout
  OPEN_KEYBOARD: 'QUIZ/OPEN_KEYBOARD',
  CLOSE_KEYBOARD: 'QUIZ/CLOSE_KEYBOARD',
  START_REDIRECT: 'QUIZ/START_REDIRECT',
  END_REDIRECT: 'QUIZ/END_REDIRECT',
};

export const startQuiz = createAction(
  types.START_QUIZ,
  (ev: { skipOnboarding?: boolean } = {}) => ({
    payload: ev,
  })
);
export const clearQuizQuestionError = createAction(
  types.CLEAR_QUIZ_QUESTION_ERROR
);
export const pauseQuiz = createAction(types.PAUSE_QUIZ);
export const resumeQuiz = createAction(types.RESUME_QUIZ);
export const finishQuiz = createAction(types.FINISH_QUIZ);
export const reviewQuiz = createAction(types.REVIEW_QUIZ);
export const quitQuiz = createAction(types.QUIT_QUIZ);

export const openKeyboard = createAction(types.OPEN_KEYBOARD);
export const closeKeyboard = createAction(types.CLOSE_KEYBOARD);
export const startRedirect = createAction(
  types.START_REDIRECT,
  (page: string) => ({
    payload: page,
  })
);
export const endRedirect = createAction(types.END_REDIRECT);

export const enterProfileQuestion = createAction(types.ENTER_PROFILE_QUESTION);
export const leaveProfileQuestion = createAction(types.LEAVE_PROFILE_QUESTION);
export const storeTimeDuration = createAction(types.STORE_TIME_DURATION);
export const toggleSubmitButton = createAction(types.TOGGLE_SUBMIT_BUTTON); // handling the enabled/disabled state of submit-exam-button

export const setProfileQuestionTargetId = createAction(
  types.SET_PROFILE_QUESTION_TARGET_ID,
  (element: string) => ({
    payload: element,
  })
);

export const setProfileQuestionDirty = createAction(
  types.SET_PROFILE_QUESTION_DIRTY,
  (isDirty: boolean) => ({
    payload: isDirty,
  })
);
export const setProfileQuestionLoading = createAction(
  types.SET_PROFILE_QUESTION_LOADING,
  (isLOading: boolean) => ({
    payload: isLOading,
  })
);

export const goQuestion = createAction(
  types.GO_QUESTION,
  (questionNo: number, options: { skipOnboarding?: boolean } = {}) => ({
    payload: { questionNo, ...options },
  })
);

export const updateAnswer = createAction(
  types.UPDATE_ANSWER,
  (value: string[], questionNo: number) => ({
    payload: {
      value,
      questionNo,
    },
  })
);

export const updateAnswerWithState = createAsyncThunk(
  types.UPDATE_ANSWER_WITH_STATE,
  (
    payload: { questionNo: number; update: (v: string[]) => string[] },
    { getState, dispatch }
  ) => {
    const state = (getState() as RootState).quiz;

    const { questionNo, update } = payload;
    const updatedValue = update(state.mappedAnswers[questionNo]);

    dispatch(updateAnswer(updatedValue, questionNo));
  }
);

export const updateNote = createAction(
  types.UPDATE_NOTE,
  (value: string | ((v: string) => string), questionNo: number) => {
    return {
      payload: {
        value: typeof value === 'function' ? value('') : value,
        questionNo,
      },
    };
  }
);

export const updateNoteWithState = createAsyncThunk(
  types.UPDATE_NOTE_WITH_STATE,
  (
    payload: { questionNo: number; update: (v: string) => string },
    { getState, dispatch }
  ) => {
    const state = (getState() as RootState).quiz;

    const { questionNo, update } = payload;
    const updatedValue = update(state.mappedNotes[questionNo]);

    dispatch(updateNote(updatedValue, questionNo));
  }
);

export const updateHTML = createAction(
  types.UPDATE_HTML,
  (value: string, code: string) => ({
    payload: {
      value,
      code,
    },
  })
);

export const toggleBookmark = createAction(
  types.TOGGLE_BOOKMARK,
  (questionNo: number) => ({
    payload: {
      questionNo,
    },
  })
);

export const updateEmoji = createAction(
  types.UPDATE_EMOJI,
  (questionNo: number, emoji: { id: string; value: string }) => ({
    payload: {
      questionNo,
      emoji,
    },
  })
);

export const eliminateAnswer = createAction(
  types.ELIMINATE_ANSWER,
  (value: string[], questionNo: number) => ({
    payload: {
      value,
      questionNo,
    },
  })
);

export const lazySubmit = createAction(types.LAZY_SUBMIT);
export const cancelSubmit = createAction(types.CANCEL_SUBMIT);

const getProfileQuestionType = (
  profile: Member,
  goal?: GetGoalRes,
  playlistHistory?: PageResponse<GetMemberQuizRes>
) => {
  if (playlistHistory && playlistHistory.data.totalCount === 0) {
    return null;
  }

  const { feedbackAt } = profile;
  if (feedbackAt && differenceInHours(new Date(), new Date(feedbackAt)) < 24) {
    return null;
  }
  if (profile.isUseDefaultDistinctName === true)
    return QuizProfileQuestionType.Username;
  if (profile.fullName === null) return QuizProfileQuestionType.FullName;
  if (profile.city === null || profile.country === null)
    return QuizProfileQuestionType.Location;
  if (profile.birthday === null || profile.birthday === '')
    return QuizProfileQuestionType.Birthday;
  if (profile.gender === null) return QuizProfileQuestionType.Gender;
  if (profile.bio === null) return QuizProfileQuestionType.Bio;
  if (profile.highSchoolGraduation === null)
    return QuizProfileQuestionType.HighSchoolGraduation;
  if (profile.highSchool === null) return QuizProfileQuestionType.HighSchool;
  if (profile.dreamCollegeId === null)
    return QuizProfileQuestionType.DreamCollege;

  if (!goal) return QuizProfileQuestionType.GoalTargetDate;
  if (goal.targetDate === null) return QuizProfileQuestionType.GoalTargetDate;
  if (goal.targetScore === null) return QuizProfileQuestionType.GoalTargetScore;
  return null;
};

export const fetchQuiz = createAsyncThunk<
  {
    quizPractice: GetQuizPracticeRes;
    quizResults: GetQuizResultRes[];
    roundNo: number;
    mode: ExamMode;
    userId: string;
    sid: string;
    profileQuestionType: QuizProfileQuestionType | null;
    isOnboarding: boolean;
  },
  {
    sectionId: string;
    quizId: string;
    roundNo: number;
    mode: ExamMode;
    userId: string;
    sid: string;
    isIncognito: boolean;
    isOnboarding: boolean;
  },
  {
    rejectValue: ResponseError;
  }
>(
  types.FETCH_QUIZ,
  async (
    {
      sectionId,
      quizId,
      roundNo = 1,
      mode,
      userId,
      sid,
      isIncognito,
      isOnboarding,
    },
    { rejectWithValue }
  ) => {
    const commonApis = [
      apis.quiz.practice(quizId, {
        isIgnoreVersionBlock: true,
        isIncognito,
        roundNo,
        mode,
      }),
      apis.quiz.oneRoundResult(quizId, roundNo).catch(() => null),
    ];

    try {
      const res = (
        mode === ExamMode.Practice
          ? await Promise.all([
              ...commonApis,
              apis.member.getProfile(),
              apis.goal.getGoal(sectionId).catch(() => undefined),
              apis.quiz.getHistoryList({ sectionId, quizId, limit: 1 }),
            ])
          : await Promise.all(commonApis)
      ) as [
        AxiosResponse<Response<GetQuizPracticeRes>, any>,
        AxiosResponse<PageResponse<GetQuizResultRes>, any>,
        AxiosResponse<Response<Member>, any> | undefined,
        AxiosResponse<Response<GetGoalRes>, any> | undefined,
        AxiosResponse<PageResponse<GetMemberQuizRes>, any> | undefined
      ];

      const profileQuestionType = res[2]
        ? getProfileQuestionType(
            res[2].data.data,
            res[3]?.data.data,
            res[4]?.data
          )
        : null;
      return {
        quizPractice: res[0].data.data,
        quizResults: res[1]?.data?.data?.items || [],
        roundNo,
        mode,
        userId,
        sid,
        profileQuestionType,
        isOnboarding,
      };
    } catch (err: any) {
      const error: AxiosError<ResponseError> = err; // cast the error for access
      if (!error.response) {
        throw err;
      }
      return rejectWithValue(error.response.data);
    }
  }
);

const getAnswerIdsOrValue = (
  question: Question,
  mappedAnswers: { [order: number]: string[] }
) => {
  const currentAnswer = mappedAnswers[question.questionNo];
  switch (question.type) {
    case ExamQuestionType.GridIn:
      return {
        answerIds: [],
        answerValue: currentAnswer[0]
          ? currentAnswer[0].replaceAll(' ', '')
          : '',
      };

    case ExamQuestionType.FreeResponse:
    case ExamQuestionType.Essay:
      return {
        answerIds: [],
        answerValue: currentAnswer[0] || '',
      };

    default: {
      const isLeaveBlank = currentAnswer[0] === BLANK_OPTION_VALUE;
      // filter out the blank option, no need to pass it as answer id, just need isLeaveBlank
      const answerIds = currentAnswer.filter((id) => id !== BLANK_OPTION_VALUE);
      return {
        answerIds,
        answerValue: '',
        isLeaveBlank,
      };
    }
  }
};

const updateScore = async (params: QuizUpdateScoreReq) => {
  try {
    await apis.quiz.updateScore(params);
  } catch (err: any) {
    //
  }
};

const saveQuizResults = async (
  quizId: string,
  roundNo: number,
  mode: ExamMode,
  mappedAnswers: { [order: number]: string[] },
  questions: Question[]
): Promise<[GetQuizResultRes[] | null, any]> => {
  const saveParams: QuizResultReq = {
    quizId,
    roundNo,
    mode,
    results: questions
      .filter((question) => !question.isSubmitted)
      .map((question) => {
        const { answerIds, answerValue, isLeaveBlank } = getAnswerIdsOrValue(
          question,
          mappedAnswers
        );

        const duration = question.timeSpent ? question.timeSpent / 1000 : 0;

        return {
          quizQuestionId: question.id,
          answerIds,
          answerValue,
          duration,
          isOvertime: question.isOvertime || duration > question.tpq,
          isAnswered: answerIds.length > 0 || answerValue !== '',
          isLeaveBlank,
        };
      }),
  };

  try {
    const result = await apis.quiz.saveResult(saveParams);
    await updateScore({ quizId, roundNo });
    return [result.data.data.items, null];
  } catch (err: any) {
    const error: AxiosError<ResponseError> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }
    return [null, error.response.data];
  }
};

const updateChallengeData = async (
  challengeId: string,
  userId: string,
  updateStatus: number,
  isComplete: boolean
): Promise<[any | null, any]> => {
  const params = {
    challengeId,
    userId,
    updateStatus,
    isComplete,
  };

  const res = await call(apis.challenge.syncChallenge(params));

  return res;
};

const updateAnalyticsData = async (): Promise<[any | null, any]> => {
  const res = call(apis.quiz.updateTagScore());
  return res;
};

export const submitAnswer = createAsyncThunk<
  GetQuizResultRes,
  undefined,
  {
    rejectValue: { error?: ResponseError; message?: string; targetId: string };
  }
>(types.SUBMIT_ANSWER, async (params, { getState, rejectWithValue }) => {
  const {
    mappedQuestions,
    mappedAnswers,
    quiz,
    roundNo,
    mode,
    questionNo,
    challenge,
    userId,
  } = (getState() as RootState).quiz;

  const currentQuestion: Question = mappedQuestions[questionNo];

  if (!currentQuestion || currentQuestion.isSubmitted)
    return rejectWithValue({
      message: 'Error Params',
      targetId: currentQuestion.id,
    });

  const [success, error] = await saveQuizResults(
    quiz.id,
    roundNo,
    mode,
    mappedAnswers,
    [currentQuestion]
  );

  const allQuestions = Object.values(mappedQuestions);

  if (challenge) {
    const isFinished =
      allQuestions.filter((question) => question.isSubmitted).length ===
      allQuestions.length - 1;
    updateChallengeData(challenge.challengeId, userId, -1, isFinished);
  }

  updateAnalyticsData();

  if (success && !success[0]) {
    return rejectWithValue({
      error,
      targetId: currentQuestion.id,
    });
  }
  if (success) return success[0];
  return rejectWithValue({
    error,
    targetId: currentQuestion.id,
  });
});

export const submitAllAnswers = createAsyncThunk<
  GetQuizResultRes[],
  undefined,
  {
    rejectValue: {
      error?: ResponseError;
      message?: string;
      targetIds: string[];
    };
  }
>(types.SUBMIT_ALL_ANSWERS, async (params, { getState, rejectWithValue }) => {
  const {
    mappedQuestions,
    mappedAnswers,
    quiz,
    roundNo,
    mode,
    challenge,
    userId,
  } = (getState() as RootState).quiz;

  const questions = Object.values(mappedQuestions).filter(
    (question) => !question.isSubmitted
  );

  if (questions.length === 0) return [];

  const [success, error] = await saveQuizResults(
    quiz.id,
    roundNo,
    mode,
    mappedAnswers,
    questions
  );

  if (challenge) updateChallengeData(challenge.challengeId, userId, -1, true);

  updateAnalyticsData();
  if (success) return success;
  return rejectWithValue({
    error,
    targetIds: questions.map((question) => question.id),
  });
});
