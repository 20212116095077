import api from './client';

const userApi = {
  sharePromoCode: (params: ShareUserPromoCodeReq) =>
    api.post<Response<ShareUserPromoCodeShareRes>>(
      'v2/user/promo-code/share',
      params
    ),
};

export default userApi;
