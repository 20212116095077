import { useCallback, useContext } from 'react';
import { StructureType } from '@lib/web/apis';
import { CreatorQuestionDetailContext, useErrorMessage } from '@lib/web/editor';
import { PositionalErrorMessages } from '@lib/web/ui';

import { useFieldErrorMessage } from '../useFieldErrorMessage';

import { ValidationData, ValidationResult } from './types';
import {
  validateAnswerArea,
  validateLeftQuestionArea,
  validateRightQuestionArea,
  validateSolutionArea,
} from './validators';

const useBuildValidationData = () => {
  const {
    getCreatorQuestion,
    getCreatorQuestionDetail,
    getCreatorQuestionCorrectAnswer,
    getCreatorQuestionGroupItem,
  } = useContext(CreatorQuestionDetailContext);

  const fetchDataForValidation = useCallback(
    async (id: string) => {
      const [questionRes, detailRes, correctAnswerRes, groupItemRes] =
        await Promise.all([
          getCreatorQuestion(id),
          getCreatorQuestionDetail(id),
          getCreatorQuestionCorrectAnswer(id),
          getCreatorQuestionGroupItem(id),
        ]);

      return {
        question: { id, ...questionRes.data.data },
        questionDetail: detailRes.data.data,
        correctAnswer: correctAnswerRes.data.data,
        groupItems: groupItemRes.data.data.items.map((item) => item.id),
      };
    },
    [
      getCreatorQuestion,
      getCreatorQuestionCorrectAnswer,
      getCreatorQuestionDetail,
      getCreatorQuestionGroupItem,
    ]
  );

  return useCallback(
    async (id: string): Promise<ValidationData> => {
      const map: ValidationData = {};

      map[id] = await fetchDataForValidation(id);

      const groupItemsValidationData = await Promise.all(
        map[id].groupItems.map((itemId) => fetchDataForValidation(itemId))
      );

      groupItemsValidationData.forEach((data) => {
        map[data.question.id] = data;
      });

      const questionGroupId = map[id].question.questionGroupId;
      if (questionGroupId && !(questionGroupId in map)) {
        map[questionGroupId] = await fetchDataForValidation(questionGroupId);
      }

      return map;
    },
    [fetchDataForValidation]
  );
};

export const useValidateBeforePublish = () => {
  const { setErrorMessage } = useErrorMessage();
  const { hasFieldError } = useFieldErrorMessage();
  const validate = useCallback(
    (
      id: string,
      validationData: ValidationData,
      position?: keyof PositionalErrorMessages
    ): ValidationResult[] => {
      const validationResult: ValidationResult[] = [];
      const { question, groupItems } = validationData[id];

      if (question.structureType === StructureType.QuestionGroup) {
        groupItems.forEach((itemId) =>
          validationResult.push(...validate(itemId, validationData))
        );
      }

      if (!position || position === 'leftQuestionArea') {
        validationResult.push({
          id,
          position: 'leftQuestionArea',
          errorMessages: validateLeftQuestionArea(id, validationData),
        });
      }

      if (!position || position === 'rightQuestionArea') {
        validationResult.push({
          id,
          position: 'rightQuestionArea',
          errorMessages: validateRightQuestionArea(id, validationData),
        });
      }

      if (!position || position === 'answerArea') {
        validationResult.push({
          id,
          position: 'answerArea',
          errorMessages: validateAnswerArea(id, validationData),
        });
      }

      if (!position || position === 'solutionArea') {
        validationResult.push({
          id,
          position: 'solutionArea',
          errorMessages: validateSolutionArea(id, validationData),
        });
      }
      return validationResult;
    },
    []
  );

  const buildValidationData = useBuildValidationData();

  const validateBeforePublish = useCallback(
    async (
      id: string,
      position?: keyof PositionalErrorMessages
    ): Promise<{
      success: boolean;
      errorMessages: Record<string, Partial<PositionalErrorMessages>>;
      validationData: ValidationData;
    }> => {
      const validationData = await buildValidationData(id);
      const validationResult = validate(id, validationData, position);

      validationResult.forEach((result) => {
        setErrorMessage(result.id, result.position, result.errorMessages);
      });

      const errorMessages: Record<
        string,
        Partial<PositionalErrorMessages>
      > = {};
      let success = true;

      validationResult.forEach((result) => {
        if (!(result.id in errorMessages)) {
          errorMessages[result.id] = {};
        }
        errorMessages[result.id][result.position] = result.errorMessages;
        if (result.errorMessages.length > 0) {
          success = false;
        }
      });

      if (hasFieldError(id)) {
        success = false;
      }
      return {
        success,
        errorMessages,
        validationData,
      };
    },
    [buildValidationData, hasFieldError, setErrorMessage, validate]
  );

  return {
    validateBeforePublish,
  };
};
