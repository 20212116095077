import { useContext, useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { AudioPlayer } from '@front/ui';
import { apis, UploadResourceType } from '@lib/web/apis';
import { call } from '@lib/web/utils';

import {
  QuizAudioActionContext,
  QuizAudioSourceContext,
} from './contexts/quizAudioContext';

export default function QuizAudioRender({ fileKey }: { fileKey: string }) {
  const audioPlayer = useRef<HTMLAudioElement | null>(null);
  const { onlyOnce, getAudioByKey } = useContext(QuizAudioSourceContext);
  const { setAudioSrc, syncAudioProgress } = useContext(QuizAudioActionContext);
  const audio = getAudioByKey(fileKey);

  const handlePlaying = () => {
    if (audioPlayer.current && onlyOnce) {
      syncAudioProgress(
        fileKey,
        audioPlayer.current.currentTime / audioPlayer.current.duration
      );
    }
  };

  useEffect(() => {
    const fetchAudioSrc = async (key: string) => {
      const [res] = await call(
        apis.file.getSignedUrl(UploadResourceType.AhaFile, key)
      );

      if (res) {
        setAudioSrc(fileKey, res.data.signedUrl);
      }
    };

    if (fileKey && !audio?.src) {
      fetchAudioSrc(fileKey);
    }
  }, [fileKey, audio?.src, setAudioSrc]);

  const handleDestroy = (progress: number) => {
    if (progress > (audio?.progress || 0)) syncAudioProgress(fileKey, progress);
    if (progress === 1 && onlyOnce && audioPlayer.current) {
      audioPlayer.current.currentTime = audioPlayer.current.duration;
    }
  };

  const handleComplete = () => {
    if (!onlyOnce) return false;

    syncAudioProgress(fileKey, 1);
    if (audioPlayer.current) {
      audioPlayer.current.currentTime = audioPlayer.current.duration;
    }
  };

  // stopPropagation: don't trigger the click event of answer option
  return (
    <Box onClick={(ev) => ev.stopPropagation()} data-content-type="audio">
      <AudioPlayer
        ref={audioPlayer}
        key={fileKey}
        loading={!audio?.src}
        src={audio?.src}
        onlyOnce={onlyOnce}
        onPlaying={handlePlaying}
        onDestroy={handleDestroy}
        onPlayComplete={handleComplete}
        options={{
          progress: audio?.progress,
        }}
      />
    </Box>
  );
}
