import { useContext } from 'react';
import { Difficulty, useQuizQuestionCount } from '@lib/web/apis';
import { isEqual } from 'lodash';

import { CreateQuizContext } from '../contexts';

export default function useCreateQuizState() {
  const state = useContext(CreateQuizContext);

  const totalQuestionDisabled =
    state.customizeDisabled.includes('total-questions');

  const modeDisabled = state.customizeDisabled.includes('exam-mode');
  const challengeFriendDisabled =
    state.customizeDisabled.includes('challenge-friends');
  const hashtagDisabled =
    state.customizeDisabled.includes('customize-hashtags');
  const timerDisabled = state.customizeDisabled.includes('show-timer');
  const difficultyDisabled = state.customizeDisabled.includes('difficulty');
  const incognitoDisabled = state.customizeDisabled.includes('incognito');

  const { data, error } = useQuizQuestionCount(
    state.sectionId
      ? {
          sectionId: state.sectionId,
          markCodeAry: hashtagDisabled
            ? undefined
            : state.values.marks.map((mark) => mark.code),
          tagCodeAry: hashtagDisabled
            ? undefined
            : state.values.tags.map((tag) => tag.code),

          difficultyAry:
            difficultyDisabled ||
            // user selected none
            (state.values.difficultyAry &&
              state.values.difficultyAry.length === 0)
              ? undefined
              : state.values.difficultyAry || [Difficulty.Easy], // selected values || default (if not touch)
        }
      : undefined
  );
  const isQuestionCountDirty =
    !totalQuestionDisabled &&
    state.values.questionCount !== state.defaultValues.questionCount;
  const isModeDirty =
    !modeDisabled && state.values.mode !== state.defaultValues.mode;
  const isChallengersDirty =
    !challengeFriendDisabled &&
    state.values.challengers.filter(
      (challenger) => !challenger.metadata?.disabledUpdate
    ).length > 0;
  const isTagsDirty = !hashtagDisabled && state.values.tags.length > 0;
  const isMarksDirty = !hashtagDisabled && state.values.marks.length > 0;
  const isTimerDirty = !timerDisabled && state.values.isOpenTimer === false;
  const isDifficultyDirty =
    !difficultyDisabled &&
    !(
      state.values.difficultyAry === undefined ||
      isEqual(state.values.difficultyAry, [Difficulty.Easy])
    );
  const isRandomChallengersDirty =
    !challengeFriendDisabled &&
    state.values.randomChallengerCount !==
      state.defaultValues.randomChallengerCount;

  const displayQuestionCount = state.isOfficial
    ? state.officialQuestionCount
    : state.values.questionCount;

  const isIncognitoDirty =
    !incognitoDisabled && state.values.isIncognito === true;

  return {
    ...state,

    isQuestionCountDirty,
    isModeDirty,
    isChallengersDirty,
    isTagsDirty,
    isMarksDirty,
    isTimerDirty,
    isIncognitoDirty,

    isDirty:
      isQuestionCountDirty ||
      isModeDirty ||
      isChallengersDirty ||
      isTagsDirty ||
      isTimerDirty ||
      isMarksDirty ||
      isDifficultyDirty ||
      isRandomChallengersDirty ||
      isIncognitoDirty,

    totalQuestionDisabled,
    modeDisabled,
    challengeFriendDisabled,
    hashtagDisabled,
    difficultyDisabled,
    timerDisabled,
    incognitoDisabled,
    displayQuestionCount,
    availableQuestionCount: error ? 0 : data?.data.totalCount,
    availableQuestionCountLoading: !data && !error,
  };
}
