import Box from '@mui/material/Box';

import ContentItem from './components/ContentItem';
import MessageItem from './components/ContentItem/MessageItem';
import NotificationItem from './components/ContentItem/NotificationItem';
import MessageActionArea from './components/MessageActionArea';
import { ChannelLayoutConfig } from './types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
};

export type ChannelLayoutProps = ChannelLayoutConfig;

export default function ChannelLayout({ items, settings }: ChannelLayoutProps) {
  return (
    <Box sx={styles.root}>
      {items.map((_, index) => {
        let itemData = _;
        if (settings?.reverseItems) {
          const revertIndex = items.length - 1 - index;
          itemData = items[revertIndex];
        }
        return (
          <ContentItem
            {...itemData}
            hoverDisabled={settings?.hoverDisabled}
            size={settings?.size || 'md'}
            key={itemData.key || itemData.id || `${index}`}
          />
        );
      })}
    </Box>
  );
}

ChannelLayout.MessageActionArea = MessageActionArea;
ChannelLayout.MessageItem = MessageItem;
ChannelLayout.NotificationItem = NotificationItem;
