import { useContext, useMemo } from 'react';

import { CreateQuizDispatchContext } from '../contexts';
import {
  CreateQuizActionTypes,
  CreateQuizFormPartTypes,
  CreateQuizStateValues,
} from '../contexts/type';

export default function useCreateQuizAction() {
  const dispatch = useContext(CreateQuizDispatchContext);

  return useMemo(() => {
    return {
      updateQuizValues: (newValues: Partial<CreateQuizStateValues>) => {
        dispatch({
          type: CreateQuizActionTypes.UPDATE_VALUES,
          payload: newValues,
        });
      },
      updateQuizInfo: (info: {
        tagId?: string;
        markId?: string;
        quizId?: string;
        challengeId?: string;
      }) => {
        dispatch({
          type: CreateQuizActionTypes.UPDATE_QUIZ_INFO,
          payload: info,
        });
      },

      resetValues: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_VALUES,
        });
      },

      resetMode: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_MODE,
        });
      },

      resetQuestionCount: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_QUESTIONS,
        });
      },

      resetTags: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_TAGS,
        });
      },

      resetMarks: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_MARKS,
        });
      },

      resetChallengers: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_CHALLENGERS,
        });
      },

      resetAllSettings: () => {
        dispatch({
          type: CreateQuizActionTypes.RESET_ALL_SETTINGS,
        });
      },

      setCustomizeDisabled: (disabledParts: CreateQuizFormPartTypes[]) => {
        dispatch({
          type: CreateQuizActionTypes.SET_CUSTOMIZE_DISABLED,
          payload: disabledParts,
        });
      },

      setDefaultSettingsBySection: (
        section: GetExamSectionRes,
        maxCount: number
      ) => {
        dispatch({
          type: CreateQuizActionTypes.SET_DEFAULT_SETTINGS_BY_SECTION,
          payload: { section, maxCount },
        });
      },

      setSectionLoading: (loading: boolean) => {
        dispatch({
          type: CreateQuizActionTypes.SET_SECTION_LOADING,
          payload: loading,
        });
      },

      setChallengersLoading: (loading: boolean) => {
        dispatch({
          type: CreateQuizActionTypes.SET_CHALLENGERS_LOADING,
          payload: loading,
        });
      },

      setIsOfficial: (loading: boolean) => {
        dispatch({
          type: CreateQuizActionTypes.SET_IS_OFFICIAL,
          payload: loading,
        });
      },

      setIsLocked: (locked: boolean) => {
        dispatch({
          type: CreateQuizActionTypes.SET_IS_LOCKED,
          payload: locked,
        });
      },

      setIsRandomChallengers: (isRandomChallengers: boolean) => {
        dispatch({
          type: CreateQuizActionTypes.SET_IS_RANDOM_CHALLENGERS,
          payload: isRandomChallengers,
        });
      },
    };
  }, [dispatch]);
}
