import { createAction } from '@reduxjs/toolkit';

const types = {
  RESEND_EMAIL: 'LAYOUT/RESEND_EMAIL',
  RESET_RESEND_EMAIL: 'LAYOUT/RESET_RESEND_EMAIL',
  UPDATE_BLOCKER_OFFSET: 'LAYOUT/UPDATE_BLOCKER_OFFSET',
};

export const resendEmail = createAction(types.RESEND_EMAIL);
export const resetResendEmail = createAction(types.RESET_RESEND_EMAIL);
export const updateBlockerOffset = createAction(
  types.UPDATE_BLOCKER_OFFSET,
  (ev: { top?: number; left?: number }) => ({
    payload: ev,
  })
);
