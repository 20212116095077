import { useMemo } from 'react';
import { useClubGroupUnreadNotificationCount } from '@lib/web/apis';
import { uniq } from 'lodash';

export default function useAllJoinedClubSlugs() {
  const { data: clubGroupData } = useClubGroupUnreadNotificationCount();

  return useMemo(() => {
    if (!clubGroupData) return [];
    const joined: ClubUnreadNotification[] = [];
    clubGroupData.data.clubGroups.forEach((group) =>
      joined.concat(group.clubs.filter((c) => c.isSubscribed))
    );
    joined.concat(clubGroupData.data.clubs.filter((c) => c.isSubscribed));

    return uniq(joined.map((c) => c.slug));
  }, [clubGroupData]);
}
