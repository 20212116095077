import React, { useEffect, useRef } from 'react';
import { useTranslation } from 'next-i18next';
import { alpha, ClickAwayListener, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { ActionCloseSmall as ActionCloseSmallIcon } from '@front/icon';
import { EmphasizeButton, EmphasizeButtonProps } from '@front/ui';
import { useAnimationFrame } from '@lib/web/hooks';

import useQuizCountdownSubmit from '../../../hooks/useQuizCountdownSubmit';

const styles = {
  loading: {
    position: 'relative',
    width: 16,
    height: 16,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& .MuiCircularProgress-root': {
      position: 'absolute',
      top: 0,
      left: 0,
    },
    '& svg': {
      width: '100%',
      height: '100%',
    },
  },

  actions: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    width: { xs: '100%', md: 'auto' },
    gap: 1,
    alignItems: 'end',
  },
  confirm: {
    '& .em-button-base': {
      background: (theme: Theme) => {
        const bgAbove = alpha(theme.palette.background.darker, 0.3);
        const bgBelow = theme.palette.text.primary;
        return `linear-gradient(${bgAbove}, ${bgAbove}), linear-gradient(${bgBelow}, ${bgBelow})`;
      },
      backgroundPosition: 'right, center',
      backgroundSize: '100% 100%, 100% 100%',
      backgroundRepeat: 'no-repeat',
    },
  },
};

export type SubmitButtonProps = Omit<EmphasizeButtonProps, 'onClick'> & {
  isPendingSubmit: boolean;
  lazySubmit: () => void;
  cancelSubmit: () => void;
  submitAnswer: () => void;
};

const DURATION_IN_SECONDS = 3;

export default function SubmitButton({
  sx,
  loading,
  disabled = false,
  children = 'Submit',
  isPendingSubmit,
  cancelSubmit,
  lazySubmit,
  submitAnswer,
  ...rest
}: SubmitButtonProps) {
  const { seconds, toggleSubmit } = useQuizCountdownSubmit({
    defaultSeconds: DURATION_IN_SECONDS,
    interval: 1000,
    isPendingSubmit,
    cancelSubmit,
    lazySubmit,
    submitAnswer,
  });

  const { t } = useTranslation('quiz');
  const confirmRef = useRef<HTMLDivElement>();
  const startCountdown = seconds === DURATION_IN_SECONDS;
  const updateProgress = (elapsed = 0) => {
    if (!confirmRef.current) return;
    const percentage = 100 - (elapsed / (DURATION_IN_SECONDS * 1000)) * 100;
    confirmRef.current.style.backgroundSize = `${percentage}% 100%, 100% 100%`;
  };

  const { start, cancel } = useAnimationFrame((elapsed: number) => {
    updateProgress(elapsed);
  }, DURATION_IN_SECONDS * 1000);

  const handleSubmit = (ev: React.MouseEvent<HTMLButtonElement>) => {
    ev.currentTarget.blur();
    toggleSubmit();
  };

  const handleCancel = (ev?: React.MouseEvent<HTMLButtonElement>) => {
    if (ev) ev.currentTarget.blur();

    cancel();
    cancelSubmit();
  };

  const handleConfirm = () => {
    submitAnswer();
  };

  const handleClickOutside = () => {
    handleCancel();
  };

  useEffect(() => {
    if (startCountdown) {
      start();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startCountdown]);

  if (!isPendingSubmit) {
    return (
      <EmphasizeButton
        sx={sx}
        disabled={disabled}
        loading={loading}
        onClick={handleSubmit}
        {...rest}
      >
        {children || t('button.Submit')}
      </EmphasizeButton>
    );
  }

  return (
    <Box sx={[styles.actions]}>
      <EmphasizeButton
        variant="outlined"
        onClick={handleCancel}
        prefixIcon={<ActionCloseSmallIcon width={16} height={16} />}
        disabled={disabled && loading}
      >
        {t('button.Cancel')}
      </EmphasizeButton>
      <ClickAwayListener onClickAway={handleClickOutside}>
        <EmphasizeButton
          sx={styles.confirm}
          ref={(ref) => {
            confirmRef.current = ref?.querySelector(
              '.em-button-base'
            ) as HTMLDivElement;
          }}
          onClick={handleConfirm}
          disabled={disabled && loading}
          loading={loading}
        >
          {t('button.Confirm')} ({seconds})
        </EmphasizeButton>
      </ClickAwayListener>
    </Box>
  );
}
