import React, {
  ReactEventHandler,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { isDesktop } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import { InputBase } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { composerConfig } from '@front/config';
import { useDebounce, useUploadFile } from '@front/helper';
import {
  ActionMore as ActionMoreIcon,
  EditorParaphrase as EditorParaphraseIcon,
  NFTPicture as NFTPictureIcon,
  OtherCopy as OtherCopyIcon,
  OtherDelete as OtherDeleteIcon,
} from '@front/icon';
import {
  IconButton,
  MenuDropdown,
  toast,
  useToggleMenuDropdown,
} from '@front/ui';
import { apis } from '@lib/web/apis';
import { blockLevelProseMirrorNodeToComposerBlocks } from '@lib/web/composer';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { notionLikeFileImageKeyboardShortcuts } from '@lib/web/composer/TextComposer/components/blocks/shared/notionLikeFileImageKeyboardShortcuts';
import { useGeneralBlockMoreActions } from '@lib/web/composer/TextComposer/components/SideMenu/hooks/useGeneralBlockMoreActions';
import { TextComposerContext } from '@lib/web/composer/TextComposer/context/TextComposerContext';
import { getBlockInfoFromPos } from '@lib/web/composer/utils/getBlockInfoFromPos';
import { ThreadBlockTypes } from '@lib/web/thread/ThreadTextComposer/config/threadBlockTypes';
import { ThreadComposerSchema } from '@lib/web/thread/ThreadTextComposer/config/threadComposerSchema';
import { VisuallyHiddenInput } from '@lib/web/ui';
import { mergeAttributes, Node } from '@tiptap/core';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { Node as ProseMirrorNode } from 'prosemirror-model';

const styles = {
  placeholder: {
    display: 'flex',
    width: '100%',
    height: '44px',
    padding: '8px 20px',
    gap: '8px',
    alignItems: 'center',
    borderRadius: '4px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    typography: 'body1',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
    cursor: 'pointer',
    '@media (hover: hover)': {
      '&:hover': {
        background: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
      },
    },
  },
  imageContainer: {
    position: 'relative',

    img: {
      verticalAlign: 'middle',
      maxWidth: '200px',
      maxHeight: '120px',
      objectFit: 'contain',
      objectPosition: 'left top',
    },

    '@media (hover: hover)': {
      '&:hover': {
        '.more-action-button': {
          opacity: 1,
        },
      },
    },
  },
  imageUploadPercentage: {
    position: 'absolute',
    right: '6px',
    bottom: '10px',
    borderRadius: '4px',
    padding: '4px 8px',
    background: (theme: Theme) => alpha(theme.palette.background.darker, 0.7),
    typography: 'body2',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.75),
  },
  caption: {
    typography: 'caption',
    width: '100%',
  },
  moreActionButton: {
    background: '#fff',
    position: 'absolute',
    right: '6px',
    top: '6px',
    color: 'background.darker',
    opacity: 0,
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        color: 'background.darker',
        background: (theme: Theme) =>
          alpha(theme.palette.background.darker, 0.3),
      },
    },
  },
  moreActionButtonAlwaysShow: {
    opacity: 1,
  },
  menuDropdown: {
    zIndex: 2,
    '& .popper-content': {
      minWidth: '140px',
    },
  },
  menuDropdownItem: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    typography: 'body',
  },
  menuDropdownItemDelete: {
    color: 'error.dark',
  },
};

type BlockProps = {
  node: ProseMirrorNode;
  getPos: (() => number) | boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const FILE_INPUT_ACCEPT = composerConfig.supportedImageTypes.join(',');

function Image({ node, getPos, updateAttributes }: BlockProps) {
  const { t } = useTranslation('editor');
  const fileInputRef = useRef<HTMLInputElement>();
  const { menuAnchorEl, toggleMenu, handleMenuClose, handleMenuOptionClick } =
    useToggleMenuDropdown();
  const [src, setSrc] = useState<string>(node.attrs.src);

  const [caption, setCaption] = useState<string | null>(
    node.attrs.caption || null
  );
  const captionRef = useRef<HTMLInputElement>(null);
  const status = useRef<string>(node.attrs.status); // useRef to make sure we always get the latest status value to prevent triggering the logic inside useEffect multiple times
  const pos = typeof getPos === 'function' ? getPos() : null;
  const { editor } = useContext(TextComposerContext);

  const blockContainerBlock = useMemo(() => {
    const blockContainerNode = pos
      ? getBlockInfoFromPos(editor._tiptapEditor.state.doc, pos).node
      : null;
    return blockContainerNode
      ? blockLevelProseMirrorNodeToComposerBlocks(blockContainerNode)[0]
      : null;
  }, [editor._tiptapEditor.state.doc, pos]);

  const { duplicateBlock, deleteBlock } =
    useGeneralBlockMoreActions<ThreadComposerSchema>({
      editor,
      block: blockContainerBlock,
    });

  const options = [
    {
      display: (
        <Box sx={styles.menuDropdownItem}>
          <EditorParaphraseIcon width={16} height={16} />
          {t('Add Caption')}
        </Box>
      ),
      onClick: () => {
        if (caption === null) setCaption('');
        // use setTimeout because the element is default hide
        setTimeout(() => {
          captionRef.current?.focus();
        });
      },
    },
    {
      display: (
        <Box sx={styles.menuDropdownItem}>
          <NFTPictureIcon width={16} height={16} />
          {t('Replace')}
        </Box>
      ),
      onClick: () => {
        fileInputRef.current?.click();
      },
    },
    {
      display: (
        <Box sx={styles.menuDropdownItem}>
          <OtherCopyIcon width={16} height={16} />
          {t('Duplicate')}
        </Box>
      ),
      onClick: () => {
        duplicateBlock();
      },
    },
    {
      display: (
        <Box sx={[styles.menuDropdownItem, styles.menuDropdownItemDelete]}>
          <OtherDeleteIcon width={16} height={16} />
          {t('Delete')}
        </Box>
      ),
      onClick: () => {
        deleteBlock();
      },
    },
  ];

  const handleCaptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCaption(event.target.value);
    updateAttributes({
      caption: event.target.value,
    });
  };

  const handleCaptionKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.key === 'Backspace' && caption === '') {
      setCaption(null);
    }
  };

  const handleCaptionBlur = () => {
    if (caption === '') setCaption(null);
  };

  const changeSrc = (newSrc: string) => {
    setSrc(newSrc);
    // when image drag and drop, the block will be re-created, so we need to keep the value of src
    updateAttributes({
      src: newSrc,
    });
  };

  const updateStatus = useCallback(
    (newStatus: string) => {
      status.current = newStatus;
      // prevent "flushSync was called" error
      setTimeout(() => {
        updateAttributes({
          status: newStatus,
        });
      });
    },
    [updateAttributes]
  );

  const { handleFileChange, progress, changeFile } = useUploadFile({
    getUploadKeyAndUrl: async (file: File) => {
      const ext = file.name.split('.').pop()?.toLowerCase() || '';
      const { data } = await apis.file.getAhaThreadImageUploadUrl(ext);
      return data;
    },
    onFileChange: ({ previewUrl }) => {
      changeSrc(previewUrl);
    },
    onSuccess: (key) => {
      updateAttributes({
        key,
        status: 'upload-success',
      });
    },
    onFail: (err) => {
      toast.error(t('Fail to upload image'));
      console.warn('upload fail', err);
      try {
        updateAttributes({
          status: 'upload-fail',
        });
        changeSrc(''); // clear previewUrl so the image block ui will change back to placeholder
      } catch (e) {
        // it's possible that user will leave this textComposer, make updateAttributes fail
        console.warn('updateAttributes fail', e);
      }
    },
    onLoadPreviewImg: ({ size }) => {
      try {
        updateAttributes({
          naturalHeight: size.naturalHeight,
          naturalWidth: size.naturalWidth,
        });
      } catch (e) {
        console.warn('updateAttributes fail', e);
      }
    },
  });

  useEffect(() => {
    if (status.current === 'added-by-insert') {
      fileInputRef.current?.click();
      updateStatus('ready-for-upload');
    }
  }, [updateStatus]);

  useEffect(() => {
    if (status.current === 'added-by-file-drop') {
      if (!composerConfig.supportedImageTypes.includes(node.attrs.file.type)) {
        toast.error(
          t('Supported file types: ##', {
            types: composerConfig.supportedImageTypesMessage,
          })
        );
        updateStatus('upload-fail');
        return;
      }

      // prevent "flushSync was called" error
      setTimeout(() => {
        changeFile(node.attrs.file);
      });
      updateStatus('ready-for-upload');
    }
  }, [changeFile, node.attrs.file, t, updateStatus]);

  const debounceIsUploadFinish = useDebounce(progress === 100); // even when progress is 100, we need to show it before disappear

  const handleImageLoaded: ReactEventHandler<HTMLImageElement> = (ev) => {
    const { width: imgWidth, height: imgHeight } = ev.currentTarget;

    const ratio =
      imgWidth && imgHeight
        ? Math.round((imgWidth / imgHeight) * 100) / 100
        : 0;

    updateAttributes({
      ratio,
    });
  };
  return (
    <NodeViewWrapper>
      <ThemeProvider mode="dark">
        <Box>
          {src ? (
            <Box sx={styles.imageContainer}>
              <img
                src={src}
                alt="user upload file"
                onLoad={handleImageLoaded}
                width={node.attrs.naturalWidth || 'auto'}
                height={node.attrs.naturalHeight || 'auto'}
              />
              {progress !== null && !debounceIsUploadFinish && (
                <Box sx={styles.imageUploadPercentage}>{progress}%</Box>
              )}
              <IconButton
                sx={[
                  styles.moreActionButton,
                  !isDesktop && styles.moreActionButtonAlwaysShow,
                ]}
                className="more-action-button"
                customSize={24}
                onClick={toggleMenu}
              >
                <ActionMoreIcon />
              </IconButton>
              <Box component="label">
                <VisuallyHiddenInput
                  type="file"
                  onChange={handleFileChange}
                  ref={fileInputRef}
                  inputProps={{ accept: FILE_INPUT_ACCEPT }}
                />
              </Box>
            </Box>
          ) : (
            <Box sx={styles.placeholder} component="label">
              <NFTPictureIcon />
              {t('Image')}
              <VisuallyHiddenInput
                type="file"
                onChange={handleFileChange}
                ref={fileInputRef}
                inputProps={{ accept: FILE_INPUT_ACCEPT }}
              />
            </Box>
          )}
          {caption !== null && (
            <InputBase
              inputRef={captionRef}
              sx={styles.caption}
              value={caption}
              placeholder={t('Write caption')}
              onChange={handleCaptionChange}
              onKeyDown={handleCaptionKeyDown}
              onBlur={handleCaptionBlur}
            />
          )}
          <MenuDropdown
            sx={styles.menuDropdown}
            anchorEl={menuAnchorEl}
            open={!!menuAnchorEl}
            options={options}
            onClose={handleMenuClose}
            onClick={handleMenuOptionClick}
          />
        </Box>
      </ThemeProvider>
    </NodeViewWrapper>
  );
}

type ImageBlockOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: ImageBlockOptions;
    node: ProseMirrorNode;
  }) => string;
};

export const imageBlockDefaultProps = {
  caption: {
    default: '',
  },
  key: {
    default: '',
  },
  src: {
    default: '',
  },
  status: {
    default: '', // added-by-insert, added-by-file-drop, ready-for-upload, upload-success, upload-fail
  },
  file: {
    default: '',
  },
  ratio: {
    default: 0,
  },
  naturalHeight: {
    default: 0,
  },
  naturalWidth: {
    default: 0,
  },
};
const ImageBlock = Node.create<ImageBlockOptions>({
  name: ThreadBlockTypes.Image,

  group: 'blockContent', // to combine tiptap node with blocknote, this one should be blockContent

  inline: false,

  selectable: false,

  atom: true,

  addAttributes() {
    return imageBlockDefaultProps;
  },

  addKeyboardShortcuts: notionLikeFileImageKeyboardShortcuts,

  parseHTML() {
    return [
      {
        tag: 'image',
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'div',
      {
        'data-content-type': this.name,
        'data-caption': HTMLAttributes.caption || '',
      },
      [
        'img',
        mergeAttributes(HTMLAttributes, {
          /**
           * set the image height is important for thread because we need to set the height of each message before image loaded,
           * otherwise we cannot auto scroll to the bottom, and make some weird ux
           */
          height: `${HTMLAttributes.naturalHeight || 330}px`,
        }),
      ],
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(Image);
  },
});

export default ImageBlock;
