import api from './client';

const memberApi = {
  getInfos: (params: GetMultipleMemberInfoReq) =>
    api.post<Response<GetMemberInfoRes[]>>('v2/member/info', params),
  getProfile: () => api.get<Response<Member>>('v2/member/profile/me'),
  getSocialProfile: (userId: string) =>
    api.get<Response<GerProfileSocialRes>>(
      `v2/member/profile/social?userId=${userId}`
    ),
  updateProfile: (params: UpdateProfileReq) =>
    api.post('v2/member/profile/me', params),
  checkUsername: (username: string) =>
    api.get(`v2/member/profile/validate?userName=${username}`),
  searchMember: ({
    keyword,
    page = 1,
    pageSize = 10,
  }: {
    keyword: string;
    page?: number;
    pageSize?: number;
  }) =>
    api
      .get<PageResponse<GetFriendUserRes>>(
        `v2/member?keyword=${encodeURIComponent(
          keyword
        )}&page=${page}&limit=${pageSize}`
      )
      .then((res) => {
        if (keyword?.toLowerCase() === 'aha') {
          const items = res.data.data.items;
          /**
           * FIXME:
           * the Aha user is a logical user (for now, the BE has not been able to convert it to a real one),
           * so the BE can not return it as a normal user in this API.
           * => need to hard code the Aha user here.
           * Once the BE fix this issue, this one can be removed
           */
          const ahaMember = {
            userId: '00000000-0000-0000-0000-000000000000',
            avatar: '/logo.png',
            displayName: 'Aha',
            distinctName: 'aha',
            nftAvatar: '/logo.png',
            userName: 'aha',
          } as GetFriendUserRes;
          res.data.data.items = [ahaMember, ...items];
        }
        return res;
      }),
  followUser: (userId: string) => api.post(`v2/member/follow/${userId}`),
  unfollowUser: (userId: string) => api.post(`/v2/member/unfollow/${userId}`),
};

export default memberApi;
