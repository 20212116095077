import { ComposerBlock } from '@lib/web/composer';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { v4 } from 'uuid';

import { BasicBlockTypes } from '../TextComposer/config/basicBlockTypes';

/**
 * We use blocknote as our main composer pacakge, which used tiptap to build up a block style editor,
 * but we need some advance feature that blocknote is not provided,
 * so we need to use tiptap in some cases, such as data handling.
 *
 * This utility is supposed to encapsulate most blocknote, tiptap logics, make other place don't need to care out the details
 *
 *
 * --------------
 * Relation between packages
 * --------------
 * <blocknote> use <tiptap> use <prosemirror>
 *
 * ---------------
 * Read Data Flow
 * ---------------
 * receive string from backend
 * -> transform to : composerBlocks
 * -> insert it to editor by : tiptap function
 *
 * ---------------
 * Write Data Flow
 * ---------------
 * prosemirror node from tiptap function
 * -> transform to : composerBlocks
 * -> transform to : api acceptable format
 * -> send to backend
 */

const proseMirrorNodeToComposerBlock = (
  node: ProseMirrorNode
): ComposerBlock => {
  return {
    id: node.attrs.id || v4(),
    type: node.type.name,
    text: node.text,
    attrs: node.attrs,
    marks: node.marks
      ?.filter((mark) =>
        ['underline', 'bold', 'italic'].includes(mark.type.name)
      )
      .map((mark) => mark.toJSON()),
    content: [],
    styles: {},
  };
};

export const proseMirrorNodeToComposerBlocks = (
  doc: ProseMirrorNode
): ComposerBlock[] => {
  const block = [];
  for (let i = 0; i < doc.childCount; i++) {
    const currentNode = doc.child(i);
    if (currentNode) {
      block.push({
        ...proseMirrorNodeToComposerBlock(currentNode),
        content: currentNode.childCount
          ? proseMirrorNodeToComposerBlocks(currentNode)
          : [],
      });
    }
  }

  return block;
};

export const cloneComposerBlock = (
  block: ComposerBlock
): ComposerBlock | null => {
  if (!('attrs' in block) || !('id' in block) || !('type' in block)) {
    return null;
  }

  const newBlock = {
    ...block,
    attrs: { ...block.attrs },
    id: v4(),
  };

  if (block.attrs.id) {
    newBlock.attrs.id = v4();
  }

  return newBlock;
};

export const blockLevelProseMirrorNodeToComposerBlocks = (
  node: ProseMirrorNode
): ComposerBlock[] => {
  switch (node.type.name) {
    case 'image':
    case 'audio':
      return [proseMirrorNodeToComposerBlock(node)];
    case BasicBlockTypes.Paragraph:
      return proseMirrorNodeToComposerBlocks(node);
    case 'blockContainer':
    case BasicBlockTypes.Heading:
    case BasicBlockTypes.Subtitle:
    case BasicBlockTypes.Step:
      return [
        {
          ...proseMirrorNodeToComposerBlock(node),
          content: node.childCount ? proseMirrorNodeToComposerBlocks(node) : [],
        },
      ];
    default:
      console.warn(`unsupported node type :${node.type.name}`);
      return [];
  }
};

export const filterComposerBlocksByExtensionsAndToolbars = (
  blocks: ComposerBlock[],
  disabledBlockTypes: string[]
): ComposerBlock[] => {
  const block = [];

  for (let i = 0; i < blocks.length; i++) {
    const currentBlock = blocks[i];
    if (!disabledBlockTypes.includes(currentBlock.type)) {
      block.push({
        ...currentBlock,
        content: currentBlock.content
          ? filterComposerBlocksByExtensionsAndToolbars(
              currentBlock.content,
              disabledBlockTypes
            )
          : [],
      });
    }
  }

  return block;
};

export const persistentStringToComposerBlock = (
  content: string
): ComposerBlock => {
  try {
    return JSON.parse(content);
  } catch (e) {
    console.warn('persistentStringToComposerBlock error', e);

    // to prevent data lost, we use a default block to render the content
    return {
      id: Math.random().toString(),
      type: 'blockContainer',
      attrs: {},
      content: [
        {
          id: Math.random().toString(),
          type: 'paragraph',
          attrs: {},
          content: [
            {
              id: Math.random().toString(),
              type: 'text',
              attrs: {},
              text: content,
              styles: {},
            },
          ],
          styles: {},
        },
      ],
      styles: {},
    };
  }
};
