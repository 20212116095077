import { ComposerRenderer } from '@lib/web/composer';
import { ComposerRendererProps } from '@lib/web/composer/TextComposer/ComposerRenderer';
import { threadBlockHtmlStyles } from '@lib/web/thread/ThreadTextComposer/config/threadComposerBlockStyles';

export type ThreadComposerRendererProps = Omit<
  ComposerRendererProps,
  'htmlStyles'
>;

export default function ThreadComposerRenderer({
  sx,
  ...rest
}: ThreadComposerRendererProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <ComposerRenderer
      sx={sxProps}
      htmlStyles={threadBlockHtmlStyles}
      {...rest}
    />
  );
}
