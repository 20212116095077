import React from 'react';
import { Skeleton } from '@mui/material';
import Box from '@mui/material/Box';
import { IaTableCellBorder } from '@lib/ia/src/layouts/TableLayout/components';
import { IaTableCheckboxCellBorder } from '@lib/ia/src/layouts/TableLayout/components/IaTableCellBorder/IaTableCellBorder';
import { TableLayoutConfig } from '@lib/ia/src/layouts/TableLayout/types';

const styles = {
  cell: {
    display: 'flex',
    alignItems: 'center',
    height: '32px',
    px: 1,
  },
};
export default function IaTableLoadingRow({
  columnOrder,
  layoutSetting,
}: {
  columnOrder: string[];
  layoutSetting: TableLayoutConfig['settings']['layoutSetting'];
}) {
  return (
    <>
      <IaTableCheckboxCellBorder layoutSetting={layoutSetting} />

      {columnOrder.map((key) => (
        <IaTableCellBorder
          key={key}
          columnOrder={columnOrder}
          columnKey={key}
          rowId="loading-row"
          layoutSetting={layoutSetting}
        >
          <Box sx={styles.cell}>
            <Skeleton width="100%" />
          </Box>
        </IaTableCellBorder>
      ))}
    </>
  );
}
