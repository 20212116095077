/* eslint-disable @typescript-eslint/no-explicit-any */
import { RefObject } from 'react';

export type BaseLayoutRightPanelParams = Record<string, any> & {
  returnPanel?: string;
  returnParams?: BaseLayoutRightPanelParams;
};

export interface BaseLayoutValue {
  leftPanelOpened: boolean;
  rightPanelOpened: boolean;
  leftPanelTarget: string;
  rightPanelTarget: string;
  rightPanelRef: RefObject<Element | null>;
  rightPanelParams: BaseLayoutRightPanelParams;
  hideLeftPanel: boolean;
  rightPanelWidth: number;
  isResizing: boolean;
  rightPanelResizable: boolean;
  rightPanelAutoClose: boolean;
  rightPanelHistory: RightPanelHistory;
  mainNavOpened: boolean;
}

export const enum BaseLayoutActionTypes {
  OPEN_LEFT_PANEL = 'OPEN_LEFT_PANEL',
  CLOSE_LEFT_PANEL = 'CLOSE_LEFT_PANEL',
  TOGGLE_LEFT_PANEL = 'TOGGLE_LEFT_PANEL',
  OPEN_RIGHT_PANEL = 'OPEN_RIGHT_PANEL',
  CLOSE_RIGHT_PANEL = 'CLOSE_RIGHT_PANEL',
  TOGGLE_RIGHT_PANEL = 'TOGGLE_RIGHT_PANEL',
  SET_RIGHT_PANEL = 'SET_RIGHT_PANEL',
  SET_RIGHT_PANEL_PARAMS = 'SET_RIGHT_PANEL_PARAMS',
  CLEAR_RIGHT_PANEL_PARAMS = 'CLEAR_RIGHT_PANEL_PARAMS',
  SET_RIGHT_PANEL_WIDTH = 'SET_RIGHT_PANEL_WIDTH',
  SET_RESIZING = 'SET_RESIZING',
  SET_RIGHT_PANEL_RESIZABLE = 'SET_RIGHT_PANEL_RESIZABLE',
  SET_RIGHT_PANEL_AUTOCLOSE = 'SET_RIGHT_PANEL_AUTOCLOSE',

  DISABLE_LEFT_PANEL = 'DISABLE_LEFT_PANEL',
  ENABLE_LEFT_PANEL = 'ENABLE_LEFT_PANEL',

  // history actions
  RIGHT_PANEL_HISTORY_BACK = 'RIGHT_PANEL_HISTORY_BACK',
  RIGHT_PANEL_HISTORY_FORWARD = 'RIGHT_PANEL_HISTORY_FORWARD',
  RIGHT_PANEL_HISTORY_RESET = 'RIGHT_PANEL_HISTORY_RESET',

  // navbar actions
  OPEN_MAIN_NAV = 'OPEN_MAIN_NAV',
  CLOSE_MAIN_NAV = 'CLOSE_MAIN_NAV',
}

type OpenLeftPanelAction = {
  type:
    | BaseLayoutActionTypes.OPEN_LEFT_PANEL
    | BaseLayoutActionTypes.TOGGLE_LEFT_PANEL;
  payload: { target: string; isDesktop?: boolean };
};

type ClosePanelAction = {
  type:
    | BaseLayoutActionTypes.CLOSE_LEFT_PANEL
    | BaseLayoutActionTypes.CLOSE_RIGHT_PANEL;
};

type OpenRightPanelAction = {
  type:
    | BaseLayoutActionTypes.OPEN_RIGHT_PANEL
    | BaseLayoutActionTypes.TOGGLE_RIGHT_PANEL;
  payload: {
    target: string;
    params?:
      | BaseLayoutRightPanelParams
      | ((ev: BaseLayoutRightPanelParams) => BaseLayoutRightPanelParams);
    isDesktop?: boolean;
  };
};

type SetRightPanelAction = {
  type: BaseLayoutActionTypes.SET_RIGHT_PANEL;
  payload: { ref: RefObject<Element | null> };
};

type SetRightParamsAction = {
  type: BaseLayoutActionTypes.SET_RIGHT_PANEL_PARAMS;
  payload: {
    params:
      | BaseLayoutRightPanelParams
      | ((ev: BaseLayoutRightPanelParams) => BaseLayoutRightPanelParams);
  };
};

type SetRightPanelWidthAction = {
  type: BaseLayoutActionTypes.SET_RIGHT_PANEL_WIDTH;
  payload: { width: number };
};

type SetResizingAction = {
  type: BaseLayoutActionTypes.SET_RESIZING;
  payload: { isResizing: boolean };
};

type SetRightPanelResizableAction = {
  type: BaseLayoutActionTypes.SET_RIGHT_PANEL_RESIZABLE;
  payload: { rightPanelResizable: boolean };
};

type SetRightPanelAutocloseAction = {
  type: BaseLayoutActionTypes.SET_RIGHT_PANEL_AUTOCLOSE;
  payload: { rightPanelAutoClose: boolean };
};

type ClearRightParamsAction = {
  type: BaseLayoutActionTypes.CLEAR_RIGHT_PANEL_PARAMS;
};

type SetLeftPanelStatusAction = {
  type:
    | BaseLayoutActionTypes.ENABLE_LEFT_PANEL
    | BaseLayoutActionTypes.DISABLE_LEFT_PANEL;
};

type RightPanelHistoryAction = {
  type:
    | BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_BACK
    | BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_FORWARD
    | BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_RESET;
};

type NavAction = {
  type:
    | BaseLayoutActionTypes.OPEN_MAIN_NAV
    | BaseLayoutActionTypes.CLOSE_MAIN_NAV;
};

export type BaseLayoutActions =
  | ClosePanelAction
  | OpenLeftPanelAction
  | OpenRightPanelAction
  | SetRightPanelAction
  | SetRightParamsAction
  | ClearRightParamsAction
  | SetLeftPanelStatusAction
  | SetRightPanelWidthAction
  | SetResizingAction
  | SetRightPanelResizableAction
  | SetRightPanelAutocloseAction
  | RightPanelHistoryAction
  | NavAction;

export interface RightPanelHistory {
  currentIndex: number;
  items: RightPanelHistoryState[];
}

export interface RightPanelHistoryState {
  panelKey: string;
  params: any;
}
