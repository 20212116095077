import { Box, ButtonBase, Typography } from '@mui/material';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import { DisplayTableLayoutButtonFooterCell } from '../../types';

export type IaDisplayTableButtonFooterCellProps =
  DisplayTableLayoutButtonFooterCell;

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    pl: 1,
  },
  button: {
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: 1,
  },
  text: {
    fontWeight: 'bold',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableButtonFooterCell({
  text,
  action,
  icon,
}: IaDisplayTableButtonFooterCellProps) {
  const { getIaAction } = useIaAction();

  const eventActionProps = {
    onClick: () => getIaAction(action)?.action(),
  };

  return (
    <Box sx={styles.root}>
      <ButtonBase sx={styles.button} disableRipple {...eventActionProps}>
        {icon && <Icon name={icon} width={16} height={16} />}
        <Typography variant="body2" sx={styles.text}>
          {text}
        </Typography>
      </ButtonBase>
    </Box>
  );
}
