import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import {
  restrictToVerticalAxis,
  restrictToWindowEdges,
} from '@dnd-kit/modifiers';
import {
  Sortable,
  SortableItem,
  SortableOnDragOverOtherItem,
} from '@front/helper';
import { ComposerBlock } from '@lib/web/composer';
import {
  UNSCRAMBLE_ANSWER_NUM_MAX,
  UNSCRAMBLE_ANSWER_NUM_MIN,
} from '@lib/web/editor/configs/answerFormats';
import NewChoice from '@lib/web/editor/EditorAnswer/components/EditorAnswerUnscramble/components/NewChoice';

import { UnscrambleValue } from '../types';

import Choice from './Choice';

const styles = {
  choiceContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  captionContainer: {
    mt: '16px',
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  caption: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
  captionHighlight: {
    color: 'text.primary',
  },
};

type UnscrambleProps = {
  enableAddRemoveOptions: boolean;
  unscrambleValues: UnscrambleValue[];
  onBlocksChange: (id: string, blocks: ComposerBlock[]) => void;
  onDragOverOtherItem: SortableOnDragOverOtherItem<UnscrambleValue>;
  onAddNewChoice: () => void;
  onRemoveChoice: (id: string) => void;
};

export default function Unscramble({
  enableAddRemoveOptions,
  unscrambleValues,
  onBlocksChange,
  onDragOverOtherItem,
  onAddNewChoice,
  onRemoveChoice,
}: UnscrambleProps) {
  return (
    <Box>
      <Sortable<UnscrambleValue>
        items={unscrambleValues}
        onDragOverOtherItem={onDragOverOtherItem}
        modifiers={[restrictToVerticalAxis, restrictToWindowEdges]}
      >
        {({ isDragging }) => (
          <Box sx={styles.choiceContainer}>
            {unscrambleValues.map((unscrambleValue, index) => (
              <SortableItem key={unscrambleValue.id} id={unscrambleValue.id}>
                {({ isGhostEl }) => (
                  <Choice
                    symbol={index + 1}
                    value={unscrambleValue}
                    onBlocksChange={onBlocksChange}
                    disableSort={isGhostEl}
                    hideRemove={
                      !enableAddRemoveOptions ||
                      unscrambleValues.length <= UNSCRAMBLE_ANSWER_NUM_MIN
                    }
                    onRemoveChoice={onRemoveChoice}
                  />
                )}
              </SortableItem>
            ))}
            {enableAddRemoveOptions &&
              !isDragging &&
              unscrambleValues.length < UNSCRAMBLE_ANSWER_NUM_MAX && (
                <NewChoice onClick={onAddNewChoice} />
              )}
          </Box>
        )}
      </Sortable>
      {unscrambleValues.length > 0 && (
        <Box sx={styles.captionContainer}>
          <Typography variant="caption" sx={styles.caption}>
            Order the correct answers from{' '}
            <Typography variant="caption" sx={styles.captionHighlight}>
              top to bottom
            </Typography>
          </Typography>
        </Box>
      )}
    </Box>
  );
}
