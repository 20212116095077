import { MouseEvent } from 'react';
import { ButtonBase, Theme, useMediaQuery } from '@mui/material';
import { alpha } from '@mui/material/styles';
import {
  LoginHide as LoginHideIcon,
  LoginSee as LoginSeeIcon,
} from '@front/icon';
import { SimpleTooltip } from '@front/ui';
import { ExamMode } from '@lib/web/apis';

const styles = {
  eliminationTooltip: {
    '& .MuiTooltip-tooltip': {
      ml: '20px !important',
      mt: '20px !important',
      maxWidth: '158px',
    },
  },
  hideEliminationTooltip: {
    '& .MuiTooltip-tooltip': {
      display: 'none',
    },
  },
  elimination: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    width: '44px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '0 12px 12px 0',
    cursor: 'pointer',
    '&.Mui-disabled': { pointerEvents: 'unset', opacity: 0.5 },
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
};
export default function PracticeEliminateToggle({
  mode,
  selected,
  eliminated,
  onEliminate,
}: {
  mode?: ExamMode;
  selected: boolean;
  eliminated: boolean;
  onEliminate: (ev: MouseEvent<HTMLButtonElement>) => void;
}) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  return (
    <SimpleTooltip
      title={
        mode === ExamMode.MockExam
          ? eliminated
            ? 'Show option that might be the answer'
            : 'Hide option that not the answer'
          : eliminated
          ? 'Show option'
          : 'Hide option'
      }
      placement="right-start"
      slotProps={{
        popper: {
          sx: [
            styles.eliminationTooltip,
            (selected || mdDown) && styles.hideEliminationTooltip,
          ],
        },
      }}
      followCursor
    >
      <span>
        <ButtonBase
          onClick={(e) => onEliminate(e)}
          sx={styles.elimination}
          disableRipple
          disabled={selected}
        >
          {!eliminated && <LoginHideIcon width={20} height={20} />}
          {eliminated && <LoginSeeIcon width={20} height={20} />}
        </ButtonBase>
      </span>
    </SimpleTooltip>
  );
}
