import { alpha, Theme, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { SquareAvatar } from '@front/ui';

import { DisplayTableLayoutAvatarTagCell } from '../../types';

export type IaDisplayTableAvatarTagCellProps = DisplayTableLayoutAvatarTagCell;

const styles = {
  root: {
    px: 1,
    display: 'flex',
    alignItems: 'center',
    overflow: 'hidden',
  },
  tag: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    gap: '4px',
    px: '6px',
    py: '2px',
    background: (theme: Theme) => alpha(theme.palette.text.primary, 0.05),
    borderRadius: '4px',
    overflow: 'hidden',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaDisplayTableAvatarTagCell({
  avatarUrl,
  text,
}: IaDisplayTableAvatarTagCellProps) {
  return (
    <Box sx={styles.root}>
      <Box sx={styles.tag}>
        <SquareAvatar blackAndWhite src={avatarUrl} size={16}>
          {text}
        </SquareAvatar>
        <Typography variant="caption" sx={styles.text}>
          {text}
        </Typography>
      </Box>
    </Box>
  );
}
