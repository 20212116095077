import { ReactNode } from 'react';
import Box from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import { TypographyProps } from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';
import { sanitize } from 'dompurify';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { getComposerRenderOptions } from '../utils/getComposerRenderOptions';

import { basicBlockHtmlStyles } from './config/basicComposerBlockStyles';

const styles = {
  styleProvider: {
    width: '100%',
    height: '100%',
  },
  oneLine: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',

    '& [data-content-type="paragraph"] , & [data-content-type="subtitle"], & [data-content-type="heading"]':
      {
        minWidth: 0,
        '& > *': {
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        },
      },

    '& br': {
      // make html element render on one line
      content: '" "',
      width: '4px',
      display: 'inline-block',
    },
  },
};

export type ComposerRendererProps = Omit<TypographyProps, 'children'> & {
  html?: string;
  oneLine?: boolean;
  styleProvider?: boolean;
  htmlStyles?: (
    | SystemStyleObject<Theme>
    | ((theme: Theme) => SystemStyleObject<Theme>)
  )[];
  children?:
    | ((ev: { renderOptions: HTMLReactParserOptions }) => ReactNode)
    | ReactNode;
  mentionRender?: (metadata: { userId: string; memberId: string }) => ReactNode;
  audioRender?: (metadata: { filename: string; fileKey: string }) => ReactNode;
};

export default function ComposerRenderer({
  html,
  oneLine = false,
  styleProvider = false,
  htmlStyles = basicBlockHtmlStyles,
  mentionRender,
  audioRender,
  sx,
  className = '',
  children,
  ...rest
}: ComposerRendererProps) {
  const classNameProp = `${oneLine ? 'render-one-line ' : ''}${className}`;

  const renderOptions = getComposerRenderOptions({
    mentionRender,
    audioRender,
  });

  if (styleProvider) {
    return (
      <Box
        className={classNameProp}
        sx={[styles.styleProvider, ...htmlStyles]}
        {...rest}
      >
        {typeof children === 'function'
          ? children({ renderOptions })
          : children}
      </Box>
    );
  }

  const sxProps = Array.isArray(sx) ? sx : [sx];

  if (html) {
    return (
      <Box
        className={classNameProp}
        sx={[oneLine && styles.oneLine, ...sxProps, ...htmlStyles]}
        {...rest}
      >
        {parse(sanitize(html), renderOptions)}
      </Box>
    );
  }

  return null;
}
