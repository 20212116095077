import { useState } from 'react';
import { alpha, Theme } from '@mui/material';
import MuiAvatar, { AvatarProps as MuiAvatarProps } from '@mui/material/Avatar';
import Badge from '@mui/material/Badge';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

import { getFontSize, stringAvatar } from './utils';

export type SquareAvatarProps = MuiAvatarProps & {
  size?: number;
  bgcolor?: string;
  textColor?: string;
  showIndicator?: boolean;
  showStacked?: boolean;
  online?: boolean;
  blackAndWhite?: boolean;
  maskUrl?: string;
  inlineComponent?: boolean;
};

const styles = {
  blackAndWhite: {
    bgcolor: 'text.primary',
    color: 'background.darker',
  },
  container: {
    position: 'relative',
    backgroundColor: 'transparent !important',
  },
  stackedAvatar: {
    position: 'absolute',
    zIndex: -1,
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.25),
  },
};

export default function SquareAvatar({
  sx,
  children,
  bgcolor,
  textColor,
  src,
  maskUrl = '/mask-avatar.svg',
  showIndicator = false,
  showStacked = false,
  online = false,
  blackAndWhite = false,
  inlineComponent = false,
  size = 40,
  slotProps = {},
  ...rest
}: SquareAvatarProps) {
  const [error, setError] = useState(false);

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const fontSize = getFontSize(size);
  const theme = useTheme();
  const avatarProps =
    typeof children === 'string' && (!src || error)
      ? stringAvatar(
          children,
          blackAndWhite ? theme.palette.text.primary : bgcolor,
          blackAndWhite ? theme.palette.background.darker : textColor
        )
      : blackAndWhite
      ? { sx: styles.blackAndWhite, children }
      : null;

  const avatarBaseSxProp = {
    width: size,
    height: size,
    fontSize,
    borderRadius: 0,
    mask: `url(${maskUrl}) center center / contain no-repeat`,
  };

  const avatarStyles = [
    avatarBaseSxProp,
    avatarProps ? avatarProps.sx : { bgcolor, color: textColor },
    ...sxProps,
  ];

  const stackedGap = size / 16;
  const component = inlineComponent ? 'span' : 'div';
  const avatarRender = (
    <Box sx={styles.container} component={component}>
      <MuiAvatar
        sx={avatarStyles}
        component={component}
        src={src}
        {...rest}
        slotProps={{
          img: {
            referrerPolicy: 'no-referrer',
            onError: () => setError(true),
            onLoad: () => setError(false),
            ...(slotProps?.img || {}),
          },
        }}
      >
        {avatarProps ? avatarProps.children : children}
      </MuiAvatar>
      {showStacked && (
        <>
          <MuiAvatar
            sx={[
              avatarBaseSxProp,
              styles.stackedAvatar,
              {
                top: stackedGap,
              },
            ]}
            component={component}
          />
          <MuiAvatar
            sx={[
              avatarBaseSxProp,
              styles.stackedAvatar,
              {
                top: stackedGap * 2,
              },
            ]}
            component={component}
          />
        </>
      )}
    </Box>
  );

  if (showIndicator) {
    return (
      <Badge
        overlap="circular"
        sx={{
          '& .MuiBadge-badge': {
            backgroundColor: online ? 'primary.light' : 'grey.400',
            width: 10,
            height: 10,
          },
        }}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        variant="dot"
      >
        {avatarRender}
      </Badge>
    );
  }
  return avatarRender;
}
