import { ReactNode } from 'react';
import { Box } from '@mui/material';

import AlbumLayoutAvatarsExtension from './AlbumLayoutAvatarsExtension';
import AlbumLayoutPieChartExtension from './AlbumLayoutPieChartExtension';
import AlbumLayoutTitleExtension from './AlbumLayoutTitleExtension';
import {
  AlbumLayoutAvatarsExtensionConfig,
  AlbumLayoutChartExtensionConfig,
  AlbumLayoutExtensionConfig,
  AlbumLayoutTitleExtensionConfig,
} from './types';

const styles = {
  container: {
    position: 'absolute',
  },
  placementTopLeft: {
    top: '8px',
    left: '8px',
  },
  placementTopRight: {
    top: '8px',
    right: '8px',
  },
  placementBottomLeft: {
    bottom: '8px',
    left: '8px',
  },
  placementBottomRight: {
    bottom: '8px',
    right: '8px',
  },
};

function extensionPlacement(
  placement: AlbumLayoutExtensionConfig['placement']
) {
  if (placement === 'top-left') return styles.placementTopLeft;
  if (placement === 'top-right') return styles.placementTopRight;
  if (placement === 'bottom-left') return styles.placementBottomLeft;
  if (placement === 'bottom-right') return styles.placementBottomRight;
  return null;
}

type ExtensionContainerProps = {
  placement: AlbumLayoutExtensionConfig['placement'];
  children: ReactNode;
};
function ExtensionContainer({ placement, children }: ExtensionContainerProps) {
  return (
    <Box sx={[styles.container, extensionPlacement(placement)]}>{children}</Box>
  );
}

type AlbumLayoutExtensionProps = AlbumLayoutExtensionConfig;

export default function AlbumLayoutExtension({
  type,
  config,
  placement,
}: AlbumLayoutExtensionProps) {
  if (type === 'pie-chart') {
    const chartConfig = config as AlbumLayoutChartExtensionConfig['config'];
    return (
      <ExtensionContainer placement={placement}>
        <AlbumLayoutPieChartExtension config={chartConfig} />
      </ExtensionContainer>
    );
  }
  if (type === 'avatars') {
    const avatarsConfig = config as AlbumLayoutAvatarsExtensionConfig['config'];
    return (
      <ExtensionContainer placement={placement}>
        <AlbumLayoutAvatarsExtension config={avatarsConfig} />
      </ExtensionContainer>
    );
  }
  if (type === 'title') {
    const avatarsConfig = config as AlbumLayoutTitleExtensionConfig['config'];
    return (
      <ExtensionContainer placement={placement}>
        <AlbumLayoutTitleExtension config={avatarsConfig} />
      </ExtensionContainer>
    );
  }
  console.warn(`Tooltip type = ${type} is not supported`);
  return null;
}
