import {
  useCreatorQuestionDetailData,
  useCreatorQuestionInfoData,
  useCurrentQuestion,
} from '@lib/web/editor';

export const useData = () => {
  const { questionId } = useCurrentQuestion();
  const { infoData, isInitializingOrLoading: isInfoDataInitializingOrLoading } =
    useCreatorQuestionInfoData(questionId);

  const { answerComponents, isInitializingOrLoading } =
    useCreatorQuestionDetailData(questionId);

  return {
    answerContentType: infoData?.answerContentType,
    isInitializingOrLoading:
      isInitializingOrLoading || isInfoDataInitializingOrLoading,
    component: answerComponents.length > 0 ? answerComponents[0] : null,
    limitMin: infoData?.essayAnswerWordLimitMin || 0,
    limitMax: infoData?.essayAnswerWordLimitMax || 0,
  };
};
