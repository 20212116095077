export const appConfig = {
  facebookId: process.env.FACEBOOK_ID || '900774237263184',
  landingPageUrl: process.env.LANDING_PAGE_URL || 'https://earnaha.com',
  getCodeInterval: 60,
  resendVerifyEmailInterval: 45,
  trialDays: 5,
  maxQuestionPickCount: 280,
  ahaUserId: '00000000-0000-0000-0000-000000000000',
  ratingScale: 5,
  resendInvitationIntervalDays: 3,
  maxThreadMentionItemsDisplay: 15,
};
