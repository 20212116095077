import { useEffect, useRef, useState } from 'react';
import RichText from '@lib/ia/src/components/RichText';
import { isString } from 'lodash';

import { MessageItemConfig } from '../../../../types';

type MessageItemProps = MessageItemConfig;

export default function TypingEffect({
  text,
  variant,
  typingSpeed = 50,
}: {
  text: MessageItemProps['content'];
  variant: MessageItemProps['contentVariant'];
  typingSpeed?: number;
}) {
  const currentTextRef = useRef('');
  const [displayedText, setDisplayedText] = useState('');

  useEffect(() => {
    if (isString(text) && text) {
      let currentIndex = 0;
      const intervalId = setInterval(() => {
        currentTextRef.current = currentTextRef.current + text[currentIndex];
        setDisplayedText(`${currentTextRef.current} ●`);
        if (++currentIndex === text.length) {
          setDisplayedText((currText) => {
            if (currText.at(-1) === '●') {
              currText = currText.slice(0, -1);
            }
            return currText;
          });
          clearInterval(intervalId);
        }
      }, typingSpeed);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [text, typingSpeed]);

  return <RichText text={displayedText} variant={variant} />;
}
