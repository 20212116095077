export const MCQ_ANSWER_NUM_MIN = 2;
export const MCQ_ANSWER_NUM_MAX = 10;
export const MCQ_ANSWER_NUM_DEFAULT = 4;

export const MRQ_ANSWER_NUM_MIN = 2;
export const MRQ_ANSWER_NUM_MAX = 10;
export const MRQ_ANSWER_NUM_DEFAULT = 4;

export const MRQ_CORRECT_ANSWER_NUM_MIN = 1;
export const MRQ_CORRECT_ANSWER_NUM_MAX = 9;
export const MRQ_CORRECT_ANSWER_NUM_DEFAULT = 1;

export const UNSCRAMBLE_ANSWER_NUM_MIN = 3;
export const UNSCRAMBLE_ANSWER_NUM_MAX = 10;
export const UNSCRAMBLE_ANSWER_NUM_DEFAULT = 5;

export const ESSAY_WORD_LIMIT_MIN_DEFAULT = 0;
export const ESSAY_WORD_LIMIT_MAX_DEFAULT = 1000;

export const FREE_RESPONSE_WORD_LIMIT_MIN_DEFAULT = 0;
export const FREE_RESPONSE_WORD_LIMIT_MAX_DEFAULT = 1000;
