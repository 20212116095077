import type { ReactNode } from 'react';
import React from 'react';
import type { SxProps, Theme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import SimpleBar, { Props } from 'simplebar-react';

import ScrollShadow, { ScrollShadowProps } from './ScrollShadow';

export interface ScrollbarProps extends Props {
  children: ReactNode;
  sx?: SxProps<Theme>;
  showShadow?: boolean;
  shadowPosition?: 'bottom' | 'top' | 'top-bottom';
  shadowColor?: ScrollShadowProps['background'];
  shadowSize?: ScrollShadowProps['size'];
  shadowSx?: ScrollShadowProps['sx'];
}

const ScrollbarRoot = styled(SimpleBar)``;

function Scrollbar({
  children,
  showShadow = false,
  shadowPosition = 'bottom',
  shadowColor,
  shadowSize,
  shadowSx,
  ...rest
}: ScrollbarProps) {
  return (
    <ScrollbarRoot {...rest}>
      {showShadow &&
        (shadowPosition === 'top' || shadowPosition === 'top-bottom') && (
          <ScrollShadow
            position="top"
            sx={shadowSx}
            size={shadowSize}
            background={shadowColor}
          />
        )}
      {children}
      {showShadow &&
        (shadowPosition === 'bottom' || shadowPosition === 'top-bottom') && (
          <ScrollShadow
            position="bottom"
            sx={shadowSx}
            size={shadowSize}
            background={shadowColor}
          />
        )}
    </ScrollbarRoot>
  );
}

export default Scrollbar;
