import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import { Key } from 'swr';
import useSWRImmutable from 'swr/immutable';
import { fetcher } from '@lib/web/apis';

import { Config } from './useRequest';
import useToken from './useToken';

export function useAuthImmutableRequest<Data = unknown, Error = unknown>(
  url?: Key,
  { fallbackData, ...config }: Config<Data, Error> = {}
) {
  const token = useToken();

  return useSWRImmutable<Data, AxiosError<Error>>(
    token ? url : undefined,
    fetcher,
    config
  );
}

export function useImmutableRequest<Data = unknown, Error = unknown>(
  url?: Key,
  { fallbackData, auth, ...config }: Config<Data, Error> = {}
) {
  const token = useToken();
  const sid = useRouter().query.sid as string;

  const urlKey = auth && !sid ? (token ? url : undefined) : url;
  return useSWRImmutable<Data, AxiosError<Error>>(
    urlKey,
    config.withPublicAccessKey
      ? (_url, _config) =>
          fetcher(_url, {
            ..._config,
            _withPublicAccessKey: true,
            _token: token,
            _sid: sid,
          })
      : (_url, _config) =>
          fetcher(_url, {
            ..._config,
            _token: token,
            _sid: sid,
            data: config.data,
            baseURL: config.baseURL,
          }),
    config
  );
}
