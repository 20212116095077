import React, { ReactElement, ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Tooltip, { TooltipProps } from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { OtherInfoSolid as OtherInfoSolidIcon } from '@front/icon';

export type InfoTooltipProps = Omit<
  TooltipProps,
  'children' | 'title' | 'content'
> & {
  content?: ReactNode;
  customContent?: ReactNode;
  listContent?: string[];
  iconSx?: BoxProps['sx'];
  children?: ReactElement;
  titleSx?: BoxProps['sx'];
  title?: ReactNode;
  titleIcon?: ReactNode;
};

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: 1,
    typography: 'caption',
  },
  title: {
    display: 'flex',
    gap: 0.5,
    fontWeight: '500',
    alignItems: 'center',
  },
  popper: {
    '& .MuiTooltip-tooltip': {
      p: 1,
      borderRadius: 1,
      maxWidth: 200,
    },
  },
  popperWhenEmpty: {
    '& .MuiTooltip-tooltip': {
      p: 0,
    },
  },
  info: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: 0.5,
    '&:hover': {
      opacity: 1,
    },
  },
  listContent: {
    ul: {
      padding: 0,
      margin: 0,
      listStyleType: 'number',
      ml: '14px',
    },
  },
};
export default function InfoTooltip({
  children,
  content,
  listContent,
  customContent,
  iconSx,
  titleSx,
  title = '',
  titleIcon,
  sx,
  ...rest
}: InfoTooltipProps) {
  const titleSxProps = Array.isArray(titleSx) ? titleSx : [titleSx];
  const iconSxProps = Array.isArray(iconSx) ? iconSx : [iconSx];
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const isEmpty = !title && !titleIcon && !content && !listContent;

  return (
    <Tooltip
      placement="bottom-start"
      enterDelay={100}
      enterTouchDelay={0}
      title={
        <Box sx={styles.root}>
          {!!(title || titleIcon) && (
            <Box sx={[styles.title, ...titleSxProps]}>
              {titleIcon}

              {!!title && <span>{title}</span>}
            </Box>
          )}
          {!!content && <Box>{content}</Box>}
          {!!listContent && (
            <Typography
              variant="caption"
              display="block"
              sx={styles.listContent}
            >
              <ul>
                {listContent.map((item, index) => (
                  <li key={index}>{item}</li>
                ))}
              </ul>
            </Typography>
          )}
          {customContent}
        </Box>
      }
      slotProps={{
        popper: {
          sx: [isEmpty ? styles.popperWhenEmpty : styles.popper, ...sxProps],
        },
      }}
      {...rest}
    >
      {children || (
        <Box sx={[styles.info, ...iconSxProps]}>
          <OtherInfoSolidIcon />
        </Box>
      )}
    </Tooltip>
  );
}
