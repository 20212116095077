import React, { createContext, ReactNode, useEffect, useReducer } from 'react';
import { useLocalStorage } from '@front/helper';

import config from '../config';

import baseLayoutReducer from './reducer';
import { BaseLayoutActions, BaseLayoutValue } from './type';

const initialValue = {
  leftPanelOpened: false,
  leftPanelTarget: '',
  rightPanelOpened: false,
  rightPanelTarget: '',
  rightPanelRef: { current: null },
  rightPanelParams: {},
  hideLeftPanel: false,
  rightPanelWidth: config.rightPanelMinWidth,
  isResizing: false,
  rightPanelResizable: false,
  rightPanelAutoClose: true,
  rightPanelHistory: { currentIndex: 0, items: [] },
  mainNavOpened: true,
};

type BaseLayoutProviderProps = {
  children: ReactNode;
};

export const BaseLayoutContext = createContext<BaseLayoutValue>(initialValue);

export const BaseLayoutDispatchContext = createContext<
  React.Dispatch<BaseLayoutActions>
>({} as React.Dispatch<BaseLayoutActions>);

export function BaseLayoutProvider({ children }: BaseLayoutProviderProps) {
  const [rightPanelWidth, setRightPanelWidth] = useLocalStorage<number>(
    'base-layout-right-panel-width',
    initialValue.rightPanelWidth
  );

  const [value, dispatch] = useReducer(baseLayoutReducer, {
    ...initialValue,
    rightPanelWidth,
  });

  useEffect(() => {
    // when resize end, store the width
    if (!value.isResizing) {
      setRightPanelWidth(value.rightPanelWidth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps  -- only need to watch isResizing
  }, [value.isResizing]);

  return (
    <BaseLayoutContext.Provider value={value}>
      <BaseLayoutDispatchContext.Provider value={dispatch}>
        {children}
      </BaseLayoutDispatchContext.Provider>
    </BaseLayoutContext.Provider>
  );
}

export const BaseLayoutConsumer = BaseLayoutContext.Consumer;
