import IaAlbumBaseItem from '../components/IaAlbumItem/IaAlbumBaseItem';

import { AlbumLayoutSimpleTooltipConfig } from './types';

type AlbumLayoutSimpleTooltipProps = {
  config: AlbumLayoutSimpleTooltipConfig['config'];
};
export default function AlbumLayoutSimpleTooltip({
  config,
}: AlbumLayoutSimpleTooltipProps) {
  return (
    <IaAlbumBaseItem.Tooltip followCursor>
      {config.content}
    </IaAlbumBaseItem.Tooltip>
  );
}
