import React from 'react';

export default function EditorDifficultyHard({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 4C9.77 7.32834 6.4 9.57721 5 10.3568V12.036C8.01 10.6367 10.92 7.93803 12 6.69865C13.08 7.94803 15.99 10.6367 19 12.036V10.3568C17.6 9.57721 14.23 7.32834 12 4Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 8.53777C9.5 11.7162 6.4 13.2954 5 13.985V15.8041C8.05 14.4048 10.92 12.3659 12 11.2564C13.08 12.3659 15.95 14.4048 19 15.8041V13.985C17.6 13.2954 14.5 11.7162 12 8.53777Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 13.0858C9.32 15.6945 6.4 17.0238 5 17.6335V19.4526C8 18.0933 9 17.6335 12 15.8144C15 17.6335 16 18.0833 19 19.4526V17.6335C17.6 17.0238 14.68 15.7045 12 13.0858Z"
        fill="currentColor"
      />
    </svg>
  );
}
