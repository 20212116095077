import {
  composerHeadingBlockStyle,
  generatedHtmlHeadingBlockStyle,
} from '../components/blocks/HeadingBlock';
import {
  composerLegacyParagraphBlockStyle,
  generatedHtmlLegacyParagraphBlockStyle,
} from '../components/blocks/LegacyParagraphBlock/styles';
import {
  composerParagraphBlockStyle,
  generatedHtmlParagraphBlockStyle,
} from '../components/blocks/ParagraphBlock';
import {
  composerStepBlockStyle,
  generatedHtmlStepBlockStyle,
} from '../components/blocks/StepBlock';
import {
  composerSubtitleBlockStyle,
  generatedHtmlSubtitleBlockStyle,
} from '../components/blocks/SubtitleBlock';

export const basicBlockEditingStyles = [
  composerHeadingBlockStyle,
  composerSubtitleBlockStyle(),
  composerParagraphBlockStyle(),
  composerLegacyParagraphBlockStyle,
  composerStepBlockStyle(),
];

export const basicBlockHtmlStyles = [
  generatedHtmlHeadingBlockStyle,
  generatedHtmlSubtitleBlockStyle(),
  generatedHtmlParagraphBlockStyle(),
  generatedHtmlLegacyParagraphBlockStyle,
  generatedHtmlStepBlockStyle(),
];
