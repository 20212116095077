import { useSearchFilterSort } from '@app/web/src/hooks/utils/useSearchFilterSort';
import combineSearch from '@lib/ia/src/utils/combineSearch';
import combineSort from '@lib/ia/src/utils/combineSort';
import { ClubViewSlug, useIaClubsList } from '@lib/web/apis';

export const useClubGroupClubs = (groupId: string) => {
  const { keyword, sort, filter } = useSearchFilterSort('club', {
    readonly: true,
  });

  return useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    ...combineSearch(filter, [`clubGroup:${groupId}`, `clubGroup:eq`]),
    ...combineSort(sort, {
      orderBy: 'officialType;clubName',
      sortBy: 'desc;asc',
    }),
    keyword,
  });
};
