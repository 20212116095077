export default function BadgeBasicPlan({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M11.5515 2.50485C11.7901 2.2362 12.2099 2.2362 12.4485 2.50485L15.9095 6.40023C15.9671 6.46509 16.0381 6.51669 16.1176 6.55145L20.8918 8.63925C21.2211 8.78324 21.3508 9.1824 21.169 9.49242L18.5338 13.9877C18.4899 14.0626 18.4628 14.146 18.4543 14.2324L17.944 19.4181C17.9088 19.7757 17.5692 20.0224 17.2182 19.9454L12.1286 18.8282C12.0439 18.8096 11.9561 18.8096 11.8714 18.8282L6.78176 19.9454C6.43075 20.0224 6.09121 19.7757 6.05601 19.4181L5.54571 14.2324C5.53722 14.146 5.51009 14.0626 5.46621 13.9877L2.83097 9.49242C2.64922 9.1824 2.77892 8.78324 3.10818 8.63925L7.8824 6.55145C7.96189 6.51669 8.0329 6.46509 8.09053 6.40023L11.5515 2.50485Z"
        fill="white"
      />
      <rect
        x="13.2607"
        y="5.90674"
        width="3.8527"
        height="11.5581"
        rx="1.92635"
        transform="rotate(30 13.2607 5.90674)"
        fill="#080808"
        fillOpacity="0.5"
      />
      <ellipse
        cx="10.1128"
        cy="15.2115"
        rx="1.92635"
        ry="1.92635"
        transform="rotate(30 10.1128 15.2115)"
        fill="#080808"
      />
    </svg>
  );
}
