import { useEffect, useState } from 'react';
import axios from 'axios';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import { Trans, useTranslation } from 'next-i18next';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import useAuthLinks from '@app/web/src/hooks/utils/useAuthLinks';
import useBodyClass from '@app/web/src/hooks/utils/useBodyClass';
import { ActionArrowLeft as ActionArrowLeftIcon } from '@front/icon';
import { EmphasizeButton } from '@front/ui';
import { useAuth } from '@lib/web/apis';

const styles = {
  root: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 5000,
    backdropFilter: 'blur(4px)',
  },
  inner: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'background.default',
    opacity: 0.7,
  },
  container: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    fontWeight: 700,
    lineHeight: 1.6,
    span: {
      display: { xs: 'block', sm: 'inline' },
    },
    b: {
      color: 'primary.main',
    },
  },
  action: {
    mt: 5,
    display: 'grid',
    gap: 3,
    gridTemplateColumns: {
      sm: 'repeat(2, minmax(min-content, 155px))',
    },
    '& > a': {
      maxWidth: 375,
      width: '100%',
      mx: 'auto',
      minWidth: 'auto',
    },
  },
  footer: {
    mt: 4,
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      mx: 1,
    },
  },
  link: {
    '&:hover': {
      color: 'primary.main',
    },
  },
};

function clearCookies() {
  axios.get('/api/auth/clear-cookie').catch(() => {
    // console.error(err);
  });
}

const Login = () => {
  const { t } = useTranslation();

  const [show, setShow] = useState(true);
  const { signUpUrl, loginUrl } = useAuthLinks();
  useEffect(() => {
    setShow(true);

    return () => setShow(false);
  }, []);

  useBodyClass(show, 'login-lock');

  useEffect(() => {
    clearCookies();
  }, []);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.inner} />
      <Container sx={styles.container}>
        <Typography sx={styles.title} variant="h5">
          <Trans
            i18nKey="login.blocker.htmlTitle"
            components={{ 1: <span />, b: <b /> }}
          />
        </Typography>
        <Typography variant="body1" color="alpha.lightA75">
          {t('login.blocker.content', 'Start Practicing for Free')}
        </Typography>

        <Box sx={styles.action}>
          <EmphasizeButton variant="filled" component={Link} href={signUpUrl}>
            {t('login.blocker.cta.signUp', 'Free Sign Up')}
          </EmphasizeButton>
          <EmphasizeButton variant="outlined" component={Link} href={loginUrl}>
            {t('login.blocker.cta.login', 'Log In')}
          </EmphasizeButton>
        </Box>
        <Box sx={styles.footer}>
          <ButtonBase
            component="a"
            disableRipple
            href="https://www.earnaha.com/"
            rel="noopener noreferrer"
          >
            <ActionArrowLeftIcon />
            <Typography sx={styles.link} variant="body2" lineHeight={1}>
              Back to earnaha.com
            </Typography>
          </ButtonBase>
        </Box>
      </Container>
    </Box>
  );
};

const HIDE_PATHNAMES_REGEX =
  /\/paywall|\/shared|\/signup|\/login|\/marketplace/i;

export default function LoginBlocker() {
  const { isLoading, isLoggedOut } = useAuth();
  const pathname = usePathname();

  if (isLoading || HIDE_PATHNAMES_REGEX.test(pathname || '')) return null;
  if (isLoggedOut) return <Login />;

  return null;
}
