import { AlbumLayoutTooltipConfig } from '../types';

import AlbumLayoutListTooltip from './AlbumLayoutListTooltip';
import AlbumLayoutSimpleTooltip from './AlbumLayoutSimpleTooltip';
import {
  AlbumLayoutListTooltipConfig,
  AlbumLayoutSimpleTooltipConfig,
} from './types';

type AlbumLayoutTooltipProps = AlbumLayoutTooltipConfig;

export default function AlbumLayoutTooltip({
  type,
  config,
}: AlbumLayoutTooltipProps) {
  if (type === 'list') {
    const listTooltipConfig = config as AlbumLayoutListTooltipConfig['config'];
    return <AlbumLayoutListTooltip config={listTooltipConfig} />;
  }
  if (type === 'simple') {
    const simpleTooltipConfig =
      config as AlbumLayoutSimpleTooltipConfig['config'];
    return <AlbumLayoutSimpleTooltip config={simpleTooltipConfig} />;
  }
  console.warn(`Tooltip type = ${type} is not supported`);
  return null;
}
