import api from './client';

const agentApi = {
  duplicateAgent: (params: DuplicateAgentReq) =>
    api.post<Response<{ id: string }>>('/v2/agent/duplicate', params),
  updateMyAgent: (params: UpdateMyAgentReq) => api.put('/v2/agent/me', params),
  addMyAgent: (params: AddMyAgentReq) => api.post('/v2/agent/me', params),
  removeMyAgent: (params: RemoveMyAgentReq) =>
    api.delete('/v2/agent/me', { data: params }),
  reorderMyAgent: (params: ReorderMyAgentReq) =>
    api.post('/v2/agent/me/order', params),
  createAgent: (params: CreateAgentReq) =>
    api.post<Response<CreateAgentRes>>('/v2/agent', params),
  updateAgent: (params: UpdateAgentReq) => api.put('/v2/agent', params),
  deleteAgent: (params: DeleteAgentReq) =>
    api.delete('/v2/agent', { data: params }),
  createPrompt: (params: CreateAgentPromptReq) =>
    api.post<Response<{ id: string }>>('/v2/agent/prompt', params),
  savePromptList: (params: SaveAgentPromptListReq) =>
    api.post('/v2/agent/prompt/list', params),
  saveDataSourceList: (params: SaveAgentDataSourceListReq) =>
    api.post('/v2/agent/datasource/list', params),
  updatePrompt: (params: UpdateAgentPromptReq) =>
    api.put('/v2/agent/prompt', params),
  reorderPrompt: (params: ReorderAgentPromptReq) =>
    api.post('/v2/agent/prompt/order', params),
  deletePrompt: (params: DeleteAgentPromptReq) =>
    api.delete('/v2/agent/prompt', { data: params }),
  duplicatePrompt: (params: DuplicateAgentPromptReq) =>
    api.post<Response<{ id: string }[]>>('/v2/agent/prompt/duplicate', params),
  addDataSource: (params: CreateAgentDataSourceReq) =>
    api.post('/v2/agent/datasource', params),
  deleteDataSource: (params: DeleteAgentDataSourceReq) =>
    api.delete('/v2/agent/datasource', { data: params }),
};

export default agentApi;
