import { formatDateString } from '@front/helper';
import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';
import { startOfMonth } from 'date-fns';

import { ViewSlugResponse } from '../../types/base/view';
import { GoalRingViewSlug } from '../../types/enums';

import generateHook, { SwrHelperParams } from './swr.helper';

export const useGoal = (sectionId?: NullableString) =>
  generateHook<GetGoalRes>(
    sectionId ? `v2/goal/section/${sectionId}/me` : null,
    {
      auth: true,
      immutable: true,
      revalidateOnFocus: false,
      errorRetryCount: 0,
    }
  );

export const useGoalDaily = (sectionId?: NullableString) =>
  generateHook<GetGoalDailyRes>(
    sectionId ? `v2/goal/section/${sectionId}/daily/me` : null,
    {
      auth: true,
      immutable: true,
    }
  );

type MyGoalRingParams = WithDate<
  WithNullable<GetMyGoalRingReq, 'sectionId'>,
  'customMonth' | 'customDate' | 'startDate' | 'endDate'
>;

const getMyGoalRingKey = ({
  sectionId,
  customMonth,
  startDate,
  endDate,
  customDate,
  timeZoneOffset,
}: Partial<GetMyGoalRingReq>) => {
  const searchParams = new URLSearchParams();

  if (!sectionId) {
    return null;
  }

  if (customMonth) searchParams.set('customMonth', customMonth);
  if (startDate) searchParams.set('startDate', startDate);
  if (endDate) searchParams.set('endDate', endDate);
  if (customDate) searchParams.set('customDate', customDate);
  if (timeZoneOffset) searchParams.set('timeZoneOffset', timeZoneOffset);

  return `v2/goal/section/${sectionId}/rings/me?${searchParams.toString()}`;
};

export const useMyGoalRing = ({
  sectionId = null,
  customMonth,
  customDate,
  startDate,
  endDate,
  timeZoneOffset,
}: MyGoalRingParams) => {
  const startDateFormatted = startDate
    ? formatDateString(startDate)
    : undefined;
  const endDateFormatted = endDate ? formatDateString(endDate) : undefined;

  const customMonthFormatted = customMonth
    ? formatDateString(startOfMonth(customMonth))
    : undefined;

  const customDateFormatted = customDate
    ? formatDateString(customDate)
    : undefined;

  return generateHook<GetMyGoalRingRes>(
    getMyGoalRingKey({
      sectionId: sectionId || '',
      customMonth: customMonthFormatted,
      startDate: startDateFormatted,
      endDate: endDateFormatted,
      customDate: customDateFormatted,
      timeZoneOffset,
    }),
    {
      auth: true,
      paginate: true,
    }
  );
};

export function useIaGoalRings(
  query: IaSearchReq<GoalRingViewSlug.CalendarCenterGoalRingsDefault>,
  config?: SwrHelperParams
): ViewSlugResponse<GetIaCalendarCenterGoalRingsDefaultViewRes>;
export function useIaGoalRings(
  query: IaSearchReq<GoalRingViewSlug.CalendarRhsGoalRingsDefault>,
  config?: SwrHelperParams
): ViewSlugResponse<GetIaCalendarRhsGoalRingsDefaultViewRes>;
export function useIaGoalRings(
  query: IaSearchReq<GoalRingViewSlug> = {},
  { ...config }: SwrHelperParams = {}
):
  | ViewSlugResponse<GetIaCalendarCenterGoalRingsDefaultViewRes>
  | ViewSlugResponse<GetIaCalendarRhsGoalRingsDefaultViewRes> {
  return generateHook(
    config.enable === false
      ? ''
      : `v2/ia/goal-rings?${getIaQueryString(query)}`,
    {
      paginate: true,
      auth: true,
      errorRetryCount: 0,
      ...config,
    }
  );
}

export const useTargetGoal = () =>
  generateHook<GetTargetGoalRes>('/v2/goal/section/me', {
    auth: true,
    revalidateOnFocus: false,
    errorRetryCount: 0,
  });
