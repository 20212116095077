import {
  BasicComposerSchema,
  basicToolbarMenuItems,
  TextComposer,
} from '@lib/web/composer';
import { TextComposerProps } from '@lib/web/composer/TextComposer/TextComposer';
import { validateThreadElementsBeforeInserting } from '@lib/web/thread/ThreadTextComposer/utils';

import { threadBlockEditingStyles } from './config/threadComposerBlockStyles';
import { threadComposerSchema } from './config/threadComposerSchema';
import { threadSlashMenuItems } from './config/threadSlashMenuItems';
import { threadTiptapExtensions } from './config/threadTiptapExtensions';
import useThreadMentionItems from './hooks/useThreadMentionItems';

const styles = {
  sideMenuPositioner: {
    '.floating-menu-root': {
      mt: '-6px',
    },
  },
};

export type EditorTextComposerProps = TextComposerProps<BasicComposerSchema> & {
  threadMemberIds?: string[];
};

export default function ThreadTextComposer({
  threadMemberIds,
  ...rest
}: EditorTextComposerProps) {
  const { dataset, setQueryText } = useThreadMentionItems(threadMemberIds);
  return (
    <TextComposer
      blockStyles={threadBlockEditingStyles}
      sideMenuPositionerSx={styles.sideMenuPositioner}
      blockSchema={threadComposerSchema}
      slashMenuItems={threadSlashMenuItems}
      toolbarItems={basicToolbarMenuItems}
      customTiptapExtensions={threadTiptapExtensions}
      onMentionQueryChange={setQueryText}
      mentionItems={dataset}
      validateDroppableFile={validateThreadElementsBeforeInserting}
      {...rest}
    />
  );
}
