import { retryWithBackoff, silentCaptureException } from '@front/helper';
import { STREAM_MAX_CHANNELS_PER_REQUEST } from '@lib/web/thread/config/constants';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { isEqual } from 'lodash';
import {
  Channel,
  ChannelFilters,
  ChannelOptions,
  ChannelSort,
  StreamChat,
} from 'stream-chat';

/**
 * Given an object and filters, it will return whether the object will be matched by the filter
 * the object is a key value map
 * the filters is stream sdk supported mongo db like schema, such as
 * { keyA: { $eq: 'value' }, keyB: { $in: ['valueB', 'valueC' ] } }
 * { $or: [{ keyA: { $eq: 'value' }} ]}
 * { $and: [{ keyA: { $in: [1, 2, 3] }}, { $or: [ { keyB: { $eq: 3 }}, { keyC: { $eq: 4}} ] }]}
 *
 * for example, giving
 * isObjectMatch({ club: 'abc' },  { club: { $eq: 'abc' } }) === true
 * isObjectMatch({ club: 'abc' },  { club: { $eq: 'cde' } }) === false
 *
 * also, the channelFilter has another format, the operator can be ignored, such as
 * isObjectMatch({ type: 'team' }, { type: 'team' }) === true
 */
export const isObjectMatch = (
  object: { [k: string]: undefined | string | number | string[] | number[] },
  filters: ChannelFilters
) => {
  for (const key in filters) {
    if (key === '$and') {
      for (const condition of filters[key] || []) {
        if (!isObjectMatch(object, condition)) {
          return false;
        }
      }
      return true;
    } else if (key === '$or') {
      for (const condition of filters[key] || []) {
        if (isObjectMatch(object, condition)) {
          return true;
        }
      }
      return false;
    } else {
      if (typeof filters[key] !== 'object') {
        return object[key] === filters[key];
      }

      // e.g. operation $eq , filters[key] = { $eq: 'value' }, filtersValue = 'value'
      for (const operation in filters[key] || {}) {
        const filtersValue = (filters[key] as any)[operation];

        if (operation === '$eq') {
          if (Array.isArray(object[key]) && Array.isArray(filtersValue)) {
            if (
              !isEqual(
                (object[key] as any[]).sort(),
                (filtersValue as any[]).sort()
              )
            ) {
              return false;
            }
          } else {
            if (object[key] !== filtersValue) {
              return false;
            }
          }
        }

        if (operation === '$in') {
          if (Array.isArray(object[key])) {
            if (
              !(filtersValue as Array<string | number>).some((value) =>
                (object[key] as any).includes(value)
              )
            ) {
              return false;
            }
          } else {
            if (
              !(filtersValue as Array<string | number>).some(
                (value) => object[key] === value
              )
            ) {
              return false;
            }
          }
        }
      }
    }
  }
  return true;
};

export const isChannelInTheSameFilter = (
  channel: Channel,
  filters: ChannelFilters
) => {
  return isObjectMatch(
    {
      type: channel.type,
      members: Object.keys(channel.state.members),
      clubId: channel.data?.clubId as string | undefined,
      location: channel.data?.location as string | undefined,
      questionId: channel.data?.questionId as string | undefined,
    },
    filters
  );
};

export const isChannelMuted = (channel: Channel) => {
  /**
   * XXX: sometimes chat client will disconnect, make this status not reachable,
   * so we add a try catch here to make it safer
   */
  try {
    return channel.muteStatus().muted;
  } catch (e) {
    return false;
  }
};

/**
 * in child channel, we will make non-active member default mute, when this user start to chat as an active member,
 * we'll make it unmute
 *
 * in the future, if we're going to implement 'mute' function for user,
 * we need some adjustment for this logic, maybe when user 'mute' the channel, we should add a flag such as
 * channel.mutedByUser = true
 */
export const maybeUnmuteSelfWhenSentMessageToChildChannel = async (
  channel: Channel
) => {
  if (!channel.initialized) {
    await channel.watch();
  }

  if (isChannelMuted(channel)) {
    await channel.unmute();
    await channel.markRead(); // because user is already in chat room to send message, so make it as read
  }
};

export const maybeUnmuteInvitedMemberInChildChannel = async (
  channel: Channel,
  memberId: string
) => {
  /**
   * this is called from server side,
   * there is no api to return mutedStatus for each person, and it's not worth to query again to check muteStatus,
   * so we always make it unmute
   */
  await channel.unmute({ user_id: memberId });
  await channel.markRead({ user_id: memberId });
};

export const safeGetChannel = (
  chatClient: StreamChat | undefined | null,
  channelCid: string
) => {
  if (!chatClient) return;
  const [channelType, channelId] = channelCid.split(':');
  if (!channelType || !channelId) return;

  try {
    return chatClient.channel(channelType, channelId);
  } catch (e) {
    silentCaptureException('failed to call client.channel', e);
    return;
  }
};

export const safeQueryChannels = async (
  chatClient: StreamChat | undefined | null,
  filters: ChannelFilters,
  sort: ChannelSort = [],
  options: ChannelOptions = {},
  getIsOutdated = () => false
) => {
  if (!chatClient || getIsOutdated()) return [];

  try {
    return await retryWithBackoff(
      () => chatClient.queryChannels(filters, sort, options),
      {
        maxRetries: 5,
        baseDelayMs: 5000,
        maxDelayMs: 120000,
        isRetryableError: (error) =>
          /**
           * 429 means 'Too Many Requests'. The Stream SDK has a strict rate limit on query channels (60 per minute).
           */
          !getIsOutdated() && (error as any)?.response?.status === 429,
      }
    );
  } catch (e) {
    if (!getIsOutdated()) {
      silentCaptureException('failed to call queryChannels', e);
    }
    return [];
  }
};

const MAXIMUM_QUERY_PAGES = 5;
export const queryAllChannels = async (
  chatClient: StreamChat,
  filters: ChannelFilters,
  sort?: ChannelSort
) => {
  const allChannels: Channel<StreamChatGenerics>[] = [];

  for (let i = 0; i < MAXIMUM_QUERY_PAGES; i++) {
    const channels = await safeQueryChannels(chatClient, filters, sort, {
      limit: STREAM_MAX_CHANNELS_PER_REQUEST,
      offset: STREAM_MAX_CHANNELS_PER_REQUEST * i,
    });
    allChannels.push(...channels);

    /**
     * stream sdk doesn't give us total number of channels, so we use this trick to know
     */
    if (channels.length < STREAM_MAX_CHANNELS_PER_REQUEST) break;
  }

  return allChannels;
};

export const isChannelAncestorInTheSameFilter = (
  chatClient: StreamChat | undefined | null,
  channel: Channel<StreamChatGenerics>,
  filters: ChannelFilters
) => {
  return channel.data?.ancestorChannelCids?.some((cid) => {
    const ancestorChannel = safeGetChannel(chatClient, cid);
    return (
      ancestorChannel && isChannelInTheSameFilter(ancestorChannel, filters)
    );
  });
};

/**
 * Assume we have multiple agent members inside a thread (channel)
 * this is how we decide which one should be responsible to give the response
 */
export const getAssignedAgentId = (memberIds: string[]) => {
  const lastAgentMemberId = memberIds
    .filter((id: string) => id.startsWith('agent_'))
    .pop();

  return lastAgentMemberId ? lastAgentMemberId.replace('agent_', '') : null;
};
