import { ActionType, dispatch } from './store';
import { ConfirmPopupSettings } from './types';

const popup = {
  open: (settings: ConfirmPopupSettings) => {
    dispatch({
      type: ActionType.OPEN,
      settings,
    });
  },
  cancel: () => {
    dispatch({
      type: ActionType.CANCEL,
    });
  },
  confirm: () => {
    dispatch({
      type: ActionType.CONFIRM,
    });
  },
  promise: (settings: ConfirmPopupSettings) =>
    new Promise<string | null>((resolve) => {
      const handleConfirm = () => {
        settings.onConfirm?.();
        resolve('OK');
      };
      const handleCancel = (cancelType: 'button' | 'close') => {
        settings.onCancel?.(cancelType);
        resolve(null);
      };

      dispatch({
        type: ActionType.OPEN,
        settings: {
          ...settings,
          onCancel: handleCancel,
          onConfirm: handleConfirm,
        },
      });
    }),
};

export { popup };
