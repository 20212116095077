import { useCallback } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import { composerHtmlToPlainText } from '@lib/web/thread/ThreadTextComposer';

import { AgentMaterial } from '../../types';

/**
 * 20000 characters is configured on stream dashboard, which is the maximum number allowed for a message
 * we use 18000 for a little buffer
 */
const MAX_MESSAGE_LENGTH = 18000;

/**
 * stream sdk only allowed maximum 5kb for a channel's custom data
 * we use 3000 for a little buffer
 */
const MAX_CHANNEL_DATA_LENGTH = 3000;

export const useBuildMessageData = () => {
  const buildMessageData = useCallback(
    ({
      text,
      blocks,
      agentMaterials,
    }: {
      text: string;
      blocks: ComposerBlock[];
      agentMaterials?: AgentMaterial[];
    }) => {
      /**
       * Transforming from HTML to text helps us save tokens when querying GPT.
       * It's said that GPT reads text just as well as HTML,
       * but if we find it more reasonable to use HTML, we can change it back.
       */
      const plainText = composerHtmlToPlainText(text);
      const roughlyCharacterCount =
        plainText.length + JSON.stringify(blocks).length;

      return {
        /**
         * if the message is too long, we don't send the text
         * we will use customBlocks to render the message (see useMessageInformation)
         */
        text: roughlyCharacterCount > MAX_MESSAGE_LENGTH ? '' : plainText,
        customBlocks: blocks,
        agentMaterials,
      };
    },
    []
  );

  const buildChannelFirstMessageData = useCallback(
    ({
      text,
      blocks,
      agentMaterials,
    }: {
      text: string;
      blocks: ComposerBlock[];
      agentMaterials?: AgentMaterial[];
    }) => {
      /**
       * Transforming from HTML to text helps us save tokens when querying GPT.
       * It's said that GPT reads text just as well as HTML,
       * but if we find it more reasonable to use HTML, we can change it back.
       */
      const plainText = composerHtmlToPlainText(text);
      const roughlyCharacterCount =
        plainText.length + JSON.stringify(blocks).length;

      /**
       * if the message is too long, we don't send the text
       * we will use firstCustomBlocks to render the message (see useChannelInformation)
       */
      return {
        firstMessageText:
          roughlyCharacterCount > MAX_CHANNEL_DATA_LENGTH ? '' : plainText,
        firstMessageCustomBlocks: blocks,
        firstAgentMaterials: agentMaterials,
      };
    },
    []
  );

  return {
    buildMessageData,
    buildChannelFirstMessageData,
  };
};
