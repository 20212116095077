import { service } from '../constants';

import api from './client';

const notificationApi = {
  read: () => api.post('notification/v1/me/read', { service }),
  readTopic: (params: ReadTopicNotificationReq) =>
    api.post('notification/v1/channel/topic/read/me', params),
  unsubscribe: (params: UnsubscribeTopicReq) =>
    api.post('notification/v1/channel/topic/unsubscribe', params),
  getThreadMessagePayload: (params: GetThreadMessagePayloadReq) =>
    api.post<Response<GetThreadMessageNotificationPayloadRes[]>>(
      'notification/v1/thread-message/payload',
      params
    ),
};

export default notificationApi;
