import React, { ReactNode, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import Typography from '@mui/material/Typography';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
} from '@front/icon';

const styles = {
  header: {
    py: 0.5,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '& svg': {
      opacity: 0.64,
    },
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 0.5,
    opacity: 0.5,
  },
};
export type AccordionProps = {
  title: string;
  titleIcon?: ReactNode;
  children?: ReactNode;
  sx?: BoxProps['sx'];
};

export default function Accordion({
  titleIcon,
  title,
  children,
  sx,
}: AccordionProps) {
  const [open, setOpen] = useState(true);

  return (
    <Box sx={sx}>
      <Box sx={styles.header} onClick={() => setOpen((st) => !st)}>
        <Typography sx={styles.title} variant="caption">
          {titleIcon}
          {title}
        </Typography>

        {open ? (
          <ActionChevronUpIcon width={16} height={16} />
        ) : (
          <ActionChevronDownIcon width={16} height={16} />
        )}
      </Box>
      <Collapse in={open}>{children}</Collapse>
    </Box>
  );
}
