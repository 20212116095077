import { sendGTMEvent } from '@next/third-parties/google';
import { kebabCase } from 'lodash';

type TrackEventName = 'click' | 'form';

type TrackEventParams = {
  elementName:
    | 'upgrade-button'
    | 'nav-subscription-button'
    | 'plan-card'
    | 'plan-table-cta-button'
    | 'checkout-button'
    | 'club-join-button'
    | 'club-accept-invite-button'
    | 'pay-to-skip-button'
    | 'question-slider'
    | 'hashtag'
    | 'start-quiz-button'
    | 'copy-link-button'
    | 'share-to-direct-button';
  fromLocation?: string;
  action?: string;
  target?: string;
};

export const trackEvent = (
  event: TrackEventName,
  params?: TrackEventParams
) => {
  const eventName = [
    event,
    params?.elementName,
    params?.fromLocation,
    params?.action,
  ]
    .filter(Boolean)
    .join('_');

  sendGTMEvent({ event: eventName, ...params });

  // because we only need this params in data layer for this event, so after event fired, reset it, otherwise future events will be polluted
  sendGTMEvent(function (this: any) {
    this.reset();
  });
};

/**
 * this is another way to track event, we add data-attributes into the link, and use gtm variable to bring those data.
 * if possible, we prefer using 'trackEvent', because that one has less dependency with gtm
 * @param params
 */
export const getTrackElementAttributes = (params: TrackEventParams) => {
  const eventName = [
    'click',
    params?.elementName,
    params?.fromLocation,
    params?.action,
  ]
    .filter(Boolean)
    .join('_');

  return Object.entries(params || {}).reduce(
    (acc, [key, value]) => {
      return {
        ...acc,
        [`data-track-${kebabCase(key)}`]: value,
      };
    },
    { 'data-track-event': eventName }
  );
};
