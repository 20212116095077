import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { DateTextField } from '@front/ui';

import useInputEvent from '../hooks/useInputEvent';
import useSuccessState from '../hooks/useSuccessState';
import { FormLayoutDateItem, FormLayoutItemStatus } from '../types';
import { getCustomValidate, getLabelIcon } from '../utils';

import ErrorIcon from './ErrorIcon';

type DateInputProps = {
  item: FormLayoutDateItem;
};

export default function DateInput({ item }: DateInputProps) {
  const { control, getValues } = useFormContext();

  const labelIcon = getLabelIcon(item.type, item.icon);

  const validate = item.customRules
    ? getCustomValidate(item.customRules, { getValues })
    : undefined;

  const {
    field: { ref, onChange, onBlur, value },
    fieldState: { isDirty, error },
  } = useController({
    control,
    name: item.name,
    rules: { ...item.rules, validate },
  });

  const successState = useSuccessState(item.name, isDirty);

  const { handleInputBlur, handleInputChange } = useInputEvent({
    name: item.name,
    actionMap: item.actionMap,
    onChange,
    onBlur,
  });

  const handleBlur = () => {
    handleInputBlur();
    void successState.handleBlur();
  };

  const handleChange = (newValue: any) => {
    void handleInputChange(newValue);
    void successState.handleChange();
  };

  return (
    <DateTextField
      className={`ia-form-layout_date ${item.className || ''}`}
      error={!!error}
      success={successState.success}
      label={item.label}
      labelIcon={labelIcon}
      errorIcon={
        item.errorIcon ? <ErrorIcon icon={item.errorIcon} /> : undefined
      }
      requiredMark={!!item.rules?.required}
      placeholder={item.placeholder}
      maxDate={item.maxDate}
      minDate={item.minDate}
      format={item.dateFormat}
      helperText={error?.message}
      size={item.textFieldSize}
      disabled={item.status === FormLayoutItemStatus.Disabled || item.disabled}
      value={value ?? item.defaultValue}
      inputRef={ref}
      onChange={handleChange}
      onBlur={handleBlur}
    />
  );
}
