export const enum ChallengeStatus {
  Ongoing = 2,
  Expired = -1,
  Invited = 1,
  NotInvited = -2,
}

export const enum ChallengeMemberStatus {
  Requested = 1,
  Accepted = 2,
  Practiced = 3,
  Completed = 4,
}

export const enum ChallengeCategoryStatus {
  Invited = 1,
  Ongoing = 2,
  Expired = -1,
  OpenChallenge = 3,
  Accepted = 5,
}

export enum ChallengeFilter {
  NoFilter = 0,
  Completed = 1,
  NotCompleted = -1,
}
