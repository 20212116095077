// eslint-disable-next-line import/prefer-default-export
/**
 * @deprecated using AuthMemberShipTier instead
 */
export const enum MembershipTier {
  Basic = 'BASIC',
  Restricted = 'RESTRICTED',
  Pro = 'PRO',
  ProAnnually = 'PRO_ANNUALLY',
}

export const enum AuthMemberShipTier {
  PlanTrial = 'PLAN_TRIAL',
  PlanFree = 'PLAN_FREE',
  PlanBasic = 'PLAN_BASIC',
  PlanPro = 'PLAN_PRO',
  PlanUnlimited = 'PLAN_UNLIMITED',
}
