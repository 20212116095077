import { alpha, Theme } from '@mui/material/styles';

const content = (type: string) => `.block-content[data-content-type="${type}"]`;
const blockContainer = (type: string) =>
  `[data-node-type="blockContainer"]:has(${content(type)})`;

export const composerStepBlockStyle = (
  typography: 'body1' | 'chatBody' = 'body1'
) => ({
  // placeholder
  [`${content('step')}[class*=isEmpty][class*=hasAnchor] p:before`]: {
    content: 'attr(data-placeholder)',
    typography,
    fontStyle: 'normal',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',

    whiteSpace: 'nowrap',
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    textOverflow: 'ellipsis',

    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },

  [`${blockContainer('step')}`]: {
    py: '10px',
  },

  [`${content('step')}`]: {
    '--index': 'attr(data-index)',
    display: 'flex',
    gap: 1,

    [`& p`]: {
      color: (theme: Theme) => theme.palette.text.primary,
      typography,
      margin: 0,
      position: 'relative',
      width: '100%',

      pt: {
        body1: 0,
        chatBody: 0.5,
      }[typography],
    },

    [`&:before`]: {
      content: 'var(--index)',
      typography,
      fontWeight: 700,
      color: (theme: Theme) => theme.palette.background.darker,
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: (theme: Theme) => theme.palette.text.primary,
      mask: 'url(/mask-avatar.svg) center center/contain no-repeat',
    },
  },
});

const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlStepBlockStyle = ({
  typography = 'body1',
  oneLineTypography = 'body2',
}: {
  typography?: 'body1' | 'chatBody';
  oneLineTypography?: string;
} = {}) => ({
  [`${htmlContent('step')}`]: {
    '--index': 'attr(data-index)',
    display: 'flex',
    gap: 1,
    py: '10px',

    [`& p`]: {
      color: (theme: Theme) => theme.palette.text.primary,
      typography,
      margin: 0,
      position: 'relative',
      width: '100%',

      pt: {
        body1: 0,
        chatBody: 0.5,
      }[typography],

      '.render-one-line&': {
        typography: oneLineTypography,
      },
    },

    [`&:before`]: {
      content: 'var(--index)',
      typography,
      fontWeight: 700,
      color: (theme: Theme) => theme.palette.background.darker,
      width: '24px',
      height: '24px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: (theme: Theme) => theme.palette.text.primary,
      mask: 'url(/mask-avatar.svg) center center/contain no-repeat',

      '.render-one-line&': {
        typography: oneLineTypography,
      },
    },
  },
});
