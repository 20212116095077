import { MouseEvent, ReactNode, useState } from 'react';
import Link from 'next/link';
import {
  Box,
  Card,
  CardActionArea,
  Skeleton,
  Theme,
  Typography,
  useMediaQuery,
} from '@mui/material';
import { ReverseTheme, SimpleTooltip, SimpleTooltipProps } from '@front/ui';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';

import Icon from '../../../../components/Icon';
import AlbumLayoutExtension from '../../AlbumLayoutExtension/AlbumLayoutExtension';
import { AlbumLayoutExtensionConfig } from '../../AlbumLayoutExtension/types';
import AlbumLayoutTooltip from '../../AlbumLayoutTooltip';
import {
  AlbumLayoutBaseItem,
  AlbumLayoutItem,
  AlbumLayoutTooltipConfig,
  IaAlbumContextMenuEvent,
} from '../../types';

const styles = {
  root: {
    width: '100%',
    display: 'grid',
    gap: 1,
    position: 'relative',
  },
  rootDisabled: {
    opacity: 0.5,
  },
  indicatorIcon: {
    position: 'absolute',
    width: '16px',
    height: '16px',
    top: '-4px',
    right: '-4px',
    zIndex: 10,
  },
  card: {
    borderRadius: '10px',
  },
  cardHover: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        outline: '3px solid',
        outlineOffset: '-3px',
      },
    },
  },
  cardActive: {
    '&:active': {
      bgcolor: 'text.primary',
      color: 'background.darker',
    },
  },
  cardAction: {
    width: '100%',
    aspectRatio: '1 / 1',
    borderRadius: '10px',
    p: 1,
  },
  cardArt: (imageUrl: string) => ({
    background: `url(${imageUrl}) center center`,
    backgroundSize: 'cover',
  }),
  infoWrapper: {
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
    width: '100%',
    overflow: 'hidden',
  },
  title: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  properties: {
    display: 'flex',
    gap: 1,
  },
  property: {
    display: 'flex',
    alignItems: 'center',
    gap: '2px',
  },
  revertBgcolor: {
    position: 'relative',
    '&:before': {
      content: '""',
      position: 'absolute',
      top: 3,
      left: 3,
      right: 3,
      bottom: 3,
      borderRadius: '7px',
      bgcolor: 'background.darker',
    },
  },
};

const bgcolorMap: Record<string, (theme: Theme) => string> = {
  success: (theme: Theme) => theme.palette.gradient.success,
  error: (theme: Theme) => theme.palette.gradient.error,
  warning: (theme: Theme) => theme.palette.gradient.warning,
};

type TooltipProps = Omit<SimpleTooltipProps, 'title' | 'children'> & {
  children: ReactNode;
};

function Tooltip({ children, ...rest }: TooltipProps) {
  const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));

  if (mdDown) return null;

  if (!children) return null;

  return (
    <SimpleTooltip title={children} {...rest}>
      <Box
        sx={{ userSelect: 'none' }}
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        zIndex={1}
      />
    </SimpleTooltip>
  );
}

type AdditionalChildrenProps = {
  tooltip?: AlbumLayoutTooltipConfig;
  extensions?: AlbumLayoutExtensionConfig[];
  active?: boolean;
};
function AdditionalChildren({
  tooltip,
  extensions,
  active,
}: AdditionalChildrenProps) {
  const children = (
    <>
      {tooltip && <AlbumLayoutTooltip {...tooltip} />}
      {extensions &&
        extensions.map((extension, index) => (
          <AlbumLayoutExtension key={index} {...extension} />
        ))}
    </>
  );

  if (active) {
    return <ReverseTheme>{children}</ReverseTheme>;
  }

  return children;
}

type IaAlbumBaseItemProps = AlbumLayoutBaseItem & {
  loading?: boolean;
  children?: ReactNode;
  item?: AlbumLayoutItem;
};

export default function IaAlbumBaseItem({
  title,
  properties,
  bgcolor,
  revertBgcolor = false,
  bgImage,
  disabled = false,
  indicatorIcon,
  loading = false,
  action,
  item,
  tooltip,
  extensions = [],
  children,
}: IaAlbumBaseItemProps) {
  const [clicked, setClicked] = useState(false);
  const { getIaAction } = useIaAction();
  const onContextMenu =
    getIaAction<IaAlbumContextMenuEvent<AlbumLayoutItem>>('onContextMenu');

  const background =
    bgcolor && bgcolor in bgcolorMap ? bgcolorMap[bgcolor] : bgcolor;

  const isDisabled = disabled || loading;

  const linkProps =
    action?.type === 'link' ? { LinkComponent: Link, href: action.value } : {};

  const handleContextMenu = (e: MouseEvent) => {
    e.preventDefault();
    onContextMenu?.action({
      position: {
        x: e.clientX,
        y: e.clientY,
      },
      payload: item,
    });
  };

  return (
    <Box sx={[styles.root, isDisabled && styles.rootDisabled]}>
      {indicatorIcon && (
        <Box sx={styles.indicatorIcon}>
          <Icon name={indicatorIcon} width={16} height={16} />
        </Box>
      )}
      <Card
        sx={[
          styles.card,
          !isDisabled && styles.cardHover,
          { background },
          !!bgImage && styles.cardArt(bgImage),
          revertBgcolor && styles.revertBgcolor,
        ]}
      >
        <CardActionArea
          sx={[styles.cardAction, !isDisabled && styles.cardActive]}
          disabled={isDisabled}
          onContextMenu={handleContextMenu}
          disableRipple
          onMouseDown={() => setClicked(true)}
          onMouseUp={() => setClicked(false)}
          {...linkProps}
        >
          {children}
          <AdditionalChildren
            tooltip={tooltip}
            extensions={extensions}
            active={clicked}
          />
        </CardActionArea>
      </Card>
      {!loading && (
        <Box sx={styles.infoWrapper}>
          <Typography sx={styles.title} variant="body1">
            {title}
          </Typography>
          <Box sx={styles.properties}>
            {properties?.map((p, index) => (
              <Typography
                key={index}
                sx={styles.property}
                variant="caption"
                color="alpha.lightA75"
              >
                <Icon name={p.icon} width={12} height={12} />
                {p.content}
              </Typography>
            ))}
          </Box>
        </Box>
      )}
      {loading && (
        <Box sx={styles.infoWrapper}>
          <Skeleton width="100%" height={24} />
          <Skeleton width="100%" height={16} />
        </Box>
      )}
    </Box>
  );
}

IaAlbumBaseItem.Tooltip = Tooltip;
