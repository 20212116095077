import { useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';

import { TableLayoutRow, TableLayoutTextCell } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaTextCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutTextCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    display: 'grid',
    alignItems: 'center',
    height: '32px',
    overflow: 'hidden',
    position: 'relative',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',

    br: {
      // make html element render on one line
      content: '" "',
      display: 'none',
    },

    '& [data-content-type="paragraph"] > p': {
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
};
export default function IaTextCell({ row, columnKey, cell }: IaTextCellProps) {
  const { text, html, sx, editable, actionMap, placeholder, emptyText } = cell;
  if (html && text) {
    console.warn('Both text and html are provided, only html will be used');
  }
  const ref = useRef<HTMLDivElement>();

  const sxProps = Array.isArray(sx) ? sx : [sx];

  return (
    <>
      <Box sx={[styles.root, ...sxProps]} ref={ref}>
        {html ? (
          <Typography
            variant="body2"
            sx={styles.text}
            dangerouslySetInnerHTML={{ __html: html }}
          />
        ) : (
          <Typography
            variant="body2"
            sx={[styles.text, !text && { color: 'alpha.lightA50' }]}
          >
            {text || emptyText}
          </Typography>
        )}
      </Box>
      <IaTextBasedEditableCell
        containerRef={ref}
        actionMap={actionMap}
        editable={editable}
        placeholder={placeholder}
        columnKey={columnKey}
        row={row}
        initValue={text}
        filterValue={(val: string) => val}
        inputSx={{
          '& .MuiInputBase-input': {
            textAlign: sxProps[0]?.textAlign,
          },
        }}
      />
    </>
  );
}
