import React from 'react';

export default function TestTestInfinity({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.37424 8.46444C5.20656 6.51185 8.17721 6.51185 10.0092 8.46444L12 10.5857L13.9904 8.46444C15.8226 6.51203 18.7934 6.51203 20.6258 8.46444C22.4581 10.4172 22.4581 13.5832 20.6258 15.5354C18.7936 17.4882 15.8228 17.4882 13.9904 15.5354L12 13.4142L10.0092 15.5354C8.17712 17.4882 5.2066 17.4882 3.37424 15.5354C1.54192 13.583 1.54192 10.4171 3.37424 8.46444ZM15.3176 9.87873L13.327 12L15.3176 14.1215C16.4151 15.2913 18.2012 15.2913 19.2985 14.1215C20.3962 12.9518 20.3962 11.0485 19.2985 9.87873C18.2012 8.70931 16.4151 8.70895 15.3176 9.87873ZM4.70114 9.87873C3.60344 11.0483 3.60344 12.9516 4.70114 14.1212C5.7985 15.2913 7.58473 15.2913 8.68208 14.1212L10.6728 11.9999L8.68208 9.87868C7.58473 8.70891 5.7985 8.70895 4.70114 9.87873Z"
        fill="currentColor"
      />
    </svg>
  );
}
