import { MouseEvent, useEffect, useMemo, useRef } from 'react';
import Box from '@mui/material/Box';
import { ChatPin as ChatPinIcon } from '@front/icon';
import { useBaseRightPanel } from '@front/ui';
import BlockTag from '@lib/web/composer/BlockTag';
import ThemeProvider from '@lib/web/composer/components/ThemeProvider';
import { EditorBlockTypes } from '@lib/web/editor/configs';
import { useEditorSelector } from '@lib/web/editor/redux';
import {
  TextComposerPanelKeys,
  TextComposerPanelParams,
} from '@lib/web/editor/TextComposerPanels/panel';
import { useLineNumberDisplay, usePassageEl } from '@lib/web/ui';
import { mergeAttributes, Node } from '@tiptap/core';
import { Node as ProseMirrorNode } from '@tiptap/pm/model';
import { NodeViewWrapper, ReactNodeViewRenderer } from '@tiptap/react';
import { v4 } from 'uuid';

export type InlineLineMarkerOptions = {
  HTMLAttributes: Record<string, any>;
  renderLabel: (props: {
    options: InlineLineMarkerOptions;
    node: ProseMirrorNode;
  }) => string;
};

type LineMarkerProps = {
  node: ProseMirrorNode;
  updateAttributes: (attributes: Record<string, any>) => void;
};

type LineMarkerStatusTagProps = {
  id: string;
  targetId: string;
  text?: string;
  lineText?: string;
  isNewCreated?: boolean;
  updateAttributes: (attributes: Record<string, any>) => void;
};
const LineMarkerStatusTag = ({
  id,
  targetId,
  text,
  lineText,
  isNewCreated,
  updateAttributes,
}: LineMarkerStatusTagProps) => {
  const {
    rightPanelTarget,
    rightPanelOpened,
    getRightParams,
    setRightParams,
    openRightPanel,
  } = useBaseRightPanel<TextComposerPanelParams>();

  const rightParams = getRightParams(
    TextComposerPanelKeys.TextComposerLineMarker
  );

  const active = rightParams.activeOptionId === id;
  const selectAnchorId = active ? rightParams.selectAnchorId : null;

  const handleOpenMenu = (ev?: MouseEvent) => {
    const clickInPanel =
      'keepMounted' in rightParams &&
      rightParams.keepMounted &&
      !!ev?.currentTarget.closest('.base-right-panel-container');

    openRightPanel(TextComposerPanelKeys.TextComposerLineMarker, {
      activeOptionId: id,
      selectAnchorId: targetId,
      returnPanel: clickInPanel ? rightPanelTarget : undefined,
      returnParams: clickInPanel ? rightParams : undefined,
    });
  };

  useEffect(() => {
    if (selectAnchorId && active) {
      setTimeout(() => {
        updateAttributes({
          targetId: selectAnchorId,
        });
      });
    }
  }, [active, selectAnchorId, targetId, updateAttributes]);

  useEffect(() => {
    if (isNewCreated) {
      handleOpenMenu();
      setTimeout(() => {
        updateAttributes({
          isNewCreated: false,
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNewCreated]);

  const onMouseEnter = () => {
    setRightParams((st) => ({ ...st, hoverAnchorId: targetId }));
  };

  const onMouseLeave = () => {
    setRightParams((st) => ({ ...st, hoverAnchorId: null }));
  };

  if (text) {
    return (
      <>
        Line&nbsp;
        <BlockTag
          active={rightPanelOpened && active}
          icon={<ChatPinIcon />}
          onClick={handleOpenMenu}
          onMouseEnter={onMouseEnter}
          onMouseLeave={onMouseLeave}
        >
          {text}
        </BlockTag>
        &nbsp;(“{lineText}”)
      </>
    );
  }

  return (
    <BlockTag
      active={active}
      error={!!targetId}
      icon={<ChatPinIcon />}
      onClick={handleOpenMenu}
    >
      Line Marker
    </BlockTag>
  );
};

const LineMarker = ({ node, updateAttributes }: LineMarkerProps) => {
  const anchorRef = useRef<HTMLElement | null>(null);
  const dropdownOptions = useEditorSelector(
    (st) => st.setting.lineMarker.options
  );
  const getLineNumberDisplay = useLineNumberDisplay();
  const { passageEl } = usePassageEl();

  const display = useMemo(() => {
    if (node.attrs.targetId && dropdownOptions.length) {
      const [lineNumber, lineText] = getLineNumberDisplay(node.attrs.targetId, {
        parentElement: passageEl,
      });

      if (typeof lineText === 'string') {
        const words = lineText.split(' ');
        if (words.length < 3) {
          return [lineNumber, lineText];
        }
        const firstWord = words[0];
        const lastWord = words[words.length - 1];

        return [lineNumber, `${firstWord}...${lastWord}`];
      }
      return [lineNumber, lineText];
    }

    return [];
  }, [node.attrs.targetId, dropdownOptions, passageEl, getLineNumberDisplay]);

  return (
    <NodeViewWrapper
      style={{ display: 'inline-block', userSelect: 'none' }}
      className="inline-line-marker"
      contentEditable={false}
    >
      <ThemeProvider mode="dark">
        <Box ref={anchorRef}>
          <LineMarkerStatusTag
            id={node.attrs.id}
            targetId={node.attrs.targetId}
            isNewCreated={node.attrs.isNewCreated}
            text={display[0] as string}
            lineText={display[1] as string}
            updateAttributes={updateAttributes}
          />
        </Box>
      </ThemeProvider>
    </NodeViewWrapper>
  );
};

export const InlineLineMarker = Node.create<InlineLineMarkerOptions>({
  name: EditorBlockTypes.InlineLineMarker,
  group: 'inline',
  inline: true,
  selectable: false,
  atom: true,

  addAttributes() {
    return {
      id: {
        default: v4,
        parseHTML: () => v4(), // when copy and paste, generate a new id
      },
      targetId: {
        default: '',
      },
      isNewCreated: {
        default: false,
      },
    };
  },

  parseHTML() {
    return [
      {
        tag: this.name,
      },
    ];
  },

  renderHTML({ HTMLAttributes }) {
    return [
      'span',
      mergeAttributes({
        class: this.name,
        'data-render-target-id': HTMLAttributes.targetId,
        'data-content-type': this.name,
      }),
    ];
  },

  addNodeView() {
    return ReactNodeViewRenderer(LineMarker);
  },
});
