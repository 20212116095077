import { forwardRef, RefObject } from 'react';

import BaseLayoutContainer, {
  BaseLayoutContainerProps,
} from './BaseLayoutContainer';
import BaseLayoutScrollArea from './BaseLayoutScrollArea';

export type BaseLayoutScrollContainerProps = BaseLayoutContainerProps & {
  scrollRef?: RefObject<HTMLDivElement>;
};

const BaseLayoutScrollContainer = forwardRef(
  ({ scrollRef, ...rest }: BaseLayoutScrollContainerProps, ref) => (
    <BaseLayoutScrollArea scrollRef={scrollRef}>
      <BaseLayoutContainer ref={ref} {...rest} />
    </BaseLayoutScrollArea>
  )
);

export default BaseLayoutScrollContainer;
