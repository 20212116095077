import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const initialState: {
  visible: boolean;
  keyword?: string;
} = {
  visible: false,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setKeyword: (state, { payload }: PayloadAction<string | undefined>) => {
      state.keyword = payload;
    },
    setVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.visible = payload;
    },
  },
});

export const { setKeyword, setVisible } = searchSlice.actions;
export default searchSlice.reducer;
