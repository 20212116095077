import {
  BaseLayoutActions,
  BaseLayoutActionTypes,
  BaseLayoutRightPanelParams,
  BaseLayoutValue,
  RightPanelHistoryState,
} from './type';

const getOpenRightPanelProperties = (
  target = '',
  params: BaseLayoutRightPanelParams = {}
) => ({
  rightPanelOpened: true,
  rightPanelTarget: target,
  rightPanelParams: params,
});

const getOpenLeftPanelProperties = (target = '') => ({
  leftPanelOpened: true,
  leftPanelTarget: target,
});

const EMPTY_HISTORY = {
  currentIndex: 0,
  items: [],
};

function pushRightPanelHistory(
  state: BaseLayoutValue,
  panelKey: string,
  params: unknown
) {
  const history = { ...state.rightPanelHistory };

  const items = history.items || [];
  if (items[items.length - 1]) {
    // update the latest history item with the latest params
    items[items.length - 1].params = {
      ...state.rightPanelParams,
    };
  }
  const newItem: RightPanelHistoryState = {
    panelKey,
    params,
  };

  history.items = [...items.slice(0, history.currentIndex + 1), newItem];
  history.currentIndex = history.items.length - 1;

  return history;
}

function updateRightPanelHistory(state: BaseLayoutValue, params: unknown) {
  const history = { ...state.rightPanelHistory };

  history.items = history.items.map((item, index) =>
    index === history.currentIndex
      ? {
          ...item,
          params,
        }
      : item
  );

  return history;
}

function rightPanelHistoryBack(state: BaseLayoutValue): BaseLayoutValue {
  const history = { ...state.rightPanelHistory };
  const items = history.items || [];
  const historyIndex = history.currentIndex || 0;

  if (items.length === 0 || historyIndex - 1 < 0) {
    return state;
  }

  history.currentIndex = historyIndex - 1;
  const current = items[historyIndex - 1];
  return {
    ...state,
    rightPanelHistory: history,
    rightPanelTarget: current.panelKey,
    rightPanelParams: current.params,
  };
}

function rightPanelHistoryForward(state: BaseLayoutValue): BaseLayoutValue {
  const history = { ...state.rightPanelHistory };
  const items = history.items || [];
  const historyIndex = history.currentIndex || 0;

  if (items.length === 0 || historyIndex + 1 >= items.length) {
    return state;
  }

  history.currentIndex = historyIndex + 1;
  const current = items[historyIndex + 1];
  return {
    ...state,
    rightPanelHistory: history,
    rightPanelTarget: current.panelKey,
    rightPanelParams: current.params,
  };
}

function rightPanelHistoryReset(state: BaseLayoutValue): BaseLayoutValue {
  return {
    ...state,
    rightPanelHistory: EMPTY_HISTORY,
  };
}

export default function reducer(
  state: BaseLayoutValue,
  action: BaseLayoutActions
): BaseLayoutValue {
  switch (action.type) {
    case BaseLayoutActionTypes.OPEN_LEFT_PANEL: {
      return {
        ...state,
        rightPanelOpened: action.payload.isDesktop
          ? state.rightPanelOpened
          : false,
        ...getOpenLeftPanelProperties(action.payload.target),
      };
    }
    case BaseLayoutActionTypes.CLOSE_LEFT_PANEL: {
      return {
        ...state,
        leftPanelOpened: false,
      };
    }
    case BaseLayoutActionTypes.TOGGLE_LEFT_PANEL: {
      return state.leftPanelTarget === action.payload.target &&
        state.leftPanelOpened
        ? {
            ...state,
            leftPanelOpened: false,
          }
        : {
            ...state,
            rightPanelOpened: action.payload.isDesktop
              ? state.rightPanelOpened
              : false,
            ...getOpenLeftPanelProperties(action.payload.target),
          };
    }
    case BaseLayoutActionTypes.OPEN_RIGHT_PANEL: {
      const rightPanelProperties = getOpenRightPanelProperties(
        action.payload.target,
        typeof action.payload.params === 'function'
          ? action.payload.params(state.rightPanelParams)
          : action.payload.params
      );

      const history = pushRightPanelHistory(
        state,
        action.payload.target,
        action.payload.params
      );

      return {
        ...state,
        leftPanelOpened: action.payload.isDesktop
          ? state.leftPanelOpened
          : false,
        ...rightPanelProperties,
        rightPanelParams: {
          ...rightPanelProperties.rightPanelParams,
        },
        rightPanelHistory: history,
      };
    }
    case BaseLayoutActionTypes.CLOSE_RIGHT_PANEL: {
      return {
        ...state,
        rightPanelOpened: false,
        rightPanelHistory: EMPTY_HISTORY,
      };
    }
    case BaseLayoutActionTypes.TOGGLE_RIGHT_PANEL: {
      if (
        state.rightPanelTarget === action.payload.target &&
        state.rightPanelOpened
      ) {
        return {
          ...state,
          rightPanelOpened: false,
          rightPanelHistory: EMPTY_HISTORY,
        };
      }

      const rightPanelProperties = getOpenRightPanelProperties(
        action.payload.target,
        typeof action.payload.params === 'function'
          ? action.payload.params(state.rightPanelParams)
          : action.payload.params
      );

      const history = pushRightPanelHistory(
        state,
        action.payload.target,
        action.payload.params
      );

      return {
        ...state,
        leftPanelOpened: action.payload.isDesktop
          ? state.leftPanelOpened
          : false,
        ...rightPanelProperties,
        rightPanelParams: {
          ...rightPanelProperties.rightPanelParams,
        },
        rightPanelHistory: history,
      };
    }
    case BaseLayoutActionTypes.SET_RIGHT_PANEL: {
      return {
        ...state,
        rightPanelRef: action.payload.ref,
      };
    }
    case BaseLayoutActionTypes.SET_RIGHT_PANEL_PARAMS: {
      const params =
        typeof action.payload.params === 'function'
          ? action.payload.params(state.rightPanelParams)
          : action.payload.params;

      return {
        ...state,
        rightPanelParams: params,
        rightPanelHistory: updateRightPanelHistory(state, params),
      };
    }
    case BaseLayoutActionTypes.CLEAR_RIGHT_PANEL_PARAMS: {
      return {
        ...state,
        rightPanelParams: {},
      };
    }
    case BaseLayoutActionTypes.DISABLE_LEFT_PANEL: {
      return {
        ...state,
        hideLeftPanel: true,
      };
    }
    case BaseLayoutActionTypes.ENABLE_LEFT_PANEL: {
      return {
        ...state,
        hideLeftPanel: false,
      };
    }
    case BaseLayoutActionTypes.SET_RIGHT_PANEL_WIDTH: {
      return {
        ...state,
        rightPanelWidth: action.payload.width,
      };
    }
    case BaseLayoutActionTypes.SET_RESIZING: {
      return {
        ...state,
        isResizing: action.payload.isResizing,
      };
    }
    case BaseLayoutActionTypes.SET_RIGHT_PANEL_RESIZABLE: {
      return {
        ...state,
        rightPanelResizable: action.payload.rightPanelResizable,
      };
    }
    case BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_BACK: {
      return {
        ...rightPanelHistoryBack(state),
      };
    }
    case BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_FORWARD: {
      return {
        ...rightPanelHistoryForward(state),
      };
    }
    case BaseLayoutActionTypes.RIGHT_PANEL_HISTORY_RESET: {
      return {
        ...rightPanelHistoryReset(state),
      };
    }
    case BaseLayoutActionTypes.OPEN_MAIN_NAV: {
      return {
        ...state,
        mainNavOpened: true,
      };
    }
    case BaseLayoutActionTypes.CLOSE_MAIN_NAV: {
      return {
        ...state,
        mainNavOpened: false,
      };
    }
    default: {
      return state;
    }
  }
}
