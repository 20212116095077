import { useThreadComposer } from '@lib/web/thread/hooks/core/useThreadComposer';
import { isBlockHtmlEmpty } from '@lib/web/thread/ThreadTextComposer';

export const useSendMessageDisabled = () => {
  const { text, latexActionState } = useThreadComposer();

  const hasAnyErrorLatexBlock = Object.entries(
    latexActionState.latexBlocksMap
  ).some(
    ([blockId, latexData]) =>
      text.includes(blockId) && latexData.errors.length > 0
  );

  const hasAnyInvalidImageOrFileBlock = [
    'status="added-by-insert"',
    'status="added-by-file-drop"',
    'status="ready-for-upload"',
    'status="upload-fail"',
  ].some((keyword) => text.includes(keyword));

  return (
    isBlockHtmlEmpty(text) ||
    hasAnyErrorLatexBlock ||
    hasAnyInvalidImageOrFileBlock
  );
};
