import generateHook, { SwrHelperParams } from './swr.helper';

export const useThreadViews = (
  reqParams: GetThreadViewReq,
  config: SwrHelperParams = {}
) => {
  const params = new URLSearchParams();
  params.set('type', reqParams.type);

  if (reqParams.threadViewId)
    params.set('threadViewId', reqParams.threadViewId);
  if (reqParams.referenceId) params.set('referenceId', reqParams.referenceId);
  if (reqParams.clubId) params.set('clubId', reqParams.clubId);
  if (reqParams.sourceId) params.set('sourceId', reqParams.sourceId);
  if (reqParams.isMyRecordOnly)
    params.set('isMyRecordOnly', reqParams.isMyRecordOnly.toString());
  if (reqParams.sortBy) params.set('sortBy', reqParams.sortBy.toString());
  if (reqParams.orderBy) params.set('orderBy', reqParams.orderBy.toString());

  return generateHook<GetThreadViewRes[]>(
    config.enable === false
      ? null
      : `notification/v1/thread-view?${new URLSearchParams(params).toString()}`,
    {
      auth: true,
      immutable: true,
    }
  );
};

export const useThreadMentionMembers = (
  params: GetThreadMentionMembersReq,
  config: SwrHelperParams = {}
) => {
  return generateHook<GetThreadMentionMembersRes>(
    config.enable === false
      ? null
      : [`v2/thread/mention/member`, params.keyword],
    {
      auth: true,
      method: 'POST',
      paginate: true,
      data: params,
      dedupingInterval: 60000, // prevent query everytime re-mount, so we set 60s cache time for the same key
    }
  );
};

export const useThreadHierarchy = (
  { channelType, channelId }: GetThreadHierarchyReq,
  config: SwrHelperParams = {}
) => {
  const params = new URLSearchParams();
  if (channelType) params.set('channelType', channelType);
  if (channelId) params.set('channelId', channelId);

  return generateHook<GetThreadHierarchyRes>(
    config.enable === false
      ? null
      : `/api/thread/query/hierarchy?${params.toString()}`,
    {
      auth: true,
      baseURL: '',
    }
  );
};
