import { createSlice } from '@reduxjs/toolkit';

const initialState: {
  type: 'channel' | 'table';
  table: {
    showRowBorder: boolean;
    showColumnBorder: boolean;
  };
} = {
  type: 'table',
  table: {
    showRowBorder: true,
    showColumnBorder: true,
  },
};

const iaLayoutSlice = createSlice({
  name: 'iaLayout',
  initialState,
  reducers: {
    updateLayoutType: (state, action) => {
      state.type = action.payload;
    },
    updateTableLayout: (state, action) => {
      state.table = { ...state.table, ...action.payload };
    },
  },
});

export const { updateLayoutType, updateTableLayout } = iaLayoutSlice.actions;
export default iaLayoutSlice.reducer;
