import { useMemo } from 'react';
import {
  ThreadViewStatus,
  ThreadViewType,
  ThreadViewUserStatus,
  useAuth,
  useUserFollowing,
} from '@lib/web/apis';
import { v4 } from 'uuid';

import { generateDmViewConfig } from '../../utils/viewUtils';

const SUGGESTION_COUNT = 5;
export const useSuggestionThreadViews = ({
  showSuggestions,
}: {
  showSuggestions: boolean;
}) => {
  const { member } = useAuth();

  const { dataset: followings } = useUserFollowing(
    showSuggestions && member?.userId ? member.userId : '',
    '',
    SUGGESTION_COUNT
  );

  return useMemo(() => {
    const myMemberId = member?.memberId || '';
    const now = new Date().toISOString();

    return followings.map((user) => {
      const { filterConfig, sortConfig } = generateDmViewConfig({
        memberIds: [myMemberId, user.memberId],
      });
      return {
        type: ThreadViewType.DmOneOnOne,
        id: user.memberId,
        status: ThreadViewStatus.Active,
        createdAt: now,
        updatedAt: now,
        referenceId: null,
        name: null,
        emoji: null,
        sourceId: null,
        clubId: null,
        hideAt: null,
        threadViewUsers: [
          {
            id: v4(),
            createdAt: now,
            customEmoji: null,
            customName: null,
            filterConfig: filterConfig || '',
            sortConfig: sortConfig || '',
            memberId: myMemberId,
            status: ThreadViewUserStatus.Active,
            updatedAt: now,
          },
        ],
      };
    });
  }, [followings, member?.memberId]);
};
