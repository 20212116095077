import { useContext, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { BaseLayoutContext, BaseLayoutDispatchContext } from '../contexts';
import { BaseLayoutActionTypes } from '../contexts/type';

export default function useBaseLeftPanel() {
  const { leftPanelOpened, leftPanelTarget, hideLeftPanel } =
    useContext(BaseLayoutContext);
  const dispatch = useContext(BaseLayoutDispatchContext);

  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  const memoizedFunction = useMemo(() => {
    const openLeftPanel = (target = '') => {
      dispatch({
        type: BaseLayoutActionTypes.OPEN_LEFT_PANEL,
        payload: { target, isDesktop: lgUp },
      });
    };

    const closeLeftPanel = () => {
      dispatch({
        type: BaseLayoutActionTypes.CLOSE_LEFT_PANEL,
      });
    };

    const toggleLeftPanel = (target = '') => {
      dispatch({
        type: BaseLayoutActionTypes.TOGGLE_LEFT_PANEL,
        payload: { target, isDesktop: lgUp },
      });
    };

    const disableLeftPanel = () => {
      dispatch({
        type: BaseLayoutActionTypes.DISABLE_LEFT_PANEL,
      });
    };

    const enableLeftPanel = () => {
      dispatch({
        type: BaseLayoutActionTypes.ENABLE_LEFT_PANEL,
      });
    };

    return {
      openLeftPanel,
      closeLeftPanel,
      toggleLeftPanel,
      disableLeftPanel,
      enableLeftPanel,
    };
  }, [dispatch, lgUp]);

  return {
    leftPanelOpened,
    leftPanelTarget,
    hideLeftPanel,
    ...memoizedFunction,
  };
}
