import { AxiosRequestConfig } from 'axios';
import { isNil } from 'lodash';

import { ExamMode } from '../types/enums';

import api from './client';

const quizApi = {
  quizByTag: (params: QuizByTagReq) =>
    api.post<Response<GetQuizByTagRes>>('v2/quiz/by-tag', params),

  quizByHashtag: (params: QuizByHashtagsReq) =>
    api.post<Response<GetQuizByTagRes>>('v2/quiz/by-hashtag', params),

  quizByMark: (params: QuizByMarkReq) =>
    api.post<Response<GetQuizByTagRes>>('v2/quiz/by-mark', params),

  practice: (
    quizId: string,
    {
      isIgnoreVersionBlock = false,
      isIncognito,
      roundNo,
      mode,
    }: {
      isIgnoreVersionBlock?: boolean;
      isIncognito?: boolean;
      roundNo?: number;
      mode?: ExamMode;
    } = {}
  ) => {
    const params = new URLSearchParams();
    params.set('isIgnoreVersionBlock', `${isIgnoreVersionBlock}`);
    if (!isNil(isIncognito)) params.set('isIncognito', `${isIncognito}`);
    if (!isNil(roundNo)) params.set('roundNo', `${roundNo}`);
    if (!isNil(mode)) params.set('mode', `${mode}`);
    return api.get<Response<GetQuizPracticeRes>>(
      `v2/quiz/practice/${quizId}?${params.toString()}`
    );
  },

  nextRoundNo: (quizId: string) =>
    api.get<Response<number>>(`v2/quiz/${quizId}/round-no/next`),

  oneRoundResult: (quizId: string, roundNo: number) =>
    api.get<PageResponse<GetQuizResultRes>>(
      `v2/quiz/${quizId}/${roundNo}/result/one-round`
    ),
  saveResult: (params: QuizResultReq) =>
    api.post<PageResponse<GetQuizResultRes>>('v2/quiz/result', params),

  saveRating: (params: QuizQuestionRatingReq) =>
    api.post<Response<null>>('v2/quiz/question/rating-info', params),

  updateScore: (params: QuizUpdateScoreReq) =>
    api.post<Response<null>>('v2/quiz/score/update', params),

  addBookmark: (quizQuestionId: string) =>
    api.post<Response<null>>(
      `v2/quiz/question/${quizQuestionId}/mark/bookmark`
    ),

  removeBookmark: (quizQuestionId: string) =>
    api.delete<Response<null>>(
      `v2/quiz/question/${quizQuestionId}/mark/bookmark`
    ),

  getEmoji: (quizQuestionId: string) =>
    api.get<Response<GetQuizQuestionMarkedEmojiRes>>(
      `v2/quiz/question/${quizQuestionId}/mark/emoji`
    ),
  addEmoji: (quizQuestionId: string, markId: string) =>
    api.post<Response<null>>(
      `/v2/quiz/question/${quizQuestionId}/mark/emoji/${markId}`
    ),

  removeEmoji: (quizQuestionId: string) =>
    api.delete<Response<null>>(`v2/quiz/question/${quizQuestionId}/mark/emoji`),
  addExamQuestionEmoji: (params: QuizAddExamQuestionEmojiReq) =>
    api.post<Response<null>>(`/v2/exam-question/emoji`, params),
  removeExamQuestionEmoji: (params: QuizRemoveExamQuestionEmojiReq) =>
    api.delete<Response<null>>(`/v2/exam-question/emoji`, {
      data: params,
    }),

  exportPDF: (
    { quizId, sid }: { quizId: string; sid?: string },
    isSendEmail = false
  ) => {
    const params = new URLSearchParams();

    if (sid) {
      params.set('sid', sid);
    }
    return api.post<Response<PostQuizPDFRes>>(
      `v2/quiz/pdf?${params.toString()}`,
      {
        quizId,
        isSendEmail,
      }
    );
  },
  getPDFLink: ({ quizId, sid }: { quizId: string; sid?: string }) => {
    const params = new URLSearchParams();

    params.set('quizId', quizId);
    if (sid) {
      params.set('sid', sid);
    }
    return api.get<Response<GetQuizPDFRes>>(
      `v2/quiz/pdf/link?${params.toString()}`
    );
  },

  savePlaylist: (quizId: string) =>
    api.post(`v2/quiz/mark/bookmark?quizId=${quizId}`),
  removePlaylist: (quizId: string) =>
    api.delete(`v2/quiz/mark/bookmark?quizId=${quizId}`),

  updateNote: (quizQuestionId: string, note: string) =>
    api.post('v2/quiz/question/note', { quizQuestionId, note }),

  getNote: (quizQuestionId: string, config: AxiosRequestConfig) =>
    api.get<Response<GetQuizQuestionNoteRes>>(
      `/v2/quiz/question/note/${quizQuestionId}`,
      config
    ),

  generateResultLink: (params: GenerateResultLinkReq) =>
    api.post<Response<ShareResultLinkRes>>('v2/quiz/result/share/link', params),

  shareResultLink: (params: ShareResultLinkReq) =>
    api.post<Response<PostShareLinkReq>>('v2/quiz/result/share', params),

  updateTagScore: () =>
    api.post<Response<ShareResultLinkRes>>('v2/quiz/tag/score/update'),

  updateQuizName: (params: QuizNameReq) =>
    api.post('v2/quiz/owner-info', params),

  generateQuizLink: (params: GenerateQuizLinkReq) =>
    api.post<Response<PostGenerateQuizLinkRes>>('v2/quiz/share/link', params),
  shareQuizLink: (params: ShareQuizLinkReq) =>
    api.post<Response<PostShareLinkReq>>('v2/quiz/share', params),

  generateQuizDynamicLink: (params: GenerateQuizDynamicLinkReq) =>
    api.post<Response<PostGenerateQuizLinkRes>>(
      'v2/quiz/dynamic/share/link',
      params
    ),
  shareQuizDynamicLink: (params: ShareQuizDynamicLinkReq) =>
    api.post<Response<PostShareLinkReq>>('v2/quiz/dynamic/share', params),

  archiveQuizData: (params: ArchiveQuizDataReq) =>
    api.post('/v2/quiz/archive-data', params),

  getHistoryList: ({
    sectionId,
    quizId,
    page = 1,
    limit = 10,
  }: {
    sectionId: string;
    quizId: string;
    page?: number;
    limit?: number;
  }) => {
    return api.get<PageResponse<GetMemberQuizRes>>(
      `v2/quiz/practice/history/list?sectionId=${sectionId}&quizId=${quizId}&page=${page}&limit=${limit}`
    );
  },

  exportQuestion: (questionId: string, config?: AxiosRequestConfig) =>
    api.get<Response<GetQuizExportRes>>(
      `v2/quiz/question/export?creatorQuestionId=${questionId}`,
      config
    ),
  getResultSummary: ({
    quizId,
    roundNo,
  }: {
    quizId: string;
    roundNo: number;
  }) =>
    api.get<Response<GetQuizResultSummaryRes>>(
      `v2/quiz/${quizId}/${roundNo}/result/summary`
    ),
};

export default quizApi;
