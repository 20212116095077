import { countWords } from '@front/helper';
import { AnswerFormatType, StructureType } from '@lib/web/apis';
import {
  getAnswerOptionDetail,
  getAnswerOptionFirstContentText,
  getAnswerOptionIdToContentHtmlMap,
  getAnswerOptionIdToSolutionHtmlMap,
  getAnswerSolutionDetail,
  getGeneralSolutionContentHtml,
  getLeftQuestionContentHtml,
  getLeftQuestionDetail,
  getRightQuestionContentHtml,
  getRightQuestionDetail,
  getSolutionDetail,
} from '@lib/web/editor';
import {
  isBlockHtmlEmpty,
  questionDetailToVariables,
} from '@lib/web/editor/EditorTextComposer';
import { ErrorMessageValue } from '@lib/web/ui';

import { getGridErrorMessage } from '../../utils/gridValidator';

import { ValidationData } from './types';

const Validators = {
  ensureLeftQuestionAreaNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question } = validationData[id];
    let { questionDetail } = validationData[id];
    if (question.structureType === StructureType.SubQuestion) {
      questionDetail = validationData[question.questionGroupId].questionDetail;
    }

    if (isBlockHtmlEmpty(getLeftQuestionContentHtml(questionDetail))) {
      return {
        id: 'Question area may not be empty, please write question here',
        type: 'error' as const,
        message: 'Question area may not be empty, please write question here',
      };
    }
    return null;
  },
  ensureLeftQuestionVariablesNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question } = validationData[id];
    let { questionDetail } = validationData[id];
    if (question.structureType === StructureType.SubQuestion) {
      questionDetail = validationData[question.questionGroupId].questionDetail;
    }

    const leftQuestionDetail = getLeftQuestionDetail(questionDetail);

    if (!leftQuestionDetail) return null;

    const detailVariables = questionDetailToVariables(leftQuestionDetail);

    const hasVariableEmpty = detailVariables.some(
      (variable) => !variable.variableId
    );

    if (hasVariableEmpty) {
      return {
        id: 'There is a variable with no selected value, please select a variable.',
        type: 'error' as const,
        message:
          'There is a variable with no selected value, please select a variable.',
      };
    }
    return null;
  },
  ensureSubQuestionRightQuestionAreaNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (question.structureType !== StructureType.SubQuestion) {
      return null;
    }

    if (isBlockHtmlEmpty(getRightQuestionContentHtml(questionDetail))) {
      return {
        id: 'Question area may not be empty, please write question here',
        type: 'error' as const,
        message: 'Question area may not be empty, please write question here',
      };
    }
    return null;
  },
  ensureRightQuestionVariablesNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { questionDetail } = validationData[id];

    const rightQuestionDetail = getRightQuestionDetail(questionDetail);

    if (!rightQuestionDetail) return null;

    const detailVariables = questionDetailToVariables(rightQuestionDetail);

    const hasVariableEmpty = detailVariables.some(
      (variable) => !variable.variableId
    );

    if (hasVariableEmpty) {
      return {
        id: 'There is a variable with no selected value, please select a variable.',
        type: 'error' as const,
        message:
          'There is a variable with no selected value, please select a variable.',
      };
    }
    return null;
  },
  ensureMcqMrqOptionNotDuplicated: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![
        AnswerFormatType.MultipleChoice,
        AnswerFormatType.MultipleResponse,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    const contentMap: Record<string, boolean> = {};

    for (const html of Object.values(
      getAnswerOptionIdToContentHtmlMap(questionDetail)
    )) {
      if (html && html in contentMap) {
        return {
          id: 'Duplicate content in answer options',
          type: 'error' as const,
          message: 'Duplicate content in answer options',
        };
      }
      contentMap[html] = true;
    }
    return null;
  },
  ensureMcqMrqOptionNotEmpty: (id: string, validationData: ValidationData) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![
        AnswerFormatType.MultipleChoice,
        AnswerFormatType.MultipleResponse,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    for (const html of Object.values(
      getAnswerOptionIdToContentHtmlMap(questionDetail)
    )) {
      if (isBlockHtmlEmpty(html)) {
        return {
          id: 'All answer options should be filled',
          type: 'error' as const,
          message: 'All answer options should be filled',
        };
      }
    }
    return null;
  },
  ensureMcqMrqUnscrambleOptionVariablesNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![
        AnswerFormatType.MultipleChoice,
        AnswerFormatType.MultipleResponse,
        AnswerFormatType.Unscramble,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    const answerOptionDetail = getAnswerOptionDetail(questionDetail);

    if (!answerOptionDetail) return null;

    const detailVariables = questionDetailToVariables(answerOptionDetail);

    const hasVariableEmpty = detailVariables.some(
      (variable) => !variable.variableId
    );

    if (hasVariableEmpty) {
      return {
        id: 'There is a variable with no selected value, please select a variable.',
        type: 'error' as const,
        message:
          'There is a variable with no selected value, please select a variable.',
      };
    }
    return null;
  },
  ensureMcqOptionAtLeaseOneCorrect: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, correctAnswer } = validationData[id];

    if (question.answerFormatType !== AnswerFormatType.MultipleChoice) {
      return null;
    }

    if (correctAnswer.length < 1) {
      return {
        id: '1 answer should be selected as correct',
        type: 'error' as const,
        message: '1 answer should be selected as correct',
      };
    }
    return null;
  },
  ensureMrqOptionAtLeastSelectNCorrect: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, correctAnswer } = validationData[id];

    if (question.answerFormatType !== AnswerFormatType.MultipleResponse) {
      return null;
    }

    const atLeastNumber = 1; // TODO: confirm where the number come from in back-end

    if (atLeastNumber === 1 && correctAnswer.length < atLeastNumber) {
      return {
        id: 'At least one option should be selected',
        type: 'error' as const,
        message: 'At least one option should be selected',
      };
    }
    if (atLeastNumber > 1 && correctAnswer.length < atLeastNumber) {
      return {
        id: `${atLeastNumber} answers should be selected as correct`,
        type: 'error' as const,
        message: `${atLeastNumber} answers should be selected as correct`,
      };
    }
    return null;
  },
  ensureMcqTfnCorrectSolutionNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail, correctAnswer } = validationData[id];

    if (
      ![
        AnswerFormatType.MultipleChoice,
        AnswerFormatType.TrueFalseNotGiven,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    const correctAnswerIdSet = new Set(
      correctAnswer.map((item) => item.componentId)
    );

    const answerOptionIdToSolutionHtmlMap =
      getAnswerOptionIdToSolutionHtmlMap(questionDetail);

    for (const [optionId, html] of Object.entries(
      answerOptionIdToSolutionHtmlMap
    )) {
      if (correctAnswerIdSet.has(optionId) && isBlockHtmlEmpty(html)) {
        return {
          id: 'Solution for correct choice should be filled',
          type: 'error' as const,
          message: 'Solution for correct choice should be filled',
        };
      }
    }
    return null;
  },
  ensureMcqTfnSolutionVariablesNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![
        AnswerFormatType.MultipleChoice,
        AnswerFormatType.TrueFalseNotGiven,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    const answerSolutionDetail = getAnswerSolutionDetail(questionDetail);

    if (!answerSolutionDetail) return null;

    const detailVariables = questionDetailToVariables(answerSolutionDetail);

    const hasVariableEmpty = detailVariables.some(
      (variable) => !variable.variableId
    );

    if (hasVariableEmpty) {
      return {
        id: 'There is a variable with no selected value, please select a variable.',
        type: 'error' as const,
        message:
          'There is a variable with no selected value, please select a variable.',
      };
    }
    return null;
  },
  ensureGridInAnswerFormatCorrect: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, correctAnswer } = validationData[id];

    if (![AnswerFormatType.GridIn].includes(question.answerFormatType)) {
      return null;
    }

    const answerValue = correctAnswer[0]?.value?.trim() || '';

    return getGridErrorMessage(answerValue);
  },

  ensureGeneralSolutionNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![
        AnswerFormatType.GridIn,
        AnswerFormatType.FreeResponse,
        AnswerFormatType.Unscramble,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    if (isBlockHtmlEmpty(getGeneralSolutionContentHtml(questionDetail))) {
      return {
        id: 'Solution should be filled',
        type: 'error' as const,
        message: 'Solution should be filled',
      };
    }

    return null;
  },
  ensureGeneralSolutionVariablesNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![
        AnswerFormatType.GridIn,
        AnswerFormatType.FreeResponse,
        AnswerFormatType.Unscramble,
      ].includes(question.answerFormatType)
    ) {
      return null;
    }

    const solutionDetail = getSolutionDetail(questionDetail);

    if (!solutionDetail) return null;

    const detailVariables = questionDetailToVariables(solutionDetail);

    const hasVariableEmpty = detailVariables.some(
      (variable) => !variable.variableId
    );

    if (hasVariableEmpty) {
      return {
        id: 'There is a variable with no selected value, please select a variable.',
        type: 'error' as const,
        message:
          'There is a variable with no selected value, please select a variable.',
      };
    }

    return null;
  },
  ensureTfnOptionAtLeastSelectOneCorrect: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, correctAnswer } = validationData[id];

    if (question.answerFormatType !== AnswerFormatType.TrueFalseNotGiven) {
      return null;
    }

    if (correctAnswer.length !== 1) {
      return {
        id: '1 answer should be selected as correct',
        type: 'error' as const,
        message: '1 answer should be selected as correct',
      };
    }

    return null;
  },
  ensureFreeResponseAnswerNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (question.answerFormatType !== AnswerFormatType.FreeResponse) {
      return null;
    }

    if (getAnswerOptionFirstContentText(questionDetail).trim() === '') {
      return {
        id: 'Answer box should be filled in',
        type: 'error' as const,
        message: 'Answer box should be filled in',
      };
    }

    return null;
  },
  ensureEssayAnswerNotEmpty: (id: string, validationData: ValidationData) => {
    const { question, questionDetail } = validationData[id];

    if (question.answerFormatType !== AnswerFormatType.Essay) {
      return null;
    }

    if (getAnswerOptionFirstContentText(questionDetail).trim() === '') {
      return {
        id: 'Example answer box should be filled',
        type: 'error' as const,
        message: 'Example answer box should be filled',
      };
    }

    return null;
  },
  ensureEssayFreeResponseAnswerMeetMinimumWords: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (
      ![AnswerFormatType.Essay, AnswerFormatType.FreeResponse].includes(
        question.answerFormatType
      ) ||
      !question.isOfferWordLimit
    ) {
      return null;
    }

    const wordLimitMin = {
      [AnswerFormatType.Essay]: question.essayAnswerWordLimitMin,
      [AnswerFormatType.FreeResponse]: question.freeResponseAnswerWordLimitMin,
    }[question.answerFormatType as number];

    // wordLimitMin = 0 means 'no limit'
    if (
      wordLimitMin &&
      countWords(getAnswerOptionFirstContentText(questionDetail)) < wordLimitMin
    ) {
      return {
        id: 'Answer does not meet minimum word requirement',
        type: 'error' as const,
        message: 'Answer does not meet minimum word requirement',
      };
    }

    return null;
  },
  ensureUnscrambleAnswerNotEmpty: (
    id: string,
    validationData: ValidationData
  ) => {
    const { question, questionDetail } = validationData[id];

    if (![AnswerFormatType.Unscramble].includes(question.answerFormatType)) {
      return null;
    }

    for (const html of Object.values(
      getAnswerOptionIdToContentHtmlMap(questionDetail)
    )) {
      if (isBlockHtmlEmpty(html)) {
        return {
          id: 'All steps should be filled in',
          type: 'error' as const,
          message: 'All steps should be filled in',
        };
      }
    }
    return null;
  },
};

export const validateLeftQuestionArea = (
  id: string,
  validationData: ValidationData
): ErrorMessageValue[] => {
  return [
    Validators.ensureLeftQuestionAreaNotEmpty(id, validationData),
    Validators.ensureLeftQuestionVariablesNotEmpty(id, validationData),
  ].filter(Boolean) as ErrorMessageValue[];
};

export const validateRightQuestionArea = (
  id: string,
  validationData: ValidationData
): ErrorMessageValue[] => {
  return [
    Validators.ensureSubQuestionRightQuestionAreaNotEmpty(id, validationData),
    Validators.ensureRightQuestionVariablesNotEmpty(id, validationData),
  ].filter(Boolean) as ErrorMessageValue[];
};

export const validateAnswerArea = (
  id: string,
  validationData: ValidationData
): ErrorMessageValue[] => {
  return [
    Validators.ensureMcqMrqOptionNotDuplicated(id, validationData),
    Validators.ensureMcqMrqOptionNotEmpty(id, validationData),
    Validators.ensureMcqMrqUnscrambleOptionVariablesNotEmpty(
      id,
      validationData
    ),
    Validators.ensureMcqOptionAtLeaseOneCorrect(id, validationData),
    Validators.ensureMrqOptionAtLeastSelectNCorrect(id, validationData),
    Validators.ensureGridInAnswerFormatCorrect(id, validationData),
    Validators.ensureTfnOptionAtLeastSelectOneCorrect(id, validationData),
    Validators.ensureFreeResponseAnswerNotEmpty(id, validationData),
    Validators.ensureEssayAnswerNotEmpty(id, validationData),
    Validators.ensureEssayFreeResponseAnswerMeetMinimumWords(
      id,
      validationData
    ),
    Validators.ensureUnscrambleAnswerNotEmpty(id, validationData),
  ].filter(Boolean) as ErrorMessageValue[];
};

export const validateSolutionArea = (
  id: string,
  validationData: ValidationData
): ErrorMessageValue[] => {
  return [
    Validators.ensureMcqTfnCorrectSolutionNotEmpty(id, validationData),
    Validators.ensureMcqTfnSolutionVariablesNotEmpty(id, validationData),
    Validators.ensureGeneralSolutionNotEmpty(id, validationData),
    Validators.ensureGeneralSolutionVariablesNotEmpty(id, validationData),
  ].filter(Boolean) as ErrorMessageValue[];
};
