import { IaSearchReq } from '@lib/ia/src/types/query';
import { getIaQueryString } from '@lib/ia/src/utils/query';

import { ViewSlugInfiniteHookResponse } from '../../types/base/view';
import { MarkViewSlug } from '../../types/enums';

import generateHook, {
  generateInfiniteHook,
  SwrHelperParams,
} from './swr.helper';

export const useMarkEmojis = () =>
  generateHook<GetMarkEmojiRes>('v2/mark/emoji-static?page=1&limit=1000', {
    immutable: true,
    paginate: true,
  });

export function useIaMarkList(
  query: IaSearchReq<MarkViewSlug.IconRhsIAMarkDefault>,
  config?: SwrHelperParams
): ViewSlugInfiniteHookResponse<GetIaMarkRhsDefaultViewRes>;
export function useIaMarkList(
  query: Omit<IaSearchReq<MarkViewSlug>, 'page'> = {},
  { ...config }: SwrHelperParams = {}
): ViewSlugInfiniteHookResponse<GetIaMarkRhsDefaultViewRes> {
  const limit = query.limit || 0;

  return generateInfiniteHook(
    (index) =>
      config.enable === false
        ? null
        : `v2/ia/mark?${getIaQueryString({
            ...query,
            page: index + 1,
            limit,
          })}`,
    limit,
    { auth: true }
  );
}
