import { createContext, ReactNode, useContext, useMemo, useState } from 'react';
import { QuestionDetailComponentType } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import {
  CreatorQuestionDetailContext,
  useCreatorQuestionDetailHandle,
} from '@lib/web/editor';
import { apiBlocksToComposerBlocks } from '@lib/web/editor/EditorTextComposer';
import { call } from '@lib/web/utils';

export type DetailComponentValue = Record<
  string,
  { componentId: string; blocks: ComposerBlock[] }
>;

export type DetailComponentContextValue = {
  isInitial: boolean;
  detailBlocks: ComposerBlock[];
  fetchDetailComponent: () => Promise<void>;
  updateDetailComponent: (values: ComposerBlock[]) => void;
};

export const DetailComponentContext =
  createContext<DetailComponentContextValue>({
    isInitial: true,
    detailBlocks: [],
    fetchDetailComponent: async () => {},
    updateDetailComponent: async () => {},
  });

type DetailComponentProviderProps = {
  questionId: string;
  children: ReactNode;
  componentType: QuestionDetailComponentType;
};

export function DetailComponentProvider({
  questionId,
  children,
  componentType,
}: DetailComponentProviderProps) {
  const [value, setValue] = useState<DetailComponentValue>({});
  const { handleCreatorQuestionDetailChange } =
    useCreatorQuestionDetailHandle(questionId);

  const { getCreatorQuestionDetail } = useContext(CreatorQuestionDetailContext);

  const memoizedValue = useMemo(() => {
    return {
      isInitial: value[questionId] === undefined,
      detailBlocks: value[questionId]?.blocks || [],
      fetchDetailComponent: async () => {
        const [res] = await call(
          getCreatorQuestionDetail(questionId, componentType)
        );

        if (res?.data[componentType]) {
          const firstData = res.data[componentType][0];
          setValue((st) => ({
            ...st,
            [questionId]: {
              componentId: firstData?.id || '',
              blocks: apiBlocksToComposerBlocks(firstData?.blocks),
            },
          }));
        } else {
          setValue((st) => ({
            ...st,
            [questionId]: { componentId: '', blocks: [] },
          }));
        }
      },
      updateDetailComponent: (newValue: ComposerBlock[]) => {
        setValue((st) => ({
          ...st,
          [questionId]: { ...st[questionId], blocks: newValue },
        }));
        void handleCreatorQuestionDetailChange(
          value[questionId].componentId,
          newValue
        );
      },
    };
  }, [
    value,
    questionId,
    getCreatorQuestionDetail,
    componentType,
    handleCreatorQuestionDetailChange,
  ]);

  return (
    <DetailComponentContext.Provider value={memoizedValue}>
      {children}
    </DetailComponentContext.Provider>
  );
}

export const DetailComponentConsumer = DetailComponentContext.Consumer;
