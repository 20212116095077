import React from 'react';
import ChannelLayoutSkeleton from '@lib/ia/src/layouts/ChannelLayout/ChannelLayoutSkeleton';
import GapLayout from '@lib/ia/src/layouts/GapLayout';

import AlbumLayout from '../AlbumLayout';
import CardLayout, { CardLayoutSkeleton } from '../CardLayout';
import CardLayoutEmpty from '../CardLayout/CardLayoutEmpty';
import CarouselLayout from '../CarouselLayout';
import ChannelLayout from '../ChannelLayout';
import DisplayTableLayout from '../DisplayTableLayout';
import FormLayout, { FormLayoutSkeleton } from '../FormLayout';
import HintLayout from '../HintLayout';
import IconListLayout, { IconListLayoutSkeleton } from '../IconListLayout';
import ListLayout from '../ListLayout';
import ProfileLayout, { ProfileLayoutSkeleton } from '../ProfileLayout';
import SmallIconListLayout from '../SmallIconListLayout';
import TableLayout from '../TableLayout';

import { IaLayoutConfig } from './types';

export type IaLayoutsProps = {
  layouts: IaLayoutConfig[];
};
export default function IaLayouts({ layouts }: IaLayoutsProps) {
  return (
    <>
      {layouts.map((layout, i) => {
        const key = `${layout.layout}-${i}`;
        switch (layout.layout) {
          case 'card-layout':
            return <CardLayout key={key} {...layout} />;
          case 'card-layout-skeleton':
            return <CardLayoutSkeleton key={key} {...layout} />;
          case 'card-layout-empty':
            return <CardLayoutEmpty key={key} {...layout} />;
          case 'table-layout':
            return <TableLayout key={key} {...layout} />;
          case 'display-table-layout':
            return <DisplayTableLayout key={key} {...layout} />;
          case 'form-layout':
            return <FormLayout key={key} {...layout} />;
          case 'form-layout-skeleton':
            return <FormLayoutSkeleton key={key} {...layout} />;
          case 'hint-layout':
            return <HintLayout key={key} {...layout} />;
          case 'gap-layout':
            return <GapLayout key={key} {...layout} />;
          case 'list-layout':
            return <ListLayout key={key} {...layout} />;
          case 'small-icon-list-layout':
            return <SmallIconListLayout key={key} {...layout} />;
          case 'profile-layout':
            return <ProfileLayout key={key} {...layout} />;
          case 'profile-layout-skeleton':
            return <ProfileLayoutSkeleton key={key} />;
          case 'icon-list-layout':
            return <IconListLayout key={key} {...layout} />;
          case 'icon-list-layout-skeleton':
            return <IconListLayoutSkeleton key={key} {...layout} />;
          case 'channel-layout':
            return <ChannelLayout key={key} {...layout} />;
          case 'channel-layout-skeleton':
            return <ChannelLayoutSkeleton key={key} {...layout} />;
          case 'carousel-layout':
            return <CarouselLayout key={key} {...layout} />;
          case 'album-layout':
            return <AlbumLayout key={key} {...layout} />;

          default:
            return null;
        }
      })}
    </>
  );
}
