import generateHook from './swr.helper';

const getLeagueGroupListKey = ({
  clubSlug,
  isSync,
  isGetFollowInfo,
}: {
  clubSlug: NullableString;
  isSync?: boolean;
  isGetFollowInfo?: boolean;
}) => {
  const params = new URLSearchParams();

  if (clubSlug) params.set('clubSlug', clubSlug);
  if (isSync) params.set('sectionId', 'true');
  if (isGetFollowInfo) params.set('isGetFollowInfo', 'true');

  return `/v2/league/group?${params.toString()}`;
};

export const useLeagueGroupList = ({
  clubSlug,
  isSync,
  isGetFollowInfo,
}: {
  clubSlug: NullableString;
  isSync?: boolean;
  isGetFollowInfo?: boolean;
}) =>
  generateHook<GetLeagueGroupItemRes[]>(
    clubSlug
      ? getLeagueGroupListKey({ clubSlug, isSync, isGetFollowInfo })
      : undefined,
    {
      auth: true,
    }
  );

const getClubLeagueInfoKey = (clubSlug: NullableString) => {
  const params = new URLSearchParams();

  if (clubSlug) params.set('clubSlug', clubSlug);

  return `/v2/club/league?${params.toString()}`;
};

export const useClubLeagueInfo = (clubSlug: NullableString) =>
  generateHook<GetClubLeagueInfoRes>(
    clubSlug ? getClubLeagueInfoKey(clubSlug) : undefined,
    {
      auth: true,
      immutable: true,
    }
  );

export const useAllLeagues = () =>
  generateHook<GetLeagueRes>('/v2/league', {
    auth: true,
    immutable: true,
  });
