import React, { ReactNode } from 'react';
import {
  ActionSearch as ActionSearchIcon,
  ChatHashtag as ChatHashtagIcon,
  EditorDifficulty as EditorDifficultyIcon,
  EditorDynamicVariable as EditorDynamicVariableIcon,
  EditorRename as EditorRenameIcon,
  MainAnalytics as MainAnalyticsIcon,
  MainGetHelp as MainGetHelpIcon,
  OtherCalendarDay as OtherCalendarDayIcon,
  OtherCopy as OtherCopyIcon,
  OtherEdit as OtherEditIcon,
  OtherFilter as OtherFilterIcon,
  OtherKeyboard as OtherKeyboardIcon,
  OtherLocation as OtherLocationIcon,
  OtherNotes as OtherNotesIcon,
  OtherShareToDirect as OtherShareToDirectIcon,
  OtherSort as OtherSortIcon,
  ProfileAdjustment as ProfileAdjustmentIcon,
  ProfileManageAIBot as ProfileManageAIBotIcon,
  ProfileSetting as ProfileSettingIcon,
  TestBookmarkOutline as TestBookmarkOutlineIcon,
  TestCalculator as TestCalculatorIcon,
  TestEmoji as TestEmojiIcon,
  TestExport as TestExportIcon,
  TestFormula as TestFormulaIcon,
  TestSolution as TestSolutionIcon,
  TestTrophy as TestTrophyIcon,
  ThreadsThreadView as ThreadsThreadViewIcon,
} from '@front/icon';

import SearchToolbarItem from './components/SearchToolbarItem';
import {
  ToolbarButtonConfig,
  ToolbarButtonItem,
  ToolbarButtonResponsiveConfig,
  ToolbarButtonType,
} from './types';

const DESKTOP_TOOLBAR_BUTTONS_ORDERED: ToolbarButtonType[] = [
  'Share',
  'AddEmoji',
  'Analytics',
  'Solution',
  'Hashtags',
  'DifficultyLevel',
  'Keyboard',
  'Notes',
  'SavePlaylist',
  'Calendar',
  'Rankings',
  'Sort',
  'Filter',
  'Search',
  'ThreadView',
  'Pins',
  'Formula',
  'Calculator',
  'Properties',
  'Layout',
  'Edit',
  'Duplicate',
  'RenamePlaylist',
  'ExportToPDF',
  'Settings',
  'QuestionExport',
  'ManageAgents',
  'Help',
];

const MOBILE_TOOLBAR_BUTTONS_ORDERED: ToolbarButtonType[] = [
  'Share',
  'ThreadView',
  'Analytics',
  'Solution',
  'Hashtags',
  'DifficultyLevel',
  'Formula',
  'Calculator',
  'Notes',
  'AddEmoji',
  'Properties',
  'Layout',
  'Edit',
  'Duplicate',
  'SavePlaylist',
  'RenamePlaylist',
  'ExportToPDF',
  'Settings',
  'QuestionExport',
  'ManageAgents',
  'Rankings',
  'Sort',
  'Filter',
  'Search',
  'Calendar',
  'Pins',
  'Help',
];

const orderOf = (type: ToolbarButtonType) => {
  return {
    mobile: MOBILE_TOOLBAR_BUTTONS_ORDERED.indexOf(type),
    desktop: DESKTOP_TOOLBAR_BUTTONS_ORDERED.indexOf(type),
  };
};

const TOOLBAR_CONFIG_MAP: Record<ToolbarButtonType, ToolbarButtonConfig> = {
  Analytics: {
    titleKey: 'toolbar.Analytics',
    icon: <MainAnalyticsIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Analytics'),
  },
  Solution: {
    titleKey: 'toolbar.Solution',
    icon: <TestSolutionIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Solution'),
  },
  Hashtags: {
    titleKey: 'toolbar.Hashtags',
    icon: <ChatHashtagIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Hashtags'),
  },
  DifficultyLevel: {
    titleKey: 'toolbar.Difficulty Level',
    icon: <EditorDifficultyIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('DifficultyLevel'),
  },
  Keyboard: {
    titleKey: 'toolbar.Math Keyboard',
    icon: <OtherKeyboardIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Keyboard'),
  },
  Notes: {
    titleKey: 'toolbar.Notes',
    icon: <OtherNotesIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Notes'),
  },
  AddEmoji: {
    titleKey: 'toolbar.Add Reaction',
    icon: <TestEmojiIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('AddEmoji'),
  },
  SavePlaylist: {
    titleKey: 'toolbar.Save Playlist',
    icon: <TestBookmarkOutlineIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('SavePlaylist'),
  },
  Calendar: {
    titleKey: 'toolbar.Calendar',
    icon: <OtherCalendarDayIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Calendar'),
  },
  Rankings: {
    titleKey: 'toolbar.Rankings',
    icon: <TestTrophyIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Rankings'),
  },
  Sort: {
    titleKey: 'toolbar.Sort',
    icon: <OtherSortIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Sort'),
  },
  Filter: {
    titleKey: 'toolbar.Filter',
    icon: <OtherFilterIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Filter'),
  },
  Search: {
    titleKey: 'toolbar.Search',
    icon: <ActionSearchIcon />,
    visible: { desktop: true, mobile: false },
    order: orderOf('Search'),
    render: (data: ToolbarButtonItem) => <SearchToolbarItem {...data} />,
  },
  Share: {
    titleKey: 'toolbar.Share',
    icon: <OtherShareToDirectIcon />,
    visible: true,
    order: orderOf('Share'),
  },
  ThreadView: {
    titleKey: 'toolbar.Thread View',
    icon: <ThreadsThreadViewIcon />,
    visible: true,
    order: orderOf('ThreadView'),
  },
  Pins: {
    titleKey: 'toolbar.Pins',
    icon: <OtherLocationIcon />,
    visible: false,
    order: orderOf('Formula'),
  },
  Formula: {
    titleKey: 'toolbar.Formula',
    icon: <TestFormulaIcon />,
    visible: false,
    order: orderOf('Formula'),
  },
  Calculator: {
    titleKey: 'toolbar.Calculator',
    icon: <TestCalculatorIcon />,
    visible: false,
    order: orderOf('Calculator'),
  },
  Properties: {
    titleKey: 'toolbar.Properties',
    icon: <ProfileAdjustmentIcon />,
    visible: false,
    order: orderOf('Properties'),
  },
  Layout: {
    titleKey: 'toolbar.Layout',
    icon: <EditorDynamicVariableIcon />,
    visible: false,
    order: orderOf('Layout'),
  },
  Edit: {
    titleKey: 'toolbar.Edit',
    icon: <OtherEditIcon />,
    visible: false,
    order: orderOf('Edit'),
  },
  Duplicate: {
    titleKey: 'toolbar.Duplicate',
    icon: <OtherCopyIcon />,
    visible: false,
    order: orderOf('Duplicate'),
  },
  RenamePlaylist: {
    titleKey: 'toolbar.Rename Playlist',
    icon: <EditorRenameIcon />,
    visible: false,
    order: orderOf('RenamePlaylist'),
  },
  ExportToPDF: {
    titleKey: 'toolbar.Export to PDF',
    icon: <TestExportIcon />,
    visible: false,
    order: orderOf('ExportToPDF'),
  },
  Settings: {
    titleKey: 'toolbar.Settings',
    icon: <ProfileSettingIcon />,
    visible: false,
    order: orderOf('Settings'),
  },
  ManageAgents: {
    titleKey: 'toolbar.Manage Agents',
    icon: <ProfileManageAIBotIcon />,
    visible: false,
    order: orderOf('ManageAgents'),
  },
  QuestionExport: {
    titleKey: 'toolbar.Export',
    icon: <TestExportIcon />,
    visible: false,
    order: orderOf('QuestionExport'),
  },
  Help: {
    titleKey: 'toolbar.Get Help',
    icon: <MainGetHelpIcon />,
    visible: false,
    order: orderOf('Help'),
  },
};

export const getToolbarButtonConfig = (
  type: ToolbarButtonType,
  customConfig: Partial<ToolbarButtonConfig> = {}
): ToolbarButtonResponsiveConfig => {
  const config = { ...TOOLBAR_CONFIG_MAP[type], ...customConfig };
  const icon = config.icon;
  if (!icon || typeof icon !== 'object' || !('mobile' in icon)) {
    config.icon = {
      mobile: icon as ReactNode,
      desktop: icon as ReactNode,
    };
  }

  const order = config.order;
  if (!order || typeof order === 'number') {
    config.order = { mobile: order, desktop: order };
  }

  const visible = config.visible;
  if (!visible || typeof visible === 'boolean') {
    config.visible = { mobile: visible, desktop: visible };
  }

  return config as ToolbarButtonResponsiveConfig;
};

export const reorderToolbarButtonItems = (
  items: ToolbarButtonItem[],
  isMobile: boolean
) => {
  items.sort((item1, item2) => {
    const item1Config = getToolbarButtonConfig(item1.type);
    const item2Config = getToolbarButtonConfig(item2.type);

    const item1Order = item1Config.order;
    const item2Order = item2Config.order;

    if (isMobile) return item1Order.mobile - item2Order.mobile;
    return item1Order.desktop - item2Order.desktop;
  });
};
