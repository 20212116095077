import { useCallback, useEffect, useMemo, useState } from 'react';
import {
  removeCriteria as removeCriteriaFn,
  resetSort as resetSortFn,
  setCriteria as setCriteriaFn,
  setFields as setFieldsFn,
  upsertCriteria as upsertCriteriaFn,
} from '@lib/ia/src/sort/reducers/sortReducer';
import { SortCriteriaConfig } from '@lib/ia/src/sort/types';
import { buildSortQuery } from '@lib/ia/src/sort/utils';
import { v4 } from 'uuid';

import useGlobalSortConfigs from '../../widgets/CommonPanels/SortPanel/hooks/useGlobalSortConfigs';
import {
  SortQuery,
  SortType,
} from '../../widgets/CommonPanels/SortPanel/types';
import { useAppDispatch, useAppSelector } from '../redux';

export function useSort(scope = 'global', type?: SortType, readonly?: boolean) {
  const configs = useGlobalSortConfigs();
  const dispatch = useAppDispatch();
  const sort = useAppSelector((st) => st.sort);

  const [sortQuery, setSortQuery] = useState<SortQuery | null>();
  const [rawQueryParams, setRawQueryParams] = useState<Record<
    string,
    string
  > | null>();

  useEffect(() => {
    const result = buildSortQuery(sort.criteria[scope] || []);
    if (result) {
      const { rawQueryParams: rawParams, ...rest } = result;
      setSortQuery(rest);
      setRawQueryParams(rawParams);
      return;
    }
    setSortQuery(null);
    setRawQueryParams(null);
  }, [sort, scope]);

  useEffect(() => {
    if (!type || readonly) return; // do nothing

    dispatch(resetSortFn({ scope }));
    setSortQuery(null);
    setRawQueryParams(null);
    const sortConfig = configs[type];
    if (sortConfig) {
      dispatch(setFieldsFn({ fields: sortConfig.fields, scope }));
    } else {
      dispatch(setFieldsFn({ fields: [], scope }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, scope]);

  const hasSort = !!sort.criteria[scope] && sort.criteria[scope].length > 0;

  const scopeSort = useMemo(() => {
    return {
      criteria: sort.criteria[scope] || [],
      fields: sort.fields[scope] || [],
    };
  }, [sort.criteria, sort.fields, scope]);

  const getCriteriaByField = useCallback(
    (fieldName: string) => {
      if (!sort.criteria[scope] || sort.criteria[scope].length === 0) {
        return;
      }
      return sort.criteria[scope].find((item) => item.field.name === fieldName);
    },
    [scope, sort.criteria]
  );

  const getFirstCriteria = useCallback(() => {
    if (!sort.criteria[scope] || sort.criteria[scope].length === 0) {
      return;
    }
    return sort.criteria[scope][0];
  }, [scope, sort.criteria]);

  const removeCriteriaByField = useCallback(
    (fieldName: string) => {
      if (!sort.criteria[scope] || sort.criteria[scope].length === 0) {
        return;
      }
      const criteria = sort.criteria[scope].find(
        (item) => item.field.name === fieldName
      );
      if (!criteria || !criteria.id) return;
      dispatch(removeCriteriaFn({ id: criteria.id, scope }));
    },
    [dispatch, scope, sort.criteria]
  );

  const replaceCriteria = useCallback(
    (sortType: SortType, fieldName: string, direction: 'asc' | 'desc') => {
      const field = configs?.[sortType]?.fields?.find(
        (f) => f.name === fieldName
      );
      if (!field) return;
      removeCriteriaByField(fieldName);

      const criteria: SortCriteriaConfig = {
        id: v4(),
        field,
        direction,
      };
      dispatch(upsertCriteriaFn({ criteria, scope }));
    },
    [configs, dispatch, removeCriteriaByField, scope]
  );

  const isCriteriaExist = useCallback(
    (fieldName: string, direction: 'asc' | 'desc') => {
      const existing = getCriteriaByField(fieldName);
      return (
        !!existing &&
        existing.field?.name === fieldName &&
        existing.direction === direction
      );
    },
    [getCriteriaByField]
  );

  return {
    sort: scopeSort,
    sortQuery,
    rawQueryParams,
    hasSort,
    setCriteria: useCallback(
      (criteria: SortCriteriaConfig[]) => {
        dispatch(setCriteriaFn({ criteria, scope }));
      },
      [dispatch, scope]
    ),
    resetSort: useCallback(() => {
      dispatch(resetSortFn({ scope }));
    }, [dispatch, scope]),
    upsertCriteria: useCallback(
      (criteria: SortCriteriaConfig) => {
        dispatch(upsertCriteriaFn({ criteria, scope }));
      },
      [dispatch, scope]
    ),
    removeCriteria: useCallback(
      (id: string) => {
        dispatch(removeCriteriaFn({ id, scope }));
      },
      [dispatch, scope]
    ),
    getCriteriaByField,
    getFirstCriteria,
    removeCriteriaByField,
    replaceCriteria,
    isCriteriaExist,
  };
}
