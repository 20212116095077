import { Box } from '@mui/material';
import EmojiText from '@lib/ia/src/components/EmojiMessage';

import IaAlbumGroup from './components/IaAlbumGroup';
import { AlbumLayoutConfig } from './types';

const styles = {
  root: {
    display: 'grid',
    gap: 3,
  },
};

type AlbumLayoutProps = AlbumLayoutConfig;

export default function AlbumLayout({
  items = [],
  groups = [],
  settings,
}: AlbumLayoutProps) {
  // filter out 'false' items
  items = items.filter((item) => !!item);
  return (
    <Box sx={styles.root}>
      <IaAlbumGroup
        items={items}
        gridTemplateColumns={settings.gridTemplateColumns}
      />
      {groups?.map((group, index) => (
        <IaAlbumGroup
          key={index}
          gridTemplateColumns={settings.gridTemplateColumns}
          {...group}
        />
      ))}
      {settings?.emptyText && items.length === 0 && groups.length === 0 && (
        <EmojiText text={settings.emptyText} />
      )}
    </Box>
  );
}
