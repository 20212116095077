import { Box } from '@mui/material';
import { ReverseTheme } from '@front/ui';

import TooltipListItem from '../../components/TooltipListItem';
import { TooltipListItemType } from '../../components/TooltipListItem/types';
import TooltipTitle from '../../components/TooltipTitle';

import { ListTooltipLayoutConfig } from './types';

const styles = {
  root: {
    width: '200px',
    p: 1,
    bgcolor: 'background.darker',
    color: 'text.primary',
    borderRadius: 1,
    display: 'grid',
    gap: 1,
  },
  properties: {
    display: 'grid',
    gap: 1,
  },
};

function isTooltipListItem(
  item: TooltipListItemType | false
): item is TooltipListItemType {
  return item !== false;
}

type ListTooltipLayoutProps = Omit<ListTooltipLayoutConfig, 'layout'>;

export default function ListTooltipLayout({
  title,
  properties,
}: ListTooltipLayoutProps) {
  return (
    <ReverseTheme>
      <Box sx={styles.root}>
        <TooltipTitle {...title} />
        <Box sx={styles.properties}>
          {properties.filter(isTooltipListItem).map((item, index) => (
            <TooltipListItem key={index} {...item} />
          ))}
        </Box>
      </Box>
    </ReverseTheme>
  );
}
