import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';

import Icon from '../../../components/Icon';

export default function NumberCardIcon({
  sx,
  children,
  size = 16,
  ...rest
}: PropsWithChildren<BoxProps & { size?: number }>) {
  return (
    <Box sx={sx} height={24} display="flex" alignItems="center" {...rest}>
      {typeof children === 'string' ? (
        <Icon name={children} width={size} height={size} />
      ) : (
        children
      )}
    </Box>
  );
}
