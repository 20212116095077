/* eslint-disable no-param-reassign */
import {
  cancelSubmit,
  clearQuizQuestionError,
  closeKeyboard,
  eliminateAnswer,
  endRedirect,
  enterProfileQuestion,
  fetchQuiz,
  finishQuiz,
  goQuestion,
  lazySubmit,
  leaveProfileQuestion,
  openKeyboard,
  pauseQuiz,
  quitQuiz,
  resumeQuiz,
  reviewQuiz,
  setProfileQuestionDirty,
  setProfileQuestionLoading,
  setProfileQuestionTargetId,
  startQuiz,
  startRedirect,
  storeTimeDuration,
  submitAllAnswers,
  submitAnswer,
  toggleSubmitButton,
  updateAnswer,
  updateHTML,
  updateNote,
} from '@app/web/src/actions/quizActions';
import { getStorageItem, setStorageItem } from '@app/web/src/utils/storage';
import { ExamMode, ExamQuestionType, QuizStatus } from '@lib/web/apis';
import { BLANK_OPTION_VALUE } from '@lib/web/practice';
import { createSlice, current } from '@reduxjs/toolkit';
import { differenceInMilliseconds } from 'date-fns';

import { QuizState, State } from './types';

const AHA_STORE_KEY = 'aha-quiz-duration';
const getTimeSpent = (startAtMilliseconds: number | null) => {
  if (!startAtMilliseconds) return 0;

  return differenceInMilliseconds(Date.now(), startAtMilliseconds);
};

const getQuestionDetails = (question: GetQuizQuestionRes) => {
  if (question.type === ExamQuestionType.Unscramble) {
    return [...question.quizQuestionDetails].sort(() => Math.random() - 0.5); // shuffle
  }
  // HACK: currently backend does not return anything, so we use the options value (but actually, we should not store and use this value in essay, need to fix it in the future to prevent confusing)
  if (question.type === ExamQuestionType.Essay) {
    return [...question.quizQuestionDetails].map((d) => ({
      ...d,
      solution: d.content,
    }));
  }
  return question.quizQuestionDetails;
};

const isLeaveBlankAnswer = (currentAnswer?: string[]) => {
  if (!currentAnswer || currentAnswer.length === 0) return false;
  return currentAnswer[0] === BLANK_OPTION_VALUE;
};

const initialState: State = {
  state: QuizState.Init,

  error: null,
  questionError: null,
  loading: false,
  loaded: false,

  startAt: null,
  totalTimeSpent: 0,
  totalTime: 0,
  totalCount: 0,

  mode: ExamMode.Unknown,

  // 所有的問題 (展開)
  mappedQuestions: {},
  mappedAnswers: {},
  mappedNotes: {},
  mappedMaterials: {},
  highlight: {},

  // quiz data
  quiz: {
    id: '',
    sectionId: '',
    practiceStationId: '',
    quizSelectRuleSetId: '',
    questionCount: 0,
    type: 0,
    practiceTimeSec: 0,
    latestVersionId: null,
    status: QuizStatus.Unknown,
    isOpenTimer: true,
  },

  isSubmitting: false, // used to disable sibling submit-exam-button (because we have 2 submit button rendered atst)
  isFinished: false,
  isCompleted: false,
  isTaken: false,
  expiredTime: null,

  mappedQuestionIds: {},
  questionNo: 0,
  roundNo: 0,

  challenge: null,
  userId: '',

  profileQuestionNo: -1,
  profileQuestionType: null,
  profileQuestionDirty: false,
  profileQuestionLoading: false,
  profileFormTargetId: '',

  eliminatedAnswers: {},
  creatorQuestionGroups: [],

  layout: {
    showKeyboard: false,
    redirectPage: null,
  },
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(quitQuiz, () => ({ ...initialState }))
      .addCase(startQuiz, (state, action) => {
        if (
          state.state === QuizState.Onboarding &&
          !action.payload.skipOnboarding
        )
          return;

        const { isFinished } = state;

        state.state = isFinished ? QuizState.Finish : QuizState.Playing;
        state.startAt = isFinished ? null : Date.now();
        const questionNo = state.questionNo || 1;
        state.questionNo = questionNo;

        const currentQuestion = state.mappedQuestions[questionNo];

        if (
          !isFinished &&
          currentQuestion &&
          !currentQuestion.isRestrictedByQuota &&
          !currentQuestion.isRestrictedHardQuestion &&
          !currentQuestion.startAt &&
          !currentQuestion.isSubmitted
        ) {
          currentQuestion.startAt = Date.now();
        }
      })
      .addCase(pauseQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const currentQuestion = state.mappedQuestions[state.questionNo];

        if (!currentQuestion.isSubmitted) {
          state.state = QuizState.Paused;

          state.totalTimeSpent += getTimeSpent(state.startAt);
          state.startAt = null;

          if (currentQuestion && currentQuestion.startAt) {
            const timeSpent =
              currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);

            currentQuestion.startAt = null;
            currentQuestion.isOvertime = timeSpent / 1000 > currentQuestion.tpq;
            currentQuestion.timeSpent = timeSpent;
            currentQuestion.isPendingSubmit = false;
          }
        }
      })
      .addCase(resumeQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const now = Date.now();
        state.state = QuizState.Playing;
        state.startAt = now;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (
          currentQuestion &&
          !currentQuestion.isRestrictedByQuota &&
          !currentQuestion.isRestrictedHardQuestion
        ) {
          currentQuestion.startAt = now;
        }
      })
      .addCase(clearQuizQuestionError, (state) => {
        state.questionError = null;
      })
      .addCase(reviewQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Review;

        const prevStartAt = state.startAt;
        if (prevStartAt) {
          state.totalTimeSpent += getTimeSpent(prevStartAt);
          state.startAt = null;
        }
      })
      .addCase(lazySubmit, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.totalTimeSpent += getTimeSpent(state.startAt);
        state.startAt = null;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (
          currentQuestion &&
          !currentQuestion.isRestrictedByQuota &&
          !currentQuestion.isRestrictedHardQuestion
        ) {
          currentQuestion.isPendingSubmit = true;

          if (currentQuestion.startAt) {
            const timeSpent =
              currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);

            currentQuestion.startAt = null;
            currentQuestion.isOvertime = timeSpent / 1000 > currentQuestion.tpq;
            currentQuestion.timeSpent = timeSpent;
          }
        }
      })
      .addCase(toggleSubmitButton, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.isSubmitting = !state.isSubmitting;
      })
      .addCase(cancelSubmit, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const now = Date.now();
        state.startAt = now;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (
          currentQuestion &&
          !currentQuestion.isRestrictedByQuota &&
          !currentQuestion.isRestrictedHardQuestion
        ) {
          currentQuestion.isPendingSubmit = false;
          currentQuestion.startAt = now;
        }
      })
      .addCase(finishQuiz, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Finish;

        if (!state.isFinished) {
          state.totalTimeSpent += getTimeSpent(state.startAt);
          state.startAt = null;
          state.isFinished = true;
        }
      })

      // step1: 取得 quiz
      .addCase(fetchQuiz.pending, (state) => ({
        ...initialState,
        questionNo: state.questionNo,
        loading: true,
      }))
      .addCase(fetchQuiz.fulfilled, (state, action) => {
        const {
          quizPractice,
          quizResults,
          roundNo,
          mode,
          userId,
          profileQuestionType,
          isOnboarding,
          sid,
        } = action.payload;

        let mappedQuestions = {};
        let mappedAnswers = {};
        let mappedQuestionIds = {};
        let totalTimeSpent = 0;
        let completedCount = 0;
        let totalTime = 0;
        let profileQuestionNo = -1;

        const storedData = getStorageItem(AHA_STORE_KEY, {});
        const quizKey = `${quizPractice.quiz.id}/${roundNo}`;

        const mappedMaterials: Record<string, GetQuizMaterialRes> =
          quizPractice.quizMaterials.reduce(
            (acc, cur) => ({
              ...acc,
              [cur.code]: cur,
            }),
            {}
          );

        quizPractice.quizQuestions.forEach((question, index) => {
          const questionNo = index + 1;
          const questionResult = quizResults.find(
            (r) => r.quizQuestionId === question.id
          );

          if (questionResult?.id) completedCount += 1;

          const updatedResult =
            questionResult && !!questionResult.id
              ? {
                  isAnswered: questionResult.isAnswered,
                  isOvertime: questionResult.isOvertime,
                  isCorrect: questionResult.isCorrect,
                  isIncorrect: !questionResult.isCorrect,
                  isSubmitted: true,
                  isLeaveBlank: questionResult.isLeaveBlank,
                  timeSpent: questionResult.duration * 1000,
                  correctAnswerIds: questionResult.correctAnswerIds || [],
                  correctAnswerValues: questionResult.correctAnswerValues || [],
                }
              : {
                  isAnswered: false,
                  isOvertime: false,
                  isCorrect: false,
                  isIncorrect: false,
                  isSubmitted: false,
                  isLeaveBlank: false,
                  timeSpent: storedData[quizKey]?.[questionNo] || 0,
                  correctAnswerIds: [],
                  correctAnswerValues: [],
                };

          if (questionResult) {
            let answerIds = questionResult.answerIds;
            if (questionResult.isLeaveBlank) {
              // the API returns answerIds=[null] when the blank option selected
              // use [BLANK_OPTION_VALUE] instead of that to synchronize with other places
              answerIds = [BLANK_OPTION_VALUE];
            }
            mappedAnswers = {
              ...mappedAnswers,
              [questionNo]: questionResult.answerValue
                ? [questionResult.answerValue]
                : answerIds || [],
            };
          } else {
            mappedAnswers = {
              ...mappedAnswers,
              [questionNo]: [],
            };
          }

          if (
            question.isRestrictedByQuota ||
            question.isRestrictedHardQuestion
          ) {
            mappedQuestions = {
              ...mappedQuestions,
              [questionNo]: {
                id: question.id,
                examQuestionSetId: question.examQuestionSetId,
                examQuestionId: question.examQuestionId,

                type: 0,
                groupIdx: 0,
                question: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s`,
                solution: null,
                isQuotaExceeded: false,
                questionDetails: [],
                refMaterialCodes: [],
                creatorId: '',
                setId: 0,

                ...updatedResult,
                isSkipped: false,
                startAt: null,
                isLoading: false,
                isRestrictedByQuota: question.isRestrictedByQuota,
                isRestrictedHardQuestion: question.isRestrictedHardQuestion,
                questionNo,
                tpq: 0,
                note: '',
                isPendingSubmit: false,
              },
            };
          } else {
            const tpq = question.practiceTimeSec;
            totalTimeSpent += updatedResult.timeSpent;
            totalTime += tpq;

            mappedQuestions = {
              ...mappedQuestions,
              [questionNo]: {
                id: question.id,
                examQuestionSetId: question.examQuestionSetId,
                examQuestionId: question.examQuestionId,
                type: question.type,
                groupIdx: question.groupIdx,
                question: question.question,
                solution: question.solution,
                isQuotaExceeded: question.isQuotaExceeded,
                questionDetails: getQuestionDetails(question),
                questionSourceType: question.sourceType,
                revision: question.revision,
                creatorQuestionInfo: question.creatorQuestionInfo,
                refMaterialCodes: question.refMaterialCodes || [],
                creatorId: question.creatorId,
                setId: question.setId,
                isDynamicDifficulty: question.isDynamicDifficulty,
                staticDifficulty: question.staticDifficulty,
                dynamicDifficulty: question.dynamicDifficulty,
                // basic states
                ...updatedResult,
                isSkipped: false,
                startAt: null,
                isLoading: false,
                isRestrictedByQuota: false,
                questionNo,
                tpq,
                note: '',
                isPendingSubmit: false,
              },
            };
          }

          mappedQuestionIds = {
            ...mappedQuestionIds,
            [question.id]: questionNo,
          };
        });

        const isFinished = completedCount === quizPractice.quizQuestions.length;
        const isCompleted = isFinished;
        const isOutdated = quizPractice.quiz.status === QuizStatus.Outdated;
        const getNewQuizStateAndLoading = () => {
          if (sid) {
            return {
              state: QuizState.Viewing,
              loaded: true,
              loading: false,
            };
          }
          if (isOnboarding) {
            return {
              state: QuizState.Onboarding,
              loaded: true,
              loading: false,
            };
          }
          if (isFinished) {
            return { state: QuizState.Finish, loaded: true, loading: false };
          }
          if (isOutdated) {
            return {
              state: QuizState.Redirecting,
              loaded: false,
              loading: true,
            };
          }

          return { state: QuizState.Pending, loaded: true, loading: false };
        };

        if (profileQuestionType) {
          profileQuestionNo = Math.round(quizPractice.quizQuestions.length / 4);
        }

        return {
          ...state,
          ...getNewQuizStateAndLoading(),
          userId,
          error: null,
          questionError: null,
          roundNo,
          mode,
          quiz: quizPractice.quiz,
          totalTime,
          totalTimeSpent,
          totalCount: quizPractice.quizQuestions.length,
          mappedQuestions,
          mappedAnswers,
          mappedQuestionIds,
          mappedMaterials,
          isFinished,
          isCompleted,
          isTaken: completedCount > 0,
          challenge: quizPractice.challenge,
          expiredTime: quizPractice.challenge?.expireAt || null,
          profileQuestionType,
          profileQuestionNo,
          creatorQuestionGroups: quizPractice.creatorQuestionGroups,
        };
      })
      .addCase(fetchQuiz.rejected, (state, action) => {
        state.loading = false;
        state.state = QuizState.Error;
        if (action.payload) {
          state.error = action.payload;
        } else {
          state.error = action.error;
        }
      })
      // step2: 到指定的題目
      .addCase(goQuestion, (state, action) => {
        if (
          state.state === QuizState.Onboarding &&
          !action.payload.skipOnboarding
        )
          return;

        const prevOrder = state.questionNo;

        if (prevOrder === action.payload.questionNo) {
          return;
        }
        const prevQuestion = state.mappedQuestions[prevOrder];
        if (
          state.state === QuizState.Playing &&
          prevQuestion &&
          prevQuestion.startAt
        ) {
          // 上一題暫停計時
          const timeSpent =
            prevQuestion.timeSpent + getTimeSpent(prevQuestion.startAt);

          prevQuestion.startAt = null;
          prevQuestion.isOvertime = timeSpent
            ? timeSpent / 1000 > prevQuestion.tpq
            : false;
          prevQuestion.timeSpent = timeSpent;
          prevQuestion.isPendingSubmit = false;
        }

        state.questionNo =
          action.payload.questionNo > state.quiz.questionCount
            ? 1
            : action.payload.questionNo || 1;

        const currentQuestion = state.mappedQuestions[state.questionNo];

        if (
          state.state === QuizState.Playing &&
          !currentQuestion.isSubmitted &&
          !currentQuestion.isRestrictedByQuota &&
          !currentQuestion.isRestrictedHardQuestion
        ) {
          currentQuestion.startAt = Date.now();
        }
      })
      // step3-1: 答題
      .addCase(updateAnswer, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedAnswers[action.payload.questionNo] = action.payload.value;
      })
      // step3-2: 作筆記
      .addCase(updateNote, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedNotes[action.payload.questionNo] = action.payload.value;
      })
      // step3-3: Passage Highlight
      .addCase(updateHTML, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.highlight = {
          ...state.highlight,
          [action.payload.code]: action.payload.value,
        };
      })
      // step3-4: Go Question
      .addCase(enterProfileQuestion, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Question;

        const currentQuestion = state.mappedQuestions[state.questionNo];

        if (currentQuestion.startAt) {
          const timeSpent =
            currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);
          currentQuestion.startAt = null;
          currentQuestion.timeSpent = timeSpent;
          currentQuestion.isOvertime =
            currentQuestion.timeSpent / 1000 > currentQuestion.tpq;
        }
      })
      .addCase(leaveProfileQuestion, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.state = QuizState.Playing;
        state.profileQuestionNo = -1;
        state.profileQuestionType = null;
      })
      .addCase(setProfileQuestionTargetId, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.profileFormTargetId = action.payload;
      })
      .addCase(setProfileQuestionDirty, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.profileQuestionDirty = action.payload;
      })
      .addCase(setProfileQuestionLoading, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.profileQuestionLoading = action.payload;
      })
      // step5-1: Submit Answer
      .addCase(submitAnswer.pending, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const currentQuestion = state.mappedQuestions[state.questionNo];
        if (!currentQuestion) return;

        currentQuestion.isLoading = true;
        currentQuestion.isPendingSubmit = false;
        if (currentQuestion.startAt) {
          const timeSpent =
            currentQuestion.timeSpent + getTimeSpent(currentQuestion.startAt);
          currentQuestion.startAt = null;
          currentQuestion.timeSpent = timeSpent;
          currentQuestion.isOvertime =
            currentQuestion.timeSpent / 1000 > currentQuestion.tpq;
        }
        const currentAnswer = state.mappedAnswers[state.questionNo];
        currentQuestion.isLeaveBlank = isLeaveBlankAnswer(currentAnswer);
      })
      .addCase(submitAnswer.fulfilled, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        const {
          quizQuestionId,
          isCorrect,
          correctAnswerIds,
          correctAnswerValues,
        } = action.payload;
        const questionNo = state.mappedQuestionIds[quizQuestionId];
        const currentQuestion = state.mappedQuestions[questionNo];
        if (!currentQuestion) return;

        currentQuestion.isLoading = false;
        currentQuestion.isCorrect = isCorrect;
        currentQuestion.isIncorrect = !isCorrect;
        currentQuestion.isSubmitted = true;
        currentQuestion.correctAnswerIds = correctAnswerIds || [];
        currentQuestion.correctAnswerValues = correctAnswerValues || [];

        // This value is always true when submitted by single
        currentQuestion.isAnswered = true;

        const allQuestions = Object.values(current(state).mappedQuestions);
        if (
          allQuestions.filter((question) => question.isSubmitted).length ===
          allQuestions.length
        ) {
          state.isFinished = true;
          state.isCompleted = true;
        }

        state.isTaken = true;
      })
      .addCase(submitAnswer.rejected, (state, action) => {
        if (action.payload?.targetId) {
          const questionNo = state.mappedQuestionIds[action.payload.targetId];
          const currentQuestion = state.mappedQuestions[questionNo];
          if (!currentQuestion) return;

          currentQuestion.isLoading = false;

          if (
            !currentQuestion.isRestrictedByQuota &&
            !currentQuestion.isRestrictedHardQuestion
          ) {
            currentQuestion.startAt = Date.now();
          }

          if (action.payload.error) {
            state.questionError = action.payload.error;
          }
        }
      })
      // step5-1: Submit All Answers
      .addCase(submitAllAnswers.pending, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedQuestions = Object.values(state.mappedQuestions).reduce(
          (acc, cur) => {
            const timeSpent =
              cur.isLoading || cur.isSubmitted
                ? cur.timeSpent
                : cur.timeSpent + getTimeSpent(cur.startAt);
            const currentAnswer = state.mappedAnswers[cur.questionNo];
            const isLeaveBlank = isLeaveBlankAnswer(currentAnswer);
            return {
              ...acc,
              [cur.questionNo]:
                cur.isLoading || cur.isSubmitted
                  ? cur
                  : {
                      ...cur,
                      isLoading: true,
                      isPendingSubmit: false,
                      isOvertime: timeSpent / 1000 > cur.tpq,
                      timeSpent,
                      startAt: null,
                      isLeaveBlank,
                    },
            };
          },
          {}
        );
        state.loading = true;
        state.totalTimeSpent += getTimeSpent(state.startAt);
        state.startAt = null;
      })
      .addCase(submitAllAnswers.fulfilled, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.mappedQuestions = Object.values(state.mappedQuestions).reduce(
          (acc, cur) => {
            const currentResult = action.payload.find(
              (result) => result.quizQuestionId === cur.id
            );
            return {
              ...acc,
              [cur.questionNo]: currentResult
                ? {
                    ...cur,
                    isLoading: false,
                    isSubmitted: true,
                    isAnswered: currentResult.isAnswered,
                    isCorrect: currentResult.isCorrect,
                    isIncorrect: !currentResult.isCorrect,
                    correctAnswerIds: currentResult.correctAnswerIds || [],
                    correctAnswerValues:
                      currentResult.correctAnswerValues || [],
                  }
                : { ...cur, isLoading: false },
            };
          },
          {}
        );
        state.loading = false;
        state.isFinished = true;
        state.isCompleted = true;
        state.isTaken = true;
      })
      .addCase(submitAllAnswers.rejected, (state, action) => {
        const { questionNo } = state;
        state.mappedQuestions = Object.values(state.mappedQuestions).reduce(
          (acc, cur) => {
            const isFailed =
              action.payload?.targetIds?.includes(cur.id) || false;

            if (questionNo === cur.questionNo) {
              return {
                ...acc,
                [cur.questionNo]: {
                  ...cur,
                  isLoading: false,
                  startAt:
                    cur.isSubmitted &&
                    !cur.isRestrictedByQuota &&
                    !cur.isRestrictedHardQuestion
                      ? null
                      : Date.now(),
                },
              };
            }

            return {
              ...acc,
              [cur.questionNo]: isFailed
                ? {
                    ...cur,
                    isLoading: false,
                  }
                : cur,
            };
          },
          {}
        );
        state.loading = false;
        state.startAt = Date.now();
        if (action.payload?.error) {
          state.questionError = action.payload.error;
        }
      })
      // step6: Save time duration
      .addCase(storeTimeDuration, (state) => {
        if (state.state === QuizState.Onboarding) return;

        const storedData = getStorageItem(AHA_STORE_KEY, {});
        const quizKey = `${state.quiz.id}/${state.roundNo}`;
        if (state.isFinished) {
          delete storedData[quizKey];
          setStorageItem(AHA_STORE_KEY, storedData);
        } else {
          const allQuestions = Object.values(state.mappedQuestions).filter(
            (question) =>
              !question.isSubmitted &&
              !!(question.timeSpent || question.startAt)
          );
          const timeSpentData = allQuestions.reduce((acc, cur) => {
            const timeSpent = cur.timeSpent + getTimeSpent(cur.startAt);

            return {
              ...acc,
              [cur.questionNo]: timeSpent,
            };
          }, {});

          const valueToSave = {
            ...storedData,
            [quizKey]: timeSpentData,
          };

          setStorageItem(AHA_STORE_KEY, valueToSave);
        }
      })
      .addCase(eliminateAnswer, (state, action) => {
        if (state.state === QuizState.Onboarding) return;

        state.eliminatedAnswers[action.payload.questionNo] =
          action.payload.value;
      })
      .addCase(openKeyboard, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.layout.showKeyboard = true;
      })
      .addCase(closeKeyboard, (state) => {
        if (state.state === QuizState.Onboarding) return;

        state.layout.showKeyboard = false;
      })
      .addCase(startRedirect, (state, action) => {
        state.layout.redirectPage = action.payload;
      })
      .addCase(endRedirect, (state) => {
        state.layout.redirectPage = null;
      });
  },
});

export default quizSlice.reducer;
