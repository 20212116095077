import { Fragment, useEffect } from 'react';
import { useBaseRightPanel } from '@front/ui';
import { CUSTOM_BR } from '@lib/web/composer';

export default function Content({
  id,
  text,
  onDelete,
  updateAttributes,
}: {
  id: string;
  text: string;
  onDelete?: () => void;
  updateAttributes: (attributes: Record<string, any>) => void;
}) {
  const { rightPanelParams, setRightParams } = useBaseRightPanel();
  const { activeAnchorId, hoverAnchorId, deleteAnchorId, pendingUpdate } =
    rightPanelParams;

  const isHover = id === hoverAnchorId;
  const isActive = id === activeAnchorId;
  const isDeleting = id === deleteAnchorId;

  const updateText = pendingUpdate?.[id];

  useEffect(() => {
    if (isDeleting) {
      onDelete?.();

      setRightParams((st) => ({
        ...st,
        deleteAnchorId: null,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDeleting]);

  useEffect(() => {
    if (updateText) {
      updateAttributes({ value: updateText });
      setRightParams((st) => ({
        ...st,
        pendingUpdate: {
          ...st?.pendingUpdate,
          [id]: null,
        },
      }));
    }
  }, [id, setRightParams, updateAttributes, updateText]);

  return text.split(CUSTOM_BR).map((split, index, array) => (
    <Fragment key={index}>
      <span
        className="inline-anchor-content"
        style={{
          textDecoration: 'underline',
          marginLeft: 4,
          backgroundColor: isHover || isActive ? 'rgba(0, 163, 255, 0.5)' : '',
        }}
      >
        {split}
      </span>
      {index !== array.length - 1 && <br />}
    </Fragment>
  ));
}
