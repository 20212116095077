import { useCallback, useMemo } from 'react';
import { nonNullable } from '@front/helper';
import {
  buildHookMutate,
  QueryThreadViewType,
  ThreadViewOrderBy,
  ThreadViewSortBy,
  useAuth,
  useThreadViews,
} from '@lib/web/apis';
import { useDefaultDmEveryoneThreadView } from '@lib/web/thread/hooks/defaultView/useDefaultDmEveryoneThreadView';

import { parseFilterConfig } from '../../utils/viewUtils';
import { useDefaultDmSelfThreadView } from '../defaultView/useDefaultDmSelfThreadView';
import { useSuggestionThreadViews } from '../defaultView/useSuggestionThreadViews';

export const useDirectMessageViews = () => {
  const { member } = useAuth();
  const { data, isLoading, mutate } = useThreadViews({
    type: QueryThreadViewType.DmAll,
    sortBy: ThreadViewSortBy.Desc,
    orderBy: ThreadViewOrderBy.CreatedAt,
  });
  const dataViews = useMemo(() => data?.data || [], [data?.data]);
  const { defaultSelfView, defaultSelfViewHasChannels } =
    useDefaultDmSelfThreadView();

  const { defaultEveryoneView } = useDefaultDmEveryoneThreadView();

  const isSelfView = useCallback(
    (view: GetThreadViewRes): boolean => {
      const { memberIds } = parseFilterConfig(
        view.threadViewUsers[0]?.filterConfig
      );
      return memberIds.length === 1 && memberIds[0] === member?.memberId;
    },
    [member?.memberId]
  );

  const isMultiMemberView = useCallback(
    (view: GetThreadViewRes): boolean => {
      const { memberIds } = parseFilterConfig(
        view.threadViewUsers[0]?.filterConfig
      );
      return memberIds.length > 1 && memberIds.includes(member?.memberId ?? '');
    },
    [member?.memberId]
  );

  const views = useMemo<GetThreadViewRes[]>(() => {
    const selfViews = dataViews.filter(isSelfView);
    const otherDMViews = dataViews.filter(isMultiMemberView);

    return [
      defaultEveryoneView,
      selfViews[0] ?? defaultSelfView,
      ...otherDMViews,
    ].filter(nonNullable);
  }, [
    dataViews,
    defaultEveryoneView,
    defaultSelfView,
    isMultiMemberView,
    isSelfView,
  ]);

  const reloadViews = useMemo(() => buildHookMutate(mutate), [mutate]);

  // How do we fetch suggestion is not listed in PRF-1020, we can check the logic if other PRF describe for this
  const suggestionViews = useSuggestionThreadViews({
    showSuggestions: views.length === 1, // when only self view, show the suggestions
  });

  const findDmView = useCallback(
    (viewId: string) => {
      return (
        views.find((v) => v.id === viewId) ||
        suggestionViews.find((v) => v.id === viewId)
      );
    },
    [suggestionViews, views]
  );

  return {
    views,
    suggestionViews,
    firstHasChannelViewId: dataViews[0]
      ? dataViews[0]?.id
      : defaultSelfViewHasChannels
      ? defaultSelfView.id
      : null,
    isLoading,
    reloadViews,
    findDmView,
  };
};
