import { AnswerFormatType } from '@lib/web/apis';

import { SupportedAnswerFormatType } from '../widgets/ClubsPage/EditClub/contexts/type';

export const answerFormatConfig: Record<
  SupportedAnswerFormatType,
  { text: string; icon: string }
> = {
  [AnswerFormatType.Essay]: { text: 'Essay', icon: 'EditorEssay' },
  [AnswerFormatType.FreeResponse]: {
    text: 'Free Response',
    icon: 'EditorFreeResponse',
  },
  [AnswerFormatType.GridIn]: { text: 'Grid-In', icon: 'EditorGridIn' },
  [AnswerFormatType.MultipleChoice]: {
    text: 'Multiple Choice',
    icon: 'EditorMCQ',
  },
  [AnswerFormatType.MultipleResponse]: {
    text: 'Multiple Response',
    icon: 'EditorMRQ',
  },
  [AnswerFormatType.TrueFalseNotGiven]: {
    text: 'True, False, Not Given',
    icon: 'EditorTFNG',
  },
  [AnswerFormatType.Unscramble]: {
    text: 'Unscramble',
    icon: 'EditorUnscramble',
  },
};
