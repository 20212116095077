import { useCallback } from 'react';
import { ComposerBlock } from '@lib/web/composer';
import {
  useCreatorQuestionDetailHandle,
  useCurrentQuestion,
} from '@lib/web/editor';

export const useEventHandle = () => {
  const { questionId } = useCurrentQuestion();
  const { handleCreatorQuestionDetailChange } =
    useCreatorQuestionDetailHandle(questionId);

  const handleBlocksChange = useCallback(
    async (id: string, blocks: ComposerBlock[]) => {
      void handleCreatorQuestionDetailChange(id, blocks);
    },
    [handleCreatorQuestionDetailChange]
  );
  return {
    handleBlocksChange,
  };
};
