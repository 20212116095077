import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from '@front/ui';
import {
  apis,
  CreatorQuestionReviewStatus,
  CreatorQuestionStatus,
  StructureType,
} from '@lib/web/apis';
import { useCreatorQuestionListData } from '@lib/web/editor/hooks/useCreatorQuestionListData';
import { callWithToast } from '@lib/web/utils';

const useHandleApproveClick = () => {
  const { reloadQuestions, selectGroupIdFromSubQuestion, selectQuestion } =
    useCreatorQuestionListData();

  return async (
    triggerQuestion: GetCreatorQuestionListRes | GetCreatorQuestionGroupItemRes
  ) => {
    const question =
      triggerQuestion.structureType === StructureType.SubQuestion
        ? selectQuestion(selectGroupIdFromSubQuestion(triggerQuestion.id)) // use its group question
        : triggerQuestion;

    if (!question) {
      console.warn('cannot find question when calling handleApproveClick');
      return;
    }

    if (question.structureType === StructureType.QuestionGroup) {
      const checked = await toast.confirm(
        'Approve group will approve all questions that are inside of this group.',
        {
          confirmText: 'Approve',
          type: 'primary',
          desktopSx: { maxWidth: 335 },
          anchorEl: document.querySelector(
            '[data-testid="approve-button"]'
          ) as Element,
        }
      );

      if (!checked) {
        return;
      }
    }

    const [res] = await callWithToast(
      () => apis.editor.approveCreatorQuestion({ id: question.id }),
      {
        successMsg: 'Question Approved!',
        errorMsg: 'Approve Failed',
        confirmMsg:
          'Once you approve a published question, the question will be added to Aha’s QBank.',
        confirmText: 'Approve',
        confirmOptions: {
          type: 'primary',
          desktopSx: { maxWidth: 335 },
        },
        anchorEl: document.querySelector(
          '[data-testid="approve-button"]'
        ) as Element,
      }
    );
    if (res) {
      void reloadQuestions();
    }
  };
};

const useHandleBatchApproveClick = () => {
  const { reloadQuestions, selectQuestion } = useCreatorQuestionListData();

  const getConfirmMessage = (ids: string[]) => {
    for (const id of ids) {
      const question = selectQuestion(id);
      if (question?.structureType === StructureType.QuestionGroup) {
        return 'Approve group will approve all questions that are inside of this group.';
      }
      return 'Once you approve a published question, the question will be added to Aha’s QBank.';
    }
    return null;
  };

  return async (ids: string[]) => {
    const confirmMsg = getConfirmMessage(ids);

    if (confirmMsg) {
      const checked = await toast.confirm(confirmMsg, {
        confirmText: 'Approve',
        type: 'primary',
        desktopSx: { maxWidth: 335 },
      });

      if (!checked) {
        return;
      }
    }

    // instead of send query at a time, approve api is heavy, so we do it sequentially
    let anyOfIdSuccess = false;
    for (const id of ids) {
      const [res] = await callWithToast(
        () => apis.editor.approveCreatorQuestion({ id }),
        {
          successMsg: 'Question Approved!',
          errorMsg: 'Approve Failed',
        }
      );
      if (res) anyOfIdSuccess = true;
    }

    if (anyOfIdSuccess) {
      void reloadQuestions();
    }
  };
};

const useHandleRejectClick = () => {
  const { reloadQuestions, selectGroupIdFromSubQuestion, selectQuestion } =
    useCreatorQuestionListData();

  return async (
    triggerQuestion: GetCreatorQuestionListRes | GetCreatorQuestionGroupItemRes
  ) => {
    const question =
      triggerQuestion.structureType === StructureType.SubQuestion
        ? selectQuestion(selectGroupIdFromSubQuestion(triggerQuestion.id)) // use its group question
        : triggerQuestion;

    if (!question) {
      console.warn('cannot find question when calling handleRejectClick');
      return;
    }

    if (question.structureType === StructureType.QuestionGroup) {
      const checked = await toast.confirm(
        'Reject group will reject all questions that are inside of this group.',
        {
          confirmText: 'Reject',
          type: 'primary',
          desktopSx: { maxWidth: 335 },
          anchorEl: document.querySelector(
            '[data-testid="approve-button"]' // approve button is on the right, which is what we want
          ) as Element,
        }
      );

      if (!checked) {
        return;
      }
    }

    const [res] = await callWithToast(
      () => apis.editor.rejectCreatorQuestion({ id: question.id }),
      {
        successMsg: 'Question Rejected!',
        errorMsg: 'Reject Failed',
        anchorEl: document.querySelector(
          '[data-testid="approve-button"]' // approve button is on the right, which is what we want
        ) as Element,
      }
    );
    if (res) {
      void reloadQuestions();
    }
  };
};

const useHandleBatchRejectClick = () => {
  const { reloadQuestions, selectQuestion } = useCreatorQuestionListData();

  const getConfirmMessage = (ids: string[]) => {
    for (const id of ids) {
      const question = selectQuestion(id);
      if (question?.structureType === StructureType.QuestionGroup) {
        return 'Reject group will reject all questions that are inside of this group.';
      }
    }
    return null;
  };

  return async (ids: string[]) => {
    const confirmMsg = getConfirmMessage(ids);

    if (confirmMsg) {
      const checked = await toast.confirm(confirmMsg, {
        confirmText: 'Reject',
        type: 'primary',
        desktopSx: { maxWidth: 335 },
      });

      if (!checked) {
        return;
      }
    }

    const [res] = await callWithToast(
      ids.map((id) => apis.editor.rejectCreatorQuestion({ id })),
      {
        successMsg: 'Question Rejected!',
        errorMsg: 'Reject Failed',
      }
    );
    if (res) {
      void reloadQuestions();
    }
  };
};

export const useApproveRejectQuestion = () => {
  const { t } = useTranslation('editor');
  const { selectQuestion } = useCreatorQuestionListData();
  const handleApproveClick = useHandleApproveClick();
  const handleRejectClick = useHandleRejectClick();
  const handleBatchApproveClick = useHandleBatchApproveClick();
  const handleBatchRejectClick = useHandleBatchRejectClick();

  const getApproveButtonAttrs = useCallback(
    (id: string) => {
      const question = selectQuestion(id);

      if (!question) {
        return {
          handleApproveClick: () => {},
          approveButtonText: '',
          showApproveOption: false,
          disableApproveButton: true,
        };
      }

      const isGroup = [
        StructureType.QuestionGroup,
        StructureType.SubQuestion,
      ].includes(question.structureType);

      const isApproved =
        question.reviewStatus === CreatorQuestionReviewStatus.Approved;

      return {
        handleApproveClick: () => handleApproveClick(question),
        approveButtonText: isApproved
          ? t('Approved')
          : isGroup
          ? t('Approve Group')
          : t('Approve'),
        showApproveOption:
          ![CreatorQuestionStatus.ChangeUnpublished].includes(
            question.status
          ) &&
          [
            CreatorQuestionReviewStatus.Rejected,
            CreatorQuestionReviewStatus.PendingReview,
            CreatorQuestionReviewStatus.Reviewing,
            CreatorQuestionReviewStatus.PendingReapprove,
            CreatorQuestionReviewStatus.ReapproveReviewing,
          ].includes(question.reviewStatus),
        disableApproveButton: isApproved,
      };
    },
    [handleApproveClick, selectQuestion, t]
  );

  const getRejectButtonAttrs = useCallback(
    (id: string) => {
      const question = selectQuestion(id);

      if (!question) {
        return {
          handleRejectClick: () => {},
          rejectButtonText: '',
          showRejectOption: false,
          disableRejectButton: true,
        };
      }

      const isGroup = [
        StructureType.QuestionGroup,
        StructureType.SubQuestion,
      ].includes(question.structureType);

      const isRejected =
        question.reviewStatus === CreatorQuestionReviewStatus.Rejected;

      return {
        handleRejectClick: () => handleRejectClick(question),
        rejectButtonText: isRejected
          ? t('Rejected')
          : isGroup
          ? t('Reject Group')
          : t('Reject'),
        showRejectOption:
          ![CreatorQuestionStatus.ChangeUnpublished].includes(
            question.status
          ) &&
          [
            CreatorQuestionReviewStatus.Approved,
            CreatorQuestionReviewStatus.PendingReview,
            CreatorQuestionReviewStatus.Reviewing,
            CreatorQuestionReviewStatus.PendingReapprove,
            CreatorQuestionReviewStatus.ReapproveReviewing,
          ].includes(question.reviewStatus),
        disableRejectButton: isRejected,
      };
    },
    [handleRejectClick, selectQuestion, t]
  );

  return {
    getApproveButtonAttrs,
    getRejectButtonAttrs,
    handleBatchApproveClick,
    handleBatchRejectClick,
  };
};
