import { useCallback } from 'react';
import { useQueueWorker } from '@front/helper';
import { apis } from '@lib/web/apis';
import { ComposerBlock } from '@lib/web/composer';
import { useJoinChannel } from '@lib/web/thread/hooks/channel/useJoinChannel';
import { useTokenBalanceControl } from '@lib/web/thread/hooks/channel/useTokenBalanceControl';
import { useBuildMessageData } from '@lib/web/thread/hooks/message/useBuildMessageData';
import {
  getMentionMemberIdsFromBlocks,
  getNewMentionMemberIdsFromBlocks,
} from '@lib/web/thread/ThreadTextComposer';
import { StreamChatGenerics } from '@lib/web/thread/types';
import {
  getAssignedAgentId,
  maybeUnmuteSelfWhenSentMessageToChildChannel,
} from '@lib/web/thread/utils/channelUtils';
import { call } from '@lib/web/utils';
import { uniq } from 'lodash';
import { Channel } from 'stream-chat';

type SendMessageToChannelParam = {
  text: string;
  blocks: ComposerBlock[];
  agentId: string | undefined;
  channel: Channel<StreamChatGenerics>;
  channelMemberIds?: string[];
  customId?: string;
};

type SendMessageToChannelReturn = {
  viewAfterId: string | null;
};

export const useSendMessageToChannel = () => {
  const { buildMessageData } = useBuildMessageData();
  const { maybeSendUpgradePlanMessage } = useTokenBalanceControl();
  const { joinChannelIfNotAMember } = useJoinChannel();
  const { addTask } = useQueueWorker();

  const sendMessageToChannel = useCallback(
    async ({
      text,
      blocks,
      agentId,
      channel,
      channelMemberIds,
      customId,
    }: SendMessageToChannelParam): Promise<SendMessageToChannelReturn> => {
      // for example : message like 'hi @xxx, @yyy, @zzz, what do you think ? ', mentionMemberIds: xxx,yyy,zzz
      const mentionMemberIds = getMentionMemberIdsFromBlocks(blocks);
      // assume xxx is already in channel, notInChannelMentionMemberIds : yyy, zzz
      const notInChannelMentionMemberIds =
        getNewMentionMemberIdsFromBlocks(blocks);
      // inChannelMentionMemberIds is xxx
      const inChannelMentionMemberIds = mentionMemberIds.filter(
        (id) => !notInChannelMentionMemberIds.includes(id)
      );
      const inChannelMentionUserMemberIds = inChannelMentionMemberIds.filter(
        (id) => !id.startsWith('agent_')
      );
      const inChannelMentionAgentMemberIds = inChannelMentionMemberIds.filter(
        (id) => id.startsWith('agent_')
      );

      const selectedAgentMemberId = agentId ? `agent_${agentId}` : null;
      const newMemberIds = uniq([
        ...notInChannelMentionMemberIds,
        ...(selectedAgentMemberId &&
        !channelMemberIds?.includes(selectedAgentMemberId)
          ? [selectedAgentMemberId]
          : []),
      ]);

      /**
       * must mention ai before message sent, then ai will response to this message
       */
      const assignedAgentId =
        agentId || getAssignedAgentId(inChannelMentionAgentMemberIds);
      if (assignedAgentId) {
        await call(
          apis.thread.mentionAi({
            agentId: assignedAgentId,
            channelType: channel.type as 'team' | 'public',
            channelId: channel.id as string,
          })
        );
      }

      let viewAfterId = null;
      /**
       * must add member before message sent, then new user will get this message notification
       */
      if (newMemberIds.length > 0) {
        const [inviteMemberResult] = await call(
          apis.thread.inviteMember({
            channelType: channel.type as 'team' | 'public',
            channelId: channel.id as string,
            memberIds: newMemberIds,
          })
        );
        if (inviteMemberResult?.data.afterInvitedDmViewId) {
          viewAfterId = inviteMemberResult.data.afterInvitedDmViewId;
        }
      }

      /**
       * must mention user before message sent
       */
      if (inChannelMentionUserMemberIds.length > 0) {
        await call(
          apis.thread.mentionUsers({
            memberIds: inChannelMentionUserMemberIds,
            channelType: channel.type as 'team' | 'public',
            channelId: channel.id as string,
          })
        );
      }

      await channel.sendMessage({
        customId,
        ...buildMessageData({
          text,
          blocks,
        }),
      });

      /**
       * after message sent, we fire the invitation system message
       */
      if (newMemberIds.length > 0) {
        await call(
          apis.thread.systemMessageMemberInvited({
            channelType: channel.type as 'team' | 'public',
            channelId: channel.id as string,
            memberIds: newMemberIds,
          })
        );
      }

      joinChannelIfNotAMember(channel);

      void maybeUnmuteSelfWhenSentMessageToChildChannel(channel);

      void maybeSendUpgradePlanMessage(channel);

      return {
        viewAfterId,
      };
    },
    [buildMessageData, joinChannelIfNotAMember, maybeSendUpgradePlanMessage]
  );

  /**
   * Instead of directly using sendMessageToChannel,
   * we need a queue to execute them sequentially.
   * This is because we might receive a series of message-sending requests,
   * some of which take longer to execute (e.g., needing to invite someone).
   * If we don't use this queue to handle the requests, it might cause incorrect sending order.
   */
  const addSendMessageToChannelTask = useCallback(
    async (param: SendMessageToChannelParam) => {
      return new Promise<SendMessageToChannelReturn>((resolve) => {
        addTask({
          scope: 'sendMessageToChannel',
          taskKey: param.channel.cid,
          task: async () => {
            resolve(await sendMessageToChannel(param));
          },
        });
      });
    },
    [addTask, sendMessageToChannel]
  );

  return {
    sendMessageToChannel: addSendMessageToChannelTask,
  };
};
