import { answerFormatConfig } from '@app/web/src/config/answerFormat';
import { PropertyType } from '@front/ui';
import { FilterFieldsConfig } from '@lib/ia/src/filter/types';
import { ClubJoinedStatus, ClubOfficialType, ClubStatus } from '@lib/web/apis';
import { sortBy } from 'lodash';

import { FilterType } from '../../../../types/filter';

const GlobalFilterConfigs: Record<FilterType, FilterFieldsConfig | null> = {
  club: {
    fields: [
      {
        label: 'Club Name',
        name: 'clubName',
        type: PropertyType.ShortText,
        placeholder: 'Type a value...',
      },
      {
        label: 'Club Status',
        name: 'clubStatus',
        type: PropertyType.Status,
        placeholder: 'Select one or more options...',
        options: [
          {
            label: 'Active',
            value: ClubStatus.Active,
            color: 'success',
          },
          {
            label: 'Coming Soon',
            value: ClubStatus.ComingSoon,
            color: 'default',
          },
          {
            label: 'Beta',
            value: ClubStatus.Beta,
            color: 'warning',
          },
        ],
      },
      {
        label: 'Join Status',
        name: 'joinStatus',
        type: PropertyType.Status,
        placeholder: 'Select one or more options...',
        options: [
          {
            label: 'Joined',
            value: ClubJoinedStatus.Joined,
            color: 'success',
          },
          {
            label: 'Not Joined',
            value: ClubJoinedStatus.NotJoined,
            color: 'default',
          },
        ],
      },
      {
        label: 'Answer Format Type',
        name: 'answerFormatType',
        type: PropertyType.MultiSelect,
        excludeOperators: ['IsEmpty'],
        placeholder: 'Select one or more options...',
        options: sortBy(
          Object.entries(answerFormatConfig).map(([key, value]) => ({
            label: value.text,
            value: Number(key),
          })),
          (option) => option.label
        ),
      },
      {
        label: 'Club Creator',
        name: 'clubCreator:clubCreatorSourceId',
        type: PropertyType.Person,
        placeholder: 'Select one or more options...',
      },
      {
        label: 'Club Type',
        name: 'officialType',
        type: PropertyType.Select,
        customIcon: 'PropertyTypeStatus',
        options: [
          {
            label: 'Official',
            value: ClubOfficialType.Official,
          },
          {
            label: 'Non-Official',
            value: ClubOfficialType.NonOfficial,
          },
        ],
      },
    ],
  },
  agent: {
    fields: [
      {
        label: 'Bot Name',
        name: 'agentName',
        type: PropertyType.ShortText,
        placeholder: 'Type a value...',
      },
      {
        label: 'Bot Username',
        name: 'agentUserName',
        type: PropertyType.ShortText,
        placeholder: 'Type a value...',
      },
      {
        label: 'Short Description',
        name: 'shortDesc',
        type: PropertyType.ShortText,
        placeholder: 'Type a value...',
      },
      {
        label: 'Long Description',
        name: 'longDesc',
        type: PropertyType.LongText,
        placeholder: 'Type a value...',
      },
      {
        label: 'Bot User Count',
        name: 'userCount',
        type: PropertyType.Number,
      },
      {
        label: 'Bot Thread Count',
        name: 'threadCount',
        type: PropertyType.Number,
      },
      {
        label: 'Bot Creator',
        name: 'agentCreator',
        type: PropertyType.Person,
        placeholder: 'Select one or more options...',
      },
    ],
  },
  playlist: {
    fields: [
      {
        label: 'Answer Result',
        name: 'practiceResult',
        type: PropertyType.Select,
        customIcon: 'TextEditorCheck',
        options: [
          {
            label: 'Correct',
            value: 1,
          },
          {
            label: 'Mistake',
            value: -1,
          },
        ],
      },
      {
        label: 'Overtime Status',
        name: 'overtimeStatus',
        type: PropertyType.Status,
        options: [
          {
            label: 'Overtime',
            value: -1,
            color: 'warning',
          },
          {
            label: 'Not overtime',
            value: 1,
            color: 'default',
          },
        ],
      },
      {
        label: 'Question Number',
        name: 'questionNumber',
        type: PropertyType.Number,
      },
      {
        label: 'Question Content',
        name: 'questionContent',
        type: PropertyType.LongText,
      },
      {
        label: 'Reaction',
        name: 'emojiCode',
        type: PropertyType.Icon,
      },
    ],
  },
  marketplace: {
    fields: [
      {
        label: 'Emoji',
        name: 'emojiCode',
        type: PropertyType.Icon,
      },
      {
        label: 'Price',
        name: 'price',
        type: PropertyType.Number,
        customIcon: 'OtherDollar',
      },
    ],
  },
  quiz: {
    fields: [
      {
        label: 'Quiz Name',
        name: 'name',
        type: PropertyType.ShortText,
        placeholder: 'Type a value...',
      },
      {
        label: 'Quiz Content',
        name: 'content',
        type: PropertyType.LongText,
      },
      {
        label: 'Quiz Tokens',
        name: 'tokenNum',
        type: PropertyType.Number,
      },
      {
        label: 'Joined Status',
        name: 'joinedStatus',
        type: PropertyType.Status,
        placeholder: 'Select one or more options...',
        options: [
          {
            label: 'Joined',
            value: 1,
            color: 'success',
          },
          {
            label: 'Not Joined',
            value: -1,
            color: 'default',
          },
        ],
      },
    ],
  },
  creatorQuiz: {
    fields: [
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
    ],
  },
  emojiQuiz: {
    fields: [
      {
        label: 'Playlist Emoji',
        name: 'emoji',
        type: PropertyType.Icon,
        customIcon: 'EmojiFavorite',
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsEmpty',
          'IsNotEmpty',
        ],
        extensionOperators: ['Eq'],
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
    ],
  },
  hashtagQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'hashtag',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
    ],
  },
  followingQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'name',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'owner',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Score',
        name: 'name',
        type: PropertyType.Number,
        customIcon: 'TestScoreTally',
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
      {
        label: 'Challenge Status',
        name: 'challengeInfo:category',
        type: PropertyType.Status,
        excludeOperators: ['Is', 'IsNot'],
        options: [],
      },
    ],
  },
  historyQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Mode',
        name: 'mode',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: 'Practice' },
          { value: 2, label: 'Mock Exam' },
        ],
      },
      {
        label: 'Playlist Type',
        name: 'type',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 10, label: 'Not Challenge' },
          { value: 4, label: 'Challenge' },
        ],
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
      {
        label: 'Saved Status',
        name: 'savedStatus',
        type: PropertyType.Status,
        options: [
          { value: 1, label: 'Saved', color: 'blue' },
          { value: -1, label: 'Unsaved', color: 'default' },
        ],
      },
      {
        label: 'Challenge Status',
        name: 'challengeInfo:category',
        type: PropertyType.Status,
        options: [
          { value: 2, label: 'Ongoing', color: 'green' },
          { value: -1, label: 'Expired', color: 'default' },
        ],
      },
      {
        label: 'Challenge Attempts',
        name: 'challengeInfo:attempts',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          { value: -1, label: 'Unlimited' },
        ],
      },
      {
        label: 'Challenge End Date',
        name: 'challengeInfo:endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Challengers',
        name: 'challengeInfo:challengers',
        type: PropertyType.Person,
      },
      {
        label: 'Challenge Rank',
        name: 'challengeInfo:rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
    ],
  },
  challengeQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Mode',
        name: 'mode',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: 'Practice' },
          { value: 2, label: 'Mock Exam ' },
        ],
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
      {
        label: 'Saved Status',
        name: 'savedStatus',
        type: PropertyType.Status,
        options: [
          { value: 1, label: 'Saved', color: 'blue' },
          { value: -1, label: 'Unsaved', color: 'default' },
        ],
      },
      {
        label: 'Challenge Attempts',
        name: 'challengeInfo:attempts',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          { value: -1, label: 'Unlimited' },
        ],
      },
      {
        label: 'Challenge End Date',
        name: 'endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Challengers',
        name: 'challengers',
        type: PropertyType.Person,
      },
      {
        label: 'Challenge Rank',
        name: 'rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
    ],
  },
  bookmarkQuiz: {
    fields: [
      {
        label: 'Playlist Name',
        name: 'playlistName',
        type: PropertyType.ShortText,
      },
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Last Updated Time',
        name: 'updatedTime',
        type: PropertyType.DateOrTime,
      },
      {
        label: 'Mode',
        name: 'mode',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: 'Practice' },
          { value: 2, label: 'Mock Exam ' },
        ],
      },
      {
        label: 'Playlist Type',
        name: 'type',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 10, label: 'Not Challenge' },
          { value: 4, label: 'Challenge' },
        ],
      },
      {
        label: 'Total Questions',
        name: 'questionCount',
        type: PropertyType.Number,
        customIcon: 'ChatQuestionList',
      },
      {
        label: 'Mistake Rate',
        name: 'mistakeRate',
        type: PropertyType.Number,
        customIcon: 'OtherWarning',
      },
      {
        label: 'Overtime Rate',
        name: 'overtimeRate',
        type: PropertyType.Number,
        customIcon: 'TestClock',
      },
      {
        label: 'Saved Status',
        name: 'savedStatus',
        type: PropertyType.Status,
        options: [
          { value: 1, label: 'Saved', color: 'blue' },
          { value: -1, label: 'Unsaved', color: 'default' },
        ],
      },
      {
        label: 'Challenge Status',
        name: 'challengeInfo:status',
        type: PropertyType.Status,
        options: [
          { value: 2, label: 'Ongoing', color: 'green' },
          { value: -1, label: 'Expired', color: 'default' },
        ],
      },
      {
        label: 'Challenge Attempts',
        name: 'challengeInfo:attempts',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: '1' },
          { value: 2, label: '2' },
          { value: 3, label: '3' },
          { value: 4, label: '4' },
          { value: 5, label: '5' },
          { value: -1, label: 'Unlimited' },
        ],
      },
      {
        label: 'Challenge End Date',
        name: 'challengeInfo:endDate',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Challengers',
        name: 'challengeInfo:challengers',
        type: PropertyType.Person,
      },
      {
        label: 'Challenge Rank',
        name: 'challengeInfo:rank',
        type: PropertyType.Number,
        customIcon: 'TestTrophy',
      },
    ],
  },
  discountCodeManagement: {
    fields: [
      {
        label: 'Discount Code',
        name: 'code',
        type: PropertyType.ShortText,
      },
      {
        label: 'Discount Type',
        name: 'discountType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 1, label: 'Percent Off' },
          { value: 2, label: 'Amount Off' },
        ],
      },
      {
        label: 'Value',
        name: 'discountVal',
        type: PropertyType.Number,
      },
      {
        label: 'Usage Type',
        name: 'usageType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: -1, label: 'Unlimited' },
          { value: 1, label: 'Limited' },
        ],
      },
      {
        label: 'Usage Limit',
        name: 'redeemLimit',
        type: PropertyType.Number,
      },
      {
        label: 'Purchase Type',
        name: 'purchaseType',
        type: PropertyType.Select,
        customIcon: 'OtherMultiselect',
        options: [
          { value: 0, label: 'All' },
          { value: 1, label: 'Avatar Only' },
          { value: 2, label: 'Subscription Only' },
        ],
      },
      {
        label: 'Start Date',
        name: 'startedAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Expiration Date',
        name: 'expiredAt',
        type: PropertyType.DateOrTime,
        customIcon: 'OtherCalendarDay',
      },
      {
        label: 'Created By',
        name: 'creator:memberId',
        type: PropertyType.Person,
        useMemberId: true,
      },
    ],
  },
  threadChannel: {
    fields: [
      {
        label: 'Created By',
        name: 'creator',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsEmpty',
          'IsNotEmpty',
        ],
        extensionOperators: ['Is'],
      },
      {
        label: 'Created Time',
        name: 'createdTime',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
      {
        label: 'Visibility',
        name: 'visibility',
        type: PropertyType.Select,
        customIcon: 'LoginSee',
        options: [
          { value: 'public', label: 'Public' },
          { value: 'team', label: 'Private' },
        ],
        excludeOperators: [
          'IsEmpty',
          'IsNotEmpty',
          'IsSelectEqual',
          'IsSelectNotEqual',
          'IsSelectIn',
        ],
        extensionOperators: ['Is'],
      },
      {
        label: 'Members',
        name: 'members',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: ['DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
        customIcon: 'PrivacyFriends',
      },
      {
        label: 'Active Members',
        name: 'customActiveMembers',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: ['DoesNotContains', 'IsEmpty', 'IsNotEmpty'],
        customIcon: 'PrivacyFriends',
      },
      {
        label: 'Member Count',
        name: 'memberCount',
        type: PropertyType.Number,
        excludeOperators: ['Neq', 'IsEmpty', 'IsNotEmpty', 'Between', 'IsIn'],
        customIcon: 'PrivacyFriends',
      },
      {
        label: 'Replies',
        name: 'customReplyCount',
        type: PropertyType.Number,
        excludeOperators: [
          'Neq',
          'Gt',
          'Lt',
          'Gte',
          'Lte',
          'IsEmpty',
          'IsNotEmpty',
          'Between',
          'IsIn',
        ],
        customIcon: 'ThreadsThread',
      },
      {
        label: 'Last activity Time',
        name: 'lastActivityTime',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
    ],
  },
  threadMessage: {
    fields: [
      {
        label: 'Message Text',
        name: 'messageText',
        type: PropertyType.ShortText,
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsNot',
          'StartsWith',
          'EndsWith',
          'IsEmpty',
          'IsNotEmpty',
        ],
      },
      {
        label: 'Reply Count',
        name: 'replyCount',
        type: PropertyType.Number,
        excludeOperators: ['Neq', 'IsEmpty', 'IsNotEmpty', 'Between', 'IsIn'],
      },
      {
        label: 'User',
        name: 'userId',
        type: PropertyType.Person,
        useMemberId: true,
        excludeOperators: [
          'Contains',
          'DoesNotContains',
          'IsEmpty',
          'IsNotEmpty',
        ],
        extensionOperators: ['Is'],
      },
      {
        label: 'Created Time',
        name: 'createdAt',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
      {
        label: 'Updated Time',
        name: 'updatedAt',
        type: PropertyType.DateOrTime,
        excludeOperators: [
          'IsBetweenTime',
          'IsRelativeToToday',
          'IsEmpty',
          'IsNotEmpty',
          'IsEqualTime',
        ],
      },
    ],
  },
};

export default function useGlobalFilterConfigs() {
  // NOTE: in the future we can use other hooks here to get dynamic options from API,...
  return GlobalFilterConfigs;
}

export function usePlaylistFilterConfigs() {
  const filterFields = GlobalFilterConfigs.playlist?.fields || [];

  const getFilterField = (fieldName: string) => {
    return filterFields.find((item) => item.name === fieldName);
  };

  return {
    fields: filterFields,
    getFilterField,
  };
}

export function useGetFilterConfigs(type: FilterType) {
  const fields = GlobalFilterConfigs[type]?.fields || [];

  const getFilterField = (fieldName: string) => {
    return fields.find((item) => item.name === fieldName);
  };

  return {
    getFilterField,
  };
}
