import React, { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { alpha, Theme } from '@mui/material/styles';
import { ElementPresenceProvider } from '@front/helper';
import { Scrollbar, SimpleTooltip } from '@front/ui';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import { useChannel } from '@lib/web/thread/hooks/channel/useChannel';
import { useChannelsHierarchyDisplay } from '@lib/web/thread/hooks/channels/useChannelsHierarchyDisplay';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { ThreadChannelProperties } from '@lib/web/thread/ThreadProperties';
import { StreamChatGenerics } from '@lib/web/thread/types';
import { isNil } from 'lodash';
import { MessageResponse } from 'stream-chat';
import { Channel as StreamChannel } from 'stream-chat/dist/types/channel';
import { Channel, Chat, StreamMessage } from 'stream-chat-react';

import { ThreadComposerProvider } from '../contexts';
import { useChannelInformation } from '../hooks/channel/useChannelInformation';
import { useMaybeMarkThreadChatRead } from '../hooks/channel/useMaybeMarkThreadChatRead';
import { useThread } from '../hooks/core/useThread';

import ThreadMessageInput from './components/ThreadMessageInput';
import { useThreadChatMessages } from './hooks/useThreadChatMessages';
import { useThreadMessageChannelLayoutConfig } from './hooks/useThreadMessageChannelLayoutConfig';

const styles = {
  root: {
    width: '100%',
  },
  container: {
    display: 'grid',
    gridTemplateRows: '1fr max-content',
    height: '100%',
  },
  containerWithThreadProperties: {
    pt: '111px',
  },
  messageList: {
    position: 'relative',
    '& > [data-simplebar]': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .simplebar-wrapper': {
      position: 'relative',
      height: '100%',
    },
    '& .simplebar-content-wrapper': {
      height: '100%',
    },
    '& .simplebar-content': {
      minHeight: '100%',
      width: '100%',
      display: 'flex',
      flexDirection: 'column-reverse',
    },
  },
};

function ThreadChatContent({
  channel,
  showThreadProperties,
  threadMemberIds,
  tooltip,
  searchedMessages,
  availableEventNames,
}: {
  channel: StreamChannel;
  showThreadProperties: boolean;
  enableChat?: boolean;
  threadMemberIds?: string[];
  tooltip?: string;
  searchedMessages?: MessageResponse<StreamChatGenerics>[];
  availableEventNames?: string[];
}) {
  const [text, setText] = useState('');

  const { messageIdToChildChannel } = useChannelsHierarchyDisplay({
    channels: useMemo(() => [channel], [channel]),
  });

  const { scrollRef, messageStatus, channelMessages, appendNewMessages } =
    useThreadChatMessages({
      channel,
    });

  const config = useThreadMessageChannelLayoutConfig({
    channel: channel,
    messages: !isNil(searchedMessages) ? searchedMessages : channelMessages,
    isLoading: messageStatus === 'init' || messageStatus === 'loading',
    messageIdToChildChannel,
    availableEventNames,
  });

  const handleBeforeSubmit = useCallback(
    (message: StreamMessage<StreamChatGenerics>) => {
      appendNewMessages([message]);
    },
    [appendNewMessages]
  );

  const { ref } = useMaybeMarkThreadChatRead({ channel });

  return (
    <ElementPresenceProvider elementRef={ref}>
      <Channel channel={channel} markReadOnMount={false}>
        <Box sx={styles.root} ref={ref}>
          {showThreadProperties && (
            <ThreadChannelProperties channel={channel} mode="expandableBrief" />
          )}
          <Box
            sx={[
              styles.container,
              showThreadProperties && styles.containerWithThreadProperties,
            ]}
          >
            <Box sx={styles.messageList} className="thread-chat-message-list">
              <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>
                <IaLayouts layouts={config} />
              </Scrollbar>
            </Box>
            <Box>
              <ThreadComposerProvider
                sendPubliclyCheckboxEnable={false}
                threadMemberIds={threadMemberIds}
                text={text}
                setText={setText}
              >
                {tooltip ? (
                  <SimpleTooltip title={tooltip} followCursor>
                    <ThreadMessageInput
                      channel={channel}
                      onBeforeSubmit={handleBeforeSubmit}
                    />
                  </SimpleTooltip>
                ) : (
                  <ThreadMessageInput
                    channel={channel}
                    onBeforeSubmit={handleBeforeSubmit}
                  />
                )}
              </ThreadComposerProvider>
            </Box>
          </Box>
        </Box>
      </Channel>
    </ElementPresenceProvider>
  );
}

type ThreadChatProps = {
  channelCid: string;
  showThreadProperties?: boolean;
  tooltip?: string;
  searchedMessages?: MessageResponse<StreamChatGenerics>[];
  availableEventNames?: string[];
};

export default function ThreadChat({
  channelCid,
  showThreadProperties = true,
  tooltip,
  searchedMessages,
  availableEventNames,
}: ThreadChatProps) {
  const { t } = useTranslation('thread');
  const { chatClient } = useThread();
  const { channel } = useChannel({ channelCid });
  const [isSelfRemovedFromChannel, setIsSelfRemovedFromChannel] =
    useState(false);
  const { getChannelInformation } = useChannelInformation();

  const { channelMemberIds = undefined } = channel
    ? getChannelInformation(channel)
    : {};

  useAddWatchingEvent({
    scope: 'chat-room-self-removed-from-channel',
    key: channelCid,
    callback: (event) => {
      if (event.channel_id !== channel?.id) return;
      if (event.type !== 'notification.removed_from_channel') return;
      setIsSelfRemovedFromChannel(true);
    },
  });

  if (!chatClient || !channel) return;

  if (channelCid !== channel.cid) return;

  if (isSelfRemovedFromChannel) {
    return (
      <Box
        sx={{
          px: 3,
          color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
        }}
      >
        {t('chat.self.removed', "You've been removed from this thread")}
      </Box>
    );
  }

  return (
    <Chat client={chatClient}>
      <ThreadChatContent
        key={channelCid}
        threadMemberIds={channelMemberIds}
        channel={channel}
        showThreadProperties={showThreadProperties}
        tooltip={tooltip}
        searchedMessages={searchedMessages}
        availableEventNames={availableEventNames}
      />
    </Chat>
  );
}
