import React, { MutableRefObject } from 'react';

export type TableLayoutActions = {
  toggleCheckbox: (rowIds: string[]) => void;
};

const TableLayoutActionsContext = React.createContext<{
  actionsRef: MutableRefObject<TableLayoutActions | undefined>;
}>({
  actionsRef: {} as MutableRefObject<TableLayoutActions>,
});
export default TableLayoutActionsContext;
