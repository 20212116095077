import { IconListLayoutItemObj } from '@lib/ia/src/layouts/IconListLayout/types';
import { Difficulty, ExamMode } from '@lib/web/apis';

export type CreateQuizFormPartTypes =
  | 'exam-mode'
  | 'total-questions'
  | 'customize-hashtags'
  | 'challenge-friends'
  | 'show-timer'
  | 'difficulty'
  | 'incognito';

export type ChallengerIconListLayoutItemObj = IconListLayoutItemObj<{
  userId: string;
  isFollowing: boolean;
  disabledUpdate?: boolean;
}>;
export type MarkItem = {
  id: string;
  code: string;
};
export type CreateQuizStateValues = {
  questionCount: number;
  tags: GetExamSectionTagsRes[];
  marks: MarkItem[];
  isOpenTimer: boolean;
  difficultyAry?: Difficulty[];
  mode: ExamMode;
  challengers: ChallengerIconListLayoutItemObj[];
  maxAttempt?: number;
  deadline?: Date;
  isIncognito?: boolean;
  randomChallengerCount?: number;
};

export interface CreateQuizState {
  values: CreateQuizStateValues;
  defaultValues: CreateQuizStateValues;

  sectionId: string;

  // create a new practice or challenge from existing playlist
  tagId?: string;
  markId?: string;
  quizId?: string;
  challengeId?: string;

  isOfficial: boolean;
  officialQuestionCount: number;
  maxQuestionCount: number;
  minQuestionCount: number;
  customizeDisabled: CreateQuizFormPartTypes[];
  isRandomChallengers: boolean;

  isLoading: boolean;
  isLocked: boolean;
  isChallengersLoading: boolean;
}

export const enum CreateQuizActionTypes {
  SET_DEFAULT_SETTINGS_BY_SECTION = 'SET_DEFAULT_SETTINGS_BY_SECTION',
  SET_CUSTOMIZE_DISABLED = 'SET_CUSTOMIZE_DISABLED',
  SET_SECTION_LOADING = 'SET_SECTION_LOADING',
  SET_IS_OFFICIAL = 'SET_IS_OFFICIAL',
  SET_IS_LOCKED = 'SET_IS_LOCKED',
  SET_IS_RANDOM_CHALLENGERS = 'SET_IS_RANDOM_CHALLENGERS',
  UPDATE_QUIZ_INFO = 'UPDATE_QUIZ_INFO',
  UPDATE_VALUES = 'UPDATE_VALUES',
  RESET_VALUES = 'RESET_VALUES',
  RESET_MODE = 'RESET_MODE',
  RESET_TAGS = 'RESET_TAGS',
  RESET_MARKS = 'RESET_MARKS',
  RESET_QUESTIONS = 'RESET_QUESTIONS',
  RESET_CHALLENGERS = 'RESET_CHALLENGERS',
  RESET_ALL_SETTINGS = 'RESET_ALL_SETTINGS',
  SET_CHALLENGERS_LOADING = 'SET_CHALLENGERS_LOADING',
}

type SetDefaultSettingsBySection = {
  type: CreateQuizActionTypes.SET_DEFAULT_SETTINGS_BY_SECTION;
  payload: { section: GetExamSectionRes; maxCount: number };
};

type SetCustomizeDisabled = {
  type: CreateQuizActionTypes.SET_CUSTOMIZE_DISABLED;
  payload: CreateQuizFormPartTypes[];
};

type UpdateQuizValues = {
  type: CreateQuizActionTypes.UPDATE_VALUES;
  payload: Partial<CreateQuizStateValues>;
};

type UpdateQuizInfo = {
  type: CreateQuizActionTypes.UPDATE_QUIZ_INFO;
  payload: {
    tagId?: string;
    markId?: string;
    quizId?: string;
    challengeId?: string;
  };
};

type SetSectionLoading = {
  type: CreateQuizActionTypes.SET_SECTION_LOADING;
  payload: boolean;
};

type SetIsOfficial = {
  type: CreateQuizActionTypes.SET_IS_OFFICIAL;
  payload: boolean;
};

type SetIsLocked = {
  type: CreateQuizActionTypes.SET_IS_LOCKED;
  payload: boolean;
};

type SetIsRandomChallengers = {
  type: CreateQuizActionTypes.SET_IS_RANDOM_CHALLENGERS;
  payload: boolean;
};

type ResetValues = {
  type: CreateQuizActionTypes.RESET_VALUES;
};

type ResetMode = {
  type: CreateQuizActionTypes.RESET_MODE;
};

type ResetTags = {
  type: CreateQuizActionTypes.RESET_TAGS;
};

type ResetMarks = {
  type: CreateQuizActionTypes.RESET_MARKS;
};

type ResetQuestions = {
  type: CreateQuizActionTypes.RESET_QUESTIONS;
};

type ResetChallengers = {
  type: CreateQuizActionTypes.RESET_CHALLENGERS;
};

type ResetAllSettings = {
  type: CreateQuizActionTypes.RESET_ALL_SETTINGS;
};

type SetChallengersLoading = {
  type: CreateQuizActionTypes.SET_CHALLENGERS_LOADING;
  payload: boolean;
};

export type CreateQuizActions =
  | SetDefaultSettingsBySection
  | SetCustomizeDisabled
  | SetSectionLoading
  | SetIsOfficial
  | SetIsLocked
  | SetIsRandomChallengers
  | UpdateQuizValues
  | UpdateQuizInfo
  | ResetValues
  | ResetMode
  | ResetTags
  | ResetMarks
  | ResetQuestions
  | ResetChallengers
  | ResetAllSettings
  | SetChallengersLoading;
