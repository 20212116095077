import { useTranslation } from 'react-i18next';
import { QuestionDetailComponentType } from '@lib/web/apis';
import {
  DetailComponentConsumer,
  DetailComponentProvider,
  useCurrentQuestion,
} from '@lib/web/editor';
import EditorTextComposer from '@lib/web/editor/EditorTextComposer';

const styles = {
  textComposer: {
    hyphens: 'auto',
  },
};

export default function EditorSolutionGeneral() {
  const { t } = useTranslation('editor');
  const { questionId } = useCurrentQuestion();

  return (
    <DetailComponentProvider
      questionId={questionId}
      componentType={QuestionDetailComponentType.GeneralSolution}
    >
      <DetailComponentConsumer>
        {({
          isInitial,
          detailBlocks,
          fetchDetailComponent,
          updateDetailComponent,
        }) => {
          if (isInitial) {
            void fetchDetailComponent();
            return null;
          } else {
            return (
              <EditorTextComposer
                key={questionId}
                sx={styles.textComposer}
                defaultBlocks={detailBlocks}
                onBlocksChange={(blocks) => updateDetailComponent(blocks)}
                placeholder={t('Write solution here')}
              />
            );
          }
        }}
      </DetailComponentConsumer>
    </DetailComponentProvider>
  );
}
