import { Fragment } from 'react';
import { Box, GlobalStyles, Typography, TypographyProps } from '@mui/material';
import { IndicatorGroup } from '@front/ui';

import { ThreadUser } from '../types';

type ThreadMemberTitleProps = {
  members: ThreadUser[];
  typographyVariant?: TypographyProps['variant'];
  badgeSize?: number;
  showIndicators?: boolean;
};

const styles = {
  root: {
    display: 'grid',
    flex: 1,
    minWidth: 0,
    gridTemplateColumns: 'minmax(0, min-content) 1fr',
    whiteSpace: 'nowrap',
  },
  hasOtherItems: {
    gridTemplateColumns: 'minmax(0, min-content) minmax(24px, 1fr)',
  },
  firstItem: {
    minWidth: 0,
    display: 'flex',
    gap: 0.5,
    '& .MuiTypography-root': {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
  },
  otherItems: {
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

export default function ThreadMemberTitle({
  members,
  typographyVariant,
  badgeSize,
  showIndicators = true,
}: ThreadMemberTitleProps) {
  const [firstMember, ...otherMembers] = members;

  return (
    <>
      <GlobalStyles styles={{ '.breadcrumbs-item': { flex: 1 } }} />
      <Box sx={[styles.root, !!otherMembers.length && styles.hasOtherItems]}>
        <Box sx={styles.firstItem}>
          <Typography variant={typographyVariant}>
            {firstMember.name}
          </Typography>
          {'indicators' in firstMember && (
            <IndicatorGroup
              indicators={showIndicators ? firstMember.indicators : undefined}
              size={badgeSize}
            />
          )}
        </Box>
        {!!otherMembers.length && (
          <Box position="relative">
            <Box sx={styles.otherItems}>
              {otherMembers.map((member) => (
                <Fragment key={member.id}>
                  <Typography variant={typographyVariant} component="span">
                    , {member.name}
                  </Typography>
                  {'indicators' in member && (
                    <IndicatorGroup
                      indicators={
                        showIndicators ? member.indicators : undefined
                      }
                      size={badgeSize}
                    />
                  )}
                </Fragment>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    </>
  );
}
