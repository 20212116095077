import type { FC, ReactNode } from 'react';
import { createContext, useEffect, useMemo, useState } from 'react';
import Router from 'next/router';

interface Settings {
  direction?: 'ltr' | 'rtl';
  responsiveFontSizes?: boolean;
  theme: 'light' | 'dark';
}

export interface SettingsContextValue {
  settings: Settings;
  saveSettings: (update: Settings) => void;
}

interface SettingsProviderProps {
  children?: ReactNode;
}

const initialSettings: Settings = {
  direction: 'ltr',
  responsiveFontSizes: false,
  theme: 'dark',
};

export const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => {},
});

const EXAM_PATHNAMES_REGEX = /\/club\/(.*?)\/exam\/|mode=exam/i;

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const isExamMode = EXAM_PATHNAMES_REGEX.test(
    (Router.router?.asPath || '') as string
  );

  const [settings, setSettings] = useState<Settings>({
    ...initialSettings,
    theme: isExamMode ? 'light' : 'dark',
  });

  const saveSettings = (updatedSettings: Settings): void => {
    setSettings(updatedSettings);
  };

  const memoizedValue = useMemo(() => {
    const value = {
      settings,
      saveSettings,
    };
    return value;
  }, [settings]);

  useEffect(() => {
    setSettings((st) => ({ ...st, theme: isExamMode ? 'light' : 'dark' }));
  }, [isExamMode]);

  return (
    <SettingsContext.Provider value={memoizedValue}>
      {children}
    </SettingsContext.Provider>
  );
};

export const SettingsConsumer = SettingsContext.Consumer;
