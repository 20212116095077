import { useRef } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { testIdConfig } from '@front/config';
import { useBaseLayout } from '@front/ui';

import usePracticeStaticData from './usePracticeStaticData';

type FunctionRefMethods = {
  toggleTimer: () => void;
  goNext: () => void;
  goPrevious: () => void;
  submit: () => void;
  toggleSolution: () => void;
};

type QuizKeyboardShortcutParams = {
  isPaused: boolean;
  hasAnswer: boolean;
  isSubmitted: boolean;
  isLoading: boolean;
  isPendingSubmit: boolean;
  startAt: number | null;
  goPreviousQuestion: () => void;
  goNextQuestion: () => void;
  resumeQuiz: () => void;
  pauseQuiz: () => void;
  lazySubmit: () => void;
  directSubmit: () => void;
};
export default function useQuizKeyboardShortcut({
  goPreviousQuestion,
  goNextQuestion,
  resumeQuiz,
  pauseQuiz,
  lazySubmit,
  directSubmit,
  isPaused,
  hasAnswer,
  isSubmitted,
  isLoading,
  isPendingSubmit,
  startAt,
}: QuizKeyboardShortcutParams) {
  const functionRef = useRef<FunctionRefMethods>({} as FunctionRefMethods);
  const { panelKeys } = usePracticeStaticData();

  const { toggleRightPanel } = useBaseLayout();

  functionRef.current = {
    toggleTimer: () => {
      if (isSubmitted || isLoading || isPendingSubmit) return;
      if (startAt === null) {
        resumeQuiz();
      } else {
        pauseQuiz();
      }
    },
    goPrevious: () => {
      if (isPaused) return;
      goPreviousQuestion();
    },
    goNext: () => {
      if (isPaused) return;
      goNextQuestion();
    },
    submit: () => {
      if (isSubmitted || isLoading || isPaused) return;
      if (!hasAnswer) return;

      if (isPendingSubmit) directSubmit();
      else lazySubmit();
    },
    toggleSolution: () => {
      if (!isSubmitted) return;
      toggleRightPanel(panelKeys.solution);
    },
  };

  useHotkeys(
    'space',
    (ev) => {
      ev.preventDefault();
      functionRef.current.toggleTimer();
    },
    []
  );
  useHotkeys(
    'left',
    (ev) => {
      ev.preventDefault();
      functionRef.current.goPrevious();
    },
    { enableOnFormTags: ['TEXTAREA'] },
    []
  );
  useHotkeys(
    'right',
    (ev) => {
      ev.preventDefault();
      functionRef.current.goNext();
    },
    { enableOnFormTags: ['TEXTAREA'] },
    []
  );
  useHotkeys(
    'enter',
    (ev) => {
      ev.preventDefault();

      // data-testid="free-response-textarea"
      // data-testid="essay-answer-textarea"
      // data-testid="grid-input-#"

      // Avoid submission when user types in textarea in Thread Chat or other components
      if (
        ev.target instanceof HTMLTextAreaElement ||
        ev.target instanceof HTMLInputElement
      ) {
        const testId = ev.target.dataset.testid || '';
        if (
          testId === testIdConfig.freeResponseTextArea ||
          testId === testIdConfig.essayAnswerTextArea ||
          testId.includes(testIdConfig.gridInPrefix)
        ) {
          functionRef.current.submit();
        }
      } else {
        functionRef.current.submit();
      }
    },
    { enableOnFormTags: ['TEXTAREA', 'INPUT'] },
    []
  );
  useHotkeys(
    'shift+ctrl+s',
    (ev) => {
      ev.preventDefault();
      functionRef.current.toggleSolution();
    },
    []
  );
}
