import { ReactNode, useContext, useEffect } from 'react';
import { Theme } from '@mui/material';
import GlobalStyles from '@mui/material/GlobalStyles';

import HighlightWrapContext from './HighlightWrapContext';

type HighlightWrapProps = {
  content: string;
  children: ReactNode;
};

const styles = (theme: Theme) => ({
  '& .inline-highlight, & .inline-highlight-anchor': {
    '.highlight-order': {
      fontFamily: theme.typography.subtitle2.fontFamily,
      fontSize: theme.typography.subtitle2.fontSize,
      lineHeight: '18px',
      userSelect: 'none',
      pointerEvents: 'none',
      minWidth: 22,
      cursor: 'pointer',
    },
  },
});
export default function HighlightWrap({
  content,
  children,
}: HighlightWrapProps) {
  const [, setHighlightOrderMap] = useContext(HighlightWrapContext);
  useEffect(() => {
    const dom = document.createElement('div');
    dom.innerHTML = content;

    const allTargets = dom.querySelectorAll('.inline-highlight-anchor');

    const highlightOrderMap: Record<string, number> = {};
    let order = 0;
    allTargets.forEach((target) => {
      const id = target.getAttribute('data-render-id');
      if (id) {
        highlightOrderMap[id] = order += 1;
      }
    });
    setHighlightOrderMap(highlightOrderMap);
  }, [content, setHighlightOrderMap]);

  return (
    <>
      <GlobalStyles styles={styles} />
      {children}
    </>
  );
}
