import { AxiosError } from 'axios';
import { useRouter } from 'next/router';
import useSWRInfinite, {
  SWRInfiniteConfiguration,
  SWRInfiniteKeyLoader,
} from 'swr/infinite';
import { fetcher } from '@lib/web/apis';

import useToken from './useToken';

export interface InfiniteConfig<Data = unknown, Error = unknown>
  extends Omit<
    SWRInfiniteConfiguration<Data, AxiosError<Error>>,
    'fallbackData'
  > {
  fallbackData?: Data[];
  withPublicAccessKey?: boolean;
  publicAccessKey?: string;
  auth?: boolean;
  data?: any;
  baseURL?: string;
  method?: 'POST' | 'GET';
}

export function useRequestInfinite<Data = unknown, Error = unknown>(
  getKey: SWRInfiniteKeyLoader,
  { fallbackData, auth, ...config }: InfiniteConfig<Data, Error> = {}
) {
  const token = useToken();
  const sid = useRouter().query.sid as string;

  const { data, error, isLoading, isValidating, mutate, size, setSize } =
    useSWRInfinite<Data, AxiosError<Error>>(
      (index, previousPageData) => {
        const key = getKey(index, previousPageData);
        const urlKey = auth && !sid ? (token ? key : undefined) : key;

        return urlKey;
      },
      config.withPublicAccessKey || config.publicAccessKey
        ? (_url, _config) =>
            fetcher(_url, {
              ..._config,
              _withPublicAccessKey: config.withPublicAccessKey,
              _publicAccessKey: config.publicAccessKey,
              _token: token,
              _sid: sid,
            })
        : (_url, _config) =>
            fetcher(_url, {
              ..._config,
              _token: token,
              _sid: sid,
              data: config.data,
              baseURL: config.baseURL,
              method: config.method,
            }),
      config
    );

  return {
    data,
    mutate,
    error,
    isLoading,
    isValidating,
    size,
    setSize,
  };
}
