import { Box } from '@mui/material';
import { camelize } from '@front/helper';
import parse, { HTMLReactParserOptions } from 'html-react-parser';

import { useIaRender } from '../core/IaRender/useIaRender';

const styles = {
  root: {
    display: 'inline-flex',
  },
};
interface HtmlRendererProps {
  htmlString: string;
}

export const HtmlRenderer = ({ htmlString }: HtmlRendererProps) => {
  const { getIaRender } = useIaRender();

  const options: HTMLReactParserOptions = {
    replace: (domNode) => {
      if (
        domNode.type === 'tag' &&
        domNode.name === 'span' &&
        !!domNode.attribs['data-render-ui']
      ) {
        const render = getIaRender(domNode.attribs['data-render-ui']);

        if (render) {
          const metadata = Object.keys(domNode.attribs)
            .filter((key) => key.includes('data-metadata'))
            .reduce((acc, cur) => {
              const curKey = camelize(cur.replace('data-metadata-', ''));
              return {
                ...acc,
                [curKey]: domNode.attribs[cur],
              };
            }, {});
          return render(metadata || {}) as any as Element;
        }
      }
    },
  };

  return (
    <Box sx={styles.root} className="html-renderer">
      {parse(htmlString, options)}
    </Box>
  );
};
