import {
  ChangeEvent,
  KeyboardEvent,
  MouseEvent,
  useRef,
  useState,
} from 'react';
import { Fade } from '@mui/material';
import Box, { BoxProps } from '@mui/material/Box';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';

const styles = {
  boxWrap: {
    display: 'grid',
    gap: 1,
    maxWidth: 290,
    justifyContent: 'flex-start',

    '& .grid-input-inner': {
      width: '100%',
      height: '100%',
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'transparent',
      textAlign: 'center',
      typography: 'body1',
      fontSize: 30,
      color: 'text.primary',
      borderRadius: '12px',
      border: '3px solid',
      borderColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
      '&:focus': {
        borderColor: 'text.primary',
      },
    },
  },
  hoverable: {
    cursor: 'pointer',
    '&:hover': {
      '& .grid-input-inner': {
        cursor: 'pointer',
        borderColor: 'text.primary',
      },
    },
  },
  errorBoxWrap: {
    '& .grid-input-inner': {
      borderColor: 'error.dark',
      bgcolor: (theme: Theme) => alpha(theme.palette.error.dark, 0.15),
    },
  },
  successBoxWrap: {
    '& .grid-input-inner': {
      borderColor: (theme: Theme) =>
        theme.palette.mode === 'dark' ? 'success.light' : 'success.dark',
      bgcolor: (theme: Theme) =>
        theme.palette.mode === 'dark'
          ? alpha(theme.palette.success.light, 0.3)
          : alpha(theme.palette.success.dark, 0.3),
      fontWeight: 'bold',
    },
  },
  correctAnswerBoxWrap: {
    '& .grid-input-inner': {
      borderColor: 'success.light',
      fontWeight: 'bold',
    },
  },
  box: {
    aspectRatio: '1 / 1',
  },
  hints: {
    mt: 1,
    p: 1,
    display: 'grid',
    gap: '18px',
    bgcolor: 'text.primary',
    color: 'background.default',
    borderRadius: 1,
    boxShadow: 9,
  },
};

export type GridInputProps = {
  value: string;
  sx?: BoxProps['sx'];
  valuesRegex?: RegExp[];
  acceptedRegex?: RegExp;
  inputCount?: number;
  error?: boolean;
  success?: boolean;
  correctAnswer?: boolean;
  disabled?: boolean;
  hints?: string[];

  onChange?: (ev: string) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  onEnter?: () => void;
};

function convertToHalf(char: string) {
  return char.replace(/[！-～]/g, (halfWidthChar) =>
    String.fromCharCode(halfWidthChar.charCodeAt(0) - 0xfee0)
  );
}

/**
 * GridInput
 * value: 1234, _134, _.34, ___5...
 * The value retains spaces,
 * so if you need to call the API and pass the value,
 * remember to replace the spaces with an empty string
 */
export default function GridInput({
  sx,
  valuesRegex,
  acceptedRegex,
  value,
  hints,
  inputCount = 4,
  error = false,
  success = false,
  correctAnswer = false,
  disabled = false,
  onChange,
  onEnter,
  onFocus,
  onBlur,
}: GridInputProps) {
  const inputsRef = useRef<HTMLInputElement[]>([]);
  const [focused, setFocused] = useState(false);
  const [hovered, setHovered] = useState(false);
  const [values] = useState(Array.from(Array(inputCount).keys()));

  const focusInput = (index: number) => {
    if (!inputsRef.current[index]) return;
    inputsRef.current[index].focus();
    setTimeout(() => {
      if (inputsRef.current[index]?.selectionStart === 0) {
        inputsRef.current[index].selectionStart = inputsRef.current[
          index
        ].selectionEnd = 1;
      }
    }, 0);
  };

  const getNewValueString = (newValue: string, index: number) => {
    let valuesString = '';

    values.forEach((v, i) => {
      const newV = i === index ? convertToHalf(newValue) : value[i] || ' ';

      valuesString += newV;
    });

    return valuesString;
  };

  const checkSingleValue = (newValue: string, index: number): boolean => {
    const validValue = convertToHalf(newValue);

    if (valuesRegex && !valuesRegex[index].test(validValue) && newValue !== ' ')
      return false;

    return true;
  };

  const checkAllValues = (newValueString: string): boolean => {
    // check accepted value (but not final value)
    if (!acceptedRegex) return true;

    return acceptedRegex.test(newValueString.replaceAll(' ', ''));
  };

  const handleInputChange = (
    ev: ChangeEvent<HTMLInputElement>,
    index: number
  ) => {
    let newValue = ev.target.value;

    if (newValue.length > 1) {
      const leftStart = ev.target.selectionStart === 1;

      if (leftStart) {
        newValue = newValue[0];
      } else {
        newValue = newValue[newValue.length - 1];
      }
    }

    const newValueStrings = getNewValueString(newValue, index);
    if (!checkSingleValue(newValue, index)) return;
    if (!checkAllValues(newValueStrings)) return;

    onChange?.(newValueStrings);

    if (
      index < inputCount - 1 &&
      inputsRef.current[index + 1] &&
      newValue !== ' ' && // space
      newValue !== ''
    ) {
      focusInput(index + 1);
    }
  };

  function handleInputKeyDown(
    ev: KeyboardEvent<HTMLInputElement>,
    index: number
  ): void {
    switch (ev.code) {
      case 'Space':
      case 'ArrowRight': {
        if (index < inputCount - 1) {
          focusInput(index + 1);
        }
        return;
      }
      case 'ArrowLeft': {
        if (index > 0) {
          focusInput(index - 1);
        }
        return;
      }
      case 'Backspace': {
        const newValueStrings = getNewValueString(' ', index);
        onChange?.(newValueStrings);

        if (index > 0) {
          focusInput(value?.[index]?.trim() ? index : index - 1);
        }

        return;
      }
      case 'Enter': {
        onEnter?.();

        return;
      }
      default:
        break;
    }
  }

  const handleFocus = (ev: MouseEvent<HTMLDivElement>) => {
    setFocused(true);

    if ((ev.target as HTMLDivElement).tagName !== 'INPUT') {
      inputsRef.current[
        value.length === inputCount ? value.length - 1 : value.length
      ]?.focus();
    }
    onFocus?.();
  };

  function handleClickAway() {
    if (focused) {
      setFocused(false);
      onBlur?.();
    }
  }

  function handleMouseEnter() {
    if (!disabled) {
      setHovered(true);
    }
  }

  function handleMouseLeave() {
    if (!disabled) {
      setHovered(false);
    }
  }

  return (
    <Box sx={sx}>
      <ClickAwayListener onClickAway={handleClickAway}>
        <Box
          sx={[
            styles.boxWrap,
            !disabled && styles.hoverable,
            error && styles.errorBoxWrap,
            success && styles.successBoxWrap,
            correctAnswer && styles.correctAnswerBoxWrap,
            {
              gridTemplateColumns: `repeat(${inputCount}, auto)`,
            },
          ]}
          className="grid-input-wrap"
          onClick={handleFocus}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {values.map((v, i) => (
            <Box key={i} sx={styles.box} className="grid-input-box">
              <Box
                data-testid={`grid-input-${i}`}
                className="grid-input-inner"
                ref={(el: any) => (inputsRef.current[i] = el)}
                value={value[i] === ' ' ? '' : value[i] || ''}
                component="input"
                maxLength={2}
                onKeyDown={(ev) => handleInputKeyDown(ev, i)}
                onChange={(ev) => handleInputChange(ev, i)}
                disabled={disabled}
              />
            </Box>
          ))}
        </Box>
      </ClickAwayListener>

      {!!hints?.length && (focused || hovered) && (
        <Fade in>
          <Box sx={styles.hints}>
            <Typography variant="caption">You can only input</Typography>
            <Box>
              {hints.map((hint) => (
                <Typography key={hint} variant="caption" component="li">
                  {hint}
                </Typography>
              ))}
            </Box>
          </Box>
        </Fade>
      )}
    </Box>
  );
}
