import { useCallback, useEffect, useState } from 'react';
import { useLatestValueRef } from '@front/helper';
import {
  AgentUserViewSlug,
  InfiniteHookResponse,
  useAgentUsers,
  useAuth,
} from '@lib/web/apis';
import { useCurrentIaClub } from '@lib/web/hooks';

export type AiActionState = ReturnType<typeof useAiAction>;

export const useAiAction = ({ enable }: { enable: boolean }) => {
  const { club } = useCurrentIaClub();
  const { userId } = useAuth();

  const agentsData = useAgentUsers(
    {
      viewSlug: AgentUserViewSlug.ThreadComposerUserDefault,
      search: club
        ? `agentClub:${club.clubId};userId:${userId};visibility:1`
        : `userId:${userId}`,
      searchFields: club ? 'agentClub:eq;userId:eq' : 'userId:eq',
      orderBy: 'order',
      sortBy: 'asc',
    },
    {
      enable,
    }
  );

  const { dataset, isLoadingInitialData } = agentsData;
  const agentsDataRef = useLatestValueRef(agentsData);

  const [selectedAgent, setSelectedAgent] =
    useState<GetIaAgentUserThreadComposerDefaultRes | null>(null);

  useEffect(() => {
    if (!isLoadingInitialData && dataset.length > 0 && selectedAgent === null) {
      setSelectedAgent(dataset[0]);
    }
  }, [dataset, isLoadingInitialData, selectedAgent]);

  const ensureLoadingFinish = useCallback(
    (
      callback: (
        agentsData: InfiniteHookResponse<GetIaAgentUserThreadComposerDefaultRes>
      ) => void
    ) => {
      if (agentsDataRef.current.error) {
        console.warn('loading aiAction fail', agentsDataRef.current.error);
        return;
      }

      if (agentsDataRef.current.isLoadingInitialData) {
        setTimeout(() => ensureLoadingFinish(callback), 1000);
        return;
      }

      callback(agentsDataRef.current);
    },
    [agentsDataRef]
  );

  return {
    selectedAgent,
    selectedAgentId: selectedAgent?.agent.agentId,
    setSelectedAgent,
    agents: dataset,
    ensureLoadingFinish,
  };
};
