import React, { useState } from 'react';
import { ButtonBase, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import { alpha, Theme } from '@mui/material/styles';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionChevronUp as ActionChevronUpIcon,
} from '@front/icon';

import { DisplayTableLayoutSection } from '../types';

const styles = {
  root: {
    mb: 1,
  },
  titleButton: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    height: '20px',
    width: '100%',
    px: 1,
    my: '2px',
  },
  title: {
    typography: 'caption',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
  chevron: {
    display: 'flex',
    alignItems: 'center',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
};

export type IaDisplayTableSectionProps = {
  section: DisplayTableLayoutSection;
  children: React.ReactNode;
};

export default function IaDisplayTableSection({
  section,
  children,
}: IaDisplayTableSectionProps) {
  const [open, setOpen] = useState(section.defaultOpen);
  const ChevronIcon = open ? ActionChevronDownIcon : ActionChevronUpIcon;

  return (
    <Box sx={styles.root}>
      {section.title ? (
        <>
          <ButtonBase
            sx={styles.titleButton}
            disableRipple
            onClick={() => setOpen(!open)}
          >
            <Typography sx={styles.title}>{section.title}</Typography>
            <Typography sx={styles.chevron}>
              <ChevronIcon width={16} height={16} />
            </Typography>
          </ButtonBase>
          <Collapse in={open}>{children}</Collapse>
        </>
      ) : (
        children
      )}
    </Box>
  );
}
