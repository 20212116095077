import { AnswerFormatType, StructureType } from '@lib/web/apis';
import { useCreatorQuestionInfoData } from '@lib/web/editor/hooks/useCreatorQuestionInfoData';
import { useCurrentExam } from '@lib/web/hooks';

export const useEditorAreaSetting = (questionId: string) => {
  const { section } = useCurrentExam();
  const { infoData } = useCreatorQuestionInfoData(questionId);

  if (!section || !infoData) {
    return {
      editorQuestionEnable: false,
      editorOptionsEnable: false,
      layout: false,
      analyticsEnable: false,
      solutionEnable: false,
      hashtagsEnable: false,
      difficultyLevelEnable: false,
      formulaEnable: false,
      answerContentTypeEnable: false,
    };
  }

  const answerFormat = infoData.answerFormatType;
  const structureFormat = infoData.structureType;
  const isQuestionGroup = structureFormat === StructureType.QuestionGroup;

  return {
    editorQuestionEnable:
      !isQuestionGroup && answerFormat !== AnswerFormatType.Essay,
    editorOptionsEnable: !isQuestionGroup,
    layout:
      answerFormat === AnswerFormatType.Essay ? 'one-column' : 'two-columns',
    analyticsEnable: !isQuestionGroup,
    solutionEnable: !isQuestionGroup,
    hashtagsEnable: !isQuestionGroup,
    difficultyLevelEnable: !isQuestionGroup,
    formulaEnable: !isQuestionGroup && infoData.isOfferFormula,
    answerContentTypeEnable: answerFormat === AnswerFormatType.FreeResponse,
    wordLimitEnable: answerFormat === AnswerFormatType.Essay,
  };
};
