import { useCallback } from 'react';
import { IaRichText } from '@lib/ia/src/core/types';
import { NotificationCtaConfig } from '@lib/ia/src/layouts/ChannelLayout/types';
import { NotificationTag } from '@lib/web/apis';
import { useThread } from '@lib/web/thread/hooks/core/useThread';

export const useNotificationMessageContent = () => {
  const { getThreadNotificationMessagePayload, getThreadUser } = useThread();

  const getNotificationMessageContent = useCallback(
    ({
      messageCreatorId,
      notificationId,
    }: {
      messageCreatorId?: string;
      notificationId?: string;
    }): {
      text: IaRichText;
      cta?: NotificationCtaConfig[];
    } => {
      if (!notificationId) return { text: '' };

      const notificationPayload =
        getThreadNotificationMessagePayload(notificationId);

      if (notificationPayload === undefined) {
        return { text: '' }; // fetching notification payload
      }

      if (notificationPayload === null) {
        return {
          text: `🔴 cannot find notification payload [${notificationId}]`,
        };
      }

      if (notificationPayload.tag === NotificationTag.SomeoneFollowedYou) {
        const messageCreatorUser = getThreadUser(messageCreatorId);

        if (messageCreatorUser?.type !== 'user') return { text: '' };

        return {
          text: [
            {
              type: 'user',
              id: messageCreatorUser.userId || '',
              name: `@${messageCreatorUser.distinctName}`,
              src: messageCreatorUser.image || '',
              action: {
                type: 'event' as const,
                value: 'viewUser',
              },
            },
            {
              type: 'text',
              value: 'followed you.',
            },
          ],
          cta: [
            {
              type: 'customize',
              value: 'cta.follow.followback',
              metadata: {
                userId: messageCreatorUser.userId,
              },
            },
          ],
        };
      }
      return {
        text: `🚧 not supported notification type: [${notificationPayload.tag}]`,
      };
    },
    [getThreadNotificationMessagePayload, getThreadUser]
  );

  return {
    getNotificationMessageContent,
  };
};
