import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { Menu, MenuItem, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { OtherError as OtherErrorIcon } from '@front/icon';
import { InfoTooltip, StatusTag, Tag } from '@front/ui';
import Icon from '@lib/ia/src/components/Icon';
import { useIaAction } from '@lib/ia/src/core/IaAction/useIaAction';
import { useIaItemStatus } from '@lib/ia/src/core/IaItemStatus/useIaItemStatus';
import { IaAction } from '@lib/ia/src/core/types';

import useTableSelectionContext from '../../hooks/useTableSelectionContext';
import {
  IaCellValueChangedEvent,
  TableLayoutRow,
  TableLayoutStatusCell,
} from '../../types';

const styles = {
  root: {
    px: 1,
    userSelect: 'none',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  actionWrap: {
    display: 'grid',
    gap: 0.5,
    minHeight: '28px',
    alignItems: 'center',
  },
  actionHintWrap: {
    py: '3.5px',
  },
  actionSelectedWrap: {},
  action: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    typography: 'body2',
    minHeight: '24px',
  },
  actionIcon: {
    display: 'flex',
    alignItems: 'center',
  },
  actionSkeleton: {
    px: 1,
    height: '28px',
  },
  hint: {
    opacity: 0.5,
    display: 'block',
  },
  popper: {
    '& .popper-content': {
      mt: '4px',
      minWidth: 95,
      py: '6px',
    },
    button: {
      padding: '0px 12px',
    },
  },
  menu: {
    '& .MuiPaper-root': {
      mt: '4px',
      borderRadius: 2,
    },
    '& .MuiMenu-list': {
      py: '6px',
      minWidth: 95,
    },
  },
  errorTooltipTitle: {
    color: 'highlight.red',
  },
  errorTooltipPopper: {
    '& .MuiTooltip-tooltip': {
      p: '8px !important',
      maxWidth: '200px',
    },
  },

  tag: {
    px: 1,
    py: '1px',
    minHeight: 26,
    typography: 'body1',
    border: 'none',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
};

function getMenuStyles(actionMenuWidth = 95) {
  return {
    '& .MuiMenu-list': {
      minWidth: actionMenuWidth,
    },
  };
}

type Option = IaAction & {
  onClick: (option: Option) => void;
  loading?: boolean;
  errorTooltips?: string[];
};

export type IaStatusCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutStatusCell;
  columnKey: string;
};

function StatusOption({
  option,
  selected,
  onClick,
  autoFocus,
}: {
  option: Option;
  selected: boolean;
  onClick: (option: Option) => void;
  autoFocus?: boolean;
}) {
  const handleClick = (e: MouseEvent) => {
    e.stopPropagation();
    onClick(option);
  };

  // option.iconSxProps
  const iconSx = Array.isArray(option.iconSxProps)
    ? option.iconSxProps
    : [option.iconSxProps];

  return (
    <MenuItem
      onClick={handleClick}
      selected={selected}
      disabled={option.disabled}
      autoFocus={autoFocus}
    >
      <Box sx={[styles.actionWrap, !!option.hint && styles.actionHintWrap]}>
        <Box sx={styles.action}>
          {option.icon && (
            <Box sx={[styles.actionIcon, ...iconSx]}>
              <Icon name={option.icon} width={16} height={16} />
            </Box>
          )}
          {option.text}
        </Box>
        {option.hint && (
          <Typography sx={styles.hint} variant="caption">
            {option.hint}
          </Typography>
        )}
      </Box>
    </MenuItem>
  );
}

function StatusOptionSkeleton() {
  return (
    <Box sx={[styles.action, styles.actionSkeleton]}>
      <Skeleton variant="circular" width={14} height={14} />
      <Skeleton sx={{ flex: 1 }} />
    </Box>
  );
}

export default function IaStatusCell({
  row,
  cell: {
    variant,
    text,
    actions,
    actionMenuWidth,
    actionSelectedValue,
    display = 'default',
    actionMap,
    value,
  },
  columnKey,
}: IaStatusCellProps) {
  const { getItemStatus, getItemStatusAsync } = useIaItemStatus<{
    item: TableLayoutRow;
    action: IaAction;
  }>();

  const ref = useRef<HTMLDivElement>(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [options, setOptions] = useState<Option[]>([]);

  const hasActions = actions && actions.length > 0;

  const { getIaAction } = useIaAction();

  const toggleMenu = useCallback(
    (open: boolean) => {
      if (!hasActions) {
        return;
      }

      setMenuOpen(open);

      if (!open) return;

      const newOptions: Option[] = actions.map((action) => {
        const newOption: Option = {
          ...action,
          ...(getItemStatus?.({ item: row, action }) || {}),
          onClick: (option: Option) =>
            getIaAction<TableLayoutRow>(option.value)?.action(row),
        };
        return newOption;
      });

      setOptions(newOptions);

      if (getItemStatusAsync) {
        // use setTimeout to trigger ui update, then we will handle async data in next render cycle
        setTimeout(async () => {
          const newOptionsWithAsyncStatus: Option[] = [];

          for (const option of newOptions) {
            const attrs = await getItemStatusAsync({
              item: row,
              action: option,
            });
            if (!attrs) {
              newOptionsWithAsyncStatus.push(option);
              return;
            }

            newOptionsWithAsyncStatus.push({
              ...option,
              disabled: attrs.disabled,
              errorTooltips: attrs.disabledTooltips,
              loading: attrs.loading,
            });
          }
          setOptions(newOptionsWithAsyncStatus);
        });
      }
    },
    [actions, getIaAction, getItemStatus, getItemStatusAsync, hasActions, row]
  );

  const handleClick = async (ev: MouseEvent<HTMLElement>) => {
    // do not add ev.stopPropagation() because it stops the event sent to MenuDropDown's clickAway listener, make multiple menu can be appeared at the same time
    ev.preventDefault(); // most of the time, the row is clickable to enter the detail, when click status, we don't want to enter the detail
    toggleMenu(!menuOpen);
  };

  const { getCellSelectedState, setSelectedState } = useTableSelectionContext();
  const selectedState = getCellSelectedState(row.id, columnKey);

  useEffect(() => {
    const open = selectedState === 'active';
    if (open !== menuOpen) {
      toggleMenu(open);
    }
  }, [selectedState, menuOpen, toggleMenu]);

  const handleValueChange = useCallback(
    (newValue: string | null) => {
      if (!actionMap) return;
      if (!actionMap?.valueChange) return;
      const action = getIaAction<IaCellValueChangedEvent<string | null>>(
        actionMap.valueChange
      );
      action?.action({ value: newValue, row, columnKey });
    },
    [actionMap, columnKey, getIaAction, row]
  );

  const handleOptionClick = useCallback(
    (option: Option) => {
      option.onClick(option);
      setMenuOpen(false);
      setSelectedState('focused');
      handleValueChange(option.value);
    },
    [handleValueChange, setSelectedState]
  );

  const selectedAction = actions?.find((a) => a.value === value);
  const label = text || selectedAction?.text;

  return (
    <Box sx={styles.root} onClick={handleClick} ref={ref}>
      {label && display === 'default' && (
        <StatusTag
          color={variant}
          name={label}
          size="small"
          active={menuOpen}
        />
      )}
      {label && display === 'tag' && (
        <Tag label={label} color="default" variant="filled" sx={styles.tag} />
      )}
      {hasActions && (
        <Menu
          open={menuOpen}
          anchorEl={ref.current}
          onClose={(e, reason) => {
            if (reason === 'backdropClick') {
              (e as Event).stopPropagation();
            }
            setMenuOpen(false);
            setSelectedState('focused');
          }}
          sx={[styles.menu, getMenuStyles(actionMenuWidth)]}
          autoFocus
        >
          {options.map((option, index) =>
            option.errorTooltips ? (
              <InfoTooltip
                key={index}
                titleIcon={<OtherErrorIcon width={16} height={16} />}
                titleSx={styles.errorTooltipTitle}
                title="Error"
                listContent={option.errorTooltips}
              >
                <Box>
                  <StatusOption
                    option={option}
                    selected={option.value === actionSelectedValue}
                    onClick={handleOptionClick}
                    autoFocus={index === 0}
                  />
                </Box>
              </InfoTooltip>
            ) : option.loading ? (
              <StatusOptionSkeleton key={index} />
            ) : (
              <StatusOption
                key={index}
                option={option}
                selected={option.value === actionSelectedValue}
                onClick={handleOptionClick}
                autoFocus={index === 0}
              />
            )
          )}
        </Menu>
      )}
    </Box>
  );
}
