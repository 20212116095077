import React, { RefObject } from 'react';
import Box, { BoxProps } from '@mui/material/Box';

import Scrollbar from '../../atoms/Scrollbar';

export type BaseLayoutScrollAreaProps = BoxProps & {
  sx?: BoxProps['sx'];
  children?: BoxProps['children'];
  scrollRef?: RefObject<HTMLDivElement>;
};

const styles = {
  root: {
    flex: 1,
    position: 'relative',
    '& > [data-simplebar]': {
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
    },
    '& .simplebar-wrapper, & .simplebar-content-wrapper': {
      height: '100%',
    },
    '& .simplebar-content': {
      minHeight: '100%',
      display: 'flex',
      flexDirection: 'column',
    },
  },
};

export default function BaseLayoutScrollArea({
  sx,
  scrollRef,
  children,
}: BaseLayoutScrollAreaProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box sx={[styles.root, ...sxProps]}>
      <Scrollbar scrollableNodeProps={{ ref: scrollRef }}>{children}</Scrollbar>
    </Box>
  );
}
