const htmlContent = (type: string) =>
  `[data-content-type="${type}"]:not(.block-content)`;

export const generatedHtmlImageBlockStyle = () => ({
  [`${htmlContent('image')}`]: {
    lineHeight: 0, // clear the default line-height 1.5, otherwise there will be a very small margin on the image bottom

    img: {
      maxWidth: '550px',
      maxHeight: '330px',
      objectFit: 'contain',
      objectPosition: 'left top',

      '.base-layout-right-panel &': {
        maxWidth: 'min(508px, 100%)',
        maxHeight: '350px',
      },
    },
    '&:after': {
      content: 'attr(data-caption)',
      typography: 'caption',
      display: 'block',
    },
    '.render-one-line&': {
      img: {
        display: 'none',
      },
      '&:after': {
        pr: '4px',
        fontStyle: 'italic',
        content: '"Image: " attr(data-caption)',
      },
    },
  },
});
