import { basicTiptapExtensions } from '@lib/web/composer';
import { InlineMention } from '@lib/web/composer/TextComposer/components/blocks/InlineMention';

import { InlineLatex } from '../components/blocks/InlineLatex';

export const threadTiptapExtensions = [
  ...basicTiptapExtensions,
  InlineLatex,
  InlineMention,
];
