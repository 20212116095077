import { ReactNode } from 'react';
import { HighlightWrap, useRenderHighlighter } from '@lib/web/ui';

import usePracticeQuestion from '../../../../../hooks/usePracticeQuestion';

type RenderHighlighterProps = {
  content: string;
  children: ReactNode;
};
export default function RenderHighlighter({
  content,
  children,
}: RenderHighlighterProps) {
  const { id } = usePracticeQuestion();

  useRenderHighlighter(id);

  return <HighlightWrap content={content}>{children}</HighlightWrap>;
}
