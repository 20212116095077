import React from 'react';

export default function OtherFriendsChallenge({ ...rest }) {
  return (
    <svg
      height="24"
      width="24"
      fill="none"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M10 12C12.2091 12 14 10.2091 14 8C14 5.79086 12.2091 4 10 4C7.79086 4 6 5.79086 6 8C6 10.2091 7.79086 12 10 12Z"
        fill="currentColor"
      />
      <path
        d="M10 14C7.33 14 2 15.34 2 18V20H18V18C18 15.34 12.67 14 10 14Z"
        fill="currentColor"
      />
      <path
        d="M21.6906 6.55978C21.6298 6.86587 21.493 7.1519 21.2929 7.39128L17.2128 12.271L17.6397 12.6299C17.7696 12.7391 17.7863 12.9336 17.677 13.0643C17.5677 13.195 17.3738 13.2125 17.2439 13.1033L16.0996 12.1414L15.6824 12.6403C15.8666 12.96 15.8422 13.375 15.5911 13.6753C15.2705 14.0587 14.7019 14.1099 14.321 13.7897C13.9401 13.4695 13.8912 12.8992 14.2118 12.5158C14.4628 12.2155 14.866 12.119 15.2121 12.245L15.6293 11.746L14.4852 10.7843C14.3553 10.6751 14.3387 10.4806 14.448 10.3499C14.5573 10.2192 14.7512 10.2017 14.881 10.3109L15.695 10.9951L19.7751 6.11535C19.9752 5.87598 20.2323 5.69101 20.5223 5.57765L22 5L21.6906 6.55978Z"
        fill="currentColor"
      />
    </svg>
  );
}
