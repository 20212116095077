import { appConfig } from '@front/config';
import { Difficulty, ExamMode } from '@lib/web/apis';

import {
  CreateQuizActions,
  CreateQuizActionTypes,
  CreateQuizState,
} from './type';

export default function reducer(
  state: CreateQuizState,
  action: CreateQuizActions
): CreateQuizState {
  switch (action.type) {
    case CreateQuizActionTypes.SET_DEFAULT_SETTINGS_BY_SECTION: {
      const maxQuestionCount = Math.min(
        action.payload.maxCount,
        appConfig.maxQuestionPickCount
      );
      const defaultValues = {
        tags: [],
        marks: [],
        isOpenTimer: true,
        mode: ExamMode.Practice,
        difficulty: Difficulty.Easy,
        challengers: [],
        questionCount: Math.min(
          action.payload.section.questionCount,
          maxQuestionCount
        ),
      };
      return {
        values: { ...defaultValues },
        defaultValues,
        officialQuestionCount: action.payload.section.questionCount,
        sectionId: action.payload.section.sectionExamId,
        isOfficial: false,
        isLocked: false,
        isLoading: false,
        isChallengersLoading: false,
        isRandomChallengers: false,
        customizeDisabled: [],
        maxQuestionCount,
        minQuestionCount: 1,
      };
    }

    case CreateQuizActionTypes.SET_CUSTOMIZE_DISABLED: {
      return {
        ...state,
        customizeDisabled: action.payload,
      };
    }

    case CreateQuizActionTypes.SET_SECTION_LOADING: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }

    case CreateQuizActionTypes.SET_CHALLENGERS_LOADING: {
      return {
        ...state,
        isChallengersLoading: action.payload,
      };
    }

    case CreateQuizActionTypes.SET_IS_OFFICIAL: {
      return {
        ...state,
        isOfficial: action.payload,
      };
    }

    case CreateQuizActionTypes.SET_IS_LOCKED: {
      return {
        ...state,
        isLocked: action.payload,
      };
    }

    case CreateQuizActionTypes.SET_IS_RANDOM_CHALLENGERS: {
      return {
        ...state,
        isRandomChallengers: action.payload,
      };
    }

    case CreateQuizActionTypes.UPDATE_VALUES: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload,
        },
      };
    }

    case CreateQuizActionTypes.UPDATE_QUIZ_INFO: {
      return {
        ...state,
        ...action.payload,
      };
    }

    case CreateQuizActionTypes.RESET_VALUES: {
      return {
        ...state,
        values: {
          ...state.defaultValues,
        },
        isOfficial: false,
      };
    }
    case CreateQuizActionTypes.RESET_TAGS: {
      return {
        ...state,
        values: {
          ...state.values,
          tags: [],
        },
      };
    }
    case CreateQuizActionTypes.RESET_MARKS: {
      return {
        ...state,
        values: {
          ...state.values,
          marks: [],
        },
      };
    }
    case CreateQuizActionTypes.RESET_QUESTIONS: {
      return {
        ...state,
        values: {
          ...state.values,
          questionCount: state.defaultValues.questionCount,
        },
        isOfficial: false,
      };
    }
    case CreateQuizActionTypes.RESET_CHALLENGERS: {
      return {
        ...state,
        values: {
          ...state.values,
          challengers: [],
        },
      };
    }
    case CreateQuizActionTypes.RESET_MODE: {
      return {
        ...state,
        values: {
          ...state.values,
          mode: ExamMode.Practice,
        },
      };
    }
    case CreateQuizActionTypes.RESET_ALL_SETTINGS: {
      if (state.isLocked) return state;

      return {
        sectionId: state.sectionId,
        defaultValues: state.defaultValues,
        minQuestionCount: state.minQuestionCount,
        maxQuestionCount: state.maxQuestionCount,
        officialQuestionCount: state.officialQuestionCount,

        values: { ...state.defaultValues },
        isOfficial: false,
        isLoading: false,
        isLocked: false,
        isChallengersLoading: false,
        isRandomChallengers: false,
        customizeDisabled: [],

        tagId: undefined,
        markId: undefined,
        quizId: undefined,
      };
    }
    default: {
      return state;
    }
  }
}
