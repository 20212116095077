import { useContext } from 'react';
import axios from 'axios';
import useSWR from 'swr';

import IaDataContext from '../core/IaData/IaDataContext';

const getMemberInfoKey = (
  userId: NullableString = '',
  memberId: NullableString = ''
) => {
  if (userId) return `v2/member/info?userId=${userId}&isGetNft=true`;
  if (memberId) return `v2/member/info?memberId=${memberId}&isGetNft=true`;
  return null;
};

interface GetMemberInfoRes {
  avatar: string;
  nftAvatar: string | null;
  displayName: string;
  distinctName: string;
  userId: string;
  memberId: string;
  isFriend: boolean;
  indicator?: string;
}

export const useIaMemberNftAvatar = ({
  userId,
  memberId,
  avatar,
  name,
}: {
  userId?: string;
  memberId?: string;
  avatar?: string | null;
  name?: string | null;
}) => {
  const { token, apiUrl = '' } = useContext(IaDataContext);
  const key = getMemberInfoKey(userId, memberId);
  const { data } = useSWR<{ data: GetMemberInfoRes }>(key, async () => {
    if (!key) return null;
    return axios
      .get(key, {
        baseURL: apiUrl,
        headers: { Authorization: `Bearer ${token}` },
      })
      .then((response) => response.data);
  });

  return {
    avatar: data?.data.nftAvatar || data?.data.avatar || avatar || '',
    name: data?.data.displayName || name || '',
  };
};
