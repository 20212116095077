import { ReactNode, useEffect, useRef, useState } from 'react';
import { alpha, Theme } from '@mui/material';
import Box from '@mui/material/Box';
import { useBaseRightPanel } from '@front/ui';
import { CUSTOM_BR } from '@lib/web/composer';
import { TextComposerPanelKeys } from '@lib/web/editor/TextComposerPanels/panel';

import BlockEditableContentPopup from './BlockEditableContentPopup';

type AnchorProps = {
  id: string;
  value: string;
  children:
    | ReactNode
    | ((props: { isHover: boolean; isShow: boolean }) => ReactNode);
  panelKey: TextComposerPanelKeys;
  placeholder?: string;
  updateAttributes: (attributes: Record<string, any>) => void;
};

const styles = {
  root: {
    height: 16,
    display: 'inline-flex',
    verticalAlign: 'middle',
  },
  anchor: {
    mt: -0.5,
    userSelect: 'none',
    width: 24,
    height: 24,
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    verticalAlign: 'middle',
    borderRadius: '4px',
  },
  hoverAnchor: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
  },
  activeAnchor: {
    bgcolor: (theme: Theme) => alpha(theme.palette.text.primary, 0.3),
  },
};

export default function Anchor({
  id,
  children,
  value,
  panelKey,
  placeholder,
  updateAttributes,
}: AnchorProps) {
  const [isShow, setIsShow] = useState(false);
  const valueRef = useRef('');
  const anchorRef = useRef<HTMLDivElement | null>(null);
  const { rightPanelParams, openRightPanel, setRightParams } =
    useBaseRightPanel();

  const { hoverAnchorId, activeAnchorId } = rightPanelParams;

  const isHover = id === hoverAnchorId;
  const shouldOpenMenu = id === activeAnchorId;

  const handleMouseEnter = () => {
    setRightParams((st) => ({ ...st, hoverAnchorId: id }));
  };
  const handleMouseLeave = () => {
    setRightParams((st) => ({ ...st, hoverAnchorId: null }));
  };

  const handleOpenMenu = () => {
    valueRef.current = value;

    setIsShow(true);

    openRightPanel(panelKey, {
      activeAnchorId: id,
    });
  };

  const handleClose = () => {
    setIsShow(false);
    setRightParams((st) => ({
      ...st,
      hoverAnchorId: null,
      activeAnchorId: null,
    }));
  };

  const handleDone = async (newValue: string) => {
    updateAttributes({
      value: newValue.replaceAll('\n', CUSTOM_BR),
    });
    handleClose();
  };

  useEffect(() => {
    // Click from RHS
    if (shouldOpenMenu) {
      valueRef.current = value;

      setIsShow(true);
    }
  }, [shouldOpenMenu, value]);

  return (
    <Box sx={styles.root} component="span">
      &nbsp;
      <Box
        ref={anchorRef}
        sx={[
          styles.anchor,
          isHover && typeof children !== 'function' && styles.hoverAnchor,
          isShow && typeof children !== 'function' && styles.activeAnchor,
        ]}
        component="span"
        onClick={handleOpenMenu}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        {typeof children === 'function'
          ? children({ isHover, isShow })
          : children}
      </Box>
      <BlockEditableContentPopup
        show={isShow}
        anchorEl={anchorRef.current}
        defaultValue={valueRef.current.replaceAll(CUSTOM_BR, '\n')}
        placeholder={placeholder}
        onClose={handleClose}
        onDone={handleDone}
      />
    </Box>
  );
}
