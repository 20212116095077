import { useContext, useEffect } from 'react';

import { usePassageEl } from '../PassageEl';

import HighlightWrapContext from './HighlightWrapContext';

export function useRenderHighlighter(
  triggerRender?: number | string | boolean,
  targetElement?: HTMLElement | null
) {
  const [highlightOrderMap] = useContext(HighlightWrapContext);

  const { passageEl } = usePassageEl();

  useEffect(() => {
    const allHighlightChild = (targetElement || document).querySelectorAll(
      '.inline-highlight'
    );
    const allHighlightTarget = (targetElement || document).querySelectorAll(
      '.inline-highlight-anchor'
    );

    allHighlightChild.forEach((highlightElement) => {
      const targetId = highlightElement.getAttribute('data-render-target-id');

      if (targetId && highlightOrderMap[targetId]) {
        const orderElement = highlightElement.querySelector('.highlight-order');
        if (orderElement) {
          orderElement.innerHTML = highlightOrderMap[targetId].toString();
        }
      }
    });

    allHighlightTarget.forEach((highlightElement) => {
      const targetId = highlightElement.getAttribute('data-render-id');

      if (targetId && highlightOrderMap[targetId]) {
        const orderElement = highlightElement.querySelector('.highlight-order');
        if (orderElement) {
          orderElement.innerHTML = highlightOrderMap[targetId].toString();
        }
      }
    });
  }, [triggerRender, highlightOrderMap, passageEl, targetElement]);
}
