export function getIndicators(indicator?: BaseMemberInfo['indicator']) {
  if (!indicator) return undefined;
  const output: { icon: string; name: string }[] = [];

  const indicatorArr =
    typeof indicator === 'string'
      ? [indicator]
      : [indicator.paid, indicator.ambassador];

  indicatorArr.forEach((indicatorName) => {
    switch (indicatorName) {
      case 'AmbassadorIndicatorEnabled':
        output.push({
          icon: 'BadgeStudentAmbassador',
          name: 'Student Ambassador',
        });
        break;
      case 'PaidIndicatorEnabledBasic':
        output.push({ icon: 'BadgeBasicPlan', name: 'Premium User' });
        break;
      case 'PaidIndicatorEnabledPro':
        output.push({ icon: 'BadgeProPlan', name: 'Pro User' });
        break;
      case 'PaidIndicatorEnabledUnlimited':
        output.push({ icon: 'BadgeUnlimitedPlan', name: 'Unlimited User' });
        break;
      default:
        break;
    }
  });
  return output;
}

export function shortEmail(email: string, maxLength = 16) {
  const [username, domain] = email.split('@');
  return `${username.slice(0, maxLength)}...@${domain}`;
}

export function getPerQuestionTime(questionCount: number, seconds: number) {
  const perQuestionSeconds = seconds / questionCount;

  if (perQuestionSeconds < 60) {
    return {
      context: 'second',
      count: Math.round(perQuestionSeconds),
    };
  }
  return {
    context: 'minute',
    count: Math.round(perQuestionSeconds / 60),
  };
}
const displayUtils = {
  getIndicators,
  shortEmail,
};

export default displayUtils;
