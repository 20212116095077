import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useState,
} from 'react';
import { BlockNoteEditor } from '@blocknote/core';

type TextComposerCommands = {
  insertContent: (text: string) => void;
  clearContent: () => void;
  focus: () => void;
};

export const TextComposerContext = React.createContext<{
  editor: BlockNoteEditor<any>;
  setEditor: Dispatch<SetStateAction<BlockNoteEditor<any>>>;
  commands: TextComposerCommands;
  setCommands: Dispatch<SetStateAction<TextComposerCommands>>;
  selectedBlockId: string | null;
  setSelectedBlockId: Dispatch<SetStateAction<string | null>>;
}>({
  commands: {} as TextComposerCommands,
  setCommands: () => {},
  editor: {} as BlockNoteEditor,
  setEditor: () => {},
  selectedBlockId: null,
  setSelectedBlockId: () => {},
});

export const TextComposerProvider = ({ children }: PropsWithChildren) => {
  const [editor, setEditor] = useState<BlockNoteEditor>({} as BlockNoteEditor);
  const [commands, setCommands] = useState<TextComposerCommands>(
    {} as TextComposerCommands
  );
  const [selectedBlockId, setSelectedBlockId] = useState<string | null>(null);

  return (
    <TextComposerContext.Provider
      value={{
        editor,
        setEditor,
        commands,
        setCommands,
        selectedBlockId,
        setSelectedBlockId,
      }}
    >
      {children}
    </TextComposerContext.Provider>
  );
};
