import { forwardRef, MouseEvent, ReactNode, useState } from 'react';
import { useMediaQuery } from '@mui/material';
import { BoxProps } from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import { alpha, Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { SystemStyleObject } from '@mui/system';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';

import ResponsiveTooltip from '../Tooltip/ResponsiveTooltip';

export type StatusTagProps = {
  name: string;
  color:
    | 'default'
    | 'success'
    | 'error'
    | 'warning'
    | 'info'
    | 'text'
    | 'yellow'
    | 'orange'
    | 'red'
    | 'green'
    | 'blue';
  active?: boolean;
  sx?: BoxProps['sx'];
  icon?: ReactNode;
  onClick?: (ev: MouseEvent<HTMLElement>) => void;
  size?: 'small' | 'medium';
  tip?: string;
};

const styles = {
  root: {
    display: 'inline-flex',
    alignItems: 'center',
    gap: '2px',
    pl: '6px',
    pr: '12px',

    borderRadius: '26px',
  },
};

const getColorValues = (
  color: 'yellow' | 'orange' | 'red' | 'green' | 'blue'
) => {
  return {
    styles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.highlight[color], 0.05),
      color: `highlight.${color}`,
    },
    activeStyles: {
      backgroundColor: `highlight.${color}`,
      color: 'background.darker',
    },
    hoverStyles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.highlight[color], 0.1),
    },
  };
};

const byTypeValues: Record<
  StatusTagProps['color'],
  {
    styles:
      | SystemStyleObject<Theme>
      | ((theme: Theme) => SystemStyleObject<Theme>);
    activeStyles:
      | SystemStyleObject<Theme>
      | ((theme: Theme) => SystemStyleObject<Theme>);
    hoverStyles:
      | SystemStyleObject<Theme>
      | ((theme: Theme) => SystemStyleObject<Theme>);
  }
> = {
  default: {
    styles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.text.primary, 0.05),
      color: 'grey.500',
    },
    activeStyles: {
      backgroundColor: 'grey.500',
      color: 'background.darker',
    },
    hoverStyles: {
      '@media (hover: hover)': {
        '&:hover': {
          backgroundColor: (theme: Theme) =>
            alpha(theme.palette.text.primary, 0.1),
        },
      },
    },
  },
  success: {
    styles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.success.light, 0.05),
      color: 'success.light',
    },
    activeStyles: {
      backgroundColor: 'success.light',
      color: 'background.darker',
    },
    hoverStyles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.success.light, 0.1),
    },
  },
  error: {
    styles: {
      backgroundColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.05),
      color: 'error.dark',
    },
    activeStyles: {
      backgroundColor: 'error.dark',
      color: 'background.darker',
    },
    hoverStyles: {
      backgroundColor: (theme: Theme) => alpha(theme.palette.error.dark, 0.1),
    },
  },
  info: {
    styles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.primary.light, 0.05),
      color: 'primary.light',
    },
    activeStyles: {
      backgroundColor: 'primary.light',
      color: 'background.darker',
    },
    hoverStyles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.primary.light, 0.1),
    },
  },
  warning: {
    styles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.warning.light, 0.05),
      color: 'warning.light',
    },
    activeStyles: {
      backgroundColor: 'warning.light',
      color: 'background.darker',
    },
    hoverStyles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.warning.light, 0.1),
    },
  },
  text: {
    styles: {
      backgroundColor: (theme: Theme) =>
        alpha(theme.palette.text.primary, 0.05),
      color: 'text.primary',
    },
    activeStyles: {
      backgroundColor: 'text.primary',
      color: 'background.darker',
    },
    hoverStyles: {
      backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    },
  },
  yellow: getColorValues('yellow'),
  orange: getColorValues('orange'),
  green: getColorValues('green'),
  red: getColorValues('red'),
  blue: getColorValues('blue'),
};

const StatusTag = forwardRef<HTMLButtonElement, StatusTagProps>(
  (
    {
      name,
      color,
      active,
      icon,
      sx,
      tip,
      onClick,
      size = 'medium',
    }: StatusTagProps,
    ref
  ) => {
    const [hovered, setHovered] = useState(false);
    const value = byTypeValues[color];
    const mdDown = useMediaQuery((theme: Theme) =>
      theme.breakpoints.down('md')
    );

    const sxProps = Array.isArray(sx) ? sx : [sx];
    const { height, variant } = {
      small: {
        height: '21px',
        variant: 'body2' as const,
      },
      medium: {
        height: '26px',
        variant: 'body1' as const,
      },
    }[size];

    const handleClick = (ev: MouseEvent<HTMLButtonElement>) => {
      if (!onClick && !tip) return;
      onClick?.(ev);
    };

    const handleMouseEnter = () => {
      setHovered(true);
    };

    const handleMouseLeave = () => {
      setHovered(false);
    };

    const renderButton = (
      <ButtonBase
        sx={[
          styles.root,
          active ? value.activeStyles : value.styles,
          hovered && value.hoverStyles,
          { height },
          ...sxProps,
        ]}
        onClick={handleClick}
        ref={ref}
        disabled={!onClick}
        component="span"
      >
        {icon ?? <ChatNotificationDotIcon width={16} height={16} />}
        <Typography variant={variant}>{name}</Typography>
      </ButtonBase>
    );

    if (tip) {
      return (
        <ResponsiveTooltip title={mdDown ? name : undefined} content={tip}>
          <span
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className="status-tag-tooltip-inner"
          >
            {renderButton}
          </span>
        </ResponsiveTooltip>
      );
    }
    return renderButton;
  }
);

export default StatusTag;
