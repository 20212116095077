import { useCallback, useEffect, useState } from 'react';
import { useAddWatchingEvent } from '@lib/web/thread/hooks/core/useAddWatchingEvent';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { safeQueryChannels } from '@lib/web/thread/utils/channelUtils';
import { cloneDeep } from 'lodash';
import { Channel as StreamChannel } from 'stream-chat/dist/types/channel';

export const useChannel = ({ channelCid }: { channelCid: string }) => {
  const { chatClient } = useThread();
  const [channel, setChannel] = useState<StreamChannel>();

  const fetchChannel = useCallback(async () => {
    if (!chatClient) return;

    const channels = await safeQueryChannels(chatClient, {
      cid: {
        $eq: channelCid,
      },
    });
    if (channels.length > 0) {
      setChannel(channels[0]);
    } else {
      // user might be removed from channel
      setChannel(undefined);
    }
  }, [channelCid, chatClient]);

  const reloadChannel = useCallback(async () => {
    if (!channel) return;

    try {
      await channel.query({});
      setChannel(cloneDeep(channel));
    } catch (err) {
      // user might be removed from channel
      console.warn(err);
    }
  }, [channel]);

  useEffect(() => {
    void fetchChannel();
  }, [fetchChannel]);

  useAddWatchingEvent({
    scope: 'channel-member-changed',
    key: channelCid,
    callback: (event) => {
      if (event.channel_id !== channel?.id) return;
      if (!['member.added', 'member.removed'].includes(event.type)) return;
      void reloadChannel();
    },
  });

  return {
    channel,
    reloadChannel,
  };
};
