import {
  LeagueBadgeAlpha as LeagueBadgeAlphaIcon,
  LeagueBadgeCenturion as LeagueBadgeCenturionIcon,
  LeagueBadgeCommandus as LeagueBadgeCommandusIcon,
  LeagueBadgeCosmus as LeagueBadgeCosmusIcon,
  LeagueBadgeIncendium as LeagueBadgeIncendiumIcon,
  LeagueBadgeLegatus as LeagueBadgeLegatusIcon,
  LeagueBadgeLocked as LeagueBadgeLockedIcon,
  LeagueBadgeMaximus as LeagueBadgeMaximusIcon,
  LeagueBadgeNocturnus as LeagueBadgeNocturnusIcon,
  LeagueBadgePrima as LeagueBadgePrimaIcon,
  LeagueBadgeSpectus as LeagueBadgeSpectusIcon,
} from '@front/icon';

const LEAGUE_BADGE_MAP = {
  Alpha: LeagueBadgeAlphaIcon,
  Centurion: LeagueBadgeCenturionIcon,
  Commandus: LeagueBadgeCommandusIcon,
  Cosmus: LeagueBadgeCosmusIcon,
  Incendium: LeagueBadgeIncendiumIcon,
  Legatus: LeagueBadgeLegatusIcon,
  Maximus: LeagueBadgeMaximusIcon,
  Nocturnus: LeagueBadgeNocturnusIcon,
  Prima: LeagueBadgePrimaIcon,
  Spectus: LeagueBadgeSpectusIcon,
  Locked: LeagueBadgeLockedIcon,
};

export type LeagueBadgeProps = {
  name: keyof typeof LEAGUE_BADGE_MAP;
  size?: number;
};

function LeagueBadge({ name, size }: LeagueBadgeProps) {
  if (!Object.keys(LEAGUE_BADGE_MAP).includes(name)) return null;
  const Icon = LEAGUE_BADGE_MAP[name as keyof typeof LEAGUE_BADGE_MAP];
  if (!Icon) return null;
  const sizeProps = size ? { width: size, height: size } : {};
  return <Icon {...sizeProps} />;
}

export default LeagueBadge;
