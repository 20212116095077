import { FieldError } from 'react-hook-form';
import {
  alpha,
  Autocomplete,
  Box,
  ButtonBase,
  Chip,
  Theme,
  Typography,
} from '@mui/material';
import { AutocompleteProps as MuiAutocompleteProps } from '@mui/material/Autocomplete/Autocomplete';
import {
  ActionChevronDown as ActionChevronDownIcon,
  ActionCloseSmall as ActionCloseSmallIcon,
  OtherCheckedSquareSolid as OtherCheckedSquareSolidIcon,
  OtherCheckSquareOutline as OtherCheckSquareOutlineIcon,
} from '@front/icon';
import {
  BottomSheet,
  BottomSheetProps,
  Icon,
  Scrollbar,
  TextField,
} from '@front/ui';

import { TagSelectOption } from './type';

const styles = {
  root: {
    '& .MuiInputBase-root': {
      pr: '50px',
      flexWrap: 'wrap',
    },
    '& .MuiAutocomplete-input': {
      width: 0,
      minWidth: '50px',
      pr: 0,
    },
    '& .MuiAutocomplete-tag': {
      margin: '1px 2px',
    },
  },
  paper: {
    '& .MuiAutocomplete-option': {
      pt: '1px !important',
      pb: '1px !important',
      height: '28px',
    },
  },
  option: {
    display: 'flex',
    gap: 1,
    alignItems: 'center',
    fontSize: { xs: 16, md: 14 },
  },
  optionLabel: {
    fontSize: 14,
  },
  chip: {
    height: 24,
    borderRadius: 1,
    backgroundColor: (theme: Theme) => alpha(theme.palette.text.primary, 0.1),
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: 16,
  },
  bottomSheetInputWrap: {
    px: '20px',
  },
  button: {
    height: 45,
    px: 2.5,
    width: '100%',
    justifyContent: 'flex-start',
    typography: 'body1',
  },
  optionItem: {
    height: 24,
    borderRadius: 1,
    color: (theme: Theme) => theme.palette.text.primary,
    fontSize: 16,
    px: '12px',
  },
  noOption: {
    fontSize: 14,
    px: '20px',
  },
};

type CheckboxProps = { checked: boolean };
function Checkbox({ checked }: CheckboxProps) {
  return checked ? (
    <OtherCheckedSquareSolidIcon width={16} height={16} />
  ) : (
    <OtherCheckSquareOutlineIcon width={16} height={16} />
  );
}

type AutoCompeteProps = MuiAutocompleteProps<
  TagSelectOption,
  true,
  false,
  false
>;

type OptionSelectFilterProps = {
  options: TagSelectOption[];
  disabled?: boolean;
  error?: FieldError;
  placeholder?: string;
  scrollHeight: number;
  showCheckbox?: boolean;
  handleClick: (option: TagSelectOption) => void;
  desktopAutocompleteProps: Omit<AutoCompeteProps, 'renderInput'>;
  mobileAutocompleteProps: Omit<AutoCompeteProps, 'renderInput'>;
  mobileBottomSheetProps: Omit<BottomSheetProps, 'children'>;
};

export default function TagSelect({
  options = [],
  disabled = false,
  error,
  placeholder,
  scrollHeight,
  showCheckbox,
  handleClick,
  desktopAutocompleteProps,
  mobileAutocompleteProps,
  mobileBottomSheetProps,
}: OptionSelectFilterProps) {
  const renderOption: AutoCompeteProps['renderOption'] = (
    props,
    option,
    { selected }
  ) => (
    <li {...props} onClick={() => handleClick(option)} key={option.value}>
      <Box sx={styles.option}>
        {showCheckbox && <Checkbox checked={selected} />}
        {option.emoji}
        {option.iconName && (
          <Icon name={option.iconName} width={16} height={16} />
        )}
        <Typography lineHeight="22px" sx={styles.optionLabel}>
          {option.label}
        </Typography>
      </Box>
    </li>
  );

  const renderTags: AutoCompeteProps['renderTags'] = (val, getTagProps) =>
    val.map((opt, index) => (
      <Chip
        {...getTagProps({ index })}
        label={opt.label}
        icon={
          opt.emoji || opt.iconName ? (
            <Box>
              {opt.emoji || (
                <Icon name={opt.iconName ?? ''} width={16} height={16} />
              )}
            </Box>
          ) : undefined
        }
        sx={styles.chip}
        onDelete={() => handleClick(opt)}
        deleteIcon={<ActionCloseSmallIcon width={16} height={16} />}
        key={index}
      />
    ));

  const renderInput: AutoCompeteProps['renderInput'] = ({
    InputProps,
    inputProps,
    InputLabelProps,
    size,
    ...params
  }) => (
    <TextField
      ref={InputProps.ref}
      endAdornment={InputProps.endAdornment}
      startAdornment={InputProps.startAdornment}
      {...params}
      inputProps={inputProps}
      labelIcon={null}
      placeholder={placeholder || 'Select one or more options...'}
      size="rwd"
      autoFocus
      error={!!error}
      helperText={error?.message}
      disabled={disabled}
    />
  );

  return (
    <>
      <Autocomplete
        {...desktopAutocompleteProps}
        sx={styles.root}
        slotProps={{ paper: { sx: styles.paper } }}
        componentsProps={{ popper: { sx: { marginTop: '-20px!important' } } }}
        popupIcon={<ActionChevronDownIcon width={16} height={16} />}
        renderOption={renderOption}
        renderTags={renderTags}
        renderInput={renderInput}
        disabled={disabled}
      />
      <BottomSheet {...mobileBottomSheetProps}>
        <Box sx={styles.bottomSheetInputWrap}>
          <Autocomplete
            {...mobileAutocompleteProps}
            sx={styles.root}
            popupIcon={<ActionChevronDownIcon width={16} height={16} />}
            renderTags={renderTags}
            renderInput={renderInput}
          />
        </Box>
        <Scrollbar sx={{ height: scrollHeight }}>
          {options.map((option, index) => {
            const isSelected = !!mobileAutocompleteProps.value?.some(
              (val) => val?.value === option.value
            );
            return (
              <ButtonBase
                key={index}
                sx={styles.button}
                onClick={() => handleClick(option)}
              >
                <Box sx={styles.option}>
                  {showCheckbox && <Checkbox checked={isSelected} />}
                  {option.emoji}
                  {option.iconName && (
                    <Icon name={option.iconName} width={16} height={16} />
                  )}
                  <Typography lineHeight="22px" sx={styles.optionItem}>
                    {option.label}
                  </Typography>{' '}
                </Box>
              </ButtonBase>
            );
          })}
        </Scrollbar>
        {options.length === 0 && (
          <Typography sx={styles.noOption}>No results found</Typography>
        )}
      </BottomSheet>
    </>
  );
}
