import { useCallback, useContext } from 'react';
import Router from 'next/router';
import { apis } from '@lib/web/apis';
import {
  CreatorQuestionConfigContext,
  useQuestionSideMenu,
} from '@lib/web/editor';
import { useCurrentIaClub } from '@lib/web/hooks';
import { callWithToast } from '@lib/web/utils';

import { useCreatorQuestionListData } from './useCreatorQuestionListData';

const normalizeQuestionsOrder = <T extends Array<{ order: number }>>(
  questions: T
): T =>
  questions.map((question, index) => ({
    ...question,
    order: index + 1,
  })) as T;

export const useCreatorQuestionListHandle = () => {
  const { club, sectionId } = useCurrentIaClub();
  const slug = club?.clubSlug || '';
  const { closeMenu } = useQuestionSideMenu();
  const { getQuestionPath } = useContext(CreatorQuestionConfigContext);

  const { questions, reloadQuestions, setQuestionsSize } =
    useCreatorQuestionListData();

  const loadNextPageQuestions = () => {
    void setQuestionsSize((prev) => prev + 1);
  };

  const handleDraggableStart = () => {
    closeMenu();
  };

  const handleDeletePlaylistClick = useCallback(async () => {
    if (!sectionId) {
      console.warn('missing sectionId when calling handleDeletePlaylistClick');
      return;
    }

    const [res] = await callWithToast(
      () => apis.editor.deleteAllCreatorQuestion({ sectionId }),
      {
        confirmText: 'Delete',
        confirmMsg:
          'Deleting this playlist will remove unapproved questions and can’t be undone.',
        confirmOptions: {
          type: 'warning',
          desktopSx: { maxWidth: 335 },
        },
      }
    );

    if (res) {
      void Router.push(`/club/${slug}/create-questions`);
    }
  }, [sectionId, slug]);

  // TODO: rename it to duplicateQuestion
  const handleDuplicateItemClick = useCallback(
    async (id: string) => {
      const res = await callWithToast(
        () => apis.editor.duplicateCreatorQuestion({ id }),
        {
          successMsg: 'Question Duplicated!',
        }
      );
      if (res) {
        void reloadQuestions();
      }
    },
    [reloadQuestions]
  );

  const batchDuplicateQuestion = useCallback(
    async (ids: string[]) => {
      const res = await callWithToast(
        ids.map((id) => () => apis.editor.duplicateCreatorQuestion({ id })),
        {
          successMsg: 'Question Duplicated!',
        }
      );
      if (res) {
        void reloadQuestions();
      }
    },
    [reloadQuestions]
  );

  const handleDragOverOtherItem = useCallback(
    async ({
      activeItem,
      overItem,
      newItems,
    }: {
      activeItem: GetCreatorQuestionListRes;
      overItem: GetCreatorQuestionListRes;
      newItems: GetCreatorQuestionListRes[];
    }) => {
      if (!sectionId) {
        console.warn('missing sectionId when calling handleDragOverOtherItem');
        return;
      }

      void reloadQuestions(
        callWithToast(
          () =>
            apis.editor.reorderCreatorQuestion({
              id: activeItem.id,
              sectionId,
              [activeItem.order < overItem.order
                ? 'afterQuestionId'
                : 'beforeQuestionId']: overItem.id,
            }),
          {
            showLoading: false,
          }
        ),
        {
          optimisticData: normalizeQuestionsOrder(newItems),
        }
      );
    },
    [reloadQuestions, sectionId]
  );

  const handleDragOverOtherGroupItem = useCallback(
    (questionGroupId: string) =>
      async ({
        activeItem,
        overItem,
        newItems,
      }: {
        activeItem: CreatorQuestionListItem;
        overItem: CreatorQuestionListItem;
        newItems: CreatorQuestionListItem[];
      }) => {
        if (!sectionId) {
          console.warn(
            'missing sectionId when calling handleDragOverOtherItem'
          );
          return;
        }

        void reloadQuestions(
          callWithToast(
            () =>
              apis.editor.reorderCreatorQuestion({
                id: activeItem.id,
                sectionId,
                [activeItem.order < overItem.order
                  ? 'afterQuestionId'
                  : 'beforeQuestionId']: overItem.id,
              }),
            {
              showLoading: false,
            }
          ),
          {
            optimisticData: questions.map((q) =>
              q.id === questionGroupId
                ? {
                    ...q,
                    subQuestions: normalizeQuestionsOrder(newItems),
                  }
                : q
            ),
          }
        );
      },
    [questions, reloadQuestions, sectionId]
  );

  const handleQuestionRowClick = (id: string) => {
    void Router.push(getQuestionPath(id));
  };

  // XXX: we are going to separate each handler to different purpose hooks
  return {
    loadNextPageQuestions,
    handleDraggableStart,
    handleDeletePlaylistClick,
    handleDuplicateItemClick,
    batchDuplicateQuestion,
    handleDragOverOtherItem,
    handleDragOverOtherGroupItem,
    handleQuestionRowClick,
  };
};
