/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { ReactNode } from 'react';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {
  ActionChevronLeft as ActionChevronLeftIcon,
  ActionChevronRight as ActionChevronRightIcon,
} from '@front/icon';
import {
  addMonths,
  compareAsc,
  compareDesc,
  format,
  subMonths,
} from 'date-fns';

export type DatePickerHeaderProps = {
  value: Date;
  minDate?: Date | string | number | null | undefined;
  maxDate?: Date | string | number | null | undefined;
  showGoToToday?: boolean;
  onChange: (v: any) => void;
  onTitleClick?: (event: any) => void;
  onTodayClick?: (event: any) => void;
  prevIcon?: ReactNode;
  nextIcon?: ReactNode;
};

const styles = {
  header: {
    px: 0.5,
    height: 34,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  goToday: {
    justifyContent: 'center',
  },
  todayButton: {
    px: 1,
    borderRadius: 0.5,
  },
};

export default function DatePickerHeader({
  value,
  minDate,
  maxDate,
  showGoToToday,
  onChange,
  onTitleClick,
  onTodayClick,
  prevIcon,
  nextIcon,
}: DatePickerHeaderProps) {
  const prevDate = subMonths(value, 1);
  const nextDate = addMonths(value, 1);
  const prevDisabled = minDate
    ? compareAsc(value, new Date(minDate)) === -1
    : false;
  const nextDisabled = maxDate
    ? compareDesc(value, new Date(maxDate)) === -1
    : false;

  const handleSubMonth = () => {
    onChange?.(prevDate);
  };
  const handleAddMonth = () => {
    onChange?.(nextDate);
  };

  if (showGoToToday)
    return (
      <Box sx={[styles.header, styles.goToday]}>
        <ButtonBase
          sx={styles.todayButton}
          onClick={onTodayClick}
          title="Pick year"
        >
          <Typography variant="button">Go To Today</Typography>
        </ButtonBase>
      </Box>
    );

  return (
    <Box sx={styles.header}>
      <IconButton
        size="small"
        onClick={handleSubMonth}
        disabled={prevDisabled}
        title="Previous month"
      >
        {prevIcon ? prevIcon : <ActionChevronLeftIcon />}
      </IconButton>
      <ButtonBase onClick={onTitleClick} title="Pick year">
        <Typography mx={2} variant="body1">
          {format(value, 'MMMM yyyy')}
        </Typography>
      </ButtonBase>
      <IconButton
        size="small"
        onClick={handleAddMonth}
        disabled={nextDisabled}
        title="Next month"
      >
        {nextIcon ? nextIcon : <ActionChevronRightIcon />}
      </IconButton>
    </Box>
  );
}
