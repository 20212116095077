import { useMemo } from 'react';
import { useBaseRightPanel } from '@front/ui';
import SquareAvatarGroup from '@lib/ia/src/components/SquareAvatarGroup';
import { useChannel } from '@lib/web/thread/hooks/channel/useChannel';
import { useThread } from '@lib/web/thread/hooks/core/useThread';
import { ResponsiveToolbar, ToolbarButtonItem } from '@lib/web/ui';

const enum GlobalPanelKeys {
  GlobalThreadProperties = 'GlobalThreadProperties',
}

type GlobalPanelParams = {
  [GlobalPanelKeys.GlobalThreadProperties]: {
    channelCid: string;
  };
};

const styles = {
  avatarGroup: {
    display: 'inline-flex',
  },
};
export default function ThreadChatToolbar({
  channelCid,
  toolbarItems,
  shouldKeepOpened,
}: {
  channelCid: string;
  toolbarItems?: Array<boolean | ToolbarButtonItem>;
  shouldKeepOpened?: boolean;
}) {
  const { openRightPanel } = useBaseRightPanel<GlobalPanelParams>();
  const { getThreadUser } = useThread();
  const { channel } = useChannel({ channelCid });

  const avatars = useMemo(
    () =>
      Object.values(channel?.state.members || {}).map((m) => {
        const threadUser = getThreadUser(m.user?.id);
        return {
          avatarUrl: threadUser?.image || '',
          name: threadUser?.name || '',
        };
      }),
    [channel?.state.members, getThreadUser]
  );

  const additionalToolbarItems: Array<boolean | ToolbarButtonItem> = [
    {
      type: 'Properties',
      onClick: () =>
        openRightPanel(GlobalPanelKeys.GlobalThreadProperties, {
          channelCid,
        }),
    },
  ];

  return (
    <>
      <SquareAvatarGroup
        sx={styles.avatarGroup}
        items={avatars}
        max={5}
        size={16}
        spacing="-4px"
      />

      <ResponsiveToolbar
        items={[...(toolbarItems || []), ...additionalToolbarItems]}
        shouldKeepOpened={shouldKeepOpened}
      />
    </>
  );
}
