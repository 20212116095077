import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useDebounce } from '@front/helper';

import {
  setKeyword as setKeywordFn,
  setVisible,
} from '../../reducers/searchReducer';
import { useAppDispatch, useAppSelector } from '../redux';

export function useSearch() {
  const dispatch = useAppDispatch();
  const search = useAppSelector((st) => st.search);
  const { pathname } = useRouter();
  const debouncedSearch = useDebounce(search?.keyword?.trim());

  useEffect(() => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(false));
  }, [dispatch, pathname]);

  const toggleSearch = () => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(!search.visible));
  };

  const hideSearch = () => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(false));
  };

  const showSearch = () => {
    dispatch(setKeywordFn(''));
    dispatch(setVisible(true));
  };

  return {
    debouncedSearch,
    keyword: search?.keyword,
    visible: !!search?.visible,
    hasSearch: !!search?.keyword,
    setKeyword: (keyword?: string) => dispatch(setKeywordFn(keyword)),
    showSearch,
    hideSearch,
    toggleSearch,
  };
}
