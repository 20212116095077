import { alpha, Box, Theme, Typography, useMediaQuery } from '@mui/material';
import { useDateFormat } from '@front/helper';
import { MaskIcon, SquareAvatar } from '@front/ui';
import Emphasis from '@lib/ia/src/components/Emphasis';
import Icon from '@lib/ia/src/components/Icon';

import { NotificationIconConfig, NotificationItemConfig } from '../../types';
import NotificationCta from '../NotificationCta/NotificationCta';

const styles = {
  root: {
    display: 'flex',
    alignItems: 'flex-start',
    gap: 1,
    width: '100%',

    px: 2.5,
    '.base-layout-right-panel &': {
      px: 1.5,
    },
    py: 1,
  },
  hoverable: {
    '@media (hover: hover)': {
      '&:not(:disabled):hover': {
        backgroundColor: (theme: Theme) =>
          alpha(theme.palette.text.primary, 0.05),
      },
    },
  },
  maskIcon: {
    width: { xs: 28, md: 32 },
    height: { xs: 28, md: 32 },
    '& svg': {
      width: { xs: 16, md: 24 },
      height: { xs: 16, md: 24 },
    },
  },
  main: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    gap: 0.5,
  },

  header: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
  },
  timestamp: {
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },

  content: {
    width: '100%',
    em: {
      fontStyle: 'normal',
      color: 'primary.light',
    },
  },
  cta: {
    mt: 0.5,
    display: 'flex',
    gap: 2,
  },

  helperText: {
    width: '100%',
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.64),
  },
};

type NotificationIconProps = NotificationIconConfig;

function NotificationIcon({
  bgColor,
  color,
  size,
  ...rest
}: NotificationIconProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  if ('avatarUrl' in rest) {
    return (
      <SquareAvatar
        size={size || mdUp ? 32 : 28}
        src={rest.avatarUrl}
        color={color}
        bgcolor={bgColor}
      >
        {rest.display}
      </SquareAvatar>
    );
  }
  return (
    <MaskIcon
      sx={[
        styles.maskIcon,
        {
          color,
          background: bgColor,
        },
      ]}
    >
      <Icon name={rest.name} width={size || 24} height={size || 24} />
    </MaskIcon>
  );
}

type NotificationItemProps = NotificationItemConfig & {
  hoverDisabled?: boolean;
};

export default function NotificationItem({
  name,
  icon,
  content,
  helperText,
  sentTime,
  hoverDisabled = false,
  cta = [],
}: NotificationItemProps) {
  const { displayMessageTimeFormat } = useDateFormat();
  return (
    <Box sx={[styles.root, !hoverDisabled && styles.hoverable]}>
      <Box>
        <NotificationIcon {...icon} />
      </Box>
      <Box sx={styles.main}>
        <Box sx={styles.header}>
          <Typography variant="body2" display="inline" fontWeight={700}>
            {name}
          </Typography>
          <Typography variant="caption" sx={styles.timestamp}>
            {displayMessageTimeFormat(sentTime)}
          </Typography>
        </Box>
        <Box sx={styles.content}>
          <Typography variant="body2">
            <Emphasis content={content} />
          </Typography>
        </Box>
        {cta.length > 0 && (
          <Box sx={styles.cta}>
            {cta.map((item, index) => (
              <NotificationCta key={index} {...item} />
            ))}
          </Box>
        )}
        {helperText && (
          <Box sx={styles.helperText}>
            <Typography variant="caption">
              <Emphasis content={helperText} />
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}
