import { useRef } from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { extractNumber } from '@lib/ia/src/utils/number';

import { TableLayoutNumberCell, TableLayoutRow } from '../../types';
import IaTextBasedEditableCell from '../IaTableEditable/IaTextBasedEditableCell';

export type IaNumberCellProps = {
  row: TableLayoutRow;
  cell: TableLayoutNumberCell;
  columnKey: string;
};

const styles = {
  root: {
    px: 1,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  text: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
};
export default function IaNumberCell({
  row,
  columnKey,
  cell,
}: IaNumberCellProps) {
  const {
    value,
    fractionDigits,
    keepFractionDigitsWhenZero = false,
    asSequence = false,
    editable,
    actionMap,
    placeholder,
  } = cell;

  let displayValue;

  if (value === null) {
    displayValue = '';
  } else if (asSequence) {
    displayValue = value.toString();
  } else if (value === 0 && !keepFractionDigitsWhenZero) {
    displayValue = 0;
  } else {
    displayValue = value.toFixed(fractionDigits);
  }

  const ref = useRef<HTMLDivElement>();

  return (
    <>
      <Box sx={styles.root} ref={ref}>
        <Typography variant="body2" sx={styles.text}>
          {displayValue}
        </Typography>
      </Box>
      <IaTextBasedEditableCell<number | null>
        containerRef={ref}
        actionMap={actionMap}
        editable={editable}
        placeholder={placeholder}
        columnKey={columnKey}
        row={row}
        initValue={value}
        filterValue={(val: string) => extractNumber(val)}
        inputSx={{
          '& .MuiInputBase-input': {
            textAlign: 'right',
          },
        }}
      />
    </>
  );
}
