import React from 'react';

import { TableLayoutHeadCell } from '../../types';

import { IaHeadDefaultCell, IaHeadIconTextCell } from './index';

export type IaTableHeadCellProps = TableLayoutHeadCell;

export default function IaTableHeadCell(headCell: IaTableHeadCellProps) {
  switch (headCell.type) {
    case 'default':
      return <IaHeadDefaultCell {...headCell} />;
    case 'iconText':
      return <IaHeadIconTextCell {...headCell} />;
    default:
      console.warn('Unknown head cell type:', headCell);
      return null;
  }
}
