import { useEffect, useRef, useState } from 'react';
import { useFieldErrorMessage } from '@lib/web/editor/hooks/useFieldErrorMessage';
import { GridInput } from '@lib/web/ui';

import { useCurrentQuestion } from '../../../hooks';
import { acceptedRegex, hints, valuesRegex } from '../../../settings/grid-in';
import { getGridErrorMessage } from '../../../utils/gridValidator';

import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorAnswerGridIn() {
  const [value, setValue] = useState('');
  const { setFieldErrorMessage } = useFieldErrorMessage();

  const { questionId } = useCurrentQuestion();
  const prevValueRef = useRef('');
  const { correctAnswerValue, isLoadingInitialData } = useData(questionId);
  const { handleSaveValue } = useEventHandle(questionId);

  function checkValue(newValue: string): void {
    const trimValue = newValue.trim();
    const error = getGridErrorMessage(trimValue);
    if (error && prevValueRef.current) {
      setFieldErrorMessage(questionId, 'answerArea', [error]);
    } else {
      setFieldErrorMessage(questionId, 'answerArea', []);

      // avoid too many api calls
      if (prevValueRef.current !== trimValue) {
        handleSaveValue(trimValue);
        prevValueRef.current = trimValue;
      }
    }
  }

  function handleChange(newValue: string): void {
    setValue(newValue);
    checkValue(newValue);
  }

  useEffect(() => {
    if (!isLoadingInitialData) {
      // only load initial data when questionId is changed
      setValue(correctAnswerValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingInitialData, questionId]);

  return (
    <GridInput
      valuesRegex={valuesRegex}
      acceptedRegex={acceptedRegex}
      hints={hints}
      value={value}
      onChange={handleChange}
      disabled={isLoadingInitialData}
    />
  );
}
