import Practice, {
  PracticeQuestionContent,
  PracticeQuestionFooter,
  PracticeSkeleton,
} from './Practice';
import PracticeContextProvider from './PracticeContextProvider';

export {
  PracticeContextProvider,
  PracticeQuestionContent,
  PracticeQuestionFooter,
  PracticeSkeleton,
};

export { default as PracticeMultipleChoices } from './components/QuestionContent/components/AnswerOptions/PracticeMultipleChoices';
export { BLANK_OPTION_VALUE } from './types';

export default Practice;
