import Box from '@mui/material/Box';
import ChallengeExpiredIcon from '@app/web/src/widgets/ChallengesPage/ChallengeIcon/ChallengeExpiredIcon';
import ChallengeInvitedIcon from '@app/web/src/widgets/ChallengesPage/ChallengeIcon/ChallengeInvitedIcon';
import ChallengeOngoingIcon from '@app/web/src/widgets/ChallengesPage/ChallengeIcon/ChallengeOngoingIcon';
import { MainChallenge as MainChallengeIcon } from '@front/icon';
import { ChallengeStatus, useQuizResultSummary } from '@lib/web/apis';

type ChallengeTitleIconProps = {
  quizId?: string;
  size?: number;
  iconSize?: number;
};

const styles = {
  root: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  icon: {
    width: 6,
    height: 6,
    position: 'absolute',
    top: 0,
    right: 0,
  },
};
export default function ChallengeTitleIcon({
  quizId,
  size,
  iconSize = 6,
}: ChallengeTitleIconProps) {
  const { data } = useQuizResultSummary({
    quizId,
  });

  const challenge = data?.data.challenge;

  if (!challenge) return <MainChallengeIcon width={size} height={size} />;

  const iconSx = [styles.icon, { width: iconSize, height: iconSize }];
  if (challenge.status === ChallengeStatus.NotInvited) {
    return (
      <Box sx={styles.root}>
        <MainChallengeIcon width={size} height={size} />
      </Box>
    );
  }
  if (challenge.status === ChallengeStatus.Expired) {
    return (
      <Box sx={styles.root}>
        <MainChallengeIcon width={size} height={size} />
        <ChallengeExpiredIcon sx={iconSx} />
      </Box>
    );
  }
  if (challenge.status === ChallengeStatus.Ongoing) {
    return (
      <Box sx={styles.root}>
        <MainChallengeIcon width={size} height={size} />
        <ChallengeOngoingIcon sx={iconSx} />
      </Box>
    );
  }
  return (
    <Box sx={styles.root}>
      <MainChallengeIcon width={size} height={size} />
      <ChallengeInvitedIcon sx={iconSx} />
    </Box>
  );
}
