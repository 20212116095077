const leagueNotifications: GetNotificationRes[] = [
  {
    id: '108101a3-7a56-434b-bda8-3cb141297cc1',
    tag: 'league.enter.promotion.zone.club',
    title:
      'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    body: 'Congratulations! You’re in the Alpha League promotion zone. Keep up the good work by practicing 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        body: {
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 2,
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          clubIcon: '',
          clubName: 'ACT Math',
        },
        cta: [
          {
            type: 'cta.league.rank',
            rank: 2,
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 1,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
        ],
      },
    },
    scheduledAt: '2024-01-09T02:03:07.351Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '0b8ed77f-55c1-4dca-af2c-c96740c7715f',
    tag: 'league.round.finish.stay.tier.club',
    title:
      'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    body: 'You achieved Rank 14 and kept your position in Alpha League. Keep practicing to get promoted! 💪',
    data: {
      icon: 'club',
      isBot: true,
      payload: {
        body: {
          prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          prevLeagueName: 'Alpha',
          leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
          leagueName: 'Alpha',
          rank: 14,
          clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
          clubSlug: 'act-math',
          clubIcon: '',
          clubName: 'ACT Math',
        },
        cta: [
          {
            type: 'cta.league.final.rank',
            rank: 14,
            prevLeagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            prevLeagueName: 'Alpha',
            clubSlug: 'act-math',
            zone: 2,
          },
          {
            type: 'cta.league.current',
            leagueId: '95317da8-43a0-46e5-8932-dbb181912561',
            leagueName: 'Alpha',
            clubSlug: 'act-math',
            iconPath: null,
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T00:07:36.648Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

const challengeNotification: GetNotificationRes[] = [
  {
    id: 'dc824b4c-aa52-461d-bab1-96febb6c5b4a',
    tag: 'challenge.ending.scheduler.winner.club',
    title:
      "mitch.wu+test-editor's ACT Math Challenge #1 has ended. The final result is out, you are the final winner!🏆",
    body: "<a href='/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606'><img class='avatar-challenge' src='' />mitch.wu+test-editor's ACT Math Challenge #1</a> has ended. The final result is out, you are the final winner!🏆",
    data: {
      icon: 'challenge',
      isBot: true,
      payload: {
        body: {
          challengeId: 'd7d6e9a3-d72c-4f80-98e5-9ea5e667ed61',
          challengeName: "mitch.wu+test-editor's ACT Math Challenge #1",
          quizId: '37e3c785-0a15-4478-862f-bdef8d7bb606',
          clubSlug: 'act-math',
        },
        cta: [
          {
            type: 'cta.challenge.ranking',
            rank: 1,
            quizId: '37e3c785-0a15-4478-862f-bdef8d7bb606',
            clubSlug: 'act-math',
            challengeRankLink:
              '/club/act-math/challenge/37e3c785-0a15-4478-862f-bdef8d7bb606?panel=playlist-challengers',
          },
        ],
      },
    },
    scheduledAt: '2023-11-21T00:48:48.406Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

const streakNotification: GetNotificationRes[] = [
  {
    id: '91c5a178-eda6-4330-b5ea-fd783b1032a3',
    tag: 'streak.reminder.daily.member.club',
    title: 'Complete at least 1 question to achieve your streak today 🔥',
    body: 'Complete at least 1 question to achieve your streak today 🔥',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '50bc7bb5-3126-4575-bf9c-59dd20905291',
    tag: 'streak.reminder.broken.member.club',
    title: 'Missed your practice yesterday? Streak resets to 0 🥶',
    body: 'Missed your practice yesterday? Streak resets to 0 🥶',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.reminder',
            streakCount: 1,
            streakDateStart: null,
            streakDateEnd: '2024-01-09T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: 'c083849b-7714-4e14-a6b8-02db0336efc3',
    tag: 'streak.achieved.single.day.member.club',
    title:
      'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    body: 'Streak achieved today! ✅ Keep it up, set records, and own your learning journey! 🚀✨',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.streak.achieved',
            streakCount: 1,
            streakDateStart: '2024-01-08T00:00:00.000Z',
            streakDateEnd: '2024-01-08T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-08T03:09:16.073Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '4c60714a-acaf-44a9-ab6d-ab5c266e4e02',
    tag: 'streak.achieved.multiple.day.member.club',
    title:
      'Congratulations on 7 streaks! Keep the streak alive and set new records! 🎉',
    body: 'Congratulations on 7 streaks! Keep the streak alive and set new records! 🎉',
    data: {
      icon: 'streak',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {
          streakCount: 7,
        },
        cta: [
          {
            type: 'cta.streak.reminder',
            streakCount: 7,
            streakDateStart: '2024-01-05T00:00:00.000Z',
            streakDateEnd: '2024-01-12T23:59:59.999Z',
          },
        ],
      },
    },
    scheduledAt: '2024-01-12T07:14:07.779Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

const emailNotification: GetNotificationRes[] = [
  {
    id: '1b9d6bcd-bbfd-4b2d-9b5d-ab8dfbbd4bed',
    tag: 'notification.email.verification.reminder.global',
    title: 'You have not verified your email. Please verify your email.',
    body: 'You have not verified your email. Please verify your email.',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
        cta: [
          {
            type: 'cta.email.verification.remind',
          },
        ],
      },
    },
    scheduledAt: '2024-01-11T10:00:26.937Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
  {
    id: '9b1deb4d-3b7d-4bad-9bdd-2b0d7b3dcb6d',
    tag: 'notification.email.verification.success.global',
    title: 'Your email is successfully verified ✅',
    body: 'Your email is successfully verified ✅',
    data: {
      icon: 'email',
      isBot: true,
      payload: {
        clubId: '5504015e-cecd-4afc-9dad-682350a3424d',
        sectionId: 'fbeb486e-e2f9-4f93-a606-1bb3814ee60e',
        body: {},
      },
    },
    scheduledAt: '2024-01-10T01:00:30.265Z',
    isRead: true,
    actionStatus: null,
    triggeredAt: null,
  },
];

export const mockNotifications: GetNotificationRes[] = [
  ...emailNotification,
  ...challengeNotification,
  ...leagueNotifications,
  ...streakNotification,
];
