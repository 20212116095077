import { createContext, ReactNode } from 'react';
import {
  apis,
  useCreatorQuestion,
  useCreatorQuestionAnalytics,
  useCreatorQuestionAnalyticsForReview,
  useCreatorQuestionCorrectAnswer,
  useCreatorQuestionCorrectAnswerForReview,
  useCreatorQuestionDetail,
  useCreatorQuestionDetailForReview,
  useCreatorQuestionForReview,
  useCreatorQuestionHashtags,
  useCreatorQuestionHashtagsForReview,
  useCreatorQuestionLinkedVariable,
  useCreatorQuestionLinkedVariableForReview,
  useCreatorQuestionVariable,
  useCreatorQuestionVariableForReview,
} from '@lib/web/apis';

type CreatorQuestionDetailContextValue = {
  useDetail:
    | typeof useCreatorQuestionDetail
    | typeof useCreatorQuestionDetailForReview;
  useCorrectAnswer:
    | typeof useCreatorQuestionCorrectAnswer
    | typeof useCreatorQuestionCorrectAnswerForReview;
  useCreatorQuestion:
    | typeof useCreatorQuestion
    | typeof useCreatorQuestionForReview;
  useAnalytics:
    | typeof useCreatorQuestionAnalytics
    | typeof useCreatorQuestionAnalyticsForReview;
  useHashtags:
    | typeof useCreatorQuestionHashtags
    | typeof useCreatorQuestionHashtagsForReview;
  addCreatorQuestionHashtags:
    | typeof apis.editor.addCreatorQuestionHashtags
    | typeof apis.editor.addCreatorQuestionHashtagsForReview;
  removeCreatorQuestionHashtags:
    | typeof apis.editor.removeCreatorQuestionHashtags
    | typeof apis.editor.removeCreatorQuestionHashtagsForReview;
  setCorrectAnswer:
    | typeof apis.editor.setCreatorQuestionCorrectAnswer
    | typeof apis.editor.setCreatorQuestionCorrectAnswerForReview;
  updateCreatorQuestion:
    | typeof apis.editor.updateCreatorQuestion
    | typeof apis.editor.updateCreatorQuestionForReview;
  updateCreatorQuestionDetail:
    | typeof apis.editor.updateCreatorQuestionDetail
    | typeof apis.editor.updateCreatorQuestionDetailForReview;
  getCreatorQuestionDetail:
    | typeof apis.editor.getCreatorQuestionDetail
    | typeof apis.editor.getCreatorQuestionDetailForReview;
  deleteCreatorQuestion:
    | typeof apis.editor.deleteCreatorQuestion
    | typeof apis.editor.deleteCreatorQuestionForReview;
  useCreatorQuestionVariable:
    | typeof useCreatorQuestionVariable
    | typeof useCreatorQuestionVariableForReview;
  useCreatorQuestionLinkedVariable:
    | typeof useCreatorQuestionLinkedVariable
    | typeof useCreatorQuestionLinkedVariableForReview;
  addCreatorQuestionVariable:
    | typeof apis.editor.addCreatorQuestionVariable
    | typeof apis.editor.addCreatorQuestionVariableForReview;
  duplicateCreatorQuestionVariable:
    | typeof apis.editor.duplicateCreatorQuestionVariable
    | typeof apis.editor.duplicateCreatorQuestionVariableForReview;
  updateCreatorQuestionVariable:
    | typeof apis.editor.updateCreatorQuestionVariable
    | typeof apis.editor.updateCreatorQuestionVariableForReview;
  deleteCreatorQuestionVariable:
    | typeof apis.editor.deleteCreatorQuestionVariable
    | typeof apis.editor.deleteCreatorQuestionVariableForReview;
  getLinkedCreatorQuestionVariable:
    | typeof apis.editor.getLinkedCreatorQuestionVariable
    | typeof apis.editor.getLinkedCreatorQuestionVariableForReview;
  linkCreatorQuestionVariable:
    | typeof apis.editor.linkCreatorQuestionVariable
    | typeof apis.editor.linkCreatorQuestionVariableForReview;
  unlinkCreatorQuestionVariable:
    | typeof apis.editor.unlinkCreatorQuestionVariable
    | typeof apis.editor.unlinkCreatorQuestionVariableForReview;
  getCreatorQuestion:
    | typeof apis.editor.getCreatorQuestion
    | typeof apis.editor.getCreatorQuestionForReview;
  getCreatorQuestionCorrectAnswer:
    | typeof apis.editor.getCreatorQuestionCorrectAnswer
    | typeof apis.editor.getCreatorQuestionCorrectAnswerForReview;
  publishCreatorQuestion:
    | typeof apis.editor.publishCreatorQuestion
    | typeof apis.editor.publishCreatorQuestionForReview;
  getCreatorQuestionGroupItem:
    | typeof apis.editor.getCreatorQuestionGroupItem
    | typeof apis.editor.getCreatorQuestionGroupItemForReview;
  addCreatorQuestionComponent:
    | typeof apis.editor.addCreatorQuestionComponent
    | typeof apis.editor.addCreatorQuestionComponentForReview;
  removeCreatorQuestionComponent:
    | typeof apis.editor.removeCreatorQuestionComponent
    | typeof apis.editor.removeCreatorQuestionComponentForReview;
};

const defaultApiCall = (apiName: string) => (): never => {
  throw new Error(
    `Missing provide apis in CreatorQuestionDetailProvider: ${apiName}`
  );
};

const defaultValue: CreatorQuestionDetailContextValue = {
  useDetail: defaultApiCall('useDetail'),
  useCorrectAnswer: defaultApiCall('useCorrectAnswer'),
  useCreatorQuestion: defaultApiCall('useCreatorQuestion'),
  useAnalytics: defaultApiCall('useAnalytics'),
  useHashtags: defaultApiCall('useHashtags'),
  addCreatorQuestionHashtags: defaultApiCall('addCreatorQuestionHashtags'),
  removeCreatorQuestionHashtags: defaultApiCall(
    'removeCreatorQuestionHashtags'
  ),
  setCorrectAnswer: defaultApiCall('setCorrectAnswer'),
  updateCreatorQuestion: defaultApiCall('updateCreatorQuestion'),
  updateCreatorQuestionDetail: defaultApiCall('updateCreatorQuestionDetail'),
  getCreatorQuestionDetail: defaultApiCall('getCreatorQuestionDetail'),
  deleteCreatorQuestion: defaultApiCall('deleteCreatorQuestion'),
  useCreatorQuestionVariable: defaultApiCall('useCreatorQuestionVariable'),
  useCreatorQuestionLinkedVariable: defaultApiCall(
    'useCreatorQuestionLinkedVariable'
  ),
  addCreatorQuestionVariable: defaultApiCall('addCreatorQuestionVariable'),
  duplicateCreatorQuestionVariable: defaultApiCall(
    'duplicateCreatorQuestionVariable'
  ),
  updateCreatorQuestionVariable: defaultApiCall(
    'updateCreatorQuestionVariable'
  ),
  deleteCreatorQuestionVariable: defaultApiCall(
    'deleteCreatorQuestionVariable'
  ),
  getLinkedCreatorQuestionVariable: defaultApiCall(
    'getLinkedCreatorQuestionVariable'
  ),
  linkCreatorQuestionVariable: defaultApiCall('linkCreatorQuestionVariable'),
  unlinkCreatorQuestionVariable: defaultApiCall(
    'unlinkCreatorQuestionVariable'
  ),
  getCreatorQuestion: defaultApiCall('getCreatorQuestion'),
  getCreatorQuestionCorrectAnswer: defaultApiCall(
    'getCreatorQuestionCorrectAnswer'
  ),
  publishCreatorQuestion: defaultApiCall('publishCreatorQuestion'),
  getCreatorQuestionGroupItem: defaultApiCall('getCreatorQuestionGroupItem'),
  addCreatorQuestionComponent: defaultApiCall('addCreatorQuestionComponent'),
  removeCreatorQuestionComponent: defaultApiCall(
    'removeCreatorQuestionComponent'
  ),
};

export const CreatorQuestionDetailContext =
  createContext<CreatorQuestionDetailContextValue>(defaultValue);

type CreatorQuestionDetailProviderProps = {
  children: ReactNode;
  value?: Partial<CreatorQuestionDetailContextValue>;
};

export function CreatorQuestionDetailProvider({
  value = defaultValue,
  children,
}: CreatorQuestionDetailProviderProps) {
  return (
    <CreatorQuestionDetailContext.Provider
      value={{ ...defaultValue, ...value }}
    >
      {children}
    </CreatorQuestionDetailContext.Provider>
  );
}
