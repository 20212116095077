import { useEffect, useState } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import { format as dateFormat, isValid } from 'date-fns';

import Button from '../../atoms/Button/Button';
import TextButton from '../../atoms/Button/TextButton';
import type { DatePickerProps } from '../DatePicker';
import DatePicker from '../DatePicker';
import { getBaseDateValue } from '../DatePicker/datePicker.helper';
import { DatePickerTypes, DateValue } from '../DatePicker/types/dateTypes';

export type DatePickerPanelProps = {
  value: DatePickerProps['value'];
  title?: string;
  defaultValue?: DateValue;
  type?: DatePickerTypes;
  sx?: BoxProps['sx'];
  styleType?: 'default' | 'mobile';
  initDate?: DatePickerProps['initDate'];
  minDate?: DatePickerProps['minDate'];
  maxDate?: DatePickerProps['maxDate'];
  renderDayButton?: DatePickerProps['renderDayButton'];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onChange?: (newValue: any) => void;
};

const styles = {
  defaultRoot: {
    width: 320,
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? 'background.darker'
        : 'background.default',
    borderRadius: '10px',
    boxShadow:
      '0px 4px 5px rgba(0, 0, 0, 0.07), 0px 1px 10px rgba(0, 0, 0, 0.06), 0px 2px 4px rgba(0, 0, 0, 0.1)',
  },
  mobileRoot: {
    width: '100%',
  },
  defaultTitle: { p: 2 },
  mobileTitle: {
    py: 2,
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    p: 2,
    gap: 2,
  },
  mobileTitleText: {
    mb: 2.5,
    opacity: 0.5,
  },
  defaultTitleText: {
    mb: 0.5,
  },
  mobileAction: {
    pt: 3,
    '& .MuiButtonBase-root': { width: '100%' },
  },
};

export default function DatePickerPanel({
  title,
  value,
  defaultValue,
  type = 'default',
  styleType,
  initDate,
  maxDate,
  minDate,
  sx,
  renderDayButton,
  onChange,
}: DatePickerPanelProps) {
  const displayDate = value || initDate;
  const [focusedDate, setFocusedDate] = useState<Date>(
    getBaseDateValue(displayDate, minDate, maxDate)
  );

  const [dateValue, setDateValue] = useState<DateValue>(value || defaultValue);

  useEffect(() => {
    setFocusedDate(getBaseDateValue(displayDate, minDate, maxDate));
  }, [displayDate, minDate, maxDate]);

  const handleConfirm = () => {
    if (!dateValue) onChange?.(dateValue);
    else if (Array.isArray(dateValue)) {
      const validDate = dateValue.map((v) => {
        if (!v) return v;
        return isValid(v) ? v : new Date(v);
      });
      onChange?.(validDate);
    } else {
      onChange?.(isValid(dateValue) ? dateValue : new Date(dateValue));
    }
  };

  const sxProps = Array.isArray(sx) ? sx : [sx];
  const isMobile = styleType === 'mobile';
  return (
    <Box sx={[isMobile ? styles.mobileRoot : styles.defaultRoot, ...sxProps]}>
      <Box sx={isMobile ? styles.mobileTitle : styles.defaultTitle}>
        {title && (
          <Typography
            variant="subtitle2"
            fontWeight={400}
            sx={isMobile ? styles.mobileTitleText : styles.defaultTitleText}
          >
            {title}
          </Typography>
        )}
        <Typography variant="h5" fontWeight={700}>
          {dateFormat(focusedDate, 'MMM, yyyy')}
        </Typography>
      </Box>
      <Box px={isMobile ? 0 : 1}>
        <DatePicker
          value={dateValue}
          width="100%"
          type={type}
          initDate={initDate}
          maxDate={maxDate}
          minDate={minDate}
          onChange={setDateValue}
          renderDayButton={renderDayButton}
        />
      </Box>
      {isMobile ? (
        <Box sx={styles.mobileAction}>
          <Button onClick={handleConfirm}>Select Date</Button>
        </Box>
      ) : (
        <Box sx={styles.action}>
          <TextButton onClick={handleConfirm}>OK</TextButton>
        </Box>
      )}
    </Box>
  );
}
