import { ReactNode } from 'react';
import { alpha, Box, Theme, Typography, useTheme } from '@mui/material';
import { SquareAvatar } from '@front/ui';
import ThemeProvider from '@lib/ia/src/components/ThemeProvider';

import { AlbumLayoutAvatarsExtensionConfig, AvatarItem } from './types';

const styles = {
  root: {
    display: 'flex',
    flexDirection: 'row-reverse',
    alignItems: 'center',
    '& > div:not(:first-of-type)': {
      mr: -1,
    },
  },
  avatarWrapper: {
    position: 'relative',
  },
  avatar: {
    mask: `url(/mask-avatar.svg) no-repeat center center`,
    maskSize: 'contain',
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? '#151515'
        : theme.palette.background.darker,
    p: '2px',
  },
  avatarBadge: {
    position: 'absolute',
    bottom: 0,
    right: 0,
    width: 18,
    height: 18,
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: (theme: Theme) =>
      theme.palette.mode === 'dark'
        ? '#151515'
        : theme.palette.background.darker,
  },
  avatarBadgeContent: {
    fontSize: '9.042px',
    fontWeight: 700,
    color: (theme: Theme) => theme.palette.text.primary,
    '& span': {
      fontSize: '5.425px',
      color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
    },
  },
};

type AvatarsContainerProps = {
  themeMode?: 'light' | 'dark';
  children: ReactNode;
};
function AvatarsContainer({ children, themeMode }: AvatarsContainerProps) {
  if (!themeMode) return children;
  return <ThemeProvider mode={themeMode}>{children}</ThemeProvider>;
}

type AvatarBadgeProps = {
  badge: AvatarItem['badge'];
};

function AvatarBadge({ badge }: AvatarBadgeProps) {
  if (!badge) return null;
  if (badge.type === 'hashtag') {
    return (
      <Box sx={styles.avatarBadge}>
        <Typography sx={styles.avatarBadgeContent}>
          <span>#</span>
          {badge.value}
        </Typography>
      </Box>
    );
  }
  return (
    <Box sx={styles.avatarBadge}>
      <Typography sx={styles.avatarBadgeContent}>{badge.value}</Typography>
    </Box>
  );
}

type AlbumLayoutAvatarsExtensionProps = {
  config: AlbumLayoutAvatarsExtensionConfig['config'];
};
export default function AlbumLayoutAvatarsExtension({
  config,
}: AlbumLayoutAvatarsExtensionProps) {
  const theme = useTheme();
  const currentTheme = theme.palette.mode;
  const {
    avatars = [],
    avatarSize = 32,
    maxDisplayNumber = 3,
    themeMode,
  } = config;
  const totalCount = avatars.length;
  const maxVisible = Math.min(avatars.length, maxDisplayNumber);
  let visibleCount = Math.min(totalCount, maxVisible);
  if (totalCount > maxDisplayNumber) {
    visibleCount -= 1;
  }
  const remainingCount = totalCount - visibleCount;
  const visibleAvatars = avatars.slice(0, visibleCount);

  return (
    <AvatarsContainer themeMode={themeMode}>
      <Box sx={styles.root}>
        {remainingCount > 0 && (
          <Box sx={[styles.avatar]}>
            <ThemeProvider mode={currentTheme}>
              <SquareAvatar size={avatarSize} blackAndWhite>
                <Typography variant="body1">+{remainingCount}</Typography>
              </SquareAvatar>
            </ThemeProvider>
          </Box>
        )}
        {visibleAvatars.reverse().map((item, index) => (
          <Box key={index} sx={styles.avatarWrapper}>
            <Box sx={styles.avatar}>
              <SquareAvatar size={avatarSize} src={item.avatarUrl}>
                {item.name}
              </SquareAvatar>
            </Box>
            <AvatarBadge badge={item.badge} />
          </Box>
        ))}
      </Box>
    </AvatarsContainer>
  );
}
