import React, { ReactNode } from 'react';
import Box, { BoxProps } from '@mui/material/Box';
import { Theme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import {
  ActionArrowLeft,
  ActionChevronLeft,
  ActionClose,
  ActionDoubleRightChevron,
  OtherMenu,
} from '@front/icon';

import IconButton, { IconButtonProps } from '../../atoms/Button/IconButton';

const ICON_MAP = {
  back: <ActionChevronLeft />,
  arrow: <ActionArrowLeft />,
  close: <ActionClose />,
  collapse: <ActionDoubleRightChevron />,
  menu: <OtherMenu />,
};

const styles = {
  root: {
    px: 2.5,
    height: 48,
    flex: '0 0 48px',
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 1,
    flex: 1,
  },
  titleText: {
    flex: 1,
    minWidth: 0,
    fontWeight: 500,
  },
};

export type BackIconType = 'back' | 'arrow' | 'close' | 'collapse' | 'menu';

export type TitleBarProps = {
  title?: ReactNode;
  titleSuffix?: ReactNode;
  sx?: BoxProps['sx'];
  toolComponent?: ReactNode;
  backIcon?: BackIconType;
  className?: string;
  customBackIcon?: ReactNode;
  customAction?: ReactNode;
  disableBack?: IconButtonProps['disabled'];
  onBackClick?: IconButtonProps['onClick'];
};

export default function TitleBar({
  sx,
  className,
  backIcon,
  customBackIcon,
  customAction,
  onBackClick,
  disableBack,
  toolComponent,
  title,
  titleSuffix,
}: TitleBarProps) {
  const sxProps = Array.isArray(sx) ? sx : [sx];
  const backIconComp = customBackIcon ?? (backIcon ? ICON_MAP[backIcon] : null);
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  return (
    <Box sx={[styles.root, ...sxProps]} className={className}>
      <Box sx={styles.title}>
        {backIconComp && (
          <IconButton
            className="title-bar-button"
            customSize={mdUp ? 24 : 28}
            onClick={onBackClick}
            disabled={disableBack}
          >
            {backIconComp}
          </IconButton>
        )}
        {customAction}
        {!!title && (
          <Typography sx={styles.titleText} component="div" variant="subtitle2">
            {title}
          </Typography>
        )}
        {titleSuffix}
      </Box>
      {toolComponent}
    </Box>
  );
}
