import { ReactNode, useState } from 'react';

import HighlightWrapContext from './HighlightWrapContext';

type HighlightWrapContextProviderProps = {
  children: ReactNode;
};

export default function HighlightWrapContextProvider({
  children,
}: HighlightWrapContextProviderProps) {
  const providerValue = useState<Record<string, number>>({});

  return (
    <HighlightWrapContext.Provider value={providerValue}>
      {children}
    </HighlightWrapContext.Provider>
  );
}
