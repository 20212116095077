import { useRouter } from 'next/router';

import useIaClub from './useIaClub';

export default function useCurrentIaClub() {
  const router = useRouter();
  const clubSlug = (router.query.clubSlug || router.query.slug) as string;

  return useIaClub(clubSlug);
}
