import { useContext, useMemo } from 'react';
import { buildHookMutate } from '@lib/web/apis';
import { CreatorQuestionDetailContext } from '@lib/web/editor/contexts';

export const useCreatorQuestionInfoData = (questionId: string) => {
  const {
    data: res,
    isLoading,
    mutate,
  } = useContext(CreatorQuestionDetailContext).useCreatorQuestion(questionId);

  const reloadInfo = useMemo(() => buildHookMutate(mutate), [mutate]);

  return {
    infoData: res?.data,
    isLoading,
    isInitializingOrLoading: !res || isLoading,
    reloadInfo,
  };
};
