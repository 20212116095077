import { useCurrentQuestion } from '../../../hooks';

import TrueFalseNotGiven from './components/TrueFalseNotGiven';
import { useData } from './hooks/useData';
import { useEventHandle } from './hooks/useEventHandle';

export default function EditorAnswerTrueFalseNotGiven() {
  const { questionId } = useCurrentQuestion();
  const { choiceValues } = useData(questionId);
  const { handleSelect } = useEventHandle(questionId);

  return (
    <TrueFalseNotGiven choiceValues={choiceValues} onSelect={handleSelect} />
  );
}
