import { ReactNode, useDeferredValue, useEffect } from 'react';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { toast } from '@front/ui';
import { ClubStatus } from '@lib/web/apis';
import { useCurrentExam, useCurrentIaClub } from '@lib/web/hooks';

import useCreateQuizAction from './hooks/useCreateQuizAction';
import useCreateQuizState from './hooks/useCreateQuizState';
import getActiveQuizButtonEl from './utils/getActiveQuizButtonEl';
import { CreateQuizProvider } from './contexts';

interface CreateQuizFormProviderProps {
  children: ReactNode;
}

function CreateQuestionContent({ children }: CreateQuizFormProviderProps) {
  const { section } = useCurrentExam();
  const { club } = useCurrentIaClub();
  const { t } = useTranslation('quiz');
  const { pathname } = useRouter();

  const { availableQuestionCount } = useCreateQuizState();
  const { setDefaultSettingsBySection, setSectionLoading, resetAllSettings } =
    useCreateQuizAction();

  useEffect(() => {
    if (section && club?.totalQuestionCount !== undefined) {
      setDefaultSettingsBySection(section, club.totalQuestionCount);
    } else {
      setSectionLoading(true);
    }
  }, [
    setDefaultSettingsBySection,
    setSectionLoading,
    section,
    club?.totalQuestionCount,
  ]);

  const deferredAvailableQuestionCount = useDeferredValue(
    availableQuestionCount
  );

  useEffect(() => {
    if (
      club?.clubStatus === ClubStatus.ComingSoon ||
      availableQuestionCount === undefined ||
      deferredAvailableQuestionCount === undefined ||
      !(deferredAvailableQuestionCount !== 0 && availableQuestionCount === 0) // not changing from non-zero to zero, return
    ) {
      return;
    }

    const activeQuizButtonEl = getActiveQuizButtonEl();
    if (!activeQuizButtonEl) return;

    toast.dismiss();
    toast.error(t('practice.adjust.question', { count: 0 }), {
      anchorEl: activeQuizButtonEl,
    });
  }, [
    availableQuestionCount,
    deferredAvailableQuestionCount,
    t,
    club?.clubStatus,
  ]);

  useEffect(() => {
    return () => {
      resetAllSettings();
    };
  }, [pathname, resetAllSettings]);

  return children;
}

export default function CreateQuizFormProvider({
  children,
}: CreateQuizFormProviderProps) {
  return (
    <CreateQuizProvider>
      <CreateQuestionContent>{children}</CreateQuestionContent>
    </CreateQuizProvider>
  );
}
