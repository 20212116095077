import React from 'react';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { alpha, Theme } from '@mui/material/styles';
import { SmallIconListLayoutDetailedIconList } from '@lib/ia/src/layouts/SmallIconListLayout/types';

import DetailedIconListItem from './DetailedIconListItem';

export type AreaDetailedIconListProps = SmallIconListLayoutDetailedIconList;

const styles = {
  title: {
    px: { xs: 2.5, md: 1.5 },
    color: (theme: Theme) => alpha(theme.palette.text.primary, 0.5),
  },
};

export default function AreaDetailedIconList({
  title,
  items,
}: AreaDetailedIconListProps) {
  return (
    <Box>
      <Typography variant="caption" sx={styles.title}>
        {title}
      </Typography>

      {items.map((item) => (
        <DetailedIconListItem key={item.id} item={item} />
      ))}
    </Box>
  );
}
