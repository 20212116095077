import {
  useCreatorQuestionCorrectAnswerData,
  useCreatorQuestionDetailData,
  useCreatorQuestionInfoData,
} from '@lib/web/editor';

import { MrqResponseValue } from '../types';

export const useData = (questionId: string) => {
  const { answerComponents } = useCreatorQuestionDetailData(questionId);
  const { infoData } = useCreatorQuestionInfoData(questionId);

  const { correctAnswerIdSet } =
    useCreatorQuestionCorrectAnswerData(questionId);

  const responseValues: MrqResponseValue[] = answerComponents.map(
    (answerComponent) => ({
      id: answerComponent.id,
      blocks: answerComponent.editorBlocks,
      selected: correctAnswerIdSet.has(answerComponent.id),
    })
  );

  return {
    responseValues,
    mrqMinCorrectAnswerNum: infoData?.mrqMinCorrectAnswerNum,
    mrqMaxCorrectAnswerNum: infoData?.mrqMaxCorrectAnswerNum,
    mrqMaxAnswerNum: infoData?.mrqMaxAnswerNum,
  };
};
