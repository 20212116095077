import {
  NFTPicture as NFTPictureIcon,
  OtherDocument as OtherDocumentIcon,
  OtherLaTex as OtherLaTexIcon,
} from '@front/icon';
import {
  basicSlashMenu,
  ComposerSlashMenuItem,
  getIsCursorOnLastBlock,
  insertOrUpdateBlock,
} from '@lib/web/composer';
import { BasicBlockTypes } from '@lib/web/composer/TextComposer/config/basicBlockTypes';
import { insertInlineBlock } from '@lib/web/composer/utils/blocksOperation';
import { validateThreadElementsBeforeInserting } from '@lib/web/thread/ThreadTextComposer';
import { ThreadBlockTypes } from '@lib/web/thread/ThreadTextComposer/config/threadBlockTypes';
import { ThreadComposerSchema } from '@lib/web/thread/ThreadTextComposer/config/threadComposerSchema';
import { v4 } from 'uuid';

const insertLatex: ComposerSlashMenuItem<ThreadComposerSchema> = {
  name: 'LaTeX',
  nodeType: ThreadBlockTypes.InlineLatex,

  execute: (editor) => {
    if (!validateThreadElementsBeforeInserting(editor)) return;

    insertInlineBlock(editor, [
      {
        type: ThreadBlockTypes.InlineLatex,
        attrs: {
          id: v4(),
        },
      },
      {
        type: 'text',
        text: ' ',
      },
    ]);
  },
  aliases: ['latex', ThreadBlockTypes.InlineLatex],
  hint: '/latex',
  group: 'Advanced Text',
  icon: <OtherLaTexIcon width={16} height={16} />,
};

const insertImage: ComposerSlashMenuItem<ThreadComposerSchema> = {
  name: 'Image',
  nodeType: ThreadBlockTypes.Image,

  execute: (editor) => {
    if (!validateThreadElementsBeforeInserting(editor)) return;

    const isLastBlock = getIsCursorOnLastBlock(editor._tiptapEditor);
    insertOrUpdateBlock<ThreadComposerSchema>(editor, [
      {
        type: ThreadBlockTypes.Image,
        props: {
          status: 'added-by-insert',
        },
      },
      ...(isLastBlock
        ? [
            {
              type: BasicBlockTypes.Paragraph as const, // add one line for better ux, otherwise user cannot add new text in the next line
            },
          ]
        : []),
    ]);
  },
  aliases: ['image'],
  hint: '/image',
  group: 'Elements',
  icon: <NFTPictureIcon width={16} height={16} />,
};

const insertFile: ComposerSlashMenuItem<ThreadComposerSchema> = {
  name: 'File',
  nodeType: ThreadBlockTypes.File,

  execute: (editor) => {
    if (!validateThreadElementsBeforeInserting(editor)) return;

    const isLastBlock = getIsCursorOnLastBlock(editor._tiptapEditor);
    insertOrUpdateBlock<ThreadComposerSchema>(editor, [
      {
        type: ThreadBlockTypes.File,
        props: {
          status: 'added-by-insert',
        },
      },
      ...(isLastBlock
        ? [
            {
              type: BasicBlockTypes.Paragraph as const, // add one line for better ux, otherwise user cannot add new text in the next line
            },
          ]
        : []),
    ]);
  },
  aliases: ['file'],
  hint: '/file',
  group: 'Elements',
  icon: <OtherDocumentIcon width={16} height={16} />,
};

export const threadSlashMenu = {
  insertLatex,
  insertImage,
  insertFile,
};

export const threadSlashMenuItems = [
  threadSlashMenu.insertLatex,
  basicSlashMenu.insertText,
  basicSlashMenu.insertHeader3,
  basicSlashMenu.insertSubtitle,
  basicSlashMenu.insertStep,
  threadSlashMenu.insertImage,
  threadSlashMenu.insertFile,
  basicSlashMenu.insertBlank,
];
