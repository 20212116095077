import React from 'react';

export default function EditorDifficultyMedium({ ...rest }) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 7C9.5 10.1784 6.4 11.7576 5 12.4473V14.2664C8.05 12.8671 10.92 10.8281 12 9.71864C13.08 10.8281 15.95 12.8671 19 14.2664V12.4473C17.6 11.7576 14.5 10.1784 12 7Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 11.548C9.32 14.1567 6.4 15.486 5 16.0957V17.9148C8 16.5555 9 16.0957 12 14.2767C15 16.0957 16 16.5455 19 17.9148V16.0957C17.6 15.486 14.68 14.1667 12 11.548Z"
        fill="currentColor"
      />
    </svg>
  );
}
