import React from 'react';
import { Box, Typography } from '@mui/material';

import IconListItem from './components/IconListItem';
import { IconListLayoutConfig } from './types';

type IconListLayoutProps = Omit<IconListLayoutConfig, 'layout'>;

const styles = {
  root: {
    py: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  title: {
    display: 'block',
    opacity: 0.5,
    py: 1,
    px: { xs: 2.5, md: '12px' },
    '&:not(:first-of-type)': { mt: 2 },
  },
};
export default function IconListLayout({
  items,
  settings,
}: IconListLayoutProps) {
  return (
    <Box sx={styles.root} className="ia-icon-list-layout">
      {items.map((item, index) => {
        if (typeof item === 'string') {
          return (
            <Typography
              key={index}
              sx={styles.title}
              variant="caption"
              className="ia-icon-list-layout_title"
            >
              {item}
            </Typography>
          );
        }
        return (
          <IconListItem
            key={item.id}
            avatarBaseSize={settings?.avatarSize}
            item={item}
          />
        );
      })}
    </Box>
  );
}
