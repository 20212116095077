import { Box } from '@mui/material';
import { ChatNotificationDot as ChatNotificationDotIcon } from '@front/icon';

const styles = {
  icon: {
    display: 'flex',
    alignItems: 'center',
    '& circle': {
      r: 8,
      stroke: '#6A6A6A',
      strokeWidth: '4px',
      fill: 'none',
      fillOpacity: '0',
    },
  },
};

export default function ChallengesOngoingIcon({ ...rest }) {
  const sx = rest.sx || {};
  const sxProps = Array.isArray(sx) ? sx : [sx];
  return (
    <Box component="span" {...rest} sx={[styles.icon, ...sxProps]}>
      <ChatNotificationDotIcon width="100%" height="100%" />
    </Box>
  );
}
