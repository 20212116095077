import { SxProps, Theme, TooltipProps } from '@mui/material';
import { ButtonProps } from '@front/ui';
import { UploadImageConfig } from '@lib/ia/src/components/ImageUploader/types';

import { IaAction } from '../../core/types';
import { FilterOptionsField } from '../../filter/types';

import { VariableOption } from './components/FormulaInput/type';

type ValidateRule<T> = {
  value: T;
  message: string;
  hiddenRule?: boolean;
};

export type FormLayoutItemRule = {
  required: string | ValidateRule<boolean>;
  min: ValidateRule<number | string>;
  max: ValidateRule<number | string>;
  minLength: ValidateRule<number>;
  maxLength: ValidateRule<number>;
  pattern: ValidateRule<RegExp>;
};

export type FormLayoutItemCustomRule = {
  syncUnique: {
    api: string;
    message: string;
    defaultValue?: string;
  };
  notInArray: {
    values: string[];
    message: string;
  };
  regex: {
    value: RegExp;
    message: string;
  };
  textInRegex: {
    value: RegExp;
    separator: string | RegExp;
    message: string;
  };
  differentAs: {
    value: string | number;
    message: string;
  };
  maxValueWithField: {
    field: string;
    message: string;
    defaultValue?: number;
  };
  minValueWithField: {
    field: string;
    message: string;
    defaultValue?: number;
  };
  formula: {
    message: string;
  };
  minDate: {
    value: Date;
    message: string;
  };
  maxDate: {
    value: Date;
    message: string;
  };
  validate: {
    field?: string;
    handler: (value: any, compareValue?: any) => string | boolean;
  };
};

export enum FormLayoutItemStatus {
  Disabled = -1,
  Enabled = 1,
}

export type FormLayoutItemActionMap = {
  blur?: Pick<IaAction, 'value'>;
  change?: Pick<IaAction, 'value'>;
  keywordChange?: Pick<IaAction, 'value'>;
};

export interface FormLayoutBaseItem {
  id?: string;
  label: string;
  name: string;
  placeholder?: string;
  defaultValue?: string;
  status?: FormLayoutItemStatus;
  disabled?: boolean;
  autofocus?: boolean;
  rules?: Partial<FormLayoutItemRule>;
  customRules?: Partial<FormLayoutItemCustomRule>;
  helperText?: string;
  labelTooltip?: string;
  textFieldSize?: 'sm' | 'md' | 'rwd';
  inputType?: string;
  icon?: string | { type: 'color'; value: string };
  errorIcon?: string;
  prefix?: {
    button?: {
      clickAction?: string;
      tooltip?: string;
    };
  } & (
    | {
        type: 'static';
        value: string;
      }
    | {
        type: 'watchField';
        field: string;
      }
  );

  suffix?:
    | {
        type: 'text';
        value: string;
      }
    | {
        type: 'icon';
        value: string;
      };

  className?: string;
  actionMap?: FormLayoutItemActionMap;
}

export type FormLayoutNoteTag =
  | { type: 'button'; sx?: SxProps<Theme>; value: string }
  | { sx?: SxProps<Theme> };

export type FormLayoutNote =
  | string
  | [
      string,
      {
        sx?: SxProps<Theme>;
        tags?: Record<string, FormLayoutNoteTag>;
      }
    ];
export type FormLayoutGapItem = {
  type: 'gap';
  value: number | string | (string | number)[];
};

export type FormLayoutDividerItem = {
  type: 'divider';
  text: string;
  textOnly?: boolean;
};

export type FormLayoutActionsItem = {
  type: 'actions';
  items: (IaAction & {
    variant?: ButtonProps['variant'];
    color?: ButtonProps['color'];
    disabled?: boolean;
  })[];
};

type AvatarStyle = { background: string; color: string };

export type FormLayoutAvatarItem = Omit<FormLayoutBaseItem, 'actionMap'> & {
  type: 'avatar';
  targetType: 'link' | 'panel';
  avatarName?:
    | { type: 'static'; value: string }
    | { type: 'watchName'; nameField: string; defaultName?: string };
  avatarStyle?:
    | { type: 'static'; value: AvatarStyle }
    | {
        type: 'watchName';
        nameField: string;
        styleChoices: AvatarStyle[];
      };
  avatarUploadConfig?: UploadImageConfig;
  photoLibrary?: {
    title: string;
    url: string;
    icon: string;
  };
  bottomSheet?: {
    photoLibraryTitle?: string;
    photoLibraryIcon?: string;
    uploadTitle?: string;
    uploadIcon?: string;
  };

  actionMap?: FormLayoutItemActionMap;

  subAvatars?: {
    avatarUrl: string;
    name: string;
  }[];
};

export type FormLayoutTextItem = FormLayoutBaseItem & {
  type: 'text' | 'textarea' | 'number' | 'email';
  minRows?: number;
  note?: FormLayoutNote;
  showWordCount?: boolean;
};

export type FormLayoutFormulaItem = FormLayoutBaseItem & {
  type: 'formula';
  options: VariableOption[];
};

export type FormLayoutDateItem = FormLayoutBaseItem & {
  type: 'date';
  maxDate?: Date | string | number | null;
  minDate?: Date | string | number | null;
  dateFormat?: string;
};

export type FormLayoutAutoCompleteItem = FormLayoutBaseItem & {
  type: 'autoComplete';
  options: { label: string; value: string | number }[];
  isOptionsAsync?: boolean;
};

export type FormLayoutSelectItem = FormLayoutBaseItem & {
  type: 'select';
  options:
    | (
        | {
            label: string;
            value: string | number;
            icon?: {
              type: 'icon' | 'image';
              value: string;
            };
            hint?: string;
            optionClickAction?: string;
          }
        | {
            label: string;
            type: 'subheader';
          }
      )[];
};

export type FormLayoutRadioGroup = FormLayoutBaseItem & {
  type: 'radioGroup';
  options: {
    label: string;
    value: string | number;
    hint?: string;
  }[];
};

export type FormLayoutCheckboxItem = FormLayoutBaseItem & {
  type: 'checkbox';
  labelPosition?: 'end' | 'start';
};

export type FormLayoutSwitchItem = FormLayoutBaseItem & {
  type: 'switch';
  labelPosition?: 'end' | 'start';
  tooltip?:
    | false
    | {
        type: 'simple';
        content: string;
      }
    | {
        type: 'info';
        title: string;
        titleIcon: string;
        content: string;
        placement?: TooltipProps['placement'];
      };
};

export type FormLayoutSquareCheckboxGroupItem = {
  icon: string;
  value: string | number | boolean;
};
export type FormLayoutSquareCheckboxGroup = Omit<
  FormLayoutBaseItem,
  'label'
> & {
  type: 'squareCheckboxGroup';
  items: FormLayoutSquareCheckboxGroupItem[];
};

export type FormLayoutChipOptionGroupItem = Omit<
  FormLayoutBaseItem,
  'label'
> & {
  type: 'chipOptionGroup';
  options: { label: string; value: string | number }[];
};

export type FormLayoutMultipleSelectInputItem = {
  label: string;
  value: string;
};
export type FormLayoutMultipleSelectInput = FormLayoutBaseItem & {
  type: 'multipleSelectInput';
  field: FilterOptionsField;
};

export type FormLayoutItem =
  | FormLayoutGroup
  | FormLayoutGapItem
  | FormLayoutDividerItem
  | FormLayoutActionsItem
  | FormLayoutAvatarItem
  | FormLayoutTextItem
  | FormLayoutDateItem
  | FormLayoutAutoCompleteItem
  | FormLayoutSelectItem
  | FormLayoutCheckboxItem
  | FormLayoutSwitchItem
  | FormLayoutSquareCheckboxGroup
  | FormLayoutRadioGroup
  | FormLayoutFormulaItem
  | FormLayoutChipOptionGroupItem
  | FormLayoutMultipleSelectInput;

export interface FormLayoutGroup {
  title?: string;
  content?: string;
  gap?: number; // input gap
  className?: string;
  type: 'group';
  items: FormLayoutItem[];
}

export interface FormLayoutSettings {
  mode?: 'onBlur' | 'onChange' | 'onSubmit' | 'all' | 'onTouched';
  reValidateMode?: 'onBlur' | 'onChange' | 'onSubmit';
  touchedFields?:
    | Partial<{
        [x: string]: any;
      }>
    | 'all';
  disableTriggerWhenRouting?: boolean;

  showActionButton?: boolean;
  triggerSubmitMode?: 'onBlur' | 'onChange';
  triggerAllUnmounting?: boolean;
  gridTemplateColumns?: string | (string | number | null)[];
}

export interface FormLayoutLoadingConfig {
  layout: 'form-layout-skeleton';
}

export interface FormLayoutConfig {
  layout: 'form-layout';
  settings?: FormLayoutSettings;
  mainItems: FormLayoutGroup[];
  defaultValues?: Record<string, any>;
  subItems?: FormLayoutGroup[];
  formAction?: IaAction;
  formStateChanged?: string;
  formValuesChanged?: string;
}
