import type { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Provider } from 'react-redux';
import type { NextPage } from 'next';
import type { AppProps } from 'next/app';
import Router from 'next/router';
import AppThreadProvider from '@app/web/src/components/AppThreadProvider';
import CircleLoading from '@app/web/src/components/CircleLoading';
import RouteHistoryProvider from '@app/web/src/components/RouteHistory/RouteHistoryProvider';
import SEOHeader from '@app/web/src/components/SEOHeader';
import ThemeProvider from '@app/web/src/components/ThemeProvider';
import { CloudfrontProvider } from '@app/web/src/contexts/cloudfront';
import { persistor, store } from '@app/web/src/store';
import CreateQuizFormProvider from '@app/web/src/widgets/PracticePage/CreateQuizForm/CreateQuizFormProvider'; // TODO: reorganize folder's path
import { UserProvider } from '@auth0/nextjs-auth0/client';
import type { EmotionCache } from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { GuestProvider, QueueWorkerContextProvider } from '@front/helper';
import { ConfirmPopup, Toaster } from '@front/ui';
import { ExamMode } from '@lib/web/apis';
import {
  SharedUserProvider,
  ShortIdPoolContextProvider,
} from '@lib/web/contexts';
import { GoogleTagManager } from '@next/third-parties/google';
import nProgress from 'nprogress';
import { PersistGate } from 'redux-persist/integration/react';

import '../i18n';
import '@lib/web/composer/style';
import '@lib/web/thread/style';

import CommonBlocker from '../src/components/CommonBlocker';
import FileTasks from '../src/components/FileTasks';
import LoginListener from '../src/components/LoginListener';
import { PaymentLinkErrorListener } from '../src/components/PaymentLinkErrorListener';
import ReferQueryKeeper from '../src/components/ReferQueryKeeper';
import { SettingsConsumer, SettingsProvider } from '../src/contexts/settings';
import createTheme from '../src/theme';
import { createEmotionCache } from '../src/utils/createEmotionCache';

import 'simplebar/dist/simplebar.min.css';
import 'react-phone-input-2/lib/material.css';
import '@blocknote/core/style.css';
import '../styles/app.css';
import 'stream-chat-react/dist/css/v2/index.layout.css';

type EnhancedAppProps = AppProps & {
  Component: NextPage;
  emotionCache: EmotionCache;
};

Router.events.on('routeChangeStart', nProgress.start);

Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

const clientSideEmotionCache = createEmotionCache();

const App: FC<EnhancedAppProps> = (props) => {
  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;
  const { ready } = useTranslation();

  const getLayout = Component.getLayout ?? ((page) => page);
  const pageHead = Component.getHead ? (
    Component.getHead(pageProps)
  ) : (
    <SEOHeader />
  );

  if (Component.isClearPage)
    return (
      <GuestProvider>
        <UserProvider>
          {/* do not add ready ('ready && getLayout..') here, because i18n ready is a client side data, which cause next hydrate weird with duplicated content */}
          {getLayout(<Component {...pageProps} />, pageProps)}
        </UserProvider>
      </GuestProvider>
    );

  if (Component.isSocialMediaPage) {
    return (
      <GuestProvider>
        <UserProvider>
          <Provider store={store}>
            <ThemeProvider
              theme={createTheme({
                mode:
                  parseInt(pageProps.mode, 10) === ExamMode.MockExam
                    ? 'light'
                    : 'dark',
              })}
            >
              <CommonBlocker />
              <Component {...pageProps} />
            </ThemeProvider>
          </Provider>
        </UserProvider>
      </GuestProvider>
    );
  }

  const gtmProperties = process.env.NEXT_PUBLIC_GTM_ID &&
    process.env.NEXT_PUBLIC_GTM_AUTH &&
    process.env.NEXT_PUBLIC_GTM_PREVIEW && {
      gtmId: process.env.NEXT_PUBLIC_GTM_ID,
      auth: process.env.NEXT_PUBLIC_GTM_AUTH,
      preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
    };

  return (
    <CloudfrontProvider>
      <RouteHistoryProvider>
        <GuestProvider>
          <UserProvider>
            {pageHead}
            <SharedUserProvider>
              <Provider store={store}>
                <PersistGate loading={null} persistor={persistor}>
                  <CacheProvider value={emotionCache}>
                    <SettingsProvider>
                      <SettingsConsumer>
                        {({ settings }) => (
                          <ThemeProvider
                            theme={createTheme({
                              direction: settings.direction,
                              responsiveFontSizes: settings.responsiveFontSizes,
                              mode: settings.theme,
                            })}
                          >
                            <CreateQuizFormProvider>
                              {gtmProperties && (
                                <GoogleTagManager {...gtmProperties} />
                              )}
                              <QueueWorkerContextProvider>
                                <ShortIdPoolContextProvider>
                                  <AppThreadProvider>
                                    <LoginListener />
                                    <ReferQueryKeeper />
                                    <Toaster />
                                    <ConfirmPopup />
                                    <CommonBlocker />
                                    <FileTasks />
                                    <PaymentLinkErrorListener />
                                    {ready ? (
                                      getLayout(
                                        <Component {...pageProps} />,
                                        pageProps
                                      )
                                    ) : (
                                      <CircleLoading
                                        message="Personalizing AI"
                                        opacity={1}
                                      />
                                    )}
                                  </AppThreadProvider>
                                </ShortIdPoolContextProvider>
                              </QueueWorkerContextProvider>
                            </CreateQuizFormProvider>
                          </ThemeProvider>
                        )}
                      </SettingsConsumer>
                    </SettingsProvider>
                  </CacheProvider>
                </PersistGate>
              </Provider>
            </SharedUserProvider>
          </UserProvider>
        </GuestProvider>
      </RouteHistoryProvider>
    </CloudfrontProvider>
  );
};

export default App;
