import { NotificationCtaConfig } from '../../types';

import NotificationCtaBigCardNumber from './NotificationCtaBigCardNumber';
import NotificationCtaButton from './NotificationCtaButton';
import NotificationCustomize from './NotificationCustomize';

type NotificationCtaProps = NotificationCtaConfig;

export default function NotificationCta({ ...rest }: NotificationCtaProps) {
  if (rest.type === 'button') {
    return <NotificationCtaButton {...rest} />;
  }
  if (rest.type === 'bigCardNumber') {
    return <NotificationCtaBigCardNumber {...rest} />;
  }
  if (rest.type === 'customize') {
    return <NotificationCustomize {...rest} />;
  }
  return null;
}
